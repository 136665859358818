import React from 'react'
import { firstBy } from 'thenby'
import classnames from 'classnames'
import Carousel from 'nuka-carousel'
import { useSelector, useDispatch } from 'react-redux'

import {
  selectQuestionObjective,
  newQuestion
} from 'redux/actions/questionsAction'

import './QuestionsObjectives.scss'

const QuestionsObjectives = ({ setTitle, setDescription }) => {
  const dispatch = useDispatch()

  const { objective } = useSelector(state => state)
  const question = useSelector(state => state.question)
  const { questions, selectedObjective } = question
  const { objectives } = objective

  const renderObjectives = objective => {
    const classes = classnames('Questions-flex', {
      selected: objective.id === selectedObjective.id
    })

    const numberOfQuestions = questions.filter(
      question => question.objective_id === objective.id
    ).length

    const onObjectiveClicked = () => {
      if (objective.id !== selectedObjective.id) {
        setTitle('')
        setDescription('')
        newQuestion(dispatch)
        selectQuestionObjective(objective, dispatch)
      }
    }

    return (
      <div key={objective.id} className={classes} onClick={onObjectiveClicked}>
        <h3>{objective.shortName}</h3>
        {questions && (
          <span className='Questions-number-of-questions text-center'>
            {numberOfQuestions}
          </span>
        )}
      </div>
    )
  }

  return (
    <div className='Questions-header'>
      <Carousel
        withoutControls
        cellSpacing={20}
        slidesToShow={4}
        slidesToScroll={4}
      >
        {objectives
          .sort(firstBy('area_id').thenBy('shortName'))
          .map(renderObjectives)}
      </Carousel>
    </div>
  )
}

export default QuestionsObjectives
