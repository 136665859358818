import { Close } from '@mui/icons-material'
import { getLoggedInUser } from 'auth'
import { React, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { setObjectives } from 'redux/actions/objectivesAction'
import { deleteObjectiveRecommendation } from 'redux/actions/recommendationActions'

import './ObjectivesList.scss'

const ObjectivesList = ({ setSelectedObjective, selectedObjective }) => {
  const { objective, assessment } = useSelector(state => state)
  const { objectives } = objective
  const dispatch = useDispatch()
  const [t] = useTranslation()
  const [localSelectedObjective, setLocalSelectedObjective] = useState(
    objectives[0]
  )
  const user = getLoggedInUser()
  const organizationId = user.organization_id
  useEffect(() => {
    setLocalSelectedObjective(selectedObjective)
  }, [selectedObjective])

  useEffect(() => {
    const fetchData = async () => {
      if (objectives[0].assessment_id) {
        await setObjectives(objectives[0].assessment_id, dispatch)
      }
    }
    fetchData()
  }, [dispatch])

  const handleDeleteObjectiveRecommendation = async (
    recommendation,
    objective
  ) => {
    const data = {
      organizationId: organizationId,
      recommendationId: recommendation.id,
      objectiveId: objective.id
    }
    await deleteObjectiveRecommendation(data)
    await setObjectives(objective.assessment_id, dispatch)
  }

  const renderRecommendation = (recommendation, objective) => {
    return (
      <div
        className='ObjectivesList-recommendation'
        key={`ObjectivesList-recommendation-${recommendation.id}`}
      >
        <span className='ObjectivesList-recommendation-title'>
          {recommendation.title}
        </span>
        <Close
          onClick={() => {
            handleDeleteObjectiveRecommendation(recommendation, objective)
          }}
          title='Remove'
          className='ObjectivesList-recommendation-remove'
        />
      </div>
    )
  }

  const renderObjective = objective => {
    return (
      <button
        className={
          localSelectedObjective?.id === objective?.id
            ? 'ObjectivesList-objective ObjectivesList-selected'
            : 'ObjectivesList-objective'
        }
        key={`ObjectivesList-objective-${objective.id}`}
        id={`ObjectivesList-objective-${objective.id}`}
        disabled={assessment.isDeployed}
        onClick={async () => {
          setSelectedObjective(objective)
        }}
      >
        <span className='ObjectivesList-objective-title'>
          {objective.shortName}
        </span>
        {objective?.recommendations?.length > 0 ? (
          <div className='ObjectivesList-objective-recommendations'>
            {objective.recommendations.map(rec => {
              return renderRecommendation(rec, objective)
            })}
          </div>
        ) : null}
      </button>
    )
  }

  return (
    <div className='ObjectivesList animated fadeInDown'>
      <span className='ObjectivesList-title'> {t('label.objectives')} </span>
      <div className='ObjectivesList-objectives z-depth-1 animated fadeInDown'>
        {objectives?.map(renderObjective)}
      </div>
    </div>
  )
}

export default ObjectivesList
