import { useTranslation } from 'react-i18next'
import Lottie from 'react-lottie'

import animationData from './404.json'

const NotFound = () => {
  const [t] = useTranslation()

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }

  return (
    <div>
      <Lottie options={defaultOptions} height={400} width={400} />
      <div className='text-center'>
        <h3>{t('page_not_found.title')}</h3>
      </div>
    </div>
  )
}

export default NotFound
