import Validator from 'validator'
import isEmpty from 'lodash/isEmpty'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import atob from 'atob'
import jwt_decode from 'jwt-decode'

import PersonIcon from '@material-ui/icons/Person'
import PeopleIcon from '@material-ui/icons/People'
import EmailIcon from '@material-ui/icons/Email'
import LockIcon from '@material-ui/icons/Lock'
import VisibilityIcon from '@material-ui/icons/Visibility'

import '../../../styles/loader.scss'
import './SignUpPage.scss'
import { createUser, sendEmailVerification } from 'redux/actions/userActions'
import { useLocation } from 'react-router-dom'
import {
  createOrganizationForAssistedSignup,
  getFormLinkForAssistedSignup,
  getOrganizationByName
} from 'redux/actions/organizationActions'
// import btoa from 'btoa'

const TOKEN_KEY = 'token'
const USER_KEY = 'user'

const SignUpPage = () => {
  const [personaId, setPersonaId] = useState(null)
  const [nodes, setNodes] = useState([])
  const [organizationId, setOrganizationId] = useState(null)
  const [firstName, setFirstName] = useState('')
  const [surName, setSurName] = useState('')
  const [role, setRole] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [isInvalid, setIsInvalid] = useState(false)
  const [isSignedinWithSocial, setIsSignedinWithSocial] = useState(false)
  const [hasSubmitted, setHasSubmitted] = useState(false)
  const [academySignupTemplateId, setAcademySignupTemplateId] = useState(null)
  const [errors, setErrors] = useState(null)
  const dispatch = useDispatch()
  const [t] = useTranslation()
  const google = window.google

  const location = useLocation()
  const hashPosition = location.pathname.lastIndexOf('signup/')
  const hashData =
    hashPosition > -1 ? location.pathname.substr(hashPosition + 7) : null

  const handleCallbackResponse = response => {
    const userObject = jwt_decode(response.credential)
    if (userObject.email_verified) {
      setFirstName(userObject.given_name)
      setSurName(userObject.family_name)
      setEmail(userObject.email)
      setIsSignedinWithSocial(true)
    }
  }

  // use this sectionn to get template id object hash
  // const jsonAsString = JSON.stringify({
  //   templateId: 3182
  // })

  // const object = btoa(jsonAsString)
  // console.log('object', object)

  useEffect(() => {
    if (google) {
      google?.accounts?.id?.initialize({
        client_id:
          '313362640847-0flg21ovk62uhqe57apurl0aa8ifhm1d.apps.googleusercontent.com',
        callback: handleCallbackResponse
      })
      google.accounts.id.renderButton(document.getElementById('googleSignIn'), {
        theme: 'outline',
        size: 'large',
        width: '400px'
      })
    }
  }, [])

  useEffect(() => {
    try {
      const parsedHashData = hashData ? JSON.parse(atob(hashData)) : null
      if (parsedHashData?.organizationId) {
        setOrganizationId(parsedHashData.organizationId)
        setRole(parsedHashData.userRole)
        setFirstName(parsedHashData.firstName)
        setEmail(parsedHashData.email)
        setPersonaId(parsedHashData.personaId)
        setNodes(parsedHashData.nodes)
      } else if (parsedHashData?.verifiedUserData) {
        setIsLoading(true)
        handleSignUpVerifiedUser(parsedHashData?.verifiedUserData)
        setIsLoading(false)
      } else if (parsedHashData?.templateId) {
        setAcademySignupTemplateId(parsedHashData.templateId)
      }
    } catch (error) {
      window.toastr.error('signup_page.errors.invalid_params')
    }
    setIsLoading(false)
  }, [hashData])

  const clearForm = () => {
    setIsSignedinWithSocial(false)
    setFirstName('')
    setSurName('')
    setEmail('')
    setPassword('')
    setPasswordConfirmation('')
    setErrors(null)
    setAcademySignupTemplateId(null)

    document.getElementById('firstName').focus()
  }

  useEffect(() => {
    setIsInvalid((!organizationId, role) || !academySignupTemplateId)
  }, [organizationId, role, academySignupTemplateId])

  const removeError = e => {
    if (e.target.value.trim() !== '' && errors && errors[e.target.name]) {
      delete errors[e.target.name]
    }
  }

  const onChange = e => {
    switch (e.target.name) {
      case 'firstName':
        setFirstName(e.target.value)
        break
      case 'surName':
        setSurName(e.target.value)
        break
      case 'email':
        setEmail(e.target.value)
        break
      case 'password':
        setPassword(e.target.value)
        break
      case 'passwordConfirmation':
        setPasswordConfirmation(e.target.value)
        break
      default:
        break
    }
    removeError(e)
  }

  const validateInput = () => {
    const errors = {}
    if (!firstName || firstName === null) {
      errors.firstName = t('signup_page.errors.first_name_required')
    }

    if (!surName || surName === null) {
      errors.surName = t('signup_page.errors.surname_required')
    }

    if (!email || !Validator.isEmail(email)) {
      errors.email = t('signup_page.errors.email_required')
    }

    if (!password || password === null) {
      errors.password = t('signup_page.errors.password_required')
    }

    if (!passwordConfirmation || passwordConfirmation === null) {
      errors.passwordConfirmation = t(
        'signup_page.errors.password_confirmation_required'
      )
    }

    if (
      !password ||
      !passwordConfirmation ||
      !Validator.equals(password, passwordConfirmation)
    ) {
      errors.passwordConfirmation = t('signup_page.errors.password_must_match')
    }

    return {
      errors
    }
  }

  const handleSignUpVerifiedUser = async userData => {
    setIsLoading(true)
    const { firstName, surName, email, password, templateId } = userData
    const foundOrganization = await getOrganizationByName(
      'Organização ' + firstName + ' ' + surName
    )
    if (foundOrganization) {
      // window.location.href = `/form?value=${hashUrl}`
      const { hashUrl } = await getFormLinkForAssistedSignup({
        organization: foundOrganization,
        templateId: templateId
      })
      window.location.href = `/form?value=${hashUrl}`
      setIsLoading(false)
    } else {
      const org = {
        name: 'Organização ' + firstName + ' ' + surName,
        billingEmail: email,
        contactName: firstName + surName,
        phoneNumber: '3129842',
        suspended: false
      }

      const user = {
        firstName,
        surName,
        email,
        avatarUrl: '',
        password,
        passwordConfirmation,
        socialLogin: isSignedinWithSocial
      }

      const requestData = {
        organization: org,
        templateId: templateId,
        user: user
      }

      const { token, hashUrl, createdUser } =
        await createOrganizationForAssistedSignup(requestData)

      window.localStorage[TOKEN_KEY] = token
      window.localStorage[USER_KEY] = JSON.stringify(createdUser)
      window.location.href = `/form?value=${hashUrl}`
      setIsLoading(false)
    }
  }

  const submit = async e => {
    e && e.stopPropagation()
    setIsLoading(true)
    setErrors(null)

    const { errors } = validateInput()

    if (!isEmpty(errors)) {
      setErrors(errors)
      setIsLoading(false)
      return
    }

    const newUser = {
      organization_id: organizationId,
      firstName,
      surName,
      role,
      email,
      avatarUrl: '',
      password,
      passwordConfirmation,
      personaId,
      nodes
    }

    try {
      if (!hasSubmitted && !!academySignupTemplateId) {
        setHasSubmitted(true)
        const userDataBundle = {
          firstName,
          surName,
          email,
          password,
          templateId: academySignupTemplateId
        }
        await sendEmailVerification(userDataBundle)
        setIsLoading(false)
        return
      } else if (organizationId) {
        await createUser(newUser, dispatch)
        window.toastr.success(t('signup_page.success'))
        window.location.href = '/login'
      }
    } catch (error) {
      setErrors(error)
      const e = error?.response?.data?.errors
      Object.keys(e).forEach(key => {
        window.toastr.error(e[key])
      })
      setIsLoading(false)
    }
    clearForm()
    setIsLoading(false)
  }

  return (
    <>
      {isLoading ? (
        <div className='d-flex flex-column justify-content-center align-items-center v100 w-100 h-100'>
          <div className='loader1' />
        </div>
      ) : (
        <div className='SignUpPage animated fadeIn d-flex align-items-center justify-content-center'>
          <div className='SignUpPage-container z-depth-4'>
            <div className='SignUpPage-header'>
              <span>{t('signup_page.header')}</span>
            </div>

            <div className='SignUpPage-body'>
              {!hasSubmitted ? (
                <>
                  {!isSignedinWithSocial ? (
                    <>
                      <div className='SignUpPage-field-container'>
                        <span className='ml-2 mb-2'>Sign in with Google:</span>
                        <div className='googleSignIn mt-3'>
                          <div id='googleSignIn'> </div>
                        </div>
                      </div>
                      <div className='SignUpPage-field-container'>
                        <div className='d-flex align-items-center'>
                          <PersonIcon className='ml-1' />
                          <span className='ml-2'>
                            {t('signup_page.fields.first_name')}
                          </span>
                        </div>
                        <input
                          className={`SignUpPage-input ${
                            errors?.firstName
                              ? 'SignUpPage-validation-error'
                              : ''
                          }`}
                          autoFocus
                          type='text'
                          name='firstName'
                          id='firstName'
                          placeholder={t(
                            'signup_page.fields.first_name_placeholder'
                          )}
                          value={firstName}
                          onChange={onChange}
                        />
                        {errors?.firstName && (
                          <p className='mt-1'>
                            <label htmlFor='firstName'>
                              <small className='error'>
                                {errors.firstName}
                              </small>
                            </label>
                          </p>
                        )}
                      </div>

                      <div className='SignUpPage-field-container'>
                        <div className='d-flex align-items-center'>
                          <PeopleIcon className='ml-1' />
                          <span className='ml-2'>
                            {t('signup_page.fields.surname')}
                          </span>
                        </div>
                        <input
                          className={`SignUpPage-input ${
                            errors?.surName ? 'SignUpPage-validation-error' : ''
                          }`}
                          type='text'
                          name='surName'
                          id='surName'
                          placeholder={t(
                            'signup_page.fields.surname_placeholder'
                          )}
                          value={surName}
                          onChange={onChange}
                        />
                        {errors?.surName && (
                          <p className='mt-1'>
                            <label htmlFor='surName'>
                              <small className='error'>{errors.surName}</small>
                            </label>
                          </p>
                        )}
                      </div>

                      <div className='SignUpPage-field-container'>
                        <div className='d-flex align-items-center'>
                          <EmailIcon className='ml-1' />
                          <span className='ml-2'>
                            {t('signup_page.fields.email')}
                          </span>
                        </div>
                        <input
                          className={`SignUpPage-input ${
                            errors?.email ? 'SignUpPage-validation-error' : ''
                          }`}
                          type='text'
                          name='email'
                          id='email'
                          placeholder={t(
                            'signup_page.fields.email_placeholder'
                          )}
                          value={email}
                          onChange={onChange}
                        />
                        {errors?.email && (
                          <p className='mt-1'>
                            <label htmlFor='email'>
                              <small className='error'>{errors.email}</small>
                            </label>
                          </p>
                        )}
                      </div>
                    </>
                  ) : (
                    <div className='SignUpPage-field-container'>
                      <div className='SignUpPage-field-description'>
                        Please enter a backup password below.
                      </div>
                    </div>
                  )}
                  <div className='SignUpPage-field-container'>
                    <div className='d-flex align-items-center'>
                      <LockIcon className='ml-1' />
                      <span className='ml-2'>
                        {t('signup_page.fields.password')}
                      </span>
                    </div>
                    <div className='input-wrapper'>
                      <input
                        className={`SignUpPage-input ${
                          errors?.password ? 'SignUpPage-validation-error' : ''
                        }`}
                        type='password'
                        name='password'
                        id='password'
                        placeholder={t(
                          'signup_page.fields.password_placeholder'
                        )}
                        value={password}
                        onChange={onChange}
                        autoComplete='new-password'
                      />
                      <VisibilityIcon
                        className='visitility-icon'
                        onClick={() => {
                          const x = document.getElementById('password')
                          if (x.type === 'password') {
                            x.type = 'text'
                          } else {
                            x.type = 'password'
                          }
                        }}
                      />
                    </div>
                    {errors?.password && (
                      <p className='mt-1'>
                        <label htmlFor='password'>
                          <small className='error'>{errors.password}</small>
                        </label>
                      </p>
                    )}
                  </div>

                  <div className='SignUpPage-field-container'>
                    <div className='d-flex align-items-center'>
                      <LockIcon className='ml-1' />
                      <span className='ml-2'>
                        {t('signup_page.fields.password_confirmation')}
                      </span>
                    </div>
                    <div className='input-wrapper'>
                      <input
                        className={`SignUpPage-input ${
                          errors?.passwordConfirmation
                            ? 'SignUpPage-validation-error'
                            : ''
                        }`}
                        type='password'
                        name='passwordConfirmation'
                        id='passwordConfirmation'
                        placeholder={t(
                          'signup_page.fields.password_confirmation_placeholder'
                        )}
                        value={passwordConfirmation}
                        onChange={onChange}
                      />
                      <VisibilityIcon
                        className='visitility-icon'
                        onClick={() => {
                          const x = document.getElementById(
                            'passwordConfirmation'
                          )
                          if (x.type === 'password') {
                            x.type = 'text'
                          } else {
                            x.type = 'password'
                          }
                        }}
                      />
                    </div>

                    {errors?.passwordConfirmation && (
                      <p className='mt-1'>
                        <label htmlFor='passwordConfirmation'>
                          <small className='error'>
                            {errors.passwordConfirmation}
                          </small>
                        </label>
                      </p>
                    )}
                    <div className='SignUpPage-button-group'>
                      <button
                        className='SignUpPage-createButton'
                        disabled={isInvalid || isLoading || hasSubmitted}
                        onClick={submit}
                      >
                        {t('label.submit')}
                      </button>
                    </div>
                  </div>
                </>
              ) : (
                <div className='d-flex align-items-center flex-column p-3'>
                  <div className='d-flex align-items-center'>
                    <span className='p-3 text-center'>
                      Por favor verifique sua caixa postal, enviamos um email
                      para verificarmos sua identidade.
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default SignUpPage
