import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { trim } from 'lodash'
import { api } from 'redux/actions/axios'
import * as auth from '../../../auth'
import CameraAltIcon from '@material-ui/icons/CameraAlt'

import './AccountFormPage.scss'
import { uploadProfilePicture } from 'redux/actions/userActions'
import { useDispatch } from 'react-redux'
import { getOrganizationById } from 'redux/actions/organizationActions'

const AccountFormPage = () => {
  const [t] = useTranslation()
  const dispatch = useDispatch()

  const loggedInUser = auth.getLoggedInUser()
  const [currentOrganization, setCurrentOrganization] = useState(null)
  const [firstName, setFirstName] = useState(loggedInUser.firstName)
  const [surName, setSurname] = useState(loggedInUser.surName)
  const [email, setEmail] = useState(loggedInUser.email)
  const [currentPassword, setCurrentPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')
  const [avatarUrl, setAvatarUrl] = useState(loggedInUser.avatarUrl)
  const [selectedFile, setSelectedFile] = useState(null)

  const fileUpload = useRef(null)

  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      if (currentOrganization === null) {
        const { organization } = await getOrganizationById(
          loggedInUser.organization_id
        )
        setCurrentOrganization(organization)
      }
    }
    // setIsLoading(true)
    fetchData()
    // setIsLoading(false)
  }, [loggedInUser, dispatch])

  const validatePasswords = (
    currentPassword,
    newPassword,
    passwordConfirmation
  ) => {
    if (trim(currentPassword).length > 0) {
      if (trim(newPassword).length === 0) {
        throw new Error(t('account_form_page.error.new_password'))
      } else if (trim(passwordConfirmation).length === 0) {
        throw new Error(t('account_form_page.error.password_confirmation'))
      } else if (newPassword !== passwordConfirmation) {
        throw new Error(t('account_form_page.error.password_doesnt_match'))
      }
    }

    return {
      currentPassword: currentPassword.length > 0 ? currentPassword : null,
      newPassword: newPassword.length > 0 ? newPassword : null,
      passwordConfirmation:
        passwordConfirmation.length > 0 ? passwordConfirmation : null
    }
  }

  const handleFormSubmit = async e => {
    e.preventDefault()

    const payload = {
      firstName,
      surName,
      email
    }

    try {
      setIsLoading(true)
      if (selectedFile) {
        const formData = new FormData()
        formData.append('file', selectedFile)
        formData.append('name', `profile-picture-user-${loggedInUser.id}`)
        const config = {
          headers: {
            'content-type': 'multipart/form-data'
          }
        }
        const fileUrl = await uploadProfilePicture(formData, config)
        payload.avatarUrl = fileUrl
        setAvatarUrl(fileUrl)
      }
    } catch (e) {
      console.error(e)
      window.toastr.error('Error uploading image')
    }
    setIsLoading(false)

    try {
      const passwordData = validatePasswords(
        currentPassword,
        newPassword,
        passwordConfirmation
      )

      payload.currentPassword = passwordData.currentPassword
      payload.newPassword = passwordData.newPassword
      payload.passwordConfirmation = passwordData.passwordConfirmation
    } catch (error) {
      window.toastr.error(error.message)
      return
    }

    try {
      setIsLoading(true)
      const response = await api.put('/users/current', payload)
      auth.update(response.data.user)
      window.toastr.success('Successfully updated user')
      setCurrentPassword('')
      setNewPassword('')
      setPasswordConfirmation('')
    } catch (e) {
      console.error(e)
      window.toastr.error('Email is already in use')
    }

    setIsLoading(false)
  }

  const handleSelectFile = async e => {
    const image = document.getElementById('output')
    image.src = URL.createObjectURL(e.target.files[0])

    setSelectedFile(e.target.files[0])
  }

  const onButtonClick = () => {
    return fileUpload.current.click()
  }

  return (
    <div className='AccountFormPage'>
      <div className='AccountFormPage-container'>
        <div className='AccountFormPage-header'>
          <h1>{t('account_form_page.edit_account')}</h1>
        </div>

        <div className='AccountFormPage-body'>
          <div className='AccountFormPage-left-panel'>
            <div className='AccountFormPage-profile-pic'>
              <label
                class='AccountFormPage-profile-pic-label'
                for='imgfile'
                onClick={onButtonClick}
                title='Change Profile Picture'
              >
                <CameraAltIcon className='AccountFormPage-camera' />
              </label>
              <input
                ref={fileUpload}
                multiple
                name='file'
                type='file'
                style={{ display: 'none' }}
                className='AccountFormPage-button'
                accept='image/png, image/jpg, image/jpeg'
                onChange={e => {
                  handleSelectFile(e)
                }}
              />

              <img
                className='profile-pic'
                src={avatarUrl}
                data-toggle='tooltip'
                alt={t('navigation_bar.settings.alt')}
                onError={e => {
                  e.target.src = '/images/avatar.png'
                }}
                id='output'
              />
            </div>
            {t('account_form_page.profile_picture')}
            <div className={`AccountFormPage-role role-${loggedInUser.role}`}>
              {t(`tenant_user_profile.${loggedInUser.role}`)}
            </div>
            <span>{currentOrganization?.name}</span>
          </div>
          <div className='AccountFormPage-fields'>
            <div className='AccountFormPage-name'>
              <div className='AccountFormPage-row'>
                <label className='AccountFormPage-label'>
                  {t('account_form_page.fields.first_name')}
                </label>
                <input
                  className='AccountFormPage-user-input'
                  id='AccountFormPage-firstName'
                  value={firstName}
                  onChange={e => setFirstName(e.target.value)}
                  disabled={isLoading}
                  placeholder={t('account_form_page.placeholder.first_name')}
                  required
                  autoFocus
                />
              </div>

              <div className='AccountFormPage-row'>
                <label className='AccountFormPage-label'>
                  {t('account_form_page.fields.surname')}
                </label>
                <input
                  className='AccountFormPage-user-input'
                  id='AccountFormPage-surname'
                  value={surName}
                  onChange={e => setSurname(e.target.value)}
                  disabled={isLoading}
                  placeholder={t('account_form_page.placeholder.surname')}
                  required
                />
              </div>
            </div>

            <div className='AccountFormPage-row'>
              <label className='AccountFormPage-label'>
                {t('account_form_page.fields.email')}
              </label>
              <input
                className='AccountFormPage-user-input'
                id='AccountFormPage-email'
                value={email}
                onChange={e => setEmail(e.target.value)}
                disabled={isLoading}
                placeholder={t('account_form_page.placeholder.email')}
                type='email'
                required
              />
            </div>

            <div className='AccountFormPage-row'>
              <label className='AccountFormPage-label'>
                {t('account_form_page.fields.current_password')}
              </label>
              <input
                className='AccountFormPage-user-input'
                id='AccountFormPage-currentPassword'
                value={currentPassword}
                onChange={e => setCurrentPassword(e.target.value)}
                disabled={isLoading}
                name='currentPassword'
                type='password'
              />
            </div>

            <div className='AccountFormPage-name'>
              <div className='AccountFormPage-row'>
                <label className='AccountFormPage-label'>
                  {t('account_form_page.fields.new_password')}
                </label>
                <input
                  className='AccountFormPage-user-input'
                  id='AccountFormPage-newPassword'
                  value={newPassword}
                  onChange={e => setNewPassword(e.target.value)}
                  disabled={isLoading}
                  name='newPassword'
                  type='password'
                />
              </div>

              <div className='AccountFormPage-row'>
                <label className='AccountFormPage-label'>
                  {t('account_form_page.fields.confirm_new_password')}
                </label>
                <input
                  className='AccountFormPage-user-input'
                  id='AccountFormPage-confirmNewPassword'
                  value={passwordConfirmation}
                  onChange={e => setPasswordConfirmation(e.target.value)}
                  disabled={isLoading}
                  name='confirmNewPassword'
                  type='password'
                />
              </div>
            </div>
          </div>
        </div>

        <div className='AccountFormPage-footer'>
          <input
            className='AccountFormPage-submit'
            type='submit'
            disabled={isLoading || !firstName || !surName || !email}
            onClick={e => handleFormSubmit(e)}
          />
        </div>
      </div>
    </div>
  )
}

export default AccountFormPage
