import React from 'react'
import { isEmpty } from 'lodash'
import atob from 'atob'
import { useTranslation } from 'react-i18next'

import AnswerForm from 'components/AnswerForm/AnswerForm'

import useQuery from 'hooks/useQuery'

const AnswerFormPage = () => {
  const [t] = useTranslation()

  const parseParams = params => {
    const value = params.get('value')
    if (isEmpty(value)) {
      return { userId: null, assessmentId: null }
    }

    try {
      const input = atob(value) // decode Base64 value
      const { userId, assessmentId } = JSON.parse(input)
      return { userId, assessmentId }
    } catch (exception) {
      console.error(exception)
      return { userId: null, assessmentId: null }
    }
  }

  const queryParams = useQuery()
  const parsedParams = parseParams(queryParams)

  if (parsedParams.userId !== null && parsedParams.assessmentId !== null) {
    const { userId, assessmentId } = parsedParams
    return <AnswerForm userId={userId} assessmentId={assessmentId} />
  } else {
    window.toastr.error(t('answer_form_page.errors.invalid_params'))
    return (
      <div className='d-flex align-items-center justify-content-center container-fluid mt-4 p-4 red white-text h2-responsive'>
        <h1>{t('answer_form_page.errors.invalid_params')}</h1>
      </div>
    )
  }
}

export default AnswerFormPage
