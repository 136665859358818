import './FormTextInput.scss'

const FormTextInput = ({
  id,
  type = 'text',
  value,
  onChange,
  required,
  autoFocus,
  pattern,
  onBlur,
  disabled
}) => {
  return (
    <input
      id={id}
      className='FormTextInput'
      type={type}
      value={value}
      onBlur={onBlur}
      onChange={onChange}
      required={required}
      autoFocus={autoFocus}
      pattern={pattern}
      disabled={disabled}
    />
  )
}

export default FormTextInput
