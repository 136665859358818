import { values } from 'lodash'
import { firstBy } from 'thenby'

export const byPosition = (a, b) => {
  if (a.position < b.position) {
    return -1
  }
  if (a.position > b.position) {
    return 1
  }
  return 0
}

export const prepareAssessmentData = (
  assessment,
  value,
  maturityIndex,
  node,
  assessmentMode,
  user
) => {
  const label = node
    ? node.nodeType.name.concat(` - ${node.name} - `).concat(assessment.name)
    : user
    ? `${user?.firstName} ${user?.surName} - id: ${user?.id}`
    : assessment.name
  let chartData
  if (!user) {
    chartData = assessment.objectives
      .map(objective => {
        return {
          axis: objective.shortName,
          value:
            user && user.id
              ? 0
              : assessmentMode
              ? objective.assessmentValue
              : objective.maturityValue,
          assessmentValue: objective.assessmentValue,
          maturityValue: objective.maturityValue,
          areaId: objective.area_id,
          objectiveId: objective.id,
          areaName: objective.area.name,
          areaColor: objective.area.color
        }
      })
      .sort(firstBy('areaName').thenBy('axis'))
  } else if (user.id) {
    chartData = user.dataPoints.chartData
      .map(item => {
        return {
          ...item,
          value: assessmentMode ? item.assessmentValue : item.maturityValue,
          areaId: item.area_id,
          objectiveId: item.objective_id,
          areaName: item.areaName,
          areaColor: item.areaColor
        }
      })
      .sort(firstBy('areaName').thenBy('axis'))
  }

  return {
    id: assessment.id,
    template_id: assessment.template_id,
    value: value || null,
    maturityIndex,
    name: label,
    userRadar: (user && user.id) || false,
    maturityLevels: assessment.maturityLevels.sort(byPosition),
    maturityModel: assessment.maturityLevels
      .sort(byPosition)
      .map(level => level.name),
    chartData
  }
}

export const extractErrors = e => {
  const errors = []

  if (typeof e === 'string') {
    errors.push(e)
  } else if (e.response) {
    // Error is coming from API call
    const { data } = e.response

    if (typeof data === 'string') {
      errors.push(data)
    }

    if (data.errors) {
      values(data.errors).forEach(e => errors.push(e))
    }

    if (data.error) {
      if (typeof data.error === 'object') {
        errors.push(JSON.stringify(data.error))
      } else {
        errors.push(data.error)
      }
    }
  }

  return errors
}
