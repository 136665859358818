import { getLoggedInUser } from 'auth'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchPersonasByOrganizationId,
  togglePersonaForm,
  togglePersonaManage
} from 'redux/actions/personaActions'
import './ManagePersona.scss'
import '../../styles/loader.scss'

import EditIcon from '@material-ui/icons/Edit'
const ManagePersonas = () => {
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const user = getLoggedInUser()
  const organizationId = user.organization_id
  const { personas } = useSelector(state => state.persona)
  const [search, setSearch] = useState('')
  const [filteredPersonas, setFilteredPersonas] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    const fetchData = async () => {
      await fetchPersonasByOrganizationId(organizationId, dispatch)
    }
    setIsLoading(true)
    fetchData()
    setIsLoading(false)
  }, [organizationId, dispatch])

  useEffect(() => {
    setFilteredPersonas(
      personas.filter(p =>
        p.title.toLowerCase().includes(search.toLowerCase().trim())
      )
    )
  }, [search, personas])

  const onChange = e => {
    setSearch(e.target.value)
  }

  const editPersona = index => {
    togglePersonaForm(
      { personaFormToggled: true, persona: filteredPersonas[index] },
      dispatch
    )
  }

  const createPersona = () => {
    togglePersonaForm({ personaFormToggled: true, persona: null }, dispatch)
  }

  const renderPersonas = () => {
    return filteredPersonas.map((persona, index) => (
      <div key={persona.id} className='ManagePersona-tag'>
        <span className='persona-title'>{persona.title}</span>
        <span data-toggle='tooltip' title={t('persona_manage.archive')}>
          <EditIcon
            className='launch-icon'
            onClick={() => editPersona(index)}
          />
        </span>
      </div>
    ))
  }

  return (
    <div className='ManagePersona animated fadeIn'>
      <div className='ManagePersona-container'>
        <div className='ManagePersona-header'>
          <p>{t('persona_manage.header')}</p>
        </div>
        <div className='ManagePersona-list'>
          <div className='ManagePersona-input'>
            <input
              autoFocus
              type='text'
              maxLength={35}
              title='search'
              id='search'
              placeholder={t('label.search')}
              value={search}
              onChange={onChange}
              className='ManagePersona-input'
            />
            {search.length > 0 && (
              <small className='blink-me'>
                <span className='foundTitle '>
                  {filteredPersonas.length}&nbsp;
                  {t('persona_manage.found')}
                </span>
              </small>
            )}
          </div>

          <div className='ManagePersona-list-content'>
            {isLoading && (
              <div className='d-flex flex-column justify-content-center align-items-center v100'>
                <div className='loader1' />
              </div>
            )}

            {!isLoading && renderPersonas()}
          </div>
        </div>
        <div className='ManagePersona-button-group'>
          <button
            className='ManagePersona-createButton'
            onClick={e => {
              e.stopPropagation()
              createPersona()
            }}
          >
            {t('label.create')}
          </button>
          <button
            className='ManagePersona-cancelButton'
            onClick={() => {
              togglePersonaManage({ personaManageToggled: false }, dispatch)
            }}
          >
            {t('label.close')}
          </button>
        </div>
      </div>
    </div>
  )
}

export default ManagePersonas
