import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { Close } from '@material-ui/icons'

import { getLoggedInUser } from 'auth'

import {
  applyAssessment,
  isAssessmentDuplicated,
  isTemplateDeployed
} from 'redux/actions/assessmentActions'
import { fetchTreeData, toggleApplyForm } from 'redux/actions/treeActions'
import { Confirm } from 'notiflix'

import './SelectNode.scss'
import '../../../styles/loader.scss'

const SelectNode = ({
  isTemplateSelected,
  onSelectNodes,
  newAssessmentData
}) => {
  const [t] = useTranslation()

  const dispatch = useDispatch()
  const user = getLoggedInUser()
  const tree = useSelector(state => state.tree)
  const { nodes } = tree
  const [selectNodes, setSelectNodes] = useState(newAssessmentData.nodes)
  const [isLoading, setIsLoading] = useState(false)
  const [isInvalid, setIsInvalid] = useState(false)

  const nodeOptions = nodes
    ? nodes.filter(
        node => !selectNodes.map(selNode => selNode.id).includes(node.id)
      )
    : []

  const deleteNode = id => {
    const filterNodes = selectNodes.filter(node => node.id !== id)
    setSelectNodes(filterNodes)
    onSelectNodes(filterNodes)
  }

  Confirm.Init({
    nameColor: 'rgba(58, 157, 184, 1)',
    okButtonBackground: '#d4a302',
    backgroundColor: 'rgb(40,40,60)',
    borderRadius: '5px',
    messageColor: 'white',
    zindex: 9999,
    width: '400px',
    distance: '10px',
    titleMaxLength: 50,
    messageMaxLength: 600
  })

  const handleSelectNode = async value => {
    const isDuplicated = await isAssessmentDuplicated(
      newAssessmentData.shortName,
      value
    )
    if (!isDuplicated) {
      const newSelectedNodeState = [
        ...selectNodes,
        nodes.filter(node => String(node.id) === String(value))[0]
      ]
      onSelectNodes(newSelectedNodeState)
      setSelectNodes(newSelectedNodeState)
    } else {
      window.toastr.error(t('select_node.duplicated_node'))
    }
  }

  const anyDuplicated = async () => {
    return await Promise.all(
      newAssessmentData.nodes.map(async node => {
        return await isAssessmentDuplicated(
          newAssessmentData.shortName,
          node.id
        )
      })
    )
  }

  useEffect(() => {
    const status = newAssessmentData.nodes.length === 0
    setIsInvalid(status)
  }, [newAssessmentData])

  const handleApplyAssessment = async e => {
    e.stopPropagation()
    setIsLoading(true)
    const checkedNodes = await anyDuplicated()
    if (checkedNodes.includes(true)) {
      window.toastr.error(
        t('select_node.duplicated', {
          duplicated_nodes: newAssessmentData.nodes.reduce((prev, curr, i) => {
            if (checkedNodes[i]) {
              return prev + `${prev !== '' ? ', ' : ' '}${curr.name}`
            } else {
              return prev
            }
          }, '')
        })
      )
      setIsLoading(false)
      return
    }
    await applyAssessment(newAssessmentData, dispatch)
    setIsLoading(false)
    await fetchTreeData(user.organization_id, user.id, dispatch)
    toggleApplyForm(dispatch)
  }

  const confirmApply = async e => {
    Confirm.Show(
      t('node.undeployed.confirm.title'),
      t('node.undeployed.confirm.description'),
      t('node.undeployed.confirm.yes'),
      t('node.undeployed.confirm.no'),
      async () => {
        try {
          await handleApplyAssessment(e)
        } catch (e) {
          console.error(e)
          window.toastr.error(e?.response?.data?.error)
        }
      }
    )
  }

  return (
    <>
      <div className='SelectNode-container w-100'>
        <div className='SelectNode-row'>
          <div className='SelectNode-form-group'>
            <select
              id='selectNode'
              name='selectNode'
              className='z-depth-2'
              onChange={e => handleSelectNode(e.target.value)}
            >
              <option>{t('select_node.options.select')}</option>
              {nodeOptions &&
                nodeOptions.map(opt => {
                  return (
                    <option key={opt.id} value={opt.id}>
                      {`${opt.name}`}
                    </option>
                  )
                })}
            </select>
          </div>
          <div className='SelectNode-title'>
            <h1>{t('select_node.selected_nodes')}</h1>
          </div>
          {isLoading && (
            <div className='d-flex flex-column justify-content-center align-items-center v100'>
              <span className='awaitProcessing'>
                {t('select_node.loading')}
              </span>
              <br />
              <div className='loader1' />
            </div>
          )}
          {!isLoading && (
            <div className='SelectNode-list z-depth-2'>
              {selectNodes.map(node => (
                <div key={node.id} className='SelectNode-tag'>
                  <span>
                    {node.name} <br />
                  </span>
                  <button onClick={() => deleteNode(node.id)}>
                    <Close />
                  </button>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className='SelectNode-button-group'>
          <button
            className='SelectNode-cancelButton'
            onClick={() => toggleApplyForm(dispatch)}
          >
            {t('label.cancel')}
          </button>
          <button
            className='SelectNode-applyButton'
            disabled={isLoading || isInvalid || !isTemplateSelected()}
            onClick={async e => {
              ;(await isTemplateDeployed({
                templateId: newAssessmentData.templateId
              }))
                ? await handleApplyAssessment(e)
                : await confirmApply(e)
            }}
          >
            {t('select_node.apply_assessment')}
          </button>
        </div>
      </div>
    </>
  )
}

export default SelectNode
