import React from 'react'

import AnswerFormChoice from 'components/AnswerForm/AnswerFormChoice/AnswerFormChoice'

import './AnswerFormQuestion.scss'

export const AnswerFormQuestion = ({ question }) => {
  const renderChoice = choice => (
    <AnswerFormChoice key={choice.id} choice={choice} />
  )

  return (
    <div className='AnswerFormQuestion'>
      <div className='AnswerFormQuestion-header'>
        <h3 className='question-title'>{question.title}</h3>
        <h4 className='question-description'>{question.description}</h4>
      </div>

      <div className='AnswerFormQuestion-choices'>
        {question?.choices?.map(renderChoice)}
      </div>
    </div>
  )
}

export default AnswerFormQuestion
