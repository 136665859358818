import { api } from './axios'
import {
  FETCH_TREE_DATA,
  REFRESH_SELECTED_NODE_DATA,
  GET_NODES_FOR_ORGANIZATION_ID,
  GET_USERS_FOR_ORGANIZATION_ID,
  GET_PERSONAS_FOR_ORGANIZATION_ID,
  TOGGLE_APPLY_FORM,
  TOGGLE_MANAGE_USERS,
  TOGGLE_TRIGGER_ASSESSMENT,
  TOGGLE_MATURITY_MODEL_FORM,
  TOGGLE_NODE_FORM_CREATE,
  TOGGLE_NODE_FORM_EDIT,
  TOGGLE_BRANCH_MATURITY,
  GET_AUTHORIZED_NODES,
  UPDATE_TOGGLED_BRANCHES
} from '../actionTypes'

export const fetchTreeData = async (organizationId, userId, dispatch) => {
  try {
    const { data } = await api.get(
      `/nodes/tree/fetch/${organizationId}/${userId}`
    )
    dispatch({
      type: FETCH_TREE_DATA,
      payload: data
    })
    dispatch({
      type: REFRESH_SELECTED_NODE_DATA,
      payload: null
    })
  } catch (e) {
    return e
  }
}

export const inviteUsers = async (
  invitedUsersData,
  organizationId,
  userId,
  dispatch
) => {
  try {
    const { data } = await api.post('/nodes/inviteUsers', invitedUsersData)
    await fetchTreeData(organizationId, userId, dispatch)
    // TODO We should *not* cause side effects on the Front End inside action files
    window.toastr.success(`Successfuly processed invitations to ${data} users`)
    return data
  } catch (e) {
    // TODO We should *not* cause side effects on the Front End inside action files
    window.toastr.error('We have got an error: ' + e)
  }
}

export const calculateBranchMaturity = async pack => {
  return api.post('/assessments/calculateMaturity', pack).then(
    res => {
      return res.data
    },
    err => {
      // TODO We should *not* cause side effects on the Front End inside action files
      window.toastr.error(err.response.data.error)
    }
  )
}
export const getRootNodeIdByOrganizationId = async organizationId => {
  try {
    const { data } = await api.get(`/nodes/root/${organizationId}`)
    return data.id
  } catch (e) {
    window.toastr.error('We have got an error: ' + e)
  }
}

export const removeUserFromNode = async (
  removeData,
  organizationId,
  userId,
  dispatch
) => {
  try {
    const { data } = await api.post('/nodes/removeUser', removeData)
    await fetchTreeData(organizationId, userId, dispatch)
    // TODO We should *not* cause side effects on the Front End inside action files
    window.toastr.success(`Successfuly removed ${data} user`)
  } catch (e) {
    // TODO We should *not* cause side effects on the Front End inside action files
    window.toastr.error('We have got an error: ' + e)
  }
}

export const getPersonasByOrganizationId = async (organizationId, dispatch) => {
  try {
    const { data } = await api.get(`/personas/organization/${organizationId}`)
    dispatch({
      type: GET_PERSONAS_FOR_ORGANIZATION_ID,
      payload: data
    })

    return data
  } catch (error) {
    return error
  }
}

// export const getAuthorizedNodes = async (organizationId, nodeId) => {
//   try {
//     const { data } = await api.get(`/nodes/authorized/${organizationId}/${nodeId}`)
//     return data
//   } catch (error) {
//     return error
//   }
// }

export const getAuthorizedNodes = async (organizationId, userId, dispatch) => {
  try {
    const { data } = await api.get(
      `/nodes/authorized/${organizationId}/${userId}`
    )
    dispatch({
      type: GET_AUTHORIZED_NODES,
      payload: data
    })
  } catch (error) {
    return error
  }
}

export const getNodesByOrganizationId = async (organizationId, dispatch) => {
  try {
    const { data } = await api.get(`/nodes/organization/${organizationId}`)
    dispatch({
      type: GET_NODES_FOR_ORGANIZATION_ID,
      payload: data
    })
    return data
  } catch (error) {
    return error
  }
}

export const getUsersByOrganizationId = async (organizationId, dispatch) => {
  try {
    const { data } = await api.get(`/users/organization/${organizationId}`)
    dispatch({
      type: GET_USERS_FOR_ORGANIZATION_ID,
      payload: data
    })
  } catch (error) {
    return error
  }
}

export const createNode = async (node, userId, dispatch) => {
  try {
    const { data } = await api.post('/nodes', node)
    // TODO We should *not* cause side effects on the Front End inside action files
    window.toastr.success('Successfuly created node')
    await fetchTreeData(node.organization_id, userId, dispatch)
    return data
  } catch (e) {
    // TODO We should *not* cause side effects on the Front End inside action files
    window.toastr.error('We have got an error: ' + e)
  }
}

export const updateNode = async (node, userId, dispatch) => {
  try {
    await api.put(`/nodes/${node.id}`, node)
    if (node.isArchived) {
      // TODO We should *not* cause side effects on the Front End inside action files
      window.toastr.warning('Successfuly archived node')
    } else {
      // TODO We should *not* cause side effects on the Front End inside action files
      window.toastr.success('Successfuly updated node')
    }
    await fetchTreeData(node.organization_id, userId, dispatch)
  } catch (e) {
    window.toastr.error(e.error)
  }
}

export const toggleApplyForm = async dispatch => {
  dispatch({
    type: TOGGLE_APPLY_FORM,
    payload: null
  })
}

export const toggleTriggerAssessment = async dispatch => {
  dispatch({
    type: TOGGLE_TRIGGER_ASSESSMENT,
    payload: null
  })
}

export const toggleBranchMaturity = async (payload, dispatch) => {
  dispatch({
    type: TOGGLE_BRANCH_MATURITY,
    payload
  })
}

export const toggleNodeFormCreate = async (payload, dispatch) => {
  dispatch({
    type: TOGGLE_NODE_FORM_CREATE,
    payload
  })
}

export const toggleManageUsers = async (payload, dispatch) => {
  dispatch({
    type: TOGGLE_MANAGE_USERS,
    payload
  })
}

export const toggleNodeFormEdit = async (payload, dispatch) => {
  dispatch({
    type: TOGGLE_NODE_FORM_EDIT,
    payload
  })
}
export const updateToggledBranches = async (payload, dispatch) => {
  dispatch({
    type: UPDATE_TOGGLED_BRANCHES,
    payload
  })
}

export const toggleMaturityModelForm = async (dispatch, nodeId) => {
  dispatch({
    type: TOGGLE_MATURITY_MODEL_FORM,
    payload: nodeId
  })
}
