import {
  CREATE_OBJECTIVE,
  UPDATE_OBJECTIVE,
  GET_OBJECTIVE,
  SET_OBJECTIVES,
  CLEAR_OBJECTIVE,
  INITIALIZE_OBJECTIVE
} from 'redux/actionTypes'

import i18n from '../../i18n'

const initialState = {
  chartData: [],
  objective: null,
  objectives: [],
  mycfg: {
    w: 350,
    h: 350, // preserving aspect ratio
    maxValue: 100,
    levels: 10,
    TranslateX: 165,
    TranslateY: 100,
    ExtraWidthX: 350,
    ExtraWidthY: 150
  },
  legendOptions: [i18n.t('label.current_state')]
}
const objectivesReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case CREATE_OBJECTIVE:
      return {
        ...state,
        chartData: pushObjective(state, action),
        objectives: setObjective(1, state, action),
        objective: null
      }
    case CLEAR_OBJECTIVE:
      return { ...state, objective: null }
    case INITIALIZE_OBJECTIVE:
      return { ...initialState }
    case UPDATE_OBJECTIVE:
      return {
        ...state,
        chartData: updateObjective(state, action),
        objectives: setObjective(2, state, action),
        objective: null
      }
    case GET_OBJECTIVE:
      return getObjective(state, action)
    case SET_OBJECTIVES:
      return setObjectives(state, action)
    default:
      return state
  }
}

const getObjective = (state, action) => {
  return { ...state, objective: action.payload }
}

const setObjectives = (state, action) => {
  state.chartData = []
  let newChartData = []
  action.payload.forEach(obj => {
    newChartData = pushObjective(state, { objective: obj })
  })
  return { ...state, objectives: action.payload, chartData: newChartData }
}

const pushObjective = (state, action) => {
  const newChartData = state.chartData
  const { objective } = action
  newChartData.push([
    { objective },
    {
      axis: objective.shortName,
      areaId: objective.area_id,
      value: objective.assessmentValue,
      id: objective.id
    }
  ])
  return newChartData
}

const updateObjective = (state, action) => {
  const chartData = state.chartData
  const { objective } = action
  const updatedArray = chartData.map(item => {
    if (parseInt(item[0].objective.id) === parseInt(objective.id)) {
      return [
        { objective },
        {
          axis: objective.shortName,
          areaId: objective.area_id,
          value: objective.assessmentValue,
          id: objective.id
        }
      ]
    } else {
      return item
    }
  })
  return updatedArray
}

const setObjective = (type, state, action) => {
  const objectives = state.objectives
  const { objective } = action
  if (type === 1) {
    objectives.push(objective)
    return objectives
  } else {
    const updatedObjectives = objectives.map(obj =>
      obj.id === parseInt(objective.id) ? objective : obj
    )
    return updatedObjectives
  }
}

export default objectivesReducer
