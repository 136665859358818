import { api } from 'redux/actions/axios'
import btoa from 'btoa'

const TOKEN_KEY = 'token'
const USER_KEY = 'user'

const authenticate = async (email, password, socialLogin) => {
  const jsonAsString = JSON.stringify({
    identifier: email,
    password: password,
    socialLogin: socialLogin
  })

  const object = btoa(jsonAsString)

  const response = await api.post('/auth', { object: object })

  const { token, user } = response.data

  window.localStorage[TOKEN_KEY] = token
  window.localStorage[USER_KEY] = JSON.stringify(user)
}

const update = async newUserData => {
  if (!isAuthenticated()) return
  window.localStorage[USER_KEY] = JSON.stringify(newUserData)
}

const isAuthenticated = () =>
  window.localStorage[TOKEN_KEY] && window.localStorage[USER_KEY]

const getLoggedInUser = () => {
  if (!isAuthenticated()) return null
  return JSON.parse(window.localStorage[USER_KEY])
}

const getToken = () => {
  if (!isAuthenticated()) return null
  return window.localStorage[TOKEN_KEY]
}

const logout = async () => {
  delete window.localStorage[TOKEN_KEY]
  delete window.localStorage[USER_KEY]
}

export {
  authenticate,
  update,
  isAuthenticated,
  getLoggedInUser,
  getToken,
  logout
}
