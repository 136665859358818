import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'lodash'

import FormPage from 'components/FormPage/FormPage'
import FormButton from 'components/FormButton/FormButton'
import FormTextInput from 'components/FormTextInput/FormTextInput'

import { api } from 'redux/actions/axios'

import './ForgotPasswordPage.scss'

const ForgotPasswordPage = () => {
  const [t] = useTranslation()

  const [email, setEmail] = useState('')
  const [errors, setErrors] = useState([])
  const [successMessages, setSuccessMessages] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const handleFormSubmit = async e => {
    e.preventDefault()

    setIsLoading(true)
    setSuccessMessages([])
    setErrors([])

    try {
      await api.post('/auth/recover', { identifier: email })

      setSuccessMessages([t('forgot_password_page.success')])
      setEmail('')
    } catch (e) {
      console.error(e)

      const { data } = e.response
      setErrors([data.errors.form])
    }

    setIsLoading(false)
  }

  const handleCancel = () => {
    window.location = '/login'
  }

  return (
    <div className='ForgotPasswordPage'>
      <FormPage
        title={t('forgot_password_page.title')}
        errors={errors}
        successMessages={successMessages}
      >
        <form onSubmit={handleFormSubmit}>
          <label htmlFor='ForgotPasswordPage-email'>{t('label.email')}</label>
          <FormTextInput
            id='ForgotPasswordPage-email'
            type='email'
            value={email}
            onChange={e => setEmail(e.target.value)}
            autoFocus
            required
          />

          <div className='ForgotPasswordPage-cta'>
            <FormButton
              variant='blue'
              disabled={isLoading || isEmpty(email)}
              onClick={handleFormSubmit}
            >
              {t('label.submit')}
            </FormButton>
          </div>
        </form>

        <div className='ForgotPasswordPage-cancel'>
          <FormButton onClick={handleCancel}>{t('label.cancel')}</FormButton>
        </div>
      </FormPage>
    </div>
  )
}

export default ForgotPasswordPage
