import { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { isAuthenticated, logout, getLoggedInUser } from 'auth'
import DashboardIcon from '@material-ui/icons/Dashboard'
import PeopleIcon from '@material-ui/icons/People'

import './NavigationBar.scss'
import { DonutLarge } from '@material-ui/icons'
import { isEmpty } from 'lodash'
import atob from 'atob'
import useQuery from 'hooks/useQuery'

const NavigationBar = () => {
  const [toggled, setToggled] = useState(false)
  const [activeLinkName, setActiveLinkName] = useState('Home')
  const location = useLocation()

  const [t, i18n] = useTranslation()

  const parseParams = params => {
    const value = params.get('value')
    if (isEmpty(value)) {
      return { userId: null, assessmentId: null }
    }

    try {
      const input = atob(value) // decode Base64 value
      const { userId, assessmentId } = JSON.parse(input)
      return { userId, assessmentId }
    } catch (exception) {
      console.error(exception)
      return { userId: null, assessmentId: null }
    }
  }

  const queryParams = useQuery()

  const parsedParams = parseParams(queryParams)

  const toggleMenu = toggleIt => setToggled(toggleIt)

  const handleFlagClicked = e => {
    e.preventDefault()

    const language = e.target.getAttribute('data-tag')
    i18n.changeLanguage(language)
    toggleMenu(false)
  }

  const handleLogout = async e => {
    e.preventDefault()

    try {
      await logout()
      window.location.href = '/login' // Redirect to login page
    } catch (e) {
      console.error(e)
    }
  }

  const FlagLink = ({ tag, image, alt }) => (
    <a
      onClick={handleFlagClicked}
      className='nav-link'
      href='http://leanit101.com'
    >
      <img
        style={{ paddingTop: 0 }}
        data-tag={tag}
        className='flagCountry'
        src={`/images/${image}.png`}
        alt={alt}
      />
    </a>
  )

  const TranslationFlags = () => (
    <div className='d-flex align-items-center'>
      <li className='nav-item'>
        <FlagLink tag='pt-BR' image='pt-br' alt='Traduza para Português' />
      </li>
      <li className='nav-item'>
        <FlagLink tag='en-US' image='en-gb' alt='Translate to English' />
      </li>
      <li className='nav-item'>
        <FlagLink tag='es' image='es' alt='Traducir al español' />
      </li>
    </div>
  )

  const LoggedInUserMenu = () => {
    const user = getLoggedInUser()
    const avatarUrl = user.avatarUrl

    return (
      <li className='nav-item dropdown'>
        <a
          className='nav-link'
          href='http://leanit101.com'
          id='navbarDropdownMenuProfile'
          data-toggle='dropdown'
          aria-expanded='false'
        >
          <img
            className='profilePic'
            src={avatarUrl}
            data-toggle='tooltip'
            title={user.email}
            alt={t('navigation_bar.settings.alt')}
            onError={e => {
              e.target.src = '/images/avatar.png'
            }}
          />
        </a>
        <div
          className='dropdown-menu dropdown-menu-right'
          aria-labelledby='navbarDropdownMenuProfile'
        >
          <Link className='dropdown-item' to='/user/edit'>
            <i className='fa fa-user' aria-hidden='true' /> &nbsp;
            {t('navigation_bar.profile')}
          </Link>

          <a
            className='dropdown-item'
            href='#logout'
            onClick={handleLogout}
            alt='Logout'
          >
            <i className='fas fa-sign-out-alt' aria-hidden='true' /> &nbsp;
            {t('navigation_bar.logout')}
          </a>
        </div>
      </li>
    )
  }

  const UserLinks = () => {
    const user = getLoggedInUser()

    const visibilityClass = toggled
      ? 'navbar-collapse collapse show'
      : 'navbar-collapse collapse'

    return (
      <div className={visibilityClass} id='navbarNavDropdown'>
        <ul className='navbar-nav mr-auto'>
          {isAuthenticated() && location.pathname !== '/form' && (
            <>
              <li
                className={`nav-item ml-2 mr-auto ${
                  activeLinkName === 'dashboard' ? 'active' : ''
                }`}
              >
                <Link
                  className='nav-link nav-link-border d-flex align-items-center'
                  to='/dashboard'
                  onClick={() => {
                    setToggled(false)
                    setActiveLinkName('dashboard')
                  }}
                >
                  <DashboardIcon className='mr-1' />
                  {t('navigation_bar.dashboard')}
                </Link>
              </li>
              {(user.role === 'super' || user.role === 'master') && (
                <li
                  className={`nav-item ml-2 ${
                    activeLinkName === 'user-management' ? 'active' : ''
                  }`}
                >
                  <Link
                    className='nav-link nav-link-border d-flex align-items-center'
                    to='/user-management'
                    onClick={() => {
                      setToggled(false)
                      setActiveLinkName('user-management')
                    }}
                  >
                    <PeopleIcon className='mr-1' />
                    {' ' + t('navigation_bar.user.management')}
                  </Link>
                </li>
              )}

              {(user.role === 'super' || user.role === 'master') && (
                <li
                  className={`nav-item ml-2 ${
                    activeLinkName === 'template-management' ? 'active' : ''
                  }`}
                >
                  <Link
                    className='nav-link nav-link-border d-flex align-items-center'
                    to='/template-management'
                    onClick={() => {
                      setToggled(false)
                      setActiveLinkName('template-management')
                    }}
                  >
                    <DonutLarge className='mr-1' />
                    {' ' + t('navigation_bar.template.management')}
                  </Link>
                </li>
              )}

              {user.role === 'master' && (
                <li
                  className={`nav-item ml-auto ${
                    activeLinkName === 'master' ? 'active' : ''
                  }`}
                >
                  <Link
                    className='nav-link nav-link-border d-flex align-items-center ml-auto'
                    to='/master'
                    onClick={() => {
                      setToggled(false)
                      setActiveLinkName('master')
                    }}
                  >
                    <PeopleIcon className='mr-1' />
                    {' master'}
                  </Link>
                </li>
              )}
            </>
          )}
        </ul>
        <ul className='navbar-nav nav-flex-icons'>
          <TranslationFlags />
          {isAuthenticated() ? <LoggedInUserMenu /> : null}
        </ul>
      </div>
    )
  }

  if (parsedParams?.assessmentId) {
    return null
  }

  return (
    <nav className='NavigationBar navbar navbar-expand-lg sticky-top navbar-dark z-depth-2 scrolling-navbar'>
      <Link className='navbar-brand' to='/'>
        <img
          className='animated fadeInDown'
          src='/images/logo-leanit101-slogan.png'
          width='220px'
          alt={t('navigation_bar.home.alt')}
        />
      </Link>

      <button
        onClick={() => toggleMenu(true)}
        className='navbar-toggler'
        type='button'
        data-toggle='collapse'
        data-target='#navbarNavDropdown'
        aria-controls='navbarNavDropdown'
        aria-expanded='false'
        aria-label={t('navigation_bar.toggle_navigation')}
      >
        <span className='navbar-toggler-icon' />
      </button>

      <UserLinks />
    </nav>
  )
}

export default NavigationBar
