import {
  CREATE_RECOMMENDATION,
  UPDATE_RECOMMENDATION,
  GET_RECOMMENDATIONS_BY_ORGANIZATION_ID,
  GET_RECOMMENDATIONS_BY_OBJECTIVE_ID
} from '../actionTypes'

const initialState = {
  recommendations: [],
  objectiveRecommendations: []
}

const recommendationReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case CREATE_RECOMMENDATION:
      return { ...state, ...action.payload }
    case UPDATE_RECOMMENDATION:
      return { ...state, ...action.payload }
    case GET_RECOMMENDATIONS_BY_ORGANIZATION_ID:
      return { ...state, recommendations: action.payload }
    case GET_RECOMMENDATIONS_BY_OBJECTIVE_ID:
      return { ...state, objectiveRecommendations: action.payload }
    default:
      return state
  }
}

export default recommendationReducer
