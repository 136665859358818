import { api } from './axios'

import {
  ANSWER_FORM_COMPLETE,
  ANSWER_FORM_ERROR,
  ANSWER_FORM_LOADING,
  ANSWER_FORM_MOVE_NEXT_PAGE,
  ANSWER_FORM_MOVE_PREVIOUS_PAGE,
  ANSWER_FORM_PREPARE_FORM,
  ANSWER_FORM_TOGGLE_CHOICE
} from '../actionTypes'

const extractResponseError = response => {
  if (typeof response === 'string') return response

  if (response.response) {
    const { data } = response.response
    if (typeof data === 'string') return data

    return data.error.message
  }

  return response.message
}

export const setLoading = (isLoading, dispatch) =>
  dispatch({
    type: ANSWER_FORM_LOADING,
    payload: isLoading
  })

export const loadForm = async (userId, assessmentId, dispatch) => {
  setLoading(true, dispatch)

  try {
    const { data } = await api.get('/form', {
      params: { user_id: userId, assessment_id: assessmentId }
    })

    dispatch({
      type: ANSWER_FORM_PREPARE_FORM,
      payload: data
    })
  } catch (error) {
    dispatch({
      type: ANSWER_FORM_ERROR,
      payload: { error: extractResponseError(error) }
    })
  }
}

export const getAssessmentWithNode = async assessmentId => {
  try {
    const { data } = await api.get(`/assessments/${assessmentId}`)
    return data
  } catch (e) {
    const err = e.response
    window.toastr.error(err.error)
  }
}

export const moveToNextPage = dispatch =>
  dispatch({
    type: ANSWER_FORM_MOVE_NEXT_PAGE
  })

export const moveToPreviousPage = dispatch =>
  dispatch({
    type: ANSWER_FORM_MOVE_PREVIOUS_PAGE
  })

export const toggleChoice = (choiceId, dispatch) =>
  dispatch({
    type: ANSWER_FORM_TOGGLE_CHOICE,
    payload: { choiceId }
  })

export const submitAnswers =
  (userId, assessmentId) => async (dispatch, getState) => {
    const { answerForm } = getState()
    const { answers } = answerForm

    setLoading(true, dispatch)

    try {
      await api.post('/form/submit', { answers, userId, assessmentId })

      dispatch({
        type: ANSWER_FORM_COMPLETE
      })
    } catch (error) {
      dispatch({
        type: ANSWER_FORM_ERROR,
        payload: { error: extractResponseError(error) }
      })
    }
  }
