import React, { useEffect, useState } from 'react'
import { RemoveCircleOutline } from '@material-ui/icons'
import classNames from 'classnames'
import EditIcon from '@material-ui/icons/Edit'

import './Area.scss'

const Area = ({
  id,
  area,
  color,
  removeArea,
  isSelected,
  isDeployed,
  handleClick,
  handleEditClick
}) => {
  const [name, setName] = useState(area)
  const [areaColor, setAreaColor] = useState(color)

  useEffect(() => {
    setName(area)
  }, [area])

  useEffect(() => {
    setAreaColor(color)
  }, [color])

  const renderDefault = () => {
    return (
      <div className='d-flex align-items-center justify-content-around'>
        {!isDeployed && (
          <>
            <div
              className='area-color'
              style={{
                backgroundColor: areaColor
              }}
            />

            <EditIcon
              className='Area-icon'
              onClick={() => handleEditClick(id)}
            />

            <RemoveCircleOutline
              className='Area-icon'
              onClick={() => removeArea(id)}
            />
          </>
        )}
      </div>
    )
  }

  const classes = classNames(
    isSelected ? 'Area-header--selected' : 'Area-header'
  )

  return (
    <div className={classes} onClick={() => handleClick(id)}>
      <span className='Area-name'>{name}</span>
      {renderDefault()}
    </div>
  )
}
export default Area
