import React, { useState, useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { scaleOrdinal, schemeCategory10 } from 'd3'

import './DashboardDataContainer.scss'
import { addAnswerPerLevelPerObjective } from 'redux/actions/dashboardActions'

const DashboardDataContainer = ({ areaName }) => {
  const color = scaleOrdinal(schemeCategory10)
  const { dashboard } = useSelector(state => state)
  const { chartData, hiddenElements } = dashboard
  const [filteredDataTable, setFilteredDataTable] = useState([])
  const dispatch = useDispatch()
  const renderDataTable = (row, j) => {
    const rowWithFilteredHidden = row.filter(
      (col, k) =>
        hiddenElements.findIndex(el => el[1] === k - 1 && k > 0) === -1
    )
    return (
      <div
        key={j + Math.random()}
        className='data-row-obj'
        onClick={e => {
          addAnswerPerLevelPerObjective(rowWithFilteredHidden, dispatch)
        }}
      >
        {rowWithFilteredHidden.map((col, i) => {
          return (
            <div
              style={
                i > 0 ? { backgroundColor: color(i) } : { color: '#d1d1d1' }
              }
              className={i > 0 ? 'data-col animated fadeInUp' : 'title-col'}
              key={i + Math.random()}
            >
              {col.value}
            </div>
          )
        })}
      </div>
    )
  }

  useEffect(() => {
    if (chartData.length > 0) {
      const dataTable = chartData[0].map((x, i) =>
        chartData.map(x => {
          return {
            ...x[i],
            value: x[i].value.toFixed(2)
          }
        })
      )
      chartData[0].forEach((obj, i) => {
        dataTable[i].splice(0, 0, { value: obj.axis, areaName: obj.areaName })
      })
      const filter = dataTable.filter(row => row[0].areaName === areaName)
      setFilteredDataTable(filter)
    }
  }, [chartData, areaName])

  return (
    <div className='z-depth-4'>{filteredDataTable.map(renderDataTable)}</div>
  )
}

export default DashboardDataContainer
