import React, { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next'
import { clearRadar, renderRadar } from '../../RadarChart/RadarChart'
import { useSelector } from 'react-redux'
import { fetchAssessmentsPerUser } from 'redux/actions/dashboardActions'
import { getAssessmentWithNode } from 'redux/actions/answerFormActions'
import { byPosition } from 'utils/utils'
import { firstBy } from 'thenby'
import AnswerRecommendations from 'components/Recommendation/AnswerRecommendations/AnswerRecommendations'

const AnswerFormRadar = ({ assessmentId, userId }) => {
  const [t] = useTranslation()
  const { dashboard, area } = useSelector(state => state)
  const { mycfg } = dashboard
  const { areas } = area
  const [chartData, setChartData] = useState([])
  const [legendOptions, setLegendOptions] = useState([])
  const [template, setTemplate] = useState(null)
  useEffect(() => {
    const fetchData = async () => {
      const { data } = await fetchAssessmentsPerUser(assessmentId)
      const userData = data.filter(user => user.id === userId)
      const dataTemplate = await getAssessmentWithNode(userData[0].template_id)
      dataTemplate.maturityModel = dataTemplate.maturityLevels
        .map(level => level.name)
        .sort(byPosition)
      setTemplate(dataTemplate)
      setLegendOptions([
        t('answer_form.radar.you'),
        t('answer_form.radar.your_team')
      ])
      const newChartData = []
      newChartData.push(
        userData[0].dataPoints.chartData
          .map(item => {
            areas.forEach(element => {
              if (element.id === item[1].areaId) {
                item[1].areaColor = element.color
              }
            })
            return {
              ...item,
              value: item.assessmentValue
            }
          })
          .sort(firstBy('area_id').thenBy('axis'))
      )

      newChartData.push(
        userData[0].nodeChartData.sort(firstBy('area_id').thenBy('axis'))
      )
      setChartData(newChartData)
    }
    fetchData()
  }, [userId, assessmentId])

  useEffect(() => {
    if (template) {
      mycfg.levels = template.maturityModel?.length
      mycfg.maxValue = template.maturityModel?.length
      mycfg.maturityModel = template.maturityModel
    }
  }, [template, mycfg])

  useEffect(() => {
    if (!isEmpty(chartData)) {
      renderRadar(
        chartData,
        mycfg,
        legendOptions,
        '#answerFormRadar',
        () => {},
        false,
        false,
        () => {}
      )
    } else {
      clearRadar('#answerFormRadar')
    }
  })
  return (
    <div id='AnswerFormRadar' className='d-flex flex-column align-items-center'>
      <div name='answerFormRadar' id='answerFormRadar' />
      <span className='p-3'>{t('answer_form.thank_you')}</span>
      <AnswerRecommendations chartData={chartData} />
    </div>
  )
}

export default AnswerFormRadar
