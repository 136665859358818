import classnames from 'classnames'

import './CountBox.scss'

const CountBox = ({ text, highlighted, variant }) => {
  const className = classnames('CountBox', {
    'CountBox--highlighted': highlighted,
    'CountBox--greenText': variant === 'green',
    'CountBox--grayText': variant === 'gray'
  })

  return (
    <div className={className}>
      <span>{text}</span>
    </div>
  )
}

export default CountBox
