import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import { DateRange } from 'react-date-range'
import { useTranslation } from 'react-i18next'
import { firstBy } from 'thenby'

import { getLoggedInUser } from 'auth'

import { clearRadar, renderRadar } from '../RadarChart/RadarChart'
import Leanit101Select from 'components/Select/Leanit101Select'
import NodeUsers from 'components/Tree/NodeUsers'

import {
  calculateBranchMaturity,
  getPersonasByOrganizationId,
  toggleBranchMaturity
} from 'redux/actions/treeActions'
import { getDeployedTemplatesByOrganizationId } from 'redux/actions/assessmentActions'

import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import DoneAllIcon from '@material-ui/icons/DoneAll'

import './BranchMaturity.scss'

import { byPosition } from 'utils/utils'
import { isToday } from 'date-fns'

const BranchMaturity = () => {
  const [t] = useTranslation()

  const { deployedTemplates } = useSelector(state => state.template)
  const { node, personas } = useSelector(state => state.tree)
  const { mycfg, assessmentMode } = useSelector(state => state.dashboard)
  const { areas } = useSelector(state => state.area)

  const [selectedTemplate, setSelectedTemplate] = useState(null)
  const [templateOptions, setTemplateOptions] = useState([])

  const [personaOptions, setPersonaOptions] = useState([])
  const [selectedPersonas, setSelectedPersonas] = useState([])

  const [isLoading, setIsLoading] = useState(false)

  const [assessmentsFiltered, setAssessmentsFiltered] = useState([])
  const [selectedAssessments, setSelectedAssessments] = useState([])

  const [chartData, setChartData] = useState([])
  const [selectedTemplateIdIndex, setSelectedTemplateIdIndex] = useState(-1)

  const dispatch = useDispatch()
  const user = getLoggedInUser()
  const organizationId = user.organization_id

  const [assessmentDates, setAssessmentDates] = useState({
    startDate: new Date(),
    endDate: new Date(),
    color: '#338ba7',
    key: 'selection'
  })

  const calculate = useCallback(
    async e => {
      setIsLoading(true)
      const calculatedChartData = await calculateBranchMaturity({
        selectedAssessments,
        selectedPersonas
      })
      if (calculatedChartData) {
        setChartData([
          calculatedChartData
            .map(axis => {
              areas.forEach(element => {
                if (element.id === axis[1]?.areaId) {
                  axis[1].areaColor = element.color
                }
              })
              return {
                ...axis,
                value: assessmentMode
                  ? axis.assessmentValue
                  : axis.maturityValue
              }
            })
            .sort(firstBy('areaName').thenBy('axis'))
        ])
      }
      setIsLoading(false)
    },
    [selectedAssessments, selectedPersonas, assessmentMode]
  )

  const filterAssessments = useCallback(
    subTree => {
      const result = []
      if (subTree.length === 0) {
        return result
      }
      if (subTree) {
        for (let i = 0; i < subTree.length; i++) {
          if (subTree[i].assessments.length > 0) {
            for (let j = 0; j < subTree[i].assessments.length; j++) {
              if (
                subTree[i].assessments[j].template_id ===
                  selectedTemplate?.id &&
                new Date(subTree[i].assessments[j].startDate) >=
                  new Date(assessmentDates.startDate) &&
                new Date(subTree[i].assessments[j].endDate) <=
                  new Date(assessmentDates.endDate)
              ) {
                result.push({
                  nodeId: subTree[i].id,
                  nodeName: subTree[i].name,
                  nodeType: subTree[i].nodeType.name,
                  nodeUsers: subTree[i].nodeUsers.map(user => {
                    return { ...user, selected: true }
                  }),
                  assessment: { ...subTree[i].assessments[j] }
                })
              }
            }
          }
          if (subTree[i].children.length > 0) {
            const found = filterAssessments(subTree[i].children)
            if (found.length > 0) result.push(...found)
          }
        }
      }
      return result
    },
    [selectedTemplate, assessmentDates.startDate, assessmentDates.endDate]
  )

  useEffect(() => {
    if (!isEmpty(chartData)) {
      if (selectedTemplate) {
        mycfg.levels = selectedTemplate.maturityModel.length
        mycfg.maxValue = selectedTemplate.maturityModel.length
        mycfg.maturityModel = selectedTemplate.maturityModel
      }
      renderRadar(
        chartData,
        mycfg,
        [
          t('branch_maturity.radar.text', {
            node_type: node?.id && node.nodeType.name,
            node_name: node.name
          })
        ],
        '#maturityChartBuilder',
        () => {},
        false,
        false,
        () => {}
      )
    } else {
      clearRadar('#maturityChartBuilder')
    }
  })

  useEffect(() => {
    const fetchData = async () => {
      await getDeployedTemplatesByOrganizationId(organizationId, dispatch)
      await getPersonasByOrganizationId(organizationId, dispatch)
    }
    setIsLoading(true)
    fetchData()
    setIsLoading(false)
  }, [organizationId, dispatch])

  useEffect(() => {
    if (selectedAssessments.length > 0) {
      setTimeout(() => {
        calculate()
      }, 500)
    } else {
      setChartData([])
    }
  }, [selectedAssessments.length, selectedPersonas, calculate])

  useEffect(() => {
    if (
      assessmentDates.startDate &&
      assessmentDates.endDate &&
      selectedTemplate?.id
    ) {
      const filtered = filterAssessments([node])
      setAssessmentsFiltered(filtered)
    } else {
      setAssessmentsFiltered([])
    }
  }, [
    node,
    filterAssessments,
    selectedTemplate,
    assessmentDates.startDate,
    assessmentDates.endDate
  ])

  useEffect(() => {
    const templateOptions = [
      {
        label: t('branch_maturity.available_templates'),
        options: deployedTemplates.map(template => {
          return {
            label: template.name,
            value: template.id,
            template
          }
        })
      }
    ]
    setTemplateOptions(templateOptions)
  }, [deployedTemplates])

  useEffect(() => {
    const personaOptions = [
      {
        label: t('branch_maturity.all_personas'),
        options: personas.map(persona => {
          return {
            label: persona.title,
            value: persona.id,
            persona
          }
        })
      }
    ]
    setPersonaOptions(personaOptions)
  }, [personas])

  const onChangeSelectedTemplate = e => {
    if (e) {
      const template = e.template
      template.maturityModel = template.maturityLevels
        .map(level => level.name)
        .sort(byPosition)
      setSelectedTemplate(template)
      if (selectedAssessments[0]?.template_id !== e.template.id) {
        setSelectedAssessments([])
      }
    } else {
      setSelectedTemplate(null)
    }
  }

  const clearFilter = () => {
    const personaOpt = [...personaOptions]
    const templateOpt = [...templateOptions]
    setSelectedTemplateIdIndex(-1)
    setPersonaOptions(personaOpt)
    setTemplateOptions(templateOpt)
    setChartData(null)
    setSelectedTemplate(null)
    setAssessmentsFiltered([])
    setSelectedAssessments([])
    setAssessmentDates({
      startDate: new Date(),
      endDate: new Date(),
      color: '#338ba7',
      key: 'selection'
    })
    clearRadar('#maturityChartBuilder')
  }

  const renderFilteredAssessments = () => {
    if (assessmentsFiltered.length === 0) {
      return (
        <span className='BranchMaturity-not-found'>
          {t('branch_maturity.no_assessments_section')}
        </span>
      )
    }

    return assessmentsFiltered.map(item => {
      return (
        <div
          className='BranchMaturity-assessment-card'
          key={item.assessment.id}
        >
          <div className='BranchMaturity-assessment-card-content'>
            <span className='assessment-name'>{item.assessment.name}</span>
            <div className='d-flex flex-wrap mt-2'>
              <span className='node-name'>{item.nodeName}</span>
              <span className='node-type'>{item.nodeType}</span>
            </div>
          </div>
          <div className='d-inline-flex align-items-center'>
            <span
              data-toggle='tooltip'
              title={t('branch_maturity.select_assessment')}
            >
              <AddCircleOutlineIcon
                onClick={e => {
                  e.stopPropagation()
                  const newSelectedAssessment = [...selectedAssessments]
                  if (
                    !newSelectedAssessment.find(
                      el => el.assessment.id === item.assessment.id
                    )
                  ) {
                    newSelectedAssessment.push(item)
                    setSelectedAssessments(newSelectedAssessment)
                  }
                  const newAssessmentsFiltered = assessmentsFiltered.filter(
                    el => el.assessment.id !== item.assessment.id
                  )
                  setAssessmentsFiltered(newAssessmentsFiltered)
                }}
                className='launch-icon'
              />
            </span>
          </div>
        </div>
      )
    })
  }

  const removePersona = index => {
    const newSelectedPersonas = selectedPersonas.filter(
      (item, i) => i !== index
    )
    setSelectedPersonas(newSelectedPersonas)
  }

  const renderPersonas = () => {
    const personasSelected = [...selectedPersonas]
    return personasSelected.map((persona, index) => (
      <div key={persona.id} className='BranchMaturity-selected-persona-tag'>
        <span className='persona-title'>
          {persona.title}
          <br />
        </span>
        <span>
          <HighlightOffIcon
            className='launch-icon'
            onClick={() => removePersona(index)}
          />
        </span>
      </div>
    ))
  }

  const onChangePersona = e => {
    if (e) {
      if (selectedPersonas.findIndex(item => item.id === e.persona.id) === -1) {
        const newSelectedPersonas = [...selectedPersonas]
        newSelectedPersonas.push(e.persona)
        setSelectedPersonas(newSelectedPersonas)
      }
    }
  }

  const renderSelectedAssessments = () => {
    if (selectedAssessments.length === 0) {
      return (
        <span className='BranchMaturity-not-found'>
          {t('branch_maturity.no_assessments_selected')}
        </span>
      )
    }

    return selectedAssessments.map(item => {
      return (
        <div
          className='BranchMaturity-assessment-selected-card'
          key={'selected_' + item.assessment.id}
        >
          <div className='d-flex flex-row justify-content-between'>
            <div className='BranchMaturity-assessment-selected-card-content'>
              <span className='selected-assessment-name'>
                {item.assessment.name}
              </span>
              <div className='d-linline-flex mt-2'>
                <span className='selected-node-name'>{item.nodeName}</span>
                <span className='selected-node-type'>{item.nodeType}</span>
              </div>
            </div>
            <div className='d-inline-flex align-items-center'>
              <span
                data-toggle='tooltip'
                title={t('branch_maturity.select_assessment')}
              >
                <HighlightOffIcon
                  onClick={e => {
                    e.stopPropagation()
                    const newSelectedAssessment = selectedAssessments.filter(
                      el => el.assessment.id !== item.assessment.id
                    )
                    setSelectedAssessments(newSelectedAssessment)
                  }}
                  className='launch-icon'
                />
              </span>
            </div>
          </div>
          <div className='mt-1'>
            <NodeUsers
              nodeUsers={item.nodeUsers}
              nodeId={item.nodeId}
              callBack={() => {}}
              assessmentId={item.assessment.id}
            />
          </div>
        </div>
      )
    })
  }

  return (
    <div className='BranchMaturity animated fadeInDown'>
      <div className='BranchMaturity-container'>
        <div className='BranchMaturity-header'>
          <p>{t('branch_maturity.header')}</p>
          <div className='mt-2'>
            <span className='template-title'>
              {node?.id
                ? t('branch_maturity.selected_node_description', {
                    node_type: node?.id && node.nodeType.name,
                    node_name: node.name
                  })
                : null}
            </span>
          </div>
        </div>
        <div className='BranchMaturity-body'>
          <div className='BranchMaturity-filter'>
            <Leanit101Select
              name='selectTemplate'
              groupedOptions={templateOptions}
              selectedPosition={selectedTemplateIdIndex}
              placeHolderText={t('branch_maturity.select_template')}
              onChange={onChangeSelectedTemplate}
              width='330'
            />

            <DateRange
              className='mt-2 z-depth-2'
              editableDateInputs
              color='#FFF'
              onChange={item => {
                setAssessmentDates(item.selection)
              }}
              moveRangeOnFirstSelection={false}
              ranges={[assessmentDates]}
            />
            <div className='mt-4'>
              <div className='section-title d-flex justify-content-between animated fadeInDown'>
                <p>
                  {selectedPersonas.length === 1
                    ? t('branch_maturity.persona')
                    : t('branch_maturity.multiple_personas')}
                </p>
                <span>
                  <InfoOutlinedIcon
                    className='mr-2 launch-icon'
                    onClick={() =>
                      window.toastr.info(t('branch_maturity.personas_info'))
                    }
                  />
                </span>
              </div>
              <div className='BranchMaturity-persona-list z-depth-2'>
                <div className='d-flex justify-content-start'>
                  <Leanit101Select
                    name='personas'
                    groupedOptions={personaOptions}
                    placeHolderText={t('branch_maturity.personas_placeholder')}
                    onChange={onChangePersona}
                    width='330'
                  />
                </div>
                <div className='BranchMaturity-persona-list-content'>
                  {renderPersonas()}
                </div>
              </div>
            </div>
          </div>
          <div className='BranchMaturity-filter-results'>
            <div className='d-flex justify-content-between z-depth-2'>
              <div className='section-title'>
                {t('branch_maturity.filtered_assessments')}
              </div>
              <DoneAllIcon
                onClick={e => {
                  e.stopPropagation()
                  const newSelectedAssessments = [...selectedAssessments]
                  assessmentsFiltered.forEach(item => {
                    if (
                      !newSelectedAssessments.find(
                        el => el.assessment.id === item.assessment.id
                      )
                    ) {
                      newSelectedAssessments.push(item)
                    }
                  })

                  setSelectedAssessments(newSelectedAssessments)
                  setAssessmentsFiltered([])
                }}
                className={
                  assessmentsFiltered.length === 0
                    ? 'mt-2 mr-2 disabled'
                    : 'mt-2 mr-2 launch-icon'
                }
              />
            </div>
            <div className='BranchMaturity-filter-results-content z-depth-2 d-flex flex-column justify-content-start align-items-center'>
              {renderFilteredAssessments()}
            </div>
            <div className='BranchMaturity-selected-assessments z-depth-2'>
              <div className='d-flex justify-content-between'>
                <div className='section-title'>
                  {t('branch_maturity.selected_assessments')}
                </div>
                <div className='BranchMaturity-countBox'>
                  <div className='BranchMaturity-countValue'>
                    {selectedAssessments.length}
                  </div>
                </div>
              </div>
              <div className='BranchMaturity-selected-assessments-content d-flex flex-column justify-content-start align-items-center'>
                {renderSelectedAssessments()}
              </div>
            </div>
          </div>

          {isLoading && (
            <div className='d-flex flex-column w-100 justify-content-center align-items-center'>
              <div className='loader1' />
            </div>
          )}

          {!isLoading && (
            <div className='BranchMaturity-radar-container'>
              {!isEmpty(chartData) && (
                <div name='maturityChartBuilder' id='maturityChartBuilder' />
              )}
              {isEmpty(chartData) && (
                <div className='BranchMaturity-select-data'>
                  {t('branch_matuity.data_select.select')}
                  <span className='data-selected'>
                    {' '}
                    {t('branch_matuity.data_select.persona_optional')}
                  </span>
                  <span
                    className={
                      selectedTemplate ? 'data-selected' : 'data-not-selected'
                    }
                  >
                    {t('branch_matuity.data_select.select_template')}
                  </span>
                  <span
                    className={
                      !isToday(assessmentDates.startDate) &&
                      !isToday(assessmentDates.endDate)
                        ? 'data-selected'
                        : 'data-not-selected'
                    }
                  >
                    {t('branch_matuity.data_select.check_period')}
                  </span>
                  <span
                    className={
                      selectedAssessments.length > 0
                        ? 'data-selected'
                        : 'data-not-selected'
                    }
                  >
                    {t('branch_matuity.data_select.selected_assessments')}
                  </span>
                </div>
              )}
            </div>
          )}
        </div>
        <div className='BranchMaturity-button-group'>
          <button
            className='BranchMaturity-clearButton'
            onClick={() => {
              clearFilter()
            }}
          >
            {t('branch_matuity.clear_filter')}
          </button>
          <button
            className='BranchMaturity-cancelButton'
            onClick={() => {
              toggleBranchMaturity(
                { branchMaturityToggled: false, node: null },
                dispatch
              )
            }}
          >
            {t('label.close')}
          </button>
        </div>
      </div>
    </div>
  )
}

export default BranchMaturity
