import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'lodash'
import jwtDecode from 'jwt-decode'

import { authenticate } from 'auth'

import FormPage from 'components/FormPage/FormPage'
import FormButton from 'components/FormButton/FormButton'
import FormTextInput from 'components/FormTextInput/FormTextInput'

import './LoginPage.scss'
import { useLocation } from 'react-router-dom'

const LoginPage = () => {
  const [t] = useTranslation()
  const location = useLocation()

  const [email, setEmail] = useState('')
  const [socialEmail, setSocialEmail] = useState(null)
  const [password, setPassword] = useState('')
  const [errors, setErrors] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isLoggedInWithSocial, setIsLoggedInWithSocial] = useState(false)
  const google = window.google

  const handleCallbackResponse = async response => {
    setIsLoading(true)
    const userObject = await jwtDecode(response.credential)
    if (userObject.email_verified) {
      setIsLoggedInWithSocial(true)
      setSocialEmail(userObject.email)

      document.getElementById('LoginPage-submit-button').click()
    }
  }

  useEffect(() => {
    if (google) {
      google?.accounts?.id?.initialize({
        client_id:
          '945487541899-0uhqjmgnoqfgndjhafpokpdr75a6rh6m.apps.googleusercontent.com',
        callback: handleCallbackResponse
      })
      google.accounts.id.renderButton(document.getElementById('googleLogIn'), {
        theme: 'outline',
        size: 'large',
        width: '400px'
      })
    }
  }, [])

  const handleFormSubmit = async e => {
    e.preventDefault()
    setIsLoading(true)
    setErrors([])

    try {
      await authenticate(socialEmail || email, password, isLoggedInWithSocial)
      const newLocation = location.state?.from.pathname
        ? location.state.from.pathname.concat(
            location.state.from.search ? location.state.from.search : ''
          )
        : '/dashboard'
      window.location.href = newLocation
    } catch (e) {
      console.error(e)
      setIsLoggedInWithSocial(false)
      setSocialEmail('')
      const { data } = e.response
      if (data.errors) {
        setErrors([data.errors.form])
      } else if (typeof data === 'string') {
        setErrors([data])
      } else {
        setErrors([t('login_page.errors.auth_failure')])
      }
    }

    setIsLoading(false)
  }

  const handleForgotPassword = () => {
    window.location = '/forgot-password'
  }

  return (
    <div className='LoginPage'>
      <FormPage title={t('login_page.title')} errors={errors}>
        <div className='d-flex align-items-center flex-column justify-content-center p-4'>
          <span>Log in with Google:</span>
        </div>
        <div className='d-flex align-items-center flex-column justify-content-center w-100'>
          <div className='' id='googleLogIn' />
        </div>
        <div className='d-flex align-items-center flex-column justify-content-center p-4'>
          <span>or</span>
        </div>
        <form onSubmit={handleFormSubmit}>
          <label htmlFor='LoginPage-email'>
            {t('login_page.fields.email')}
          </label>
          <FormTextInput
            id='LoginPage-email'
            type='email'
            value={email}
            onChange={e => setEmail(e.target.value)}
            required
            autoFocus
            disabled={isLoggedInWithSocial}
            pattern='[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$'
          />
          <label htmlFor='LoginPage-password'>
            {t('login_page.fields.password')}
          </label>
          <FormTextInput
            id='LoginPage-password'
            type='password'
            value={password}
            required
            disabled={isLoggedInWithSocial}
            onChange={e => setPassword(e.target.value)}
          />
          &nbsp;
          <div className='LoginPage-button-group'>
            <button
              id='LoginPage-submit-button'
              className='LoginPage-createButton'
              disabled={
                !isLoggedInWithSocial &&
                (isLoading || isEmpty(email) || isEmpty(password))
              }
              onClick={handleFormSubmit}
            >
              {t('label.submit')}
            </button>
          </div>
        </form>
        <div className='LoginPage-forgotPassword'>
          <FormButton onClick={handleForgotPassword}>
            {t('login_page.forgot_password_link')}
          </FormButton>
        </div>
      </FormPage>
    </div>
  )
}

export default LoginPage
