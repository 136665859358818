import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import TenantUsersSelect from './TenantUsersSelect'

import PeopleIcon from '@material-ui/icons/People'

import './TenantUsers.scss'
import TenantUserProfile from 'components/TenantUserProfile/TenantUserProfile'

const TenantUsers = ({ users, addOn, callBack }) => {
  const [t] = useTranslation()

  const [userProfiled, setUserProfiled] = useState(null)

  const getRoleClass = role => {
    switch (role) {
      case 'user':
        return 'TenantUsers-role-user-selected'
      case 'admin':
        return 'TenantUsers-role-admin-selected'
      case 'super':
        return 'TenantUsers-role-super-selected'
      default:
        break
    }
  }

  const renderUser = user => {
    return (
      <div
        key={user.id}
        className='TenantUsers-user z-depth-1'
        onClick={() => {
          setUserProfiled({ ...user })
        }}
      >
        <div className='TenantUsers-pic-panel'>
          <img
            className='TenantUsers-profilePic'
            src={user.avatarUrl}
            alt={user.email}
            data-toggle='tooltip'
            title={user.email}
            onError={e => {
              e.target.src = '/images/avatar.png'
            }}
          />
        </div>
        <div className='TenantUsers-user-tag animated fadeInLeft'>
          <div className='TenantUser-user-tag-top-panel'>
            <h1 className='mr-auto TenantUsers-user-name'>
              {`${user.firstName} ${user.surName}`}
              <p>
                <span className='TenantUsers-user-email'>{`${user.email}`}</span>
              </p>
            </h1>
            <div className='TenantUsers-role-panel'>
              {addOn && (
                <TenantUsersSelect
                  value={user.isActive}
                  userId={user.id}
                  addOn
                  callBack={callBack}
                  user={{ ...user }}
                />
              )}

              <div className={`TenantUsers-role ${getRoleClass(user?.role)}`}>
                {t(`manage_users.${user?.role}`)}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const byFirstName = (a, b) => {
    const firstNameA = a.firstName.toLowerCase()
    const firstNameB = b.firstName.toLowerCase()
    if (firstNameA < firstNameB) {
      return -1
    }
    if (firstNameA > firstNameB) {
      return 1
    }
    return 0
  }

  return (
    <div className='TenantUsers-container z-depth-1 animated fadeInDown'>
      <div
        className='TenantUsers-content-title d-flex justify-content-between align-items-center'
        alt={t('node_user.show_member_names')}
      >
        <h1 className='mr-auto mt-1 mb-1'>
          <div>
            <PeopleIcon />{' '}
            {t('node_user.members_count', { nodeUsers: users.length })}
          </div>
        </h1>
      </div>

      <div className='TenantUsers-content'>
        {!userProfiled && users.sort(byFirstName).map(renderUser)}
        {userProfiled && (
          <TenantUserProfile
            userSelected={userProfiled}
            closeCallBack={setUserProfiled}
          />
        )}
      </div>
    </div>
  )
}

export default TenantUsers
