import React from 'react'
import { isEmpty, map } from 'lodash'

import AnswerFormQuestion from 'components/AnswerForm/AnswerFormQuestion/AnswerFormQuestion'

import './AnswerFormPage.scss'

const AnswerFormPage = ({ area }) => {
  const renderObjective = objective => {
    if (isEmpty(objective.questions)) return null

    return (
      <div className='AnswerFormPage-objective'>
        <h2>{objective.name}</h2>
        <h3>{objective.description}</h3>

        <div className='AnswerFormPage-questions'>
          {map(objective.questions, renderQuestion)}
        </div>
      </div>
    )
  }

  const renderQuestion = question => {
    if (isEmpty(question.choices)) return null

    return <AnswerFormQuestion key={question.id} question={question} />
  }

  return (
    <div className='AnswerFormPage'>
      <div className='AnswerFormPage-header'>
        <h2>{area.name}</h2>
        <p>{area.description}</p>
      </div>

      {map(area.objectives, renderObjective)}
    </div>
  )
}

export default AnswerFormPage
