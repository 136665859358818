import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { getAssessmentCompletion } from '../../../redux/actions/assessmentActions'
import ProgressBar from '../../ProgressBar/ProgressBar'

import './ObjectiveMaturityProgressBar.scss'

const ObjectiveMaturityProgressBar = () => {
  const dispatch = useDispatch()
  const [t] = useTranslation()

  const { assessment, objectiveMaturity } = useSelector(state => state)
  const { selectedObjective } = objectiveMaturity

  useEffect(() => {
    getAssessmentCompletion(assessment.id, dispatch)
  }, [assessment.id, dispatch])

  if (!selectedObjective) return null

  return (
    <div className='ObjectiveMaturityProgressBar'>
      <div className='ObjectiveMaturityProgressBar-text'>
        {t('objective_maturity_progress_bar.text')}
      </div>
      <ProgressBar completed={assessment.completed} />
    </div>
  )
}

export default ObjectiveMaturityProgressBar
