import {
  select,
  scaleBand,
  axisLeft,
  axisRight,
  axisBottom,
  scaleLinear,
  scaleOrdinal,
  schemeCategory10
} from 'd3'
// import * as d3 from 'd3'

// import { max } from 'd3-array'

export const renderHorizontalBarChart = async (element, data, range) => {
  const color = scaleOrdinal(schemeCategory10)
  const margin = { top: 20, right: 30, bottom: 40, left: 180 }
  const width = 400 - margin.left - margin.right
  const height = 206 - margin.top - margin.bottom

  clearHorizontalBarChart(element)

  // append the svg object to the body of the page
  const svg = select(element)
    .append('svg')
    .attr('width', width + margin.left + margin.right)
    .attr('height', height + margin.top + margin.bottom)
    .append('g')
    .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')')

  // Parse the Data

  // const data = [
  //   { assessment: 'United States', value: 12394 },
  //   { assessment: 'Russia', value: 6148 },
  //   { assessment: 'Germany (FRG)', value: 1653 },
  //   { assessment: 'France', value: 2162 },
  //   { assessment: 'United Kingdom', value: 1214 },
  //   { assessment: 'China', value: 1131 },
  //   { assessment: 'Spain', value: 814 },
  //   { assessment: 'Netherlands', value: 1167 },
  //   { assessment: 'Italy', value: 660 },
  //   { assessment: 'Israel', value: 1263 }
  // ]

  // Add X axis
  const x = scaleLinear().domain([0, range]).range([0, width])
  svg
    .append('g')
    .attr('transform', 'translate(0,' + height + ')')
    .call(axisBottom(x))
    .selectAll('text')
    .attr('transform', 'translate(-10,0)rotate(-45)')
    .style('text-anchor', 'end')

  // Y axis
  const y = scaleBand()
    .range([0, height])
    .domain(
      data.map(function (d) {
        const parts = d.assessment.split(' - ')
        return `${parts[2]?.length > 0 ? parts[2] : ''} ${parts[0]} ${parts[1]}`
      })
    )
    .padding(0.1)

  svg.append('g').call(axisLeft(y))

  // Bars
  svg
    .selectAll('myRect')
    .data(data)
    .enter()
    .append('rect')
    .transition()
    .duration(800)
    .attr('x', x(0))
    .attr('y', function (d) {
      const parts = d.assessment.split(' - ')
      return y(
        `${parts[2]?.length > 0 ? parts[2] : ''} ${parts[0]} ${parts[1]}`
      )
    })
    .attr('width', function (d) {
      return x(d.value)
    })
    .attr('height', y.bandwidth())
    .attr('fill', function (d) {
      const parts = d.assessment.split(' - ')
      return color(
        `${parts[2]?.length > 0 ? parts[2] : ''} ${parts[0]} ${parts[1]}`
      )
    })

  // bar numbers
  svg
    .append('g')
    .selectAll('text')
    .style('text-anchor', 'end')
    .style('font-family', "'Quicksand', sans-serif")
    .style('font-size', '0.65rem')
    .style('font-weight', '500')
    .attr('text-anchor', 'right')
    .attr('dy', '1em')
    .attr('fill', 'lightgray')
  // .attr('x', function (d) {
  //   return d.value
  // })

  // Y axis
  const wY = scaleBand()
    .range([0, height])
    .domain(
      data.map(function (d) {
        return d.value.toFixed(2)
      })
    )
    .padding(0.1)

  svg.append('g').call(axisRight(wY))
}

export const clearHorizontalBarChart = element => {
  select(element).selectAll('svg').remove()
}
