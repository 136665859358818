import React from 'react'
import { useTranslation } from 'react-i18next'

import './ModalHeader.scss'

const ModalHeader = ({ tabs }) => {
  const [t] = useTranslation()
  const renderTabs = (el, index) => {
    return (
      <div
        key={index}
        id={el.indexTab}
        className='col-md text-center ModalHeader-tab'
      >
        <div className='ModalHeader-title'>
          <span>{t(el.title)}</span>
          <div>
            <hr className='ModalHeader-fixed-tab' />
          </div>
        </div>
      </div>
    )
  }
  return (
    <div className='ModalHeader'>
      <div className='ModalHeader-top d-flex justify-content-around'>
        {tabs.map((el, index) => renderTabs(el, index))}
      </div>
    </div>
  )
}

export default ModalHeader
