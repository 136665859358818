import { api } from './axios'
import {
  CREATE_OBJECTIVE_MATURITY,
  UPDATE_OBJECTIVE_MATURITY,
  GET_OBJECTIVE_MATURITY,
  UPDATE_SELECTED_OBJECTIVE,
  CLEAR_SELECTED_OBJECTIVE,
  UPDATE_SELECTED_AREA,
  DELETE_OBJECTIVE_MATURITY,
  INITIALIZE_OBJECTIVE_MATURITY
} from 'redux/actionTypes'
import { getObjectiveById } from './objectivesAction'

export const createObjectiveMaturity = async (
  objectiveId,
  maturityId,
  description,
  dispatch
) => {
  try {
    const newObjectiveMaturity = {
      objective_id: objectiveId,
      maturity_id: maturityId,
      description
    }
    const { data } = await api.post('/objectiveMaturity', newObjectiveMaturity)
    dispatch({
      type: CREATE_OBJECTIVE_MATURITY,
      payload: data
    })
  } catch (e) {
    const err = e.response
    const error = {
      status: err.status,
      errorMsg: err.data.errors.objectiveMaturityError
    }
    return error
  }
}

export const updateObjectiveMaturity = async (
  id,
  objectiveId,
  maturityId,
  description,
  dispatch
) => {
  if (!id || !description) return
  const payload = {
    objective_id: objectiveId,
    maturity_id: maturityId,
    description
  }
  const { data } = await api
    .put(`/objectiveMaturity/${id}`, payload)
    .then(response => response)
    .catch(err => console.log(err))
  if (data) {
    dispatch({
      type: UPDATE_OBJECTIVE_MATURITY,
      payload: data
    })
  }
}

export const selectObjective = async (id, description, areaId, dispatch) => {
  dispatch({
    type: UPDATE_SELECTED_OBJECTIVE,
    payload: {
      id,
      description,
      areaId
    }
  })
  dispatch({
    type: UPDATE_SELECTED_AREA,
    payload: {
      areaId
    }
  })
  await getObjectiveById(id, dispatch)
}

export const clearOnlySelectedObjective = dispatch => {
  dispatch({
    type: CLEAR_SELECTED_OBJECTIVE,
    payload: null
  })
}
export const clearSelectedObjective = dispatch => {
  dispatch({
    type: CLEAR_SELECTED_OBJECTIVE,
    payload: null
  })
  dispatch({
    type: UPDATE_SELECTED_AREA,
    payload: null
  })
}

export const deleteObjectiveMaturity = async (id, dispatch) => {
  const { data } = await api
    .delete(`/objectiveMaturity/${id}`)
    .then(response => response)
    .catch(err => console.log(err))
  if (data) {
    dispatch({
      type: DELETE_OBJECTIVE_MATURITY,
      id
    })
  }
}

export const getObjectiveMaturitiesByObjectveId = async (
  objectiveId,
  dispatch
) => {
  const { data } = await api.get(
    `/objectives/${objectiveId}/objectiveMaturities`
  )

  if (data) {
    dispatch({
      type: GET_OBJECTIVE_MATURITY,
      payload: data
    })
  }
}

export const initializeObjectiveMaturities = async dispatch => {
  dispatch({
    type: INITIALIZE_OBJECTIVE_MATURITY,
    payload: null
  })
}
