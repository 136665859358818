import React, { useEffect, useState } from 'react'
import chroma from 'chroma-js'
import Select from 'react-select'
import { useTranslation } from 'react-i18next'

const Leanit101Select = ({
  name,
  groupedOptions,
  selectedPosition,
  placeHolderText,
  onChange,
  width = 450
}) => {
  const [t] = useTranslation()

  const [selectedIndex, setSelectedIndex] = useState(-1)
  useEffect(() => {
    setSelectedIndex(selectedPosition)
  }, [selectedPosition])

  if (groupedOptions.length === 0) return null
  const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  }

  const groupBadgeStyles = {
    backgroundColor: '#149bb3',
    borderRadius: 2,
    color: 'white',
    display: 'inline-block',
    fontSize: 13,
    fontWeight: '100',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16em 0.5em',
    textAlign: 'center',
    textShadow: 'none'
  }

  const formatGroupLabel = data => (
    <div style={groupStyles}>
      <span>{data.label}</span>
      <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
  )

  const dot = (color = '#202638') => ({
    alignItems: 'center',
    display: 'flex',

    ':before': {
      backgroundColor: color,
      borderRadius: 10,
      content: '""',
      display: 'block',
      marginRight: 8,
      height: 10,
      width: 10
    }
  })

  const selectStyles = {
    control: styles => ({
      ...styles,
      color: 'dfe0e2',
      width: `${width}px`,
      maxHeight: 5,
      backgroundColor: 'vulcan',
      outLineStyle: 'none',
      border: 'solid 1px rgba(50, 50, 73, 0.42)'
    }),
    option: (styles, { isDisabled, isFocused, isSelected }) => {
      const color = chroma('#202638')
      return {
        ...styles,
        backgroundColor: isSelected
          ? '#3e465f'
          : isFocused
          ? color.alpha(0.1).css()
          : null,
        color: isDisabled
          ? 'vulcan'
          : isSelected
          ? chroma.contrast(color, 'white') > 2
            ? 'white'
            : 'black'
          : '#202638',
        cursor: isDisabled ? 'not-allowed' : 'default'
      }
    },
    input: styles => ({
      ...styles,
      color: '#ccc',
      caretColor: 'white',
      ...dot()
    }),
    placeholder: styles => ({
      ...styles,
      textShadow: 'none',
      ...dot()
    }),
    singleValue: styles => ({
      ...styles,
      color: '#ccc',
      ...dot()
    })
  }
  return (
    <Select
      value={groupedOptions[0].options[selectedIndex]}
      options={groupedOptions}
      formatGroupLabel={formatGroupLabel}
      isClearable
      isSearchable
      name={name}
      placeholder={placeHolderText}
      noOptionsMessage={() => t('select.no_options_found')}
      styles={selectStyles}
      onChange={onChange}
    />
  )
}

export default Leanit101Select
