import React, { useEffect, useState } from 'react'
import { getLoggedInUser } from 'auth'
import { useTranslation } from 'react-i18next'

import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import EditIcon from '@material-ui/icons/Edit'
import CheckIcon from '@material-ui/icons/Check'
import ClearIcon from '@material-ui/icons/Clear'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'

import { Confirm } from 'notiflix'

import * as auth from '../../auth'
import './TenantUserProfile.scss'
import '../../styles/loader.scss'
import ToggleButton from 'react-toggle-button'
import { updateUser } from 'redux/actions/userActions'
import { useDispatch, useSelector } from 'react-redux'
import {
  getNodesByOrganizationId,
  getPersonasByOrganizationId,
  getUsersByOrganizationId,
  inviteUsers,
  removeUserFromNode
} from 'redux/actions/treeActions'
import Leanit101Select from 'components/Select/Leanit101Select'

const TenantUserProfile = ({ userSelected, closeCallBack }) => {
  const [t] = useTranslation()
  const { nodes, personas } = useSelector(state => state.tree)

  const [isLoading, setIsLoading] = useState(false)
  const [toggleAddNode, setToggleAddNode] = useState(false)
  const [userData, setUserData] = useState(userSelected)
  const [userUpdateData, setUserUpdateData] = useState(null)
  const [toggleEditUser, setToggleEditUser] = useState(true)
  const [nodeOptions, setNodeOptions] = useState([])
  const [personaOptions, setPersonaOptions] = useState([])
  const [selectedPersona, setSelectedPersona] = useState(null)
  const [selectedRole, setSelectedRole] = useState('user')
  const dispatch = useDispatch()

  const user = getLoggedInUser()
  const organizationId = user.organization_id

  const tbColors = {
    activeThumb: {
      base: 'rgb(141, 160, 86)'
    },
    inactiveThumb: {
      base: 'rgba(96, 96, 120, 1)'
    },
    active: {
      base: 'rgba(50, 68, 73, 0.42)',
      hover: 'rgba(31, 31, 49, 1)'
    },
    inactive: {
      base: 'rgba(50, 68, 73, 0.42)',
      hover: 'rgba(31, 31, 49, 1)'
    }
  }

  Confirm.Init({
    titleColor: 'rgba(58, 157, 184, 1)',
    okButtonBackground: 'rgba(172, 2, 2, 1)',
    backgroundColor: 'rgb(40,40,60)',
    borderRadius: '5px',
    messageColor: 'white',
    zindex: 9999
  })

  useEffect(() => {
    setIsLoading(true)
    setUserData(userSelected)
    setIsLoading(false)
  }, [userSelected])

  useEffect(() => {
    const fetchData = async () => {
      await getNodesByOrganizationId(organizationId, dispatch)
      await getPersonasByOrganizationId(organizationId, dispatch)
    }
    setIsLoading(true)
    fetchData()
    setIsLoading(false)
  }, [organizationId, dispatch])

  useEffect(() => {
    if (nodes.length > 0) {
      const unselectedNodes = nodes.filter(node => {
        const userNodes = userData.nodes
        return !userNodes.map(userNode => userNode.id).includes(node.id)
      })
      const nodeOptions = [
        {
          label: t('manage_users.nodes'),
          options: unselectedNodes?.map(node => {
            return {
              label: node.name,
              value: node.id,
              node
            }
          })
        }
      ]

      renderNodes(userData)
      setNodeOptions(nodeOptions)
    }
  }, [nodes, userData.nodes])

  useEffect(() => {
    const personaOptions = [
      {
        label: t('manage_users.personas'),
        options: personas.map(persona => {
          return {
            label: persona.title,
            value: persona.id,
            persona
          }
        })
      }
    ]
    setPersonaOptions(personaOptions)
  }, [personas])

  const onChangePersona = e => {
    if (e) {
      setSelectedPersona(e.persona)
    } else {
      setSelectedPersona(null)
    }
  }

  const onChangeNode = e => {
    if (e && !userData.nodes.includes(e.node)) {
      Confirm.Show(
        t('manage_users.confirm.invite.title'),
        t('manage_users.confirm.invite.description'),
        t('manage_users.confirm.invite.yes'),
        t('manage_users.confirm.invite.no'),
        async () => {
          // Yes button callback
          setIsLoading(true)
          await inviteUsers(
            {
              invitedUsers: [
                {
                  id: userData.id,
                  name: userData.firstName,
                  email: userData.email,
                  role: userData.role,
                  avatarUrl: userData.avatarUrl,
                  persona: selectedPersona
                }
              ],
              selectedNodes: [e.node]
            },
            organizationId,
            user.id,
            dispatch
          )
          setUserData({
            ...userData,
            nodes: [...userData.nodes, e.node]
          })
          await getUsersByOrganizationId(user.organization_id, dispatch)
          await getNodesByOrganizationId(user.organization_id, dispatch)
          renderNodes(userData)
          setSelectedPersona(null)
          setToggleAddNode(false)
          setIsLoading(false)
        }
      )
    }
  }

  const removeNode = (nodeId, userId) => {
    Confirm.Show(
      t('manage_users.confirm.removal.title'),
      t('tenant_user_profile.cancel.description'),
      t('manage_users.confirm.removal.yes'),
      t('manage_users.confirm.removal.no'),
      async () => {
        setIsLoading(true)
        await removeUserFromNode(
          { nodeId, userId },
          organizationId,
          userId,
          dispatch
        )
        setUserData({
          ...userData,
          nodes: userData.nodes.filter(node => node.id !== nodeId)
        })

        await getUsersByOrganizationId(user.organization_id, dispatch)
        setSelectedPersona(null)
        renderNodes(userData)
        setIsLoading(false)
      }
    )
  }

  const renderNodes = user => {
    return user?.nodes?.map((node, index) => (
      <div key={node?.id} className='TenantUserProfile-selected-node-tag'>
        <span className='node-title'>
          {node?.name}
          <p style={{ height: 5 }}> &nbsp; </p>
          <small>{node?.nodeType?.name}</small>
        </span>
        {toggleAddNode && (
          <span>
            <HighlightOffIcon
              className='launch-icon'
              onClick={() => removeNode(node.id, user.id)}
            />
          </span>
        )}
      </div>
    ))
  }

  const handleCancel = e => {
    Confirm.Show(
      t('tenant_user_profile.confirm.title'),
      t('tenant_user_profile.cancel.description'),
      t('tenant_user_profile.confirm.yes'),
      t('tenant_user_profile.confirm.no'),
      async () => {
        setToggleEditUser(!toggleEditUser)
      }
    )
  }

  const handleSubmit = async e => {
    if (
      !userUpdateData.firstName ||
      !userUpdateData.surName ||
      !userUpdateData.email
    ) {
      window.toastr.error('you cannot leave any blank fields')
      return
    }
    e.preventDefault()
    if (userUpdateData !== userData || selectedRole !== userData.role) {
      Confirm.Show(
        t('tenant_user_profile.confirm.title'),
        t('tenant_user_profile.confirm.description'),
        t('tenant_user_profile.confirm.yes'),
        t('tenant_user_profile.confirm.no'),
        async () => {
          const data = {
            firstName: userUpdateData.firstName,
            surName: userUpdateData.surName,
            email: userUpdateData.email,
            role: selectedRole,
            isActive: userUpdateData.isActive
          }

          try {
            const response = await updateUser({ data, userSelected: userData })
            if (response.data.updatedUser.id === user.id) {
              auth.update(response.data.updatedUser)
            }
            setUserData({
              ...userUpdateData,
              role: selectedRole
            })
            setToggleEditUser(!toggleEditUser)
            await getUsersByOrganizationId(user.organization_id, dispatch)
          } catch (e) {
            console.error(e)
            window.toastr.error('Email or name are already in use')
          }
        }
      )
    } else {
      setToggleEditUser(!toggleEditUser)
    }
  }

  const getRoleClass = role => {
    switch (role) {
      case 'user':
        return 'role-user-selected'
      case 'admin':
        return 'role-admin-selected'
      case 'super':
        return 'role-super-selected'
      default:
        break
    }
  }

  return (
    <div className='TenantUserProfile'>
      <div className='TenantUserProfile-container z-depth-4'>
        <div className='TenantUserProfile-header'>
          <p>{t('tenant_user_profile.header_title')}</p>
          <span className='mt-1'>{user.organization_name}</span>
        </div>

        <div className='TenantUserProfile-body'>
          {(isLoading || !userData) && (
            <div className='d-flex flex-column justify-content-center align-items-center v100'>
              <div className='loader1' />
            </div>
          )}
          {!isLoading && (
            <>
              <div className='TenantUserProfile-leftPanel z-depth-1'>
                <div className='TenantUserProfile-user'>
                  <img
                    className='TenantUserProfile-profilePic'
                    src={userData?.avatarUrl}
                    alt={userData?.email}
                    data-toggle='tooltip'
                    title={userData?.email}
                    onError={e => {
                      e.target.src = '/images/avatar.png'
                    }}
                  />
                  <h1 className='TenantUserProfile-user-name'>{`${userData?.firstName} ${userData?.surName}`}</h1>
                  {toggleEditUser && (
                    <span
                      className='ml-auto'
                      data-toggle='tooltip'
                      title={t('')}
                    >
                      <EditIcon
                        className='launch-icon'
                        onClick={() => {
                          setToggleEditUser(!toggleEditUser)
                          setUserUpdateData(userData)
                          setSelectedRole(userData?.role)
                        }}
                      />
                    </span>
                  )}

                  {!toggleEditUser && (
                    <>
                      <span
                        className='ml-auto'
                        data-toggle='tooltip'
                        title={t('')}
                      >
                        <CheckIcon
                          className='launch-icon'
                          onClick={e => handleSubmit(e)}
                        />
                      </span>
                      <span className='' data-toggle='tooltip' title={t('')}>
                        <ClearIcon
                          className='launch-icon'
                          onClick={() => {
                            userUpdateData !== userData
                              ? handleCancel()
                              : setToggleEditUser(!toggleEditUser)
                          }}
                        />
                      </span>
                    </>
                  )}
                </div>

                <div className='TenantUserProfile-user-data'>
                  <p className='TenantUserProfile-user-field-set'>
                    <span className='TenantUserProfile-user-field-title'>
                      Status
                    </span>

                    {toggleEditUser && (
                      <ToggleButton
                        colors={tbColors}
                        value={userData?.isActive}
                        inactiveLabel=''
                        activeLabel=''
                        trackStyle={{
                          height: '20px',
                          width: '40px',
                          margin: 'auto',
                          padding: '0px'
                        }}
                      />
                    )}
                    {!toggleEditUser && (
                      <ToggleButton
                        colors={tbColors}
                        inactiveLabel=''
                        activeLabel=''
                        trackStyle={{
                          height: '20px',
                          width: '40px',
                          margin: 'auto',
                          padding: '0px'
                        }}
                        value={userUpdateData?.isActive}
                        onToggle={e => {
                          setUserUpdateData({
                            ...userUpdateData,
                            isActive: !userUpdateData.isActive
                          })
                        }}
                      />
                    )}
                  </p>
                  <p className='TenantUserProfile-user-field-set'>
                    <span className='TenantUserProfile-user-field-title'>
                      {t('account_form_page.fields.first_name')}
                    </span>

                    {toggleEditUser && (
                      <span className='TenantUserProfile-user-field-content'>{`${userData?.firstName}`}</span>
                    )}
                    {!toggleEditUser && (
                      <input
                        name='name'
                        className='TenantUserProfile-user-input'
                        type='text'
                        placeholder={t('tenant_user_profile.first_name')}
                        value={userUpdateData?.firstName}
                        onChange={e => {
                          setUserUpdateData({
                            ...userUpdateData,
                            firstName: e.target.value
                          })
                        }}
                      />
                    )}
                  </p>

                  <p className='TenantUserProfile-user-field-set'>
                    <span className='TenantUserProfile-user-field-title'>
                      {t('account_form_page.fields.surname')}
                    </span>

                    {toggleEditUser && (
                      <span className='TenantUserProfile-user-field-content'>{`${userData?.surName}`}</span>
                    )}
                    {!toggleEditUser && (
                      <input
                        name='name'
                        className='TenantUserProfile-user-input'
                        type='text'
                        placeholder={t('tenant_user_profile.surname')}
                        value={userUpdateData?.surName}
                        onChange={e => {
                          setUserUpdateData({
                            ...userUpdateData,
                            surName: e.target.value
                          })
                        }}
                      />
                    )}
                  </p>

                  <p className='TenantUserProfile-user-field-set'>
                    <span className='TenantUserProfile-user-field-title'>
                      {t('account_form_page.fields.email')}
                    </span>

                    {toggleEditUser && (
                      <span className='TenantUserProfile-user-field-content'>{`${userData?.email}`}</span>
                    )}

                    {!toggleEditUser && (
                      <input
                        name='name'
                        className='TenantUserProfile-user-input'
                        type='text'
                        placeholder={t('tenant_user_profile.email')}
                        value={userUpdateData?.email}
                        onChange={e => {
                          setUserUpdateData({
                            ...userUpdateData,
                            email: e.target.value
                          })
                        }}
                      />
                    )}
                  </p>

                  <p className='TenantUserProfile-user-field-set'>
                    <span className='mr-auto TenantUserProfile-user-field-title'>
                      {t('account_form_page.fields.role')}
                    </span>
                    {toggleEditUser && (
                      <span className='TenantUserProfile-user-field-content'>
                        {() => {
                          setSelectedRole(userData?.role)
                        }}
                        <div
                          className={`TenantUserProfile-role ${getRoleClass(
                            userData?.role
                          )}`}
                        >
                          {t(`tenant_user_profile.${userData?.role}`)}
                        </div>
                      </span>
                    )}

                    {!toggleEditUser && (
                      <div className='TenantUserProfile-roles'>
                        <div
                          className={`TenantUserProfile-role ${
                            selectedRole === 'user' && 'role-user-selected'
                          }`}
                          onClick={() => setSelectedRole('user')}
                        >
                          {t('tenant_user_profile.user')}
                        </div>
                        <div
                          className={`TenantUserProfile-role ${
                            selectedRole === 'admin' && 'role-admin-selected'
                          }`}
                          onClick={() => setSelectedRole('admin')}
                        >
                          {t('tenant_user_profile.admin')}
                        </div>
                        <div
                          className={`TenantUserProfile-role ${
                            selectedRole === 'super' && 'role-super-selected'
                          }`}
                          onClick={() => setSelectedRole('super')}
                        >
                          {t('tenant_user_profile.super')}
                        </div>
                      </div>
                    )}
                  </p>
                </div>
              </div>
              <div className='TenantUserProfile-rightPanel z-depth-1'>
                <p className='ml-1 d-flex'>
                  {t('tenant_user_profile.nodes')}
                  <div className='ml-auto'>
                    <AddCircleOutlineIcon
                      className='launch-icon'
                      onClick={() => {
                        setToggleAddNode(!toggleAddNode)
                        setSelectedPersona(null)
                      }}
                    />
                  </div>
                </p>

                <div className='TenantUserProfile-add-nodes'>
                  {toggleAddNode && (
                    <span className='animated fadeInDown'>
                      <div className='animated fadeInDown d-flex mt-2 justify-content-center'>
                        <Leanit101Select
                          name='persona'
                          groupedOptions={personaOptions}
                          placeHolderText={t(
                            'tenant_user_profile.select_personas'
                          )}
                          onChange={onChangePersona}
                          width='400'
                        />
                      </div>
                      {selectedPersona && (
                        <div className='animated fadeInDown d-flex mt-2 justify-content-center'>
                          <Leanit101Select
                            name='node'
                            groupedOptions={nodeOptions}
                            placeHolderText={t(
                              'tenant_user_profile.select_nodes'
                            )}
                            onChange={onChangeNode}
                            width='400'
                          />
                        </div>
                      )}
                    </span>
                  )}
                </div>

                <div className='TenantUserProfile-nodes'>
                  {renderNodes(userData)}
                </div>
              </div>
            </>
          )}
        </div>

        <div className='TenantUserProfile-footer'>
          <button
            className='TenantUserProfile-close-button'
            onClick={() => {
              closeCallBack(null)
            }}
          >
            {t('label.close')}
          </button>
        </div>
      </div>
    </div>
  )
}

export default TenantUserProfile
