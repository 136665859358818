import * as d3 from 'd3'
import './BubbleChart.scss'
import { isEmpty } from 'lodash'
import { scaleLinear } from 'd3'

export const clearBubbleChart = element => {
  d3.select(element).select('svg').remove()
}

export const drawBubbleChart = (
  element,
  radarChartData,
  data,
  toggleBranches
) => {
  if (!data) return
  if (!isEmpty(radarChartData)) return

  const margin = 20
  const diameter = 760

  const chooseColor = depth => {
    const colors = [
      '#1f77b4',
      '#ff7f0e',
      '#2ca02c',
      '#d62728',
      '#9467bd',
      '#8c564b',
      '#e377c2',
      '#7f7f7f',
      '#bcbd22',
      '#17becf'
    ]
    return colors[depth % 10]
  }

  const color = scaleLinear()
    .domain([0, depthCount(data)])
    .range(['hsl(240, 23%, 16%)', 'hsl(228,30%,40%)'])
    .interpolate(d3.interpolateHcl)

  const packLayout = d3.pack().size([1000, 1000]).padding(3)

  const root = d3.hierarchy(data)

  root
    .sum(function (d) {
      return d.value > 0 ? d.value : 1
    })
    .sort((a, b) => b.value - a.value)

  packLayout(root)

  d3.select(element).select('svg').remove()

  const svg = d3
    .select(element)
    .append('svg')
    .attr('width', diameter)
    .attr('height', diameter)
    .append('g')
    .attr('width', diameter)
    .attr('height', diameter)
    .attr('transform', 'translate(' + diameter / 2 + ',' + diameter / 2 + ')')

  let focus = root

  let view

  const circulo = svg
    .selectAll('circle')
    .data(root)
    .enter()
    .append('circle')
    .attr('class', function (d) {
      return d.parent
        ? d.children
          ? 'node'
          : 'node node--leaf'
        : 'node node--root'
    })
    .attr('class', function (d) {
      return 'node node-color node-color-' + (d.depth % 10)
    })
    .style('fill', function (d) {
      return d.children ? color(d.depth) : 'rgba(27, 27, 44, .5)'
    })
    .on('click', async function (event, d) {
      await toggleBranches(d)
      if (focus !== d) {
        zoom(event, d)
        event.stopPropagation()
      }
    })

  svg
    .selectAll('text')
    .data(root)
    .enter()
    .append('text')
    .attr('class', 'label')
    .attr('fill', function (d) {
      return chooseColor(d.depth)
    })
    .style('fill-opacity', function (d) {
      return d.parent === root ? 0 : 1
    })
    .style('display', function (d) {
      return d.parent === root ? 'null' : 'none'
    })
    .attr('x', 0)
    .attr('dy', d => {
      return d === focus ? -20 : 0
    })
    .style('text-transform', 'capitalize')
    .text(function (d) {
      return d.data.name
    })
    .append('svg:tspan')
    .attr('x', 0)
    .attr('dy', 20)
    .text(function (d) {
      return d.data.value + ' members'
    })
    .append('svg:tspan')
    .attr('x', 0)
    .attr('dy', 20)
    .text(function (d) {
      return !d.children ? d.data.description : ''
    })
    .style('font-size', '1rem')

  const node = svg.selectAll('circle,text')
  zoom(null, root)

  d3.select(
    'div#dashboardBubbleContainer.d-flex.justify-content-start.text-white'
  ).on('click', function (event) {
    zoom(event, root)
  })

  zoomTo([root.x, root.y, root.r * 2 + margin])

  function zoom(event, d) {
    focus = d
    const transition = d3
      .transition()
      .duration(750)
      .tween('zoom', function (d) {
        const i = d3.interpolateZoom(view, [
          focus.x,
          focus.y,
          focus.r * 2 + margin
        ])
        return function (t) {
          zoomTo(i(t))
        }
      })

    transition
      .selectAll('text')
      // .filter(function (d) { // this filter makes it so text only appears once you click the node once, unsure of what the original purpose was
      //   return this.style.display === 'inline' || d === focus
      // })
      .style('fill-opacity', function (d) {
        return d.parent === focus || (!d.children && d === focus) ? 1 : 0
      })
      .on('start', function (d) {
        if (d.parent === focus || (!d.children && d === focus)) {
          this.style.display = 'inline'
        }
      })
      .on('end', function (d) {
        // the following is commented to allow leaf nodes info to be presented
        // although it's a little buggy, better solution TBD
        // console.log('d', d)
        if (!(d.parent === focus || (!d.children && d === focus))) {
          this.style.display = 'none'
        }
      })
  }

  function zoomTo(v) {
    const k = diameter / v[2]
    view = v
    node.attr('transform', function (d) {
      return 'translate(' + (d.x - v[0]) * k + ',' + (d.y - v[1]) * k + ')'
    })
    circulo.attr('r', function (d) {
      return d.r * k
    })
  }

  function depthCount(branch) {
    if (!branch.children) {
      return 1
    }
    return 1 + d3.max(branch.children.map(depthCount))
  }

  d3.select(window.frameElement).style('height', diameter + 'px')
}
