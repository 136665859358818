import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import classnames from 'classnames'
import { Confirm, Report, Notify } from 'notiflix'

import Slider from '@material-ui/core/Slider'

import './MaturityLevel.scss'

import {
  createMaturityLevel,
  updateMaturityLevel,
  handleRowsChange,
  updateRow
} from 'redux/actions/maturityLevelActions'

const MaturityLevel = () => {
  const [t] = useTranslation()
  const dispatch = useDispatch()

  const { assessment } = useSelector(state => state)
  const state = useSelector(state => state)
  const { maturityLevel: maturityLevelState } = state
  const { rows, numberOfEnabledRows } = maturityLevelState
  const [enabledRows, setEnabledRows] = useState(0)

  Confirm.Init({
    titleColor: 'rgba(58, 157, 184, 1)',
    okButtonBackground: 'rgba(58, 157, 184, 1)',
    backgroundColor: 'rgb(40,40,60)',
    borderRadius: '5px',
    messageColor: 'white'
  })
  Report.Init({
    backgroundColor: 'rgb(40,40,60)',
    borderRadius: '5px',
    success: {
      titleColor: 'rgba(58, 157, 184, 1)',
      svgColor: 'rgba(58, 157, 184, 1)',
      buttonBackground: 'rgba(58, 157, 184, 1)',
      backOverlayColor: 'rgba(0,0,0,0.5)',
      messageColor: 'white'
    }
  })
  Notify.Init({
    position: 'left-bottom',
    zindex: 9999
  })

  useEffect(() => {
    setEnabledRows(numberOfEnabledRows)
  }, [numberOfEnabledRows])

  const renderReport = errorMsg => Notify.Failure(errorMsg)

  const renderRow = row => {
    const className = classnames('MaturityLevel-row', {
      'MaturityLevel-row--disabled': row.position > enabledRows
    })

    const processMaturityChange = e => {
      if ('id' in row && row.id !== null) {
        const { enabled, ...maturityToUpdate } = row
        updateMaturityLevel({ ...maturityToUpdate }, dispatch)
      } else {
        if (row.name && row.description) {
          createMaturityLevel(
            { ...row, assessment_id: assessment.id },
            dispatch
          )
        } else {
        }
      }
    }

    return (
      <div key={row.position} className={className}>
        <div className='MaturityLevel-countBox'>
          <span>{row.position}</span>
        </div>
        <input
          name='name'
          className='MaturityLevel-rowName'
          type='text'
          placeholder={t('label.maturity_name')}
          value={row.name}
          onChange={e =>
            updateRow(
              row.position,
              e.target.name,
              e.target.value,
              rows,
              dispatch
            )
          }
          onBlur={processMaturityChange}
          disabled={row.position > enabledRows || assessment.isDeployed}
        />

        <textarea
          name='description'
          className='MaturityLevel-rowDescription'
          placeholder={t('label.maturity_description')}
          value={row.description}
          onChange={e =>
            updateRow(
              row.position,
              e.target.name,
              e.target.value,
              rows,
              dispatch
            )
          }
          onBlur={processMaturityChange}
          disabled={row.position > enabledRows || assessment.isDeployed}
        />
      </div>
    )
  }

  const handleNumberOfRowsChange = async (e, value) => {
    if (numberOfEnabledRows > value && rows[value].id) {
      Confirm.Show(
        t('maturity_level.confirm.title'),
        t('maturity_level.confirm.description'),
        t('maturity_level.confirm.yes'),
        t('maturity_level.confirm.no'),
        () => {
          Report.Success(
            t('maturity_level.report.deleted.title'),
            t('maturity_level.report.deleted.description'),
            t('maturity_level.report.deleted.ok')
          )
          handleRowsChange(value, rows, dispatch).then(resp => {
            if (resp.status === 500) {
              renderReport(resp.errorMsg)
            }
          })
        }
      )
    } else {
      handleRowsChange(value, rows, dispatch)
    }
  }

  const byPosition = (a, b) => {
    if (a.position < b.position) {
      return -1
    }
    if (a.position < b.position) {
      return 1
    }
    return 0
  }

  return (
    <div className='MaturityLevel'>
      <div className='d-flex align-items-center'>
        <label className='MaturityLevel-label'>
          {t('maturity_level.title')}
        </label>
        <div className='ml-auto MaturityLevel-countBox'>
          <div className='MatutrityLevel-countValue'>{enabledRows}</div>
        </div>
      </div>

      <Slider
        min={0}
        max={10}
        value={enabledRows}
        onChange={handleNumberOfRowsChange}
        disabled={!assessment.id || assessment.isDeployed}
        className='MaturityLevel-slider'
      />

      {rows ? rows.sort(byPosition).map(renderRow) : null}
    </div>
  )
}

export default MaturityLevel
