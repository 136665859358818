import { getLoggedInUser } from 'auth'
import Leanit101Select from 'components/Select/Leanit101Select'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import {
  createNode,
  toggleNodeFormCreate,
  updateNode
} from 'redux/actions/treeActions'
import { Confirm } from 'notiflix'

import './NodeForm.scss'
import { getNodeTypesByOrganizationId } from 'redux/actions/nodeTypeActions'
import { clearDashboard } from 'redux/actions/dashboardActions'

const NodeForm = ({ parentNode, node }) => {
  const [nodeType, setNodeType] = useState(null)
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [groupedOptions, setGroupedOptions] = useState([])
  const [t] = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const [isInvalid, setIsInvalid] = useState(false)
  const dispatch = useDispatch()

  const user = getLoggedInUser()
  const organizationId = user.organization_id

  Confirm.Init({
    titleColor: 'rgba(58, 157, 184, 1)',
    okButtonBackground: '#d4a302',
    backgroundColor: 'rgb(40,40,60)',
    borderRadius: '5px',
    messageColor: 'white',
    zindex: 9999
  })

  const clearForm = () => {
    setName('')
    setDescription('')
    setNodeType(null)
    document.getElementById('name').focus()
  }

  useEffect(() => {
    setIsInvalid(!name || !nodeType)
  }, [name, nodeType])

  useEffect(() => {
    if (node?.id) {
      setName(node.name)
      setDescription(node.description)
      document.getElementById('name').focus()
    }
  }, [node])

  useEffect(() => {
    const fetchData = async () => {
      const nodeTypes = await getNodeTypesByOrganizationId(
        organizationId,
        dispatch
      )
      const groupedOptions = [
        {
          label: t('node_form.node_types'),
          options: nodeTypes.map(nodeType => {
            return {
              label: nodeType.name,
              value: nodeType.id,
              nodeType
            }
          })
        }
      ]
      setGroupedOptions(groupedOptions)
      if (groupedOptions.length > 0 && node) {
        const selectedNodeIdIndex =
          node?.id && groupedOptions.length > 0
            ? groupedOptions[0].options.findIndex(
                item => item.nodeType.id === node.node_type_id
              )
            : null
        if (selectedNodeIdIndex > -1 && groupedOptions.length > 0) {
          setNodeType(groupedOptions[0].options[selectedNodeIdIndex].nodeType)
        }
      }
    }
    fetchData()
    document.getElementById('name').focus()
  }, [organizationId, node, dispatch])

  const onChange = e => {
    switch (e.target.name) {
      case 'name':
        setName(e.target.value)
        break
      case 'description':
        setDescription(e.target.value)
        break
      default:
        break
    }
  }

  const onChangeNodeType = e => {
    if (e) {
      setNodeType(e.nodeType)
      if (node?.node_type_id) {
        node.node_type_id = e.nodeType.id
      }
    } else {
      setNodeType(null)
    }
  }

  const handleCreateNode = async e => {
    e && e.stopPropagation()
    setIsLoading(true)
    const newNode = {
      parent_id: parentNode.id,
      node_type_id: nodeType.id,
      organization_id: organizationId,
      name,
      description
    }
    await createNode(newNode, user.id, dispatch)
    clearForm()
    toggleNodeFormCreate({ nodeFormToggled: false, node: null }, dispatch)
    setIsLoading(false)
  }

  const handleUpdateNode = e => {
    e && e.stopPropagation()
    clearDashboard(organizationId, user.id, dispatch)

    if (node && node.id) {
      setIsLoading(true)
      const nodeToUpdate = { ...node }
      delete nodeToUpdate.children
      updateNode(
        { ...nodeToUpdate, name, description, node_type_id: nodeType.id },
        user.id,
        dispatch
      )
      clearForm()
      toggleNodeFormCreate({ nodeFormToggled: false, node: null }, dispatch)
      setIsLoading(false)
    }
  }

  const confirmArchive = () => {
    Confirm.Show(
      t('node_form.confirm.title'),
      t('node_form.confirm.description'),
      t('node_form.confirm.yes'),
      t('node_form.confirm.no'),
      async () => {
        if (node && node.id) {
          setIsLoading(true)
          updateNode({ ...node, isArchived: 1 }, user.id, dispatch)
          clearForm()
          toggleNodeFormCreate({ nodeFormToggled: false, node: null }, dispatch)
          setIsLoading(false)
        }
      }
    )
  }

  const handleArchiveNode = e => {
    e && e.stopPropagation()
    confirmArchive()
  }

  const selectedNodeIdIndex =
    node?.id && groupedOptions.length > 0
      ? groupedOptions[0].options.findIndex(
          item => item.nodeType.id === node.node_type_id
        )
      : null

  return (
    <div className='NodeForm animated fadeIn'>
      <div className='NodeForm-container'>
        <div className='NodeForm-header'>
          <p>{node?.id ? t('node_form.edit_node') : t('node_form.new_node')}</p>
          <span className='mt-1'>{node?.id ? node.name : parentNode.name}</span>
        </div>
        <div className='p-2 mt-2 d-flex justify-content-center'>
          <Leanit101Select
            name='nodeTypeId'
            groupedOptions={groupedOptions}
            selectedPosition={selectedNodeIdIndex}
            placeHolderText={t('node_form.select_node_type')}
            onChange={onChangeNodeType}
            width='460'
          />
        </div>
        <div className='NodeForm-input'>
          <input
            autoFocus
            type='text'
            maxLength={35}
            name='name'
            id='name'
            placeholder={t('node_form.node_name')}
            value={name}
            onChange={onChange}
          />
          {name.length > 15 && (
            <small className='blink-me' style={{ color: '#e438db' }}>
              &nbsp;
              {35 - name.length}
            </small>
          )}
        </div>
        <div className='NodeForm-texarea'>
          <textarea
            name='description'
            id='description'
            placeholder={t('label.description')}
            value={description}
            cols='30'
            rows='10'
            onChange={onChange}
          />
        </div>
        <div className='NodeForm-button-group'>
          {node && node.id && (
            <button
              className='NodeForm-updateButton'
              disabled={isLoading || isInvalid}
              onClick={handleUpdateNode}
            >
              {t('label.update')}
            </button>
          )}
          {!node && (
            <button
              className='NodeForm-createButton'
              disabled={isLoading || isInvalid}
              onClick={handleCreateNode}
            >
              {t('label.create')}
            </button>
          )}
          {node && node.id && (
            <button
              className='NodeForm-archiveButton'
              disabled={isLoading || isInvalid}
              onClick={handleArchiveNode}
            >
              {t('label.archive')}
            </button>
          )}
          <button
            className='NodeForm-cancelButton'
            onClick={() => {
              toggleNodeFormCreate(
                { nodeFormToggled: false, node: null },
                dispatch
              )
            }}
          >
            {t('label.cancel')}
          </button>
        </div>
      </div>
    </div>
  )
}

export default NodeForm
