import { GET_USER_BY_ID } from '../actionTypes'

const initialState = {
  avatarUrl: null,
  email: null,
  firstName: null,
  id: null,
  isActive: true,
  nodes: [],
  organization_id: null,
  phoneNumber: null,
  role: null,
  surName: null
}

const userReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_USER_BY_ID:
      return getUserById(action, state)
    // case UPLOAD_PROFILE_PICTURE:
    //   return uploadFile(action, state)
    default:
      return state
  }
}

const getUserById = (action, state) => {
  return {
    email: action.payload.email,
    firstName: action.payload.firstName,
    isActive: action.payload.isActive,
    nodes: action.payload.nodes,
    role: action.payload.role,
    surName: action.payload.surName,
    avatarUrl: action.payload.avatarUrl
  }
}

// const uploadFile = (action, state) => {
//   return {
//     ...state,
//     avatarUrl: action.payload.avatarUrl
//   }
// }

export default userReducer
