import React, { useState, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toInteger } from 'lodash'
import { DateRange } from 'react-date-range'
import { useTranslation } from 'react-i18next'

import { AccessTime } from '@material-ui/icons'

import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'

import './SelectTemplates.scss'
import {
  getCompleteTemplatesByOrganizationId,
  getPublicTemplates,
  getTemplatesByOrganizationId
} from 'redux/actions/assessmentActions'
import { getLoggedInUser } from 'auth'

const SelectTemplates = ({ onSelectTemplate }) => {
  const [t] = useTranslation()

  const { templates, completeTemplates, publicTemplates } = useSelector(
    state => state.template
  )
  const shortName = useRef()
  const shortDescription = useRef()

  const dispatch = useDispatch()
  const user = getLoggedInUser()
  const organizationId = user.organization_id

  useEffect(() => {
    const fetchData = async () => {
      await getTemplatesByOrganizationId(organizationId, dispatch)
      await getCompleteTemplatesByOrganizationId(organizationId, dispatch)
      await getPublicTemplates(dispatch)
    }
    fetchData()
  }, [organizationId, dispatch])

  const [assessmentDate, setAssessmentDate] = useState({
    startDate: new Date(),
    endDate: new Date(),
    color: '#338ba7',
    key: 'selection'
  })

  const [openForm, setOpenForm] = useState({
    startDate: new Date(),
    endDate: new Date(),
    color: '#338ba7',
    key: 'selection'
  })

  const renderSelect = () => {
    return (
      <select
        id='templates'
        onChange={e => {
          const setAssessmentData = async (i, templates) => {
            shortName.current.value = templates[i].name
            shortDescription.current.value = templates[i].description
            setAssessmentDate({
              ...assessmentDate,
              startDate: new Date(templates[i].startDate),
              endDate: new Date(templates[i].endDate)
            })
            onSelectTemplate('templateId', Number(e.target.value))
            onSelectTemplate('shortName', templates[i].name)
            onSelectTemplate('shortDescription', templates[i].description)
            onSelectTemplate('startDate', new Date(templates[i].startDate))
            onSelectTemplate('endDate', new Date(templates[i].endDate))
            onSelectTemplate('openFormDate', new Date(templates[i].startDate))
            onSelectTemplate('closeFormDate', new Date(templates[i].endDate))
          }

          const i = templates.findIndex(
            template => template.id === toInteger(e.target.value)
          )
          if (i > -1) {
            setAssessmentData(i, templates)
          } else {
            const j = publicTemplates.findIndex(
              template => template.id === toInteger(e.target.value)
            )
            setAssessmentData(j, publicTemplates)
          }
        }}
        className='z-depth-2'
      >
        <option selected disabled>
          {t('select_templates.options.select')}
        </option>
        {publicTemplates?.length > 0 && (
          <optgroup label={t('select_templates.options.group.public_template')}>
            {publicTemplates?.map(opt => {
              return (
                <option key={opt.id} value={opt?.id}>
                  {opt.name}
                </option>
              )
            })}
          </optgroup>
        )}
        <optgroup label={t('select_templates.options.group.private_template')}>
          {completeTemplates?.map(opt => {
            return (
              <option key={opt?.id} value={opt?.id}>
                {opt?.name}
              </option>
            )
          })}
        </optgroup>
      </select>
    )
  }

  return (
    <div className='SelectTemplates-container'>
      <div className='SelectTemplates-form-group'>{renderSelect()}</div>
      <div className='text-center SelectTemplates-formField d-flex justify-content-between'>
        <label htmlFor='shortName'>
          {t('label.short_name')}
          <input
            className='SelectTemplates-input z-depth-2'
            id='shortName'
            ref={shortName}
            type='text'
            onChange={e => onSelectTemplate('shortName', e.target.value)}
          />
        </label>
        <label htmlFor='shortDescription'>
          {t('select_templates.description')}
          <textarea
            className='SelectTemplates-input z-depth-2'
            id='shortDescription'
            type='text'
            ref={shortDescription}
            onChange={e => onSelectTemplate('shortDescription', e.target.value)}
          />
        </label>
      </div>
      <div className='d-flex justify-content-between mt-4'>
        <div>
          <label
            htmlFor='startDate'
            className='d-flex justify-content-center align-items-center mb-3'
          >
            <AccessTime />
            &nbsp;&nbsp;
            {t('select_templates.assessment_period')}
          </label>
          <div className='d-flex justify-content-center mb-2'>
            <span className='SelectTemplates-date-label'>
              {t('label.start_date')}
            </span>
            <span className='SelectTemplates-date-label'>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>

            <span className='SelectTemplates-date-label'>
              {t('label.end_date')}
            </span>
          </div>
          <div>
            <DateRange
              className='z-depth-2'
              editableDateInputs
              color='#FFF'
              onChange={item => {
                setAssessmentDate(item.selection)
                onSelectTemplate(
                  'startDate',
                  item.selection.startDate.toISOString()
                )
                onSelectTemplate(
                  'endDate',
                  item.selection.endDate.toISOString()
                )
              }}
              moveRangeOnFirstSelection={false}
              ranges={[assessmentDate]}
            />
          </div>
        </div>
        <div>
          <div>
            <label
              htmlFor='startDate'
              className='d-flex justify-content-center align-items-center mb-3'
            >
              <AccessTime />
              &nbsp;&nbsp;
              {t('select_templates.form_period')}
            </label>
            <div className='d-flex justify-content-center mb-2'>
              <span className='SelectTemplates-date-label'>
                {t('label.start_date')}
              </span>
              <span>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>

              <span className='SelectTemplates-date-label'>
                {t('label.end_date')}
              </span>
            </div>
            <DateRange
              className='z-depth-2'
              editableDateInputs
              color='#FFF'
              onChange={item => {
                setOpenForm(item.selection)
                onSelectTemplate(
                  'openFormDate',
                  new Date(item.selection.startDate.toISOString())
                )
                onSelectTemplate(
                  'closeFormDate',
                  new Date(item.selection.endDate.toISOString())
                )
              }}
              moveRangeOnFirstSelection={false}
              ranges={[openForm]}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SelectTemplates
