// Area actions
export const CREATE_AREA = 'CREATE_AREA'
export const DELETE_AREA = 'DELETE_AREA'
export const SET_AREAS = 'SET_AREAS'
export const CLEAR_AREAS = 'CLEAR_AREAS'
export const UPDATE_AREA = 'UPDATE_AREA'

// Persona actions
export const FETCH_PERSONAS_FOR_ORGANIZATION_ID =
  'FETCH_PERSONAS_FOR_ORGANIZATION_ID'
export const TOGGLE_PERSONA_MANAGE = 'TOGGLE_PERSONA_MANAGE'
export const TOGGLE_PERSONA_FORM = 'TOGGLE_PERSONA_FORM'

// Assessment actions
export const CREATE_ASSESSMENT = 'CREATE_ASSESSMENT'
export const GET_ASSESSMENT = 'GET_ASSESSMENT'
export const SET_ASSESSMENT = 'SET_ASSESSMENT'
export const GET_ASSESSMENT_COMPLETION = 'GET_ASSESSMENT_COMPLETION'
export const UPDATE_ASSESSMENT = 'UPDATE_ASSESSMENT'
export const INITIALIZE_ASSESSMENT = 'INITIALIZE_ASSESSMENT'
export const TOGGLE_TRIGGER_ASSESSMENT = 'TOGGLE_TRIGGER_ASSESSMENT'
export const TOGGLE_MATURITY_MATRIX = 'TOGGLE_MATURITY_MATRIX'

// Template Actions
export const GET_TEMPLATES_BY_ORGANIZATION_ID =
  'GET_TEMPLATES_BY_ORGANIZATION_ID'
export const GET_COMPLETE_TEMPLATES_BY_ORGANIZATION_ID =
  'GET_COMPLETE_TEMPLATES_BY_ORGANIZATION_ID'
export const GET_DEPLOYED_TEMPLATES_BY_ORGANIZATION_ID =
  'GET_DEPLOYED_TEMPLATES_BY_ORGANIZATION_ID'
export const TOGGLE_APPLY_FORM = 'TOGGLE_APPLY_FORM'
export const TOGGLE_MATURITY_MODEL_FORM = 'TOGGLE_MATURITY_MODEL_FORM'
export const TOGGLE_NODE_FORM_CREATE = 'TOGGLE_NODE_FORM_CREATE'
export const TOGGLE_NODE_FORM_EDIT = 'TOGGLE_NODE_FORM_EDIT'
export const GET_PUBLIC_TEMPLATES = 'GET_PUBLIC_TEMPLATES'
export const GET_SUBMITTED_PUBLIC_TEMPLATES = 'GET_SUBMITTED_PUBLIC_TEMPLATES'

// Recommendation actions
export const CREATE_RECOMMENDATION = 'CREATE_RECOMMENDATION'
export const UPDATE_RECOMMENDATION = 'UPDATE_RECOMMENDATION'
export const GET_RECOMMENDATIONS_BY_ORGANIZATION_ID =
  'GET_RECOMMENDATIONS_BY_ORGANIZATION_ID'
export const GET_RECOMMENDATIONS_BY_OBJECTIVE_ID =
  'GET_RECOMMENDATIONS_BY_OBJECTIVE_ID'

// Objective actions
export const CREATE_OBJECTIVE = 'CREATE_OBJECTIVE'
export const CLEAR_OBJECTIVE = 'CLEAR_OBJECTIVE'
export const DELETE_OBJECTIVES = 'DELETE_OBJECTIVES'
export const GET_OBJECTIVE = 'GET_OBJECTIVE'
export const SET_OBJECTIVES = 'SET_OBJECTIVES'
export const UPDATE_OBJECTIVE = 'UPDATE_OBJECTIVE'
export const INITIALIZE_OBJECTIVE = 'INITIALIZE_OBJECTIVE'

// Objective maturity actions
export const CLEAR_SELECTED_OBJECTIVE = 'CLEAR_SELECTED_OBJECTIVE'
export const CREATE_OBJECTIVE_MATURITY = 'CREATE_OBJECTIVE_MATURITY'
export const DELETE_OBJECTIVE_MATURITY = 'DELETE_OBJECTIVE_MATURITY'
export const GET_OBJECTIVE_MATURITY = 'GET_OBJECTIVE_MATURITY'
export const UPDATE_OBJECTIVE_MATURITY = 'UPDATE_OBJECTIVE_MATURITY'
export const UPDATE_SELECTED_AREA = 'UPDATE_SELECTED_AREA'
export const UPDATE_SELECTED_OBJECTIVE = 'UPDATE_SELECTED_OBJECTIVE'
export const INITIALIZE_OBJECTIVE_MATURITY = 'INITIALIZE_OBJECTIVE_MATURITY'

// Choice actions
export const ADD_CHOICE = 'ADD_CHOICE'
export const DELETE_CHOICE = 'DELETE_CHOICE'
export const UPDATE_CHOICE = 'UPDATE_CHOICE'

// Question actions
export const CREATE_QUESTION = 'CREATE_QUESTION'
export const DELETE_QUESTION = 'DELETE_QUESTION'
export const NEW_QUESTION = 'NEW_QUESTION'
export const SELECT_QUESTION = 'SELECT_QUESTION'
export const UPDATE_QUESTION = 'UPDATE_QUESTION'
export const SET_QUESTIONS = 'SET_QUESTIONS'
export const INITIALIZE_QUESTION = 'INITIALIZE_QUESTION'
export const UPDATE_SELECTED_QUESTION_OBJECTIVE =
  'UPDATE_SELECTED_QUESTION_OBJECTIVE'

// Matuirty level actions
export const CREATE_MATURITY_LEVEL = 'CREATE_MATURITY_LEVEL'
export const GET_MATURITY_LEVELS = 'GET_MATURITY_LEVELS'
export const SET_MATURITY_LEVELS = 'SET_MATURITY_LEVELS'
export const GET_MATURITY_LEVELS_BY_ASSESSMENT_ID =
  'GET_MATURITY_LEVELS_BY_ASSESSMENT_ID'
export const HANDLE_ROWS_CHANGE = 'HANDLE_ROWS_CHANGE'
export const UPDATE_MATURITY_LEVEL = 'UPDATE_MATURITY_LEVEL'
export const UPDATE_MATURITY_LEVEL_ROWS = 'UPDATE_MATURITY_LEVEL_ROWS'
export const INITIALIZE_MATURITY_LEVELS = 'INITIALIZE_MATURITY_LEVELS'

// User actions
export const SET_CURRENT_USER = 'SET_CURRENT_USER'
export const AUTH_FAILED = 'AUTH_FAILED'
export const GET_USER_BY_ID = 'GET_USER_BY_ID'

// Tree actions
export const FETCH_TREE_DATA = 'FETCH_TREE_DATA'
export const GET_NODES_FOR_ORGANIZATION_ID = 'GET_NODES_FOR_ORGANIZATION_ID'
export const GET_AUTHORIZED_NODES = 'GET_AUTHORIZED_NODES'
export const GET_PERSONAS_FOR_ORGANIZATION_ID =
  'GET_PERSONAS_FOR_ORGANIZATION_ID'
export const TOGGLE_MANAGE_USERS = 'TOGGLE_MANAGE_USERS'
export const GET_USERS_FOR_ORGANIZATION_ID = 'GET_USERS_FOR_ORGANIZATION_ID'
export const REFRESH_SELECTED_NODE_DATA = 'REFRESH_SELECTED_NODE_DATA'
export const TOGGLE_BRANCH_MATURITY = 'TOGGLE_BRANCH_MATURITY'
export const UPDATE_TOGGLED_BRANCHES = 'UPDATE_TOGGLED_BRANCHES'

// Node type actions
export const GET_NODE_TYPES_BY_ORGANIZATION_ID =
  'FETCH_NODE_TYPES_FOR_ORGANIZATION_ID'
export const TOGGLE_MANAGE_NODE_TYPES = 'TOGGLE_MANAGE_NODE_TYPES'
export const TOGGLE_NODE_TYPE_FORM = 'TOGGLE_NODE_TYPE_FORM'

// Personas actions
export const GET_PERSONAS = 'GET_PERSONAS'

// Answer form actions
export const ANSWER_FORM_COMPLETE = 'ANSWER_FORM_COMPLETE'
export const ANSWER_FORM_ERROR = 'ANSWER_FORM_ERROR'
export const ANSWER_FORM_LOADING = 'ANSWER_FORM_LOADING'
export const ANSWER_FORM_MOVE_NEXT_PAGE = 'ANSWER_FORM_MOVE_NEXT_PAGE'
export const ANSWER_FORM_MOVE_PREVIOUS_PAGE = 'ANSWER_FORM_MOVE_PREVIOUS_PAGE'
export const ANSWER_FORM_PREPARE_FORM = 'ANSWER_FORM_PREPARE_FORM'
export const ANSWER_FORM_SUBMIT_ANSWERS = 'ANSWER_FORM_SUBMIT_ANSWERS'
export const ANSWER_FORM_TOGGLE_CHOICE = 'ANSWER_FORM_TOGGLE_CHOICE'

// Dashboard Actions
export const TOGGLE_MODE = 'TOGGLE_MODE'
export const TOGGLE_RADAR = 'TOGGLE_RADAR'
export const TOGGLE_AVERAGE = 'TOGGLE_AVERAGE'
export const TOGGLE_MATURITY = 'TOGGLE_MATURITY'
export const TOGGLE_SIDE_BAR = 'TOGGLE_SIDE_BAR'
export const TOGGLE_HIDE_ASSESSMENT = 'TOGGLE_HIDE_ASSESSMENT'
export const TOGGLE_ASSESSMENT_CONTAINER = 'TOGGLE_ASSESSMENT_CONTAINER'
export const CLEAR_DASHBOARD = 'CLEAR_DASHBOARD'
export const ADD_BAR_CHART_ANSWERS_PER_LEVEL_PER_OBJECTIVE =
  'ADD_BAR_CHART_ANSWERS_PER_LEVEL_PER_OBJECTIVE'

// Organization actions
