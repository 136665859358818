// Practically all this code comes from https://github.com/alangrafu/radar-chart-d3
// I only made some additions and aesthetic adjustments to make the chart look better
// (of course, that is only my point of view)
// Such as a better placement of the titles at each line end,
// adding numbers that reflect what each circular level stands for
// Not placing the last level and slight differences in color
//
// For a bit of extra information check the blog about it:
// http://nbremer.blogspot.nl/2013/09/making-d3-radar-chart-look-bit-better.html

import { select, scaleOrdinal, schemeCategory10 } from 'd3'

import { max } from 'd3-array'

const color = scaleOrdinal(schemeCategory10)

export function draw(id, d, options, editCallBack, showAverage, showMaturity) {
  let { cfg, radius, g, allAxis, total, dataValues, newX, newY, tooltip, z } =
    initializingRadar(options, d, id)
  const j = drawCircularSegments(cfg, radius, g, allAxis, total)
  drawLevels(j, cfg, radius, g)
  let series = drawAxis(g, allAxis, cfg, total, radius)
  ;({ dataValues, series } = drawSeries(
    d,
    dataValues,
    g,
    cfg,
    total,
    series,
    showAverage,
    showMaturity
  ))
  ;({ series, newX, newY, tooltip, z } = addSeriesEvents(
    series,
    d,
    g,
    cfg,
    dataValues,
    total,
    newX,
    newY,
    tooltip,
    z,
    editCallBack,
    allAxis
  ))
}

function drawCircularSegments(cfg, radius, g, allAxis, total) {
  const x1 = (levelFactor, d, i) =>
    levelFactor * (1 - cfg.factor * Math.sin((i * cfg.radians) / total))
  const y1 = (levelFactor, d, i) =>
    levelFactor * (1 - cfg.factor * Math.cos((i * cfg.radians) / total))
  const x2 = (levelFactor, d, i) =>
    levelFactor * (1 - cfg.factor * Math.sin(((i + 1) * cfg.radians) / total))
  const y2 = (levelFactor, d, i) =>
    levelFactor * (1 - cfg.factor * Math.cos(((i + 1) * cfg.radians) / total))

  for (var j = 0; j <= cfg.levels - 1; j++) {
    const levelFactor = cfg.factor * radius * ((j + 1) / cfg.levels)
    g.selectAll('.levels')
      .data(allAxis)
      .enter()
      .append('svg:line')
      .attr('x1', x1.bind(this, levelFactor))
      .attr('y1', y1.bind(this, levelFactor))
      .attr('x2', x2.bind(this, levelFactor))
      .attr('y2', y2.bind(this, levelFactor))
      .attr('class', 'line')
      .style('stroke', 'gray')
      .style('stroke-opacity', '0.75')
      .style('stroke-width', '0.2px')
      .attr(
        'transform',
        'translate(' +
          (cfg.w / 2 - levelFactor) +
          ', ' +
          (cfg.h / 2 - levelFactor) +
          ')'
      )
  }
  return j
}

function addSeriesEvents(
  series,
  d,
  g,
  cfg,
  dataValues,
  total,
  newX,
  newY,
  tooltip,
  z,
  editCallBack,
  allAxis
) {
  series = 0
  d.forEach(function (y, x) {
    g.selectAll('.nodes')
      .data(y)
      .enter()
      .append('svg:circle')
      .attr('class', 'radar-chart-serie' + series)
      .style('cursor', 'pointer')
      .attr('r', cfg.radius)
      .attr('alt', function (j) {
        return Math.max(j.value, 0)
      })
      .attr('cx', function (j, i) {
        dataValues.push([
          (cfg.w / 2) *
            (1 -
              (parseFloat(Math.max(j.value, 0)) / cfg.maxValue) *
                cfg.factor *
                Math.sin((i * cfg.radians) / total)),
          (cfg.h / 2) *
            (1 -
              (parseFloat(Math.max(j.value, 0)) / cfg.maxValue) *
                cfg.factor *
                Math.cos((i * cfg.radians) / total))
        ])
        return (
          (cfg.w / 2) *
          (1 -
            (Math.max(j.value, 0) / cfg.maxValue) *
              cfg.factor *
              Math.sin((i * cfg.radians) / total))
        )
      })
      .attr('cy', function (j, i) {
        return (
          (cfg.h / 2) *
          (1 -
            (Math.max(j.value, 0) / cfg.maxValue) *
              cfg.factor *
              Math.cos((i * cfg.radians) / total))
        )
      })
      .attr('data-id', function (j) {
        return j.axis
      })
      .style('fill', cfg.color(series))
      .style('fill-opacity', 0.8)
      .on('mouseover', function (d) {
        newX = parseFloat(select(this).attr('cx')) - 10
        newY = parseFloat(select(this).attr('cy')) - 5
        tooltip
          .attr('x', newX)
          .attr('y', newY)
          .style('fill', 'rgba(92, 230, 233, 1)')
          .style('opacity', 1)
          .text(
            'Maturity Index for ' +
              this.__data__.axis +
              ': ' +
              this.__data__.value
          )
          .transition(200)
        z = 'polygon.' + select(this).attr('class')
        g.selectAll('polygon').transition(200).style('fill-opacity', 0.1)
        g.selectAll(z).transition(200).style('fill-opacity', 0.7)
      })
      .on('click', function (d) {
        editCallBack(
          this.__data__.axis,
          this.__data__.value,
          this.__data__.id,
          this.__data__.areaId
        )
      })
      .on('mouseout', function () {
        tooltip.transition(200).style('opacity', 0)
        g.selectAll('polygon')
          .transition(200)
          .style('fill-opacity', cfg.opacityArea)
      })
      .append('svg:title')
      .text(function (j) {
        return Math.max(j.value, 0)
      })
    series++
  })
  // Tooltip
  tooltip = g
    .append('text')
    .style('opacity', 0)
    .style('font-family', 'sans-serif')
    .style('font-size', '18px')
  return { series, newX, newY, tooltip, z }
}

function drawSeries(
  d,
  dataValues,
  g,
  cfg,
  total,
  series,
  showAverage,
  showMaturity
) {
  d.forEach(function (y, x) {
    let stroke
    let strokeDashArray
    if (x === d.length - 1 && (showAverage || showMaturity)) {
      stroke = '1px'
      strokeDashArray = 3
    } else {
      stroke = '1px'
      strokeDashArray = 0
    }
    dataValues = []
    g.selectAll('.nodes').data(y, function (j, i) {
      dataValues.push([
        (cfg.w / 2) *
          (1 -
            (parseFloat(Math.max(j.value, 0)) / cfg.maxValue) *
              cfg.factor *
              Math.sin((i * cfg.radians) / total)),
        (cfg.h / 2) *
          (1 -
            (parseFloat(Math.max(j.value, 0)) / cfg.maxValue) *
              cfg.factor *
              Math.cos((i * cfg.radians) / total))
      ])
    })

    dataValues.push(dataValues[0])
    g.selectAll('.area')
      .data([dataValues])
      .enter()
      .append('polygon')
      .attr('class', 'radar-chart-serie' + series)
      .style('stroke-dasharray', strokeDashArray)
      .style('stroke-width', stroke)
      .style('stroke', cfg.color(series))
      .attr('points', function (d) {
        let str = ''
        for (let pti = 0; pti < d.length; pti++) {
          str = str + d[pti][0] + ',' + d[pti][1] + ' '
        }
        return str
      })
      .style('fill', function (j, i) {
        return cfg.color(series)
      })
      .style('fill-opacity', cfg.opacityArea)
      .on('mouseover', function (d) {
        const z = 'polygon.' + select(this).attr('class')
        g.selectAll('polygon')
          .transition(200)
          .style('fill-opacity', 0.1)
          .style('stroke-width', '3px')
        g.selectAll(z).transition(200).style('fill-opacity', 0.5)
      })
      .on('mouseout', function () {
        g.selectAll('polygon')
          .transition(200)
          .style('stroke-width', '1px')
          .style('fill-opacity', cfg.opacityArea)
      })
    series++
  })
  return { dataValues, series }
}

function drawAxis(g, allAxis, cfg, total, radius) {
  const series = 0
  const axis = g
    .selectAll('.axis')
    .data(allAxis)
    .enter()
    .append('g')
    .attr('class', 'axis')
  axis
    .append('line')
    .attr('x1', cfg.w / 2)
    .attr('y1', cfg.h / 2)
    .attr('x2', function (d, i) {
      return cfg.w / 2
    })
    .attr('y2', function (d, i) {
      return cfg.h / 2
    })
    .attr('class', 'line')
    .style('stroke', 'rgba(74, 211,230,1)')
    .style('stroke-width', '1px')

    .transition()
    .duration(800)
    .attr('x1', cfg.w / 2)
    .attr('y1', cfg.h / 2)
    .attr('x2', function (d, i) {
      return (
        (cfg.w / 2) * (1 - cfg.factor * Math.sin((i * cfg.radians) / total))
      )
    })
    .attr('y2', function (d, i) {
      return (
        (cfg.h / 2) * (1 - cfg.factor * Math.cos((i * cfg.radians) / total))
      )
    })
    .attr('class', 'line')
    .style('stroke', 'rgba(74, 211,230,1)')
    .style('stroke-width', '1px')

  axis
    .selectAll('text')
    .data(allAxis)
    .enter()
    .append('text')
    .attr('class', 'legend')
    .text(function (d) {
      return d.axis
    })
    .style('font-family', "'Quicksand', sans-serif")
    .style('font-size', '0.9rem')
    .style('font-weight', '600')
    .style('text-transform', 'capitalize')
    .attr('text-anchor', 'right')
    // .attr('dx', '1px')
    .attr('dy', '1em')
    .style('fill', function (d) {
      return d.color ? d.color : '#c1c1c1'
    })
    // fix side of axis title
    .attr('transform', function (d, i) {
      return 'translate(0, -15)'
    })
    .attr('x', function (d, i) {
      let x =
        (cfg.w / 2) *
          (1 - cfg.factorLegend * Math.sin((i * cfg.radians) / total)) -
        55 * Math.sin((i * cfg.radians) / total)
      if (x < radius) {
        x = x - d.axis.length * 7.5
      }
      return x
    })
    .attr('y', function (d, i) {
      return (
        (cfg.h / 2) * (1 - Math.cos((i * cfg.radians) / total)) -
        30 * Math.cos((i * cfg.radians) / total)
      )
    })

  return series
}

function drawLevels(j, cfg, radius, g) {
  for (j = 0; j < cfg.levels; j++) {
    const levelFactor = cfg.factor * radius * ((j + 1) / cfg.levels)
    g.selectAll('.levels')
      .data([1]) // dummy data
      .enter()
      .append('svg:text')
      .attr('x', function (d) {
        return levelFactor * (1 - cfg.factor * Math.sin(0))
      })
      .attr('y', function (d) {
        return levelFactor * (1 - cfg.factor * Math.cos(0))
      })
      .attr('class', 'legend')
      .style('font-family', "'Roboto', sans-serif")
      .style('font-size', '8px')
      .style('font-weight', '900')
      .attr(
        'transform',
        'translate(' +
          (cfg.w / 2 - levelFactor + cfg.ToRight + 5) +
          ', ' +
          (cfg.h / 2 - levelFactor) +
          ')'
      )
      .attr('fill', '#C8C8C8')
      .text('  ' + cfg.maturityModel[j])
  }
  return j
}

function initializingRadar(options, d, id) {
  const dataValues = []
  let newX
  let newY
  let z
  const cfg = {
    radius: 4,
    w: 400,
    h: 400,
    factor: 1,
    factorLegend: 0.75,
    levels: options.levels,
    maxValue: 10,
    radians: 2 * Math.PI,
    opacityArea: 0.1,
    ToRight: 0,
    TranslateX: 0,
    TranslateY: 0,
    ExtraWidthX: 0,
    ExtraWidthY: 0,
    color: color
  }

  if (typeof options !== 'undefined') {
    for (const i in options) {
      if (typeof options[i] !== 'undefined') {
        cfg[i] = options[i]
      }
    }
  }

  cfg.maxValue = Math.max(
    cfg.maxValue,
    max(d, function (i) {
      return max(
        i.map(function (o) {
          return o.value
        })
      )
    })
  )
  const allAxis = d[0].map(function (i, j) {
    return {
      axis: i.axis,
      color: i.areaColor
    }
  })
  const total = allAxis.length
  const radius = cfg.factor * Math.min(cfg.w / 2, cfg.h / 2)

  select(id).select('svg').remove()
  const g = select(id)
    .append('svg')
    .attr('width', cfg.w + cfg.ExtraWidthX)
    .attr('height', cfg.h + cfg.ExtraWidthY)
    .append('g')
    .attr(
      'transform',
      'translate(' + cfg.TranslateX + ',' + cfg.TranslateY + ')'
    )
  let tooltip
  return { cfg, radius, g, allAxis, total, dataValues, newX, newY, tooltip, z }
}

export function renderRadar(
  chartData,
  mycfg,
  legendOptions,
  element,
  editCallBack,
  showAverage,
  showMaturity,
  hideElement,
  hiddenElements
) {
  draw(element, chartData, mycfg, editCallBack, showAverage, showMaturity)

  /// //////// Initiate legend ////////////////
  const svg = select(element)
    .selectAll('svg')
    .append('svg')
    .attr('width', mycfg.w)
    .attr('height', mycfg.h)
  // Create the title for the legend

  // svg
  //   .append('text')
  //   .attr('class', 'title')
  //   .attr('transform', 'translate(90,0)')
  //   .attr('x', -70)
  //   .attr('y', 10)
  //   .attr('font-size', '1rem')
  //   .attr('fill', '#a59f91')
  //   .text('Legenda')
  // Initiate Legend

  const legend = svg
    .append('g')
    .attr('class', 'legend')
    .attr('height', 100)
    .attr('width', 200)
    .attr('transform', 'translate(90,0)')
  // Create colour squares
  legend
    .selectAll('rect')
    .data(legendOptions)
    .enter()
    .append('rect')
    .attr('x', -60)
    .attr('y', function (d, i) {
      return i * 20
    })
    .attr('width', 10)
    .attr('height', 10)
    .style('fill', function (d, i) {
      return color(i)
    })
  // Create text next to squares
  legend
    .selectAll('text')
    .data(legendOptions)
    .enter()
    .append('text')
    .style('cursor', 'pointer')

    .attr('x', -45)
    .attr('y', function (d, i) {
      return i * 20 + 9
    })
    .attr('font-size', '10px')
    .style('fill', '#737373')
    .text(function (d) {
      return d
    })
    .on('click', function (d, i) {
      hideElement(i)
    })
  if (hiddenElements && hiddenElements.length > 0) {
    const hiddenLegends = svg
      .append('g')
      .attr('class', 'legend')
      .attr('height', 100)
      .attr('width', 200)
      .attr('transform', `translate(90,${legendOptions.length * 20})`)
    // Create colour squares
    hiddenLegends
      .selectAll('rect')
      .data(hiddenElements)
      .enter()
      .append('rect')
      .attr('x', -60)
      .attr('y', function (d, i) {
        return i * 20
      })
      .attr('width', 10)
      .attr('height', 10)
      .style('fill', function (d, i) {
        return 'rgba(115, 115, 115, 0.2)'
      })
    // Create text next to squares
    hiddenLegends
      .selectAll('text')
      .data(hiddenElements)
      .enter()
      .append('text')
      .style('cursor', 'pointer')

      .attr('x', -45)
      .attr('y', function (d, i) {
        return i * 20 + 9
      })
      .attr('font-size', '10px')
      .style('fill', function (d, i) {
        return 'rgba(115, 115, 115, 0.3)'
      })
      .text(function (d) {
        return d[0]
      })
      .on('click', function (d, i) {
        hideElement(i[0])
      })
  }
}

export function clearRadar(element) {
  select(element).selectAll('svg > *').remove()
}
