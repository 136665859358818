import { api } from './axios'
import {
  GET_NODE_TYPES_BY_ORGANIZATION_ID,
  TOGGLE_MANAGE_NODE_TYPES,
  TOGGLE_NODE_TYPE_FORM
} from '../actionTypes'
import { fetchTreeData } from './treeActions'

export const getNodeTypesByOrganizationId = async (
  organizationId,
  dispatch
) => {
  const { data } = await api.get(`/nodeTypes/organization/${organizationId}`)
  dispatch({
    type: GET_NODE_TYPES_BY_ORGANIZATION_ID,
    payload: data
  })
  return data
}

export const createNodeType = async nodeType => {
  const { data } = await api.post('/nodeTypes', nodeType)
  return data
}

export const updateNodeType = async (
  nodeType,
  organizationId,
  userId,
  dispatch
) => {
  await api.put(`/nodeTypes/${nodeType.id}`, nodeType)
  await getNodeTypesByOrganizationId(organizationId, dispatch)
  await fetchTreeData(organizationId, userId, dispatch)
}

export const archiveNodeType = async (
  nodeType,
  organizationId,
  userId,
  dispatch
) => {
  await api.put(`/nodeTypes/archive/${nodeType.id}`, nodeType)
  await getNodeTypesByOrganizationId(organizationId, dispatch)
  await fetchTreeData(organizationId, userId, dispatch)
}

export const toggleManageNodeTypes = async (payload, dispatch) => {
  dispatch({
    type: TOGGLE_MANAGE_NODE_TYPES,
    payload
  })
}

export const toggleNodeTypesForm = async (payload, dispatch) => {
  dispatch({
    type: TOGGLE_NODE_TYPE_FORM,
    payload
  })
}
