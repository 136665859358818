import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import NodeUsersSelect from './NodeUsersSelect'

import PeopleIcon from '@material-ui/icons/People'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'

import './NodeUsers.scss'

const NodeUsers = ({
  nodeUsers,
  addOn,
  callBack,
  nodeId,
  assessmentId,
  checkAll
}) => {
  const [t] = useTranslation()

  const [showNames, setShowNames] = useState(false)
  const [checked, setChecked] = useState(true)

  useEffect(() => {
    if (checkAll) {
      checkAll(checked, nodeId, assessmentId)
    }
  }, [checked])

  const renderUser = user => {
    return (
      <div key={user.id} className='NodeUsers-user'>
        <img
          className='NodeUsers-profilePic'
          src={user.avatarUrl}
          alt={user.email}
          data-toggle='tooltip'
          title={user.email}
          onError={e => {
            e.target.src = '/images/avatar.png'
          }}
        />
        {showNames && (
          <div className='NodeUsers-user-tag animated fadeInLeft'>
            <div>
              <h1 className='mr-auto NodeUsers-user-name'>{`${user.firstName} ${user.surName}`}</h1>
            </div>
            <div className='d-flex mt-1'>
              <p>
                <span className='NodeUsers-user-persona mr-2'>{`${user.persona.title}`}</span>
              </p>
              {addOn && (
                <NodeUsersSelect
                  value={user.selected}
                  nodeId={nodeId}
                  assessmentId={assessmentId}
                  userId={user.id}
                  callBack={callBack}
                  user={{ ...user }}
                />
              )}
            </div>
          </div>
        )}
      </div>
    )
  }

  const byFirstName = (a, b) => {
    const firstNameA = a.firstName.toLowerCase()
    const firstNameB = b.firstName.toLowerCase()
    if (firstNameA < firstNameB) {
      return -1
    }
    if (firstNameA > firstNameB) {
      return 1
    }
    return 0
  }

  return (
    <div className='NodeUsers-container z-depth-1 animated fadeInDown'>
      <div
        className='NodeUsers-content-title d-flex justify-content-between align-items-center'
        alt={t('node_user.show_member_names')}
      >
        <h1
          className='mr-auto mt-1 mb-1'
          onClick={() => setShowNames(!showNames)}
        >
          <div>
            <PeopleIcon />{' '}
            {t('node_user.members_count', { nodeUsers: nodeUsers.length })}
          </div>
        </h1>
        {checkAll && (
          <div
            id='toggleCheckAll'
            name='toggleCheckAll'
            data-toggle='tooltip'
            title='Toggle select all users'
            className='NodeUsers-toggleCheckAll'
            onClick={() => {
              setChecked(!checked)
            }}
          >
            {checked ? (
              <CheckBoxIcon className='NodeUsers-toggleCheckAllIcon' />
            ) : (
              <CheckBoxOutlineBlankIcon className='NodeUsers-toggleCheckAllIcon' />
            )}
          </div>
        )}
      </div>

      <div className='NodeUsers-content'>
        {nodeUsers.sort(byFirstName).map(renderUser)}
      </div>
    </div>
  )
}

export default NodeUsers
