import {
  CREATE_AREA,
  UPDATE_AREA,
  SET_AREAS,
  CLEAR_AREAS,
  DELETE_AREA,
  UPDATE_SELECTED_AREA
} from 'redux/actionTypes'

const initialState = {
  area: {
    id: null,
    assessment_id: null,
    name: '',
    color: ''
  },
  areas: [],
  selectedAreaId: null
}

const areaReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_AREAS:
      return { ...state, areas: [...action.payload] }
    case CLEAR_AREAS:
      return { ...state, areas: [] }

    case CREATE_AREA:
      return addToAreasArray(state, action.payload)
    case UPDATE_AREA:
      return updateAreaArray(state, action.payload)
    case UPDATE_SELECTED_AREA:
      return updateSelectedArea(state, action.payload)
    case DELETE_AREA:
      return {
        ...state,
        areas: state.areas.filter(area => {
          return area.id !== action.id
        })
      }
    default:
      return state
  }
}

const addToAreasArray = (state, payload) => {
  const updatedArray = [...state.areas]
  updatedArray.push(payload)
  return { ...state, area: payload, areas: updatedArray }
}

const updateAreaArray = (state, payload) => {
  const updatedArray = state.areas.map(item =>
    parseInt(item.id) === parseInt(payload.id) ? payload : item
  )

  return { ...state, areas: updatedArray, area: payload }
}

const updateSelectedArea = (state, payload) => {
  return { ...state, selectedAreaId: payload ? payload.areaId : null }
}

export default areaReducer
