import { React } from 'react'

import {
  AreaContainer,
  ObjectiveMaturityContainer,
  ObjectiveMaturityProgressBar
} from 'components'
import ObjectiveRadarContainer from 'components/RadarChart/ObjectiveRadarContainer'

import './ObjectivesForm.scss'

const ObjectivesForm = () => {
  return (
    <div className='ObjectivesForm container-fluid'>
      <div className='d-flex justify-content-between'>
        <div>
          <AreaContainer />
        </div>
        <div>
          <ObjectiveRadarContainer />
        </div>
        <div className='ObjectivesForm-rightPanel'>
          <ObjectiveMaturityProgressBar />
          <ObjectiveMaturityContainer />
        </div>
      </div>
    </div>
  )
}

export default ObjectivesForm
