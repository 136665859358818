import { api } from './axios'
import {
  FETCH_PERSONAS_FOR_ORGANIZATION_ID,
  TOGGLE_PERSONA_MANAGE,
  TOGGLE_PERSONA_FORM
} from '../actionTypes'
import { fetchTreeData } from './treeActions'

export const fetchPersonasByOrganizationId = async (
  organizationId,
  dispatch
) => {
  try {
    const { data } = await api.get(`/personas/organization/${organizationId}`)
    dispatch({
      type: FETCH_PERSONAS_FOR_ORGANIZATION_ID,
      payload: data
    })
  } catch (e) {
    window.toastr.error('We have got an error: ' + e)
  }
}

export const createPersona = async (persona, organizationId, dispatch) => {
  const { data } = await api.post('/personas', persona)
  await fetchPersonasByOrganizationId(organizationId, dispatch)
  return data
}

export const updatePersona = async (
  persona,
  organizationId,
  userId,
  dispatch
) => {
  await api.put(`/personas/${persona.id}`, persona)
  await fetchPersonasByOrganizationId(organizationId, dispatch)
  await fetchTreeData(organizationId, userId, dispatch)
}

export const archivePersona = async (
  persona,
  organizationId,
  userId,
  dispatch
) => {
  await api.put(`/personas/archive/${persona.id}`, persona)
  await fetchPersonasByOrganizationId(organizationId, dispatch)
  await fetchTreeData(organizationId, userId, dispatch)
}

export const togglePersonaManage = async (payload, dispatch) => {
  dispatch({
    type: TOGGLE_PERSONA_MANAGE,
    payload
  })
}

export const togglePersonaForm = async (payload, dispatch) => {
  dispatch({
    type: TOGGLE_PERSONA_FORM,
    payload
  })
}
