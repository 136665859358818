import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { ProgressBar } from 'components'
import './QuestionProgressBar.scss'

const QuestionProgressBar = ({ percentage, setPercentage }) => {
  const [t] = useTranslation()
  const { maturityLevel, objective, question } = useSelector(state => state)
  const { rows } = maturityLevel
  const { objectives } = objective
  const { questions } = question

  useEffect(() => {
    const maturies = rows.filter(r => r.id)
    handlePercentage(maturies.length, objectives, questions)
  })

  const handlePercentage = (numberOfMaturities, objectives, questions) => {
    const maturityLevels = rows.filter(ml => ml.id)
    const total = new Array(objectives.length)
      .fill(0)
      .map(() => new Array(maturityLevels.length).fill(0))
    withOneQuestionPerLevelPerObjectiveReaches100Percent(
      objectives,
      maturityLevels,
      questions,
      total
    )
    const numberOfChoices = total.reduce((prev, curr) => {
      let subTotal = prev
      curr.forEach(item => {
        subTotal += item
      })
      return subTotal
    }, 0)

    setPercentage(
      (
        (numberOfChoices * 100) /
        (numberOfMaturities * objectives.length)
      ).toFixed(2)
    )
  }

  return (
    <div className='QuestionMaturityProgressBar'>
      <div className='QuestionMaturityProgressBar-text'>
        {t('question_progress_bar.text')}
      </div>
      <ProgressBar completed={percentage} />
    </div>
  )
}

function withOneQuestionPerLevelPerObjectiveReaches100Percent(
  objectives,
  maturityLevels,
  questions,
  total
) {
  objectives.forEach((objective, index) => {
    maturityLevels.forEach((ml, j) => {
      questions.forEach(question => {
        const { choices } = question
        if (choices && choices.length > 0) {
          for (let i = 0; i < choices.length; i++) {
            if (
              objective.id === choices[i].objective_id &&
              choices[i].maturity_id === ml.id
            ) {
              if (total[index][j] === 0) {
                total[index][j] += 1
              }
            }
          }
        }
      })
    })
  })
}

export default QuestionProgressBar
