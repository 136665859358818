import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import cloneDeep from 'lodash/cloneDeep'
import { useSelector, useDispatch } from 'react-redux'

import { getLoggedInUser } from 'auth'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import FilterAltIconOff from '@mui/icons-material/FilterAltOff'
import { UnfoldLess, UnfoldMore, ToggleOff } from '@material-ui/icons'
import { fetchTreeData, updateToggledBranches } from 'redux/actions/treeActions'
import '../../styles/loader.scss'
import './Tree.scss'

import Node from './Node'
import {
  clearDashboard,
  toggleAssessmentContainer
} from 'redux/actions/dashboardActions'

const Tree = () => {
  const [t] = useTranslation()
  const user = getLoggedInUser()

  const { tree, dashboard } = useSelector(state => state)
  const { chartDataIndex } = dashboard
  const { treeData } = tree
  const dispatch = useDispatch()
  useEffect(() => {
    const fetchData = async () => {
      await fetchTreeData(user.organization_id, user.id, dispatch)
    }
    fetchData()
  }, [user.organization_id, dispatch])

  useEffect(() => {
    setContent(tree.treeData)
  }, [treeData])

  const [content, setContent] = useState([])
  const [filterToggled, setFilterToggled] = useState(false)
  const [filteredContent, setfilteredContent] = useState([])
  const [toggledNodes, setToggledNodes] = useState([])
  const [searchString, setSearchString] = useState('')

  useEffect(() => {
    document.getElementById('contentSearchInput').value = ''
    if (filterToggled && toggledNodes.length > 0) {
      setFilterToggled(false)
    }
    const toggledNodesIds = [
      ...new Set(
        chartDataIndex.map(element => {
          return element[2]?.nodeId
        })
      )
    ]

    const getToggledNodes = node => {
      const added = []
      if (toggledNodesIds.includes(node.id)) {
        const foundIndex = toggledNodes.indexOf(({ id }) => id === node.id)
        if (foundIndex === -1) {
          added.push(node)
        }
      }
      if (node.children) {
        node.children.forEach(element => {
          const foundMore = getToggledNodes(element)
          if (foundMore.length > 0) added.push(...foundMore)
        })
      }
      return added
    }
    if (content[0]) setToggledNodes(getToggledNodes(content[0]))
  }, [chartDataIndex])

  const handleToggleFilter = () => {
    setFilterToggled(!filterToggled)
  }

  const filterContent = (subMenuItems, searchString) => {
    const result = []
    if (searchString.trim() === '') return result
    if (subMenuItems) {
      for (let i = 0; i < subMenuItems.length; i++) {
        if (
          subMenuItems[i].name
            .toLowerCase()
            .includes(searchString.toLowerCase())
        ) {
          result.push(subMenuItems[i])
        }
        if (subMenuItems[i].children.length > 0) {
          const found = filterContent(subMenuItems[i].children, searchString)
          if (found.length > 0) result.push(...found)
        }
      }
    }
    return result
  }

  const onKeyUp = e => {
    e.preventDefault()
    updateToggledBranches('collapse', dispatch)
    const searchString = e.target.value
    const prevContent = cloneDeep(content)
    const filteredResult = filterContent(prevContent, searchString)
    setfilteredContent(filteredResult)
    setSearchString(searchString)
  }

  return (
    <div className='rootTree p-2'>
      <div className=''>
        <form
          onSubmit={e => {
            e.preventDefault()
          }}
          className='form-inline md-form form-sm active-cyan active-cyan-2 m-2 p-2 shadow'
        >
          <div className='container d-flex align-items-center search-bar'>
            <i className='fas fa-search' aria-hidden='true' />
            <input
              className='form-control form-control-sm ml-3'
              type='text'
              placeholder={t('tree.filter')}
              aria-label={t('tree.filter')}
              onKeyUp={onKeyUp}
              id='contentSearchInput'
              style={{ width: '100%' }}
            />
          </div>
          <div className='pl-2'>
            {searchString.trim() !== '' && (
              <small className='animated flash'>
                {t('tree.found', { filteredContent: filteredContent.length })}
              </small>
            )}
          </div>
        </form>
      </div>

      <div className='toolbar'>
        {chartDataIndex.length ? (
          <div
            title={t('tree.filter')}
            className={filterToggled ? 'toolbar-item enabled' : 'toolbar-item'}
          >
            <FilterAltIcon
              onClick={async () => {
                await updateToggledBranches('collapse', dispatch)
                handleToggleFilter()
              }}
            />
          </div>
        ) : (
          <div title={t('tree.filter')} disabled className='toolbar-disabled'>
            <FilterAltIconOff />
          </div>
        )}
        <div title='Untoggle all' className='toolbar-item'>
          <ToggleOff
            onClick={async () => {
              await clearDashboard(user.organization_id, user.id, dispatch)
              await toggleAssessmentContainer(null, dispatch)
            }}
          />
        </div>
        <div title={t('tree.collapse')} className='toolbar-item'>
          <UnfoldLess
            onClick={async () => {
              await updateToggledBranches('collapse', dispatch)
            }}
          />
        </div>
        <div title={t('tree.expand')} className='toolbar-item'>
          <UnfoldMore
            onClick={async () => {
              await updateToggledBranches('expand', dispatch)
            }}
          />
        </div>
      </div>

      {content && content.length > 0 && (
        <Node
          content={
            filterToggled && toggledNodes.length > 0
              ? toggledNodes
              : filteredContent.length > 0
              ? filteredContent
              : content
          }
        />
      )}

      {content && content.length === 0 && (
        <div className='d-flex flex-column justify-content-center align-items-center'>
          <div className='loader1' />
        </div>
      )}
    </div>
  )
}

export default Tree
