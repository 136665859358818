import { AddCircleOutline, Search } from '@material-ui/icons'
import { Edit } from '@mui/icons-material'
import { getLoggedInUser } from 'auth'
import isUrl from 'is-url'

import { React, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { setObjectives } from 'redux/actions/objectivesAction'
import {
  createObjectiveRecommendation,
  createRecommendation,
  getRecommendationsByOrganizationId,
  updateRecommendation
} from 'redux/actions/recommendationActions'

import './ObjectiveRecomendations.scss'

const ObjectiveRecomendations = ({ selectedObjective }) => {
  const [t] = useTranslation()

  const { recommendation } = useSelector(state => state)
  const { recommendations } = recommendation
  const assessment = useSelector(state => state.assessment)
  const [search, setSearch] = useState('')
  const [filteredRecommendations, setFilteredRecommendations] = useState([])
  const [recommendationToUpdate, setRecommendationToUpdate] = useState(null)
  const [formToggled, setFormToggled] = useState(false)
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [url, setUrl] = useState('')

  // const fetchUrlData = async () => {
  //   const { status, data } = await mql(
  //     'https://www.youtube.com/watch?v=9P6rdqiybaw',
  //     { meta: true }
  //   )

  //   console.log(data)
  // }

  // fetchUrlData()
  const dispatch = useDispatch()

  const user = getLoggedInUser()
  const organizationId = user.organization_id

  const onChange = e => {
    setSearch(e.target.value)
  }

  function isUrlValid(url) {
    if (isUrl(url)) {
      document
        .getElementById('ObjectiveRecommendations-input-url')
        .classList.remove('invalid')
      return true
    } else {
      document
        .getElementById('ObjectiveRecommendations-input-url')
        .classList.add('invalid')
      window.toastr.error('Please insert a valid url') // todo translate
      return false
    }
  }

  const handleCreateRecommendation = async () => {
    if (!title || !isUrlValid(url) || !description) return

    const recomendation = {
      organizationId: organizationId,
      title,
      url,
      description
    }
    try {
      await createRecommendation(recomendation)
      await getRecommendationsByOrganizationId(organizationId, dispatch)
      setFormToggled(false)
      setTitle('')
      setUrl('')
      setDescription('')
      window.toastr.success('Successfuly created recommendation') // todo translate
    } catch (e) {
      window.toastr.error(e)
    }
  }

  const handleUpdateRecommendation = async isArchived => {
    if (!title || !isUrlValid(url) || !description) return

    const recomendation = {
      title,
      url,
      description,
      isArchived: isArchived
    }
    try {
      await updateRecommendation(
        recommendationToUpdate,
        recomendation,
        dispatch
      )
      await getRecommendationsByOrganizationId(organizationId, dispatch)
      setFormToggled(false)
      setTitle('')
      setUrl('')
      setDescription('')
      setRecommendationToUpdate(null)
      window.toastr.success('Successfuly updated recommendation') // todo translate
    } catch (e) {
      window.toastr.error(e)
    }
  }

  const toggleUpdateRecommendation = async recommendation => {
    setFormToggled(true)
    setRecommendationToUpdate(recommendation.id)
    setTitle(recommendation.title)
    setDescription(recommendation.description)
    setUrl(recommendation.url)
  }

  const handleCreateObjectiveRecommendation = async (rec, obj) => {
    const objectiveRecomendationData = {
      objectiveId: obj.id,
      recommendationId: rec.id
    }
    await createObjectiveRecommendation(objectiveRecomendationData)
    await setObjectives(selectedObjective.assessment_id, dispatch)
  }

  useEffect(() => {
    const fetchData = async () => {
      await getRecommendationsByOrganizationId(organizationId, dispatch)
    }
    fetchData()
  }, [organizationId, dispatch])

  useEffect(() => {
    setFilteredRecommendations(
      recommendations.filter(
        t =>
          t.title.toLowerCase().includes(search.toLowerCase().trim()) ||
          t.description.toLowerCase().includes(search.toLowerCase().trim()) ||
          t.url.toLowerCase().includes(search.toLowerCase().trim())
      )
    )
  }, [search, recommendations])

  const renderRecommendation = recommendation => {
    return (
      <div
        className='ObjectiveRecommendations-recommendation d-flex'
        key={`ObjectiveRecommendations-recomendation-${recommendation.id}`}
      >
        <div className='ObjectiveRecommendations-recommendation-header'>
          <span className='ObjectiveRecommendations-recommendation-name'>
            {recommendation.title}
          </span>
          {!assessment.isDeployed && (
            <div>
              <Edit
                onClick={() => {
                  toggleUpdateRecommendation(recommendation)
                }}
                className='ObjectiveRecommendations-recommendation-icon'
              />

              {!!selectedObjective && (
                <AddCircleOutline
                  onClick={() => {
                    handleCreateObjectiveRecommendation(
                      recommendation,
                      selectedObjective
                    )
                  }}
                  className='ObjectiveRecommendations-recommendation-icon'
                />
              )}
            </div>
          )}
        </div>

        <span className='ObjectiveRecommendations-recommendation-url'>
          {recommendation.url}
        </span>
        <span className='ObjectiveRecommendations-recommendation-description'>
          {recommendation.description}
        </span>
      </div>
    )
  }

  return (
    <div className='ObjectiveRecommendations'>
      <div className='ObjectiveRecommendations-header'>
        <span className='ObjectiveRecommendations-title'>
          {' '}
          Recommendations{' '}
        </span>{' '}
        {/*  // todo translate */}
        <div className='ObjectiveRecommendations-toggle-form'>
          {!assessment.isDeployed && (
            <AddCircleOutline
              title='Create a new recommendation'
              onClick={() => {
                setFormToggled(!formToggled)
                setRecommendationToUpdate(null)
                setTitle('')
                setUrl('')
                setDescription('')
              }}
            />
          )}
        </div>
      </div>
      {formToggled && (
        <div className='ObjectiveRecommendations-fields z-depth-1 animated fadeInDown'>
          <input
            placeholder='Title'
            type='text'
            value={title}
            onChange={e => {
              setTitle(e.target.value)
            }}
            className='ObjectiveRecommendations-input'
          />
          <input
            placeholder='Url'
            id='ObjectiveRecommendations-input-url'
            type='text'
            value={url}
            onChange={e => {
              setUrl(e.target.value)
            }}
            className='ObjectiveRecommendations-input'
          />

          <textarea
            placeholder='Description'
            type='text'
            value={description}
            onChange={e => {
              setDescription(e.target.value)
            }}
            className='ObjectiveRecommendations-input-description'
          />
          <div className='ObjectiveRecommendations-input-buttons'>
            {!recommendationToUpdate ? (
              <button
                disabled={!title || !description || !url}
                type='button'
                onClick={handleCreateRecommendation}
                className='ObjectiveRecommendations-input-button create'
              >
                Create
              </button>
            ) : (
              <>
                <button
                  type='button'
                  onClick={e => {
                    e.preventDefault()
                    handleUpdateRecommendation(1)
                  }}
                  className='ObjectiveRecommendations-input-button delete'
                >
                  Archive
                </button>
                <button
                  disabled={!title || !description || !url}
                  type='button'
                  onClick={e => {
                    e.preventDefault()
                    handleUpdateRecommendation(0)
                  }}
                  className='ObjectiveRecommendations-input-button update'
                >
                  Update
                </button>
                <button
                  type='button'
                  onClick={() => {
                    setRecommendationToUpdate(null)
                  }}
                  className='ObjectiveRecommendations-input-button cancel'
                >
                  Cancel {/* todo translate */}
                </button>
              </>
            )}
          </div>
        </div>
      )}
      <div className='ObjectiveRecommendations-recommendations z-depth-1 animated fadeInDown'>
        <div className='ObjectiveRecommendations-input-container'>
          <div className='input-icon'>
            <Search />
          </div>
          <input
            autoFocus
            type='text'
            maxLength={35}
            title='search'
            id='search'
            placeholder={t('label.search')}
            value={search}
            onChange={onChange}
            className='ObjectiveRecommendations-input-search'
          />
          {search.length > 0 && (
            <small className='blink-me p-3 d-flex justify-content-center align-items-center'>
              <span className='foundTitl'>
                {filteredRecommendations.length}&nbsp;
                {t('persona_manage.found')}
              </span>
            </small>
          )}
        </div>
        {filteredRecommendations.map(renderRecommendation)}
      </div>
    </div>
  )
}

export default ObjectiveRecomendations
