import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import addrs from 'email-addresses'
import { Confirm } from 'notiflix'

import { getLoggedInUser } from 'auth'

import NodeUsers from 'components/Tree/NodeUsers'
import Leanit101Select from 'components/Select/Leanit101Select'
import AutoComplete from 'components/AutoComplete/AutoComplete'

import {
  getAuthorizedNodes,
  getNodesByOrganizationId,
  getPersonasByOrganizationId,
  getUsersByOrganizationId,
  inviteUsers,
  removeUserFromNode,
  toggleManageUsers
} from 'redux/actions/treeActions'

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'

import './ManageUsers.scss'
import '../../styles/loader.scss'

const ManageUsers = ({ node }) => {
  const [t] = useTranslation()

  const user = getLoggedInUser()
  const organizationId = user.organization_id
  const { personas, users, nodes } = useSelector(state => state.tree)
  const [selectedRole, setSelectedRole] = useState('user')
  const [emails, setEmails] = useState('')
  const [selectedUser, setSelectedUser] = useState(null)
  const [invitedUsers, setInvitedUsers] = useState([])

  const [isLoading, setIsLoading] = useState(true)
  const [isInvalid, setIsInvalid] = useState(false)

  const [personaOptions, setPersonaOptions] = useState([])
  const [selectedPersona, setSelectedPersona] = useState({})

  const [nodeOptions, setNodeOptions] = useState([])
  const [selectedNodes, setSelectedNodes] = useState([])
  const [currentNodeUsers, setCurrentNodeUsers] = useState([])

  const dispatch = useDispatch()

  Confirm.Init({
    titleColor: 'rgba(58, 157, 184, 1)',
    okButtonBackground: 'rgba(172, 2, 2, 1)',
    backgroundColor: 'rgb(40,40,60)',
    borderRadius: '5px',
    messageColor: 'white',
    zindex: 9999
  })

  useEffect(() => {
    setIsInvalid(invitedUsers.length === 0 || selectedNodes.length === 0)
  }, [invitedUsers, selectedNodes])

  useEffect(() => {
    if (node) {
      setSelectedNodes([node])
    } else {
      setSelectedNodes([])
    }
  }, [node])

  useEffect(() => {
    setCurrentNodeUsers(node?.nodeUsers.length > 0 ? node.nodeUsers : [])
  }, [node?.nodeUsers])

  useEffect(() => {
    if (nodes.length > 0) {
      const nodeOptions = [
        {
          label: t('manage_users.nodes'),
          options: nodes.map(node => {
            return {
              label: node.name,
              value: node.id,
              node
            }
          })
        }
      ]
      setNodeOptions(nodeOptions)
    }
  }, [nodes])

  useEffect(() => {
    const personaOptions = [
      {
        label: t('manage_users.personas'),
        options: personas.map(persona => {
          return {
            label: persona.title,
            value: persona.id,
            persona
          }
        })
      }
    ]
    setPersonaOptions(personaOptions)
  }, [personas])

  useEffect(() => {
    const fetchData = async () => {
      await getUsersByOrganizationId(organizationId, dispatch)
      await getPersonasByOrganizationId(organizationId, dispatch)
      user.role === 'master' || user.role === 'super'
        ? await getNodesByOrganizationId(organizationId, dispatch)
        : await getAuthorizedNodes(organizationId, user.id, dispatch)
    }
    setIsLoading(true)
    fetchData()
    setIsLoading(false)
  }, [organizationId, dispatch])

  const parseEmails = () => {
    const parsedEmails = addrs.parseAddressList(emails)
    if (parseEmails) {
      setEmails('')
    }

    const newInvitedUsers = [...invitedUsers]

    parsedEmails?.forEach(invitee => {
      if (
        newInvitedUsers.findIndex(user => user.email === invitee.address) === -1
      ) {
        if (selectedUser?.id && invitee.address === selectedUser.email) {
          newInvitedUsers.push({
            id: selectedUser.id,
            name: selectedUser.firstName,
            email: selectedUser.email,
            role: selectedUser.role,
            avatarUrl: selectedUser.avatarUrl,
            persona: selectedPersona
          })
          window.toastr.info(
            t('manage_users.errors.user_already_exists', {
              firstName: selectedUser.firstName,
              surName: selectedUser.surName,
              role: selectedUser.role.toUpperCase()
            })
          )
          setSelectedUser(null)
        } else {
          newInvitedUsers.push({
            id: null,
            name: invitee.name,
            email: invitee.address,
            role: selectedRole,
            avatarUrl: '',
            persona: selectedPersona
          })
        }
      }
    })

    setInvitedUsers(newInvitedUsers)
  }

  const onChangePersona = e => {
    if (e) {
      setSelectedPersona(e.persona)
    } else {
      setSelectedPersona(null)
    }
  }

  const onChangeNode = e => {
    if (e) {
      if (selectedNodes.findIndex(item => item.id === e.node.id) === -1) {
        const newSelectedNodes = [...selectedNodes]
        newSelectedNodes.push(e.node)
        setSelectedNodes(newSelectedNodes)
      }
    }
  }

  const renderInvitedUsers = () => {
    if (invitedUsers.length === 0) {
      return (
        <div className='mt-4 ManageUsers-not-found'>
          {t('manage_users.no_user_selected')}
        </div>
      )
    }

    return invitedUsers.map((user, index) => {
      return (
        <div
          className='ManageUsers-selected-card'
          key={'selected_' + user.email + '_' + index}
        >
          <div className='d-flex flex-row justify-content-between'>
            <div className='picContainer'>
              <img
                className='ManageUsers-profilePic'
                src={user.avatarUrl}
                alt={user.firstName}
                data-toggle='tooltip'
                title={user.firstName}
                onError={e => {
                  e.target.src = '/images/avatar.png'
                }}
              />
            </div>
            <div className='ManageUsers-selected-card-content'>
              <span className='selected-name'>{user.name}</span>
              <div className='selected-info'>
                <span className='selected-email'>{user.email}</span>
                <span className='selected-persona'>{user.persona.title}</span>
                <span className='selected-role'>{user.role}</span>
              </div>
            </div>
            <div className='d-inline-flex align-users-center'>
              <span
                data-toggle='tooltip'
                title={t('manage_users.remove_invitee')}
              >
                <HighlightOffIcon
                  onClick={e => {
                    e.stopPropagation()
                    const newInvitedUsers = invitedUsers.filter(
                      (item, i) => i !== index
                    )
                    setInvitedUsers(newInvitedUsers)
                  }}
                  className='launch-icon'
                />
              </span>
            </div>
          </div>
        </div>
      )
    })
  }

  const removeNode = index => {
    const newSelectedNodes = selectedNodes.filter((item, i) => i !== index)
    setSelectedNodes(newSelectedNodes)
  }

  const removeUser = (value, nodeId, assessmentId, userId, user) => {
    confirmUserRemoval(nodeId, userId, user)
  }

  const renderNodes = () => {
    const nodesSelected = [...selectedNodes]
    return nodesSelected.map((node, index) => (
      <div key={node?.id} className='ManageUsers-selected-node-tag'>
        <span className='node-title'>
          {node?.name}
          <p style={{ height: 5 }}> &nbsp; </p>
          <small>{node?.nodeType?.name}</small>
        </span>
        <span>
          <HighlightOffIcon
            className='launch-icon'
            onClick={() => removeNode(index)}
          />
        </span>
      </div>
    ))
  }

  const confirmInvite = () => {
    Confirm.Show(
      t('manage_users.confirm.invite.title'),
      t('manage_users.confirm.invite.description'),
      t('manage_users.confirm.invite.yes'),
      t('manage_users.confirm.invite.no'),
      async () => {
        // Yes button callback
        setIsLoading(true)
        await inviteUsers(
          {
            invitedUsers,
            selectedNodes: selectedNodes.map(node => {
              const newNode = { ...node }
              delete newNode.children
              return newNode
            })
          },
          organizationId,
          user.id,
          dispatch
        )
        setInvitedUsers([])
        setIsLoading(false)
        if (!node) {
          toggleManageUsers({ manageUsersToggled: false, node: null }, dispatch)
        }
      }
    )
  }

  const confirmUserRemoval = (nodeId, userId, user) => {
    Confirm.Show(
      t('manage_users.confirm.removal.title'),
      t('manage_users.confirm.removal.description', {
        firstName: user.firstName.toUpperCase(),
        surName: user.surName.toUpperCase(),
        node_name: node.name.toUpperCase()
      }),
      t('manage_users.confirm.removal.yes'),
      t('manage_users.confirm.removal.no'),
      async () => {
        setIsLoading(true)
        await removeUserFromNode(
          { nodeId, userId },
          organizationId,
          userId,
          dispatch
        )
        setIsLoading(false)
      }
    )
  }

  return (
    <div className='ManageUsers'>
      <div className='ManageUsers-container'>
        <div className='ManageUsers-header'>
          <p>{t('manage_users.title')}</p>
          <div>
            <span className='mt-1 title'>{t('manage_users.header')}</span>
            <span className='mt-1'>
              {node?.id && selectedNodes.length === 1
                ? node.name
                : 'Multiple nodes'}
            </span>
          </div>
        </div>

        <div className='ManageUsers-body'>
          <div className='ManageUsers-left-panel'>
            <div className='d-flex justify-content-between mb-2'>
              <div
                className={`ManageUsers-role ${
                  selectedRole === 'user' && 'role-user-selected'
                }`}
                onClick={() => setSelectedRole('user')}
              >
                {t('manage_users.user')}
              </div>
              <div
                className={`ManageUsers-role ${
                  selectedRole === 'admin' && 'role-admin-selected'
                }`}
                onClick={() => setSelectedRole('admin')}
              >
                {t('manage_users.admin')}
              </div>
              <div
                className={`ManageUsers-role ${
                  selectedRole === 'super' && 'role-super-selected'
                }`}
                onClick={() => setSelectedRole('super')}
              >
                {t('manage_users.super')}
              </div>
            </div>

            <div className='d-flex justify-content-center mt-1'>
              <Leanit101Select
                name='persona'
                groupedOptions={personaOptions}
                placeHolderText={t('manage_users.persona_placeholder')}
                onChange={onChangePersona}
                width='550'
              />
            </div>
            {selectedPersona?.id && (
              <div className='ManageUsers-input'>
                <AutoComplete
                  users={users}
                  setData={setEmails}
                  setSelectedUser={setSelectedUser}
                  newValue={emails}
                />
                <AddCircleOutlineIcon
                  onClick={parseEmails}
                  className='launch-icon'
                />
              </div>
            )}
            {!selectedPersona?.id && (
              <div className='please-select'>
                <span className='animated fadeInDown'>
                  {t('manage_users.select_persona')}
                </span>
              </div>
            )}
            <div className='ManageUsers-invited-users-content z-depth-1'>
              <div className='mb-2 ml-2'>
                <span>{t('manage_users.invite_users')}</span>
              </div>
              {isLoading && (
                <div className='d-flex flex-column justify-content-center align-items-center v100'>
                  <div className='loader1' />
                </div>
              )}

              {!isLoading && renderInvitedUsers()}
            </div>
          </div>
          <div className='ManageUsers-right-panel'>
            {node && (
              <div className='ManageUsers-users-container'>
                <div className='ManageUsers-section-title animated fadeInDown'>
                  <span className='no-wrap'>
                    {t('manage_users.node_current_users', { node: node?.name })}
                  </span>
                  <span>
                    <InfoOutlinedIcon
                      className='mr-2 launch-icon'
                      onClick={() =>
                        window.toastr.info(
                          'Users of the current node will appear in this section!'
                        )
                      }
                    />
                  </span>
                </div>
                <NodeUsers
                  nodeUsers={currentNodeUsers.map(item => {
                    return { ...item, selected: true }
                  })}
                  addOn
                  callBack={removeUser}
                  nodeId={node?.id}
                  assessmentId={null}
                />
              </div>
            )}
            <div className={node && 'mt-4'}>
              <div className='ManageUsers-section-title animated fadeInDown'>
                <p>
                  {selectedNodes.length === 1
                    ? t('manage_users.nodes')
                    : t('manage_users.multiple_nodes')}
                </p>
                <span>
                  <InfoOutlinedIcon
                    className='mr-2 launch-icon'
                    onClick={() =>
                      window.toastr.info(
                        'You can add multiple users to several nodes simultaneously if you select more nodes in this section!'
                      )
                    }
                  />
                </span>
              </div>
              <div className='ManageUsers-nodes-list z-depth-1'>
                <div className='d-flex justify-content-center'>
                  <Leanit101Select
                    name='node'
                    groupedOptions={nodeOptions}
                    placeHolderText={t('manage_users.select_nodes')}
                    onChange={onChangeNode}
                    width='550'
                  />
                </div>

                <div className='ManageUsers-nodes-list-content'>
                  {renderNodes()}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='ManageUsers-button-group'>
          {selectedNodes && selectedNodes.length > 0 && (
            <button
              className='ManageUsers-updateButton'
              disabled={isLoading || isInvalid}
              onClick={() => confirmInvite()}
            >
              {t('manage_users.send_invitations', {
                count: invitedUsers.length
              })}
            </button>
          )}
          <button
            className='ManageUsers-cancelButton mr-4'
            onClick={() => {
              toggleManageUsers(
                { manageUsersToggled: false, node: null },
                dispatch
              )
            }}
          >
            {t('label.close')}
          </button>
        </div>
      </div>
    </div>
  )
}

export default ManageUsers
