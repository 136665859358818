import { getLoggedInUser } from 'auth'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { Confirm } from 'notiflix'

import './PersonaForm.scss'
import {
  archivePersona,
  createPersona,
  fetchPersonasByOrganizationId,
  togglePersonaForm,
  updatePersona
} from 'redux/actions/personaActions'

const PersonaForm = () => {
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [t] = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const [isInvalid, setIsInvalid] = useState(false)
  const dispatch = useDispatch()

  const { persona } = useSelector(state => state.persona)

  const user = getLoggedInUser()
  const organizationId = user.organization_id

  Confirm.Init({
    titleColor: 'rgba(58, 157, 184, 1)',
    okButtonBackground: '#d4a302',
    backgroundColor: 'rgb(40,40,60)',
    borderRadius: '5px',
    messageColor: 'white',
    zindex: 9999
  })

  const clearForm = () => {
    setTitle('')
    setDescription('')
    document.getElementById('title').focus()
  }

  useEffect(() => {
    setIsInvalid(!title)
  }, [title])

  useEffect(() => {
    if (persona?.id) {
      setTitle(persona.title)
      setDescription(persona.description)
    }
  }, [persona])

  const onChange = e => {
    switch (e.target.title) {
      case 'title':
        setTitle(e.target.value)
        break
      case 'description':
        setDescription(e.target.value)
        break
      default:
        break
    }
  }

  const handleCreatePersona = async e => {
    e && e.stopPropagation()
    setIsLoading(true)
    const newPersona = {
      organization_id: organizationId,
      title,
      description
    }
    try {
      await createPersona(newPersona, organizationId, dispatch)
    } catch (error) {
      const message = error?.response?.data?.errors?.title
      if (message) {
        window.toastr.error(message)
        setIsLoading(false)
        document.getElementById('title').focus()
        return
      }
    }
    clearForm()
    togglePersonaForm({ personaFormToggled: false }, dispatch)
    setIsLoading(false)
  }

  const handleUpdatePersona = async e => {
    e && e.stopPropagation()
    if (persona && persona.id && persona.title !== title) {
      setIsLoading(true)
      try {
        await updatePersona(
          { ...persona, title, description },
          organizationId,
          user.id,
          dispatch
        )
      } catch (error) {
        const message = error?.response?.data?.errors?.title
        window.toastr.error(message)
        setIsLoading(false)
        return
      }
    }
    window.toastr.success('Successfuly updated persona')
    clearForm()
    togglePersonaForm({ personaManageToggled: false }, dispatch)
    setIsLoading(false)
  }

  const confirmArchive = () => {
    Confirm.Show(
      t('persona_form.confirm.title'),
      t('persona_form.confirm.description'),
      t('persona_form.confirm.yes'),
      t('persona_form.confirm.no'),
      async () => {
        if (persona && persona.id) {
          setIsLoading(true)
          try {
            await archivePersona(persona, organizationId, user.id, dispatch)
          } catch (error) {
            const message = error?.response?.data?.errors?.message
            if (message) {
              window.toastr.error(message)
              setIsLoading(false)
              return
            }
          }
          window.toastr.success('Successfuly archived persona')
          clearForm()
          togglePersonaForm(
            { personaFormToggled: false, persona: null },
            dispatch
          )
          setIsLoading(false)
        }
      }
    )
  }

  const handleArchivePersona = e => {
    e && e.stopPropagation()
    confirmArchive()
    fetchPersonasByOrganizationId(organizationId, dispatch)
  }

  return (
    <div className='Persona animated fadeIn'>
      <div className='Persona-container'>
        <div className='Persona-header'>
          <p>
            {persona?.id
              ? t('persona_form.edit_persona')
              : t('persona_form.new_persona')}
            <span className='mt-1'> {persona?.id && persona.title}</span>
          </p>
        </div>

        <div className='Persona-input'>
          <input
            autoFocus
            type='text'
            maxLength={35}
            title='title'
            id='title'
            placeholder={t('persona_form.persona_title')}
            value={title}
            onChange={onChange}
          />
          {title.length > 15 && (
            <small className='blink-me' style={{ color: '#e438db' }}>
              &nbsp;
              {35 - title.length}
            </small>
          )}
        </div>
        <div className='Persona-texarea'>
          <textarea
            title='description'
            id='description'
            placeholder={t('label.description')}
            value={description}
            cols='30'
            rows='10'
            onChange={onChange}
          />
        </div>
        <div className='Persona-button-group'>
          {persona && persona.id && (
            <button
              className='Persona-updateButton'
              disabled={isLoading || isInvalid}
              onClick={handleUpdatePersona}
            >
              {t('label.update')}
            </button>
          )}
          {!persona?.id && (
            <button
              className='Persona-createButton'
              disabled={isLoading || isInvalid}
              onClick={handleCreatePersona}
            >
              {t('label.create')}
            </button>
          )}
          {persona && persona.id && (
            <button
              className='Persona-archiveButton'
              disabled={isLoading || isInvalid}
              onClick={handleArchivePersona}
            >
              {t('label.archive')}
            </button>
          )}
          <button
            className='Persona-cancelButton'
            onClick={() => {
              togglePersonaForm({ personaFormToggled: false }, dispatch)
            }}
          >
            {t('label.cancel')}
          </button>
        </div>
      </div>
    </div>
  )
}

export default PersonaForm
