import React, { useState, useRef, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { isEmpty, size, trim, find } from 'lodash'
import { useTranslation } from 'react-i18next'

import { Area, AreaInput, ObjectivesAreas } from 'components'
import { createArea, updateArea, deleteArea } from 'redux/actions/areasAction'

import './AreaContainer.scss'
import { clearOnlySelectedObjective } from 'redux/actions/objectiveMaturityActions'

const AreaContainer = () => {
  const [t] = useTranslation()

  const areaName = useRef()
  const areaDescription = useRef()

  const dispatch = useDispatch()

  const assessment = useSelector(state => state.assessment)
  const area = useSelector(state => state.area)
  const { areas, selectedAreaId } = area
  const [areaColor, setAreaColor] = useState('#ff4040')
  const [selectedArea, setSelectedArea] = useState(null)
  const [editMode, setEditMode] = useState(null)
  const isEdit = editMode !== null

  useEffect(() => {
    setSelectedArea(selectedAreaId)
  }, [selectedAreaId])

  const renderReport = errorMsg => window.toastr.error(errorMsg)

  const parseForm = () => {
    const name = trim(areaName.current.value)
    if (size(name) === 0) {
      throw t('area_container.errors.name_required')
    }

    const description = trim(areaDescription.current.value)
    if (size(description) === 0) {
      throw t('area_container.errors.description_required')
    }

    return [name, description]
  }

  const handleCreate = () => {
    try {
      const [name, description] = parseForm()

      createArea(assessment.id, name, description, areaColor, dispatch)

      cleanForm()
    } catch (msg) {
      renderReport(msg)
    }
  }

  const handleUpdate = () => {
    const id = parseInt(editMode)
    if (!id) return

    try {
      const [name, description] = parseForm()

      updateArea(id, name, description, areaColor, dispatch)
      cleanForm()
    } catch (msg) {
      renderReport(msg)
    }
  }

  const cleanForm = () => {
    areaName.current.value = ''
    areaDescription.current.value = ''
    setEditMode(null)
  }

  const removeArea = id => {
    deleteArea(id, dispatch).then(resp => {
      if (resp.status === 500) {
        renderReport(resp.errorMsg)
      }
    })
    setSelectedArea(state => {
      if (state === id) {
        return null
      }
    })
  }

  const renderAreas = () => {
    if (isEmpty(areas)) return null

    return areas.map(area => {
      return (
        <Area
          key={area.id}
          isDeployed={assessment.isDeployed}
          disabled={!assessment.isDeployed}
          id={area.id}
          area={area.name}
          color={area.color}
          removeArea={removeArea}
          isSelected={Number(area.id) === Number(selectedArea)}
          handleClick={id => {
            if (isEdit) return
            clearOnlySelectedObjective(dispatch)
            setSelectedArea(state => {
              return state === id ? null : id
            })
          }}
          handleEditClick={id => {
            if (!assessment.isDeployed) {
              clearOnlySelectedObjective(dispatch)
              const area = find(areas, area => area.id === id)
              areaName.current.value = area.name
              areaDescription.current.value = area.description
              areaName.current.focus()
              setEditMode(id)
            }
          }}
        />
      )
    })
  }
  return (
    <div className='AreaContainer'>
      <div className='AreaContainer-container'>
        {!assessment.isDeployed && (
          <div>
            <AreaInput
              onCreate={handleCreate}
              onUpdate={handleUpdate}
              onCancel={cleanForm}
              refAreaName={areaName}
              refAreaDescription={areaDescription}
              setAreaColor={setAreaColor}
              isDeployed={assessment.isDeployed}
              isEdit={editMode !== null}
            />
          </div>
        )}
      </div>
      <div className='AreaContainer-content'>{renderAreas()}</div>
      {selectedArea && !assessment.isDeployed && (
        <ObjectivesAreas areaId={selectedArea} assessmentId={assessment.id} />
      )}
    </div>
  )
}

export default AreaContainer
