import { api } from './axios'
import {
  CREATE_AREA,
  UPDATE_AREA,
  DELETE_AREA,
  SET_AREAS,
  CLEAR_AREAS
} from 'redux/actionTypes'

export const createArea = async (
  assessmentId,
  name,
  description,
  color,
  dispatch
) => {
  try {
    if (name === '') return
    const newArea = {
      assessment_id: assessmentId,
      name,
      description: description,
      color
    }
    const { data } = await api.post('/areas', newArea)

    dispatch({
      type: CREATE_AREA,
      payload: data
    })
  } catch (e) {
    const err = e.response
    const error = {
      status: err.status,
      errorMsg: err.data.errors.objectiveMaturityError
    }
    return error
  }
}

export const updateArea = async (id, name, description, color, dispatch) => {
  if (!id || !name || !color) return
  const updateArea = {
    name,
    description,
    color
  }
  const { data } = await api
    .put(`/areas/${id}`, updateArea)
    .then(response => response)
    .catch(err => console.log(err))
  if (data) {
    dispatch({
      type: UPDATE_AREA,
      payload: data
    })
  }
}

export const deleteArea = async (id, dispatch) => {
  try {
    if (!id) return
    const { data } = await api.delete(`/areas/${id}`)
    if (data) {
      dispatch({
        type: DELETE_AREA,
        id
      })
    }
    return data
  } catch (e) {
    const err = e.response
    const error = {
      status: err.status,
      errorMsg: err.data.errors.areaError
    }
    return error
  }
}

export const setAreas = async (assessmentId, dispatch) => {
  const { data } = await api.get(`/areas/assessment/${assessmentId}`)
  if (data) {
    dispatch({
      type: SET_AREAS,
      payload: data
    })
  }
}
export const clearAreas = async dispatch => {
  dispatch({
    type: CLEAR_AREAS
  })
}
