import React from 'react'
import { useTranslation } from 'react-i18next'
import { isEmpty, map } from 'lodash'

import './FormPage.scss'

const FormPage = ({ title, errors, successMessages, children, onClose }) => {
  const [t] = useTranslation()

  const renderSuccessMessages = () => (
    <ul className='FormPage-successMessages'>
      {map(successMessages, successMessage => (
        <li key={successMessage}>{successMessage}</li>
      ))}
    </ul>
  )

  const renderErrors = () => (
    <ul className='FormPage-errors'>
      {map(errors, error => (
        <li key={error}>{error}</li>
      ))}
    </ul>
  )

  return (
    <div className='FormPage'>
      <div className='FormPage-container'>
        <div className='FormPage-header'>
          <h1>{title}</h1>
          {onClose && (
            <a href='#close' onClick={onClose}>
              <i
                data-toggle='tooltip'
                title={t('label.close')}
                className='fa fa-window-close'
              />
            </a>
          )}
        </div>

        {isEmpty(successMessages) ? null : renderSuccessMessages()}
        {isEmpty(errors) ? null : renderErrors()}

        {children}
      </div>
    </div>
  )
}

export default FormPage
