import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { toggleRadar } from 'redux/actions/dashboardActions'

import { prepareAssessmentData } from 'utils/utils'
import { getLoggedInUser } from 'auth'

import './DashBoardListAssessmentUsers.scss'

const DashBoardListAssessmentUsers = () => {
  const [t] = useTranslation()

  const { dashboard } = useSelector(state => state)
  const {
    checkedData,
    assessmentMode,
    containerAssessment: assessment
  } = dashboard
  const dispatch = useDispatch()
  const loggedIndUser = getLoggedInUser()

  const getCheckedState = user => {
    return (
      checkedData.indexOf(
        `${user.firstName} ${user.surName} - id: ${user.id}`
      ) !== -1
    )
  }

  const renderUser = user => {
    if (loggedIndUser.role === 'user' && user.id !== loggedIndUser.id) return
    const maturityIndex =
      user.dataPoints.chartData
        .map(axis =>
          assessmentMode ? axis.assessmentValue : axis.maturityValue
        )
        .reduce((p, c) => {
          return p + c
        }, 0) / user.dataPoints.chartData.length
    const userStyle = `DashBoardListAssessmentUsers-user ${
      getCheckedState(user) && 'selected'
    }`
    return (
      <div
        key={user.id}
        className={userStyle}
        onClick={() => {
          const assessmentData = prepareAssessmentData(
            assessment,
            getCheckedState(user),
            maturityIndex,
            null,
            assessmentMode,
            user
          )
          toggleRadar(assessmentData, dispatch)
        }}
      >
        <img
          className='DashBoardListAssessmentUsers-profilePic'
          src={user.avatarUrl}
          data-toggle='tooltip'
          title={user.email}
          alt={t('dashboard_list_assessment_users.edit_settings')}
          onError={e => {
            e.target.src = '/images/avatar.png'
          }}
        />
        <div className='userTagContainer'>
          <div className='userPersonaContainer'>
            <h1 className='mr-auto'>{`${user.firstName} ${user.surName}`}</h1>
            <span className='DashBoardListAssessmentUsers-user-persona'>
              {user?.persona?.title}
            </span>
          </div>
          <span
            className='tag'
            alt={t('dashboard_list_assessment_users.maturity_index')}
          >
            {maturityIndex.toFixed(2)}
          </span>
        </div>
      </div>
    )
  }
  const { users } = assessment
  return (
    <div className='DashBoardListAssessmentUsers-container z-depth-2 animated fadeInDown'>
      <div className='DashBoardListAssessmentUsers-assessment-name'>
        {assessment.name}
      </div>
      <div className='DashBoardListAssessmentUsers-header'>
        <div className='DashBoardListAssessmentUsers-textLeft'>
          <h1 className='DashBoardListAssessmentUsers-title'>
            {t('dashboard_list_assessment_users.respondents')}
          </h1>
        </div>
      </div>
      <div className='DashBoardListAssessmentUsers-content'>
        {users.length === 0 && (
          <div className='DashBoardListAssessmentUsers-user'>
            <h1 className='mr-auto disabled'>
              {t('dashboard_list_assessment_users.no_answer')}
            </h1>
          </div>
        )}
        {users.map(renderUser)}
      </div>
    </div>
  )
}

export default DashBoardListAssessmentUsers
