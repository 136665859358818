import {
  UPDATE_MATURITY_LEVEL_ROWS,
  HANDLE_ROWS_CHANGE,
  CREATE_MATURITY_LEVEL,
  GET_MATURITY_LEVELS,
  SET_MATURITY_LEVELS,
  UPDATE_MATURITY_LEVEL,
  INITIALIZE_MATURITY_LEVELS
} from 'redux/actionTypes'

const initialRows = new Array(10).fill().map((_, i) => {
  return {
    id: null,
    assessment_id: null,
    name: '',
    description: '',
    position: i + 1,
    enabled: false
  }
})

const initialState = {
  maturityLevel: {
    id: null,
    assessment_id: null,
    name: '',
    description: '',
    position: null
  },
  rows: initialRows,
  numberOfEnabledRows: 0
}

const maturityLevelReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case CREATE_MATURITY_LEVEL:
      return addMaturityLevelToRows(state, action.payload)
    case UPDATE_MATURITY_LEVEL:
      return { ...state, ...action.payload }
    case GET_MATURITY_LEVELS:
      return { ...state, rows: action.payload }
    case INITIALIZE_MATURITY_LEVELS:
      return { ...initialState }
    case SET_MATURITY_LEVELS:
      return setMaturityLevels(state, action.payload)
    case HANDLE_ROWS_CHANGE:
      return {
        ...state,
        rows: action.payload.rows,
        numberOfEnabledRows: action.payload.numberOfEnabledRows
      }
    case UPDATE_MATURITY_LEVEL_ROWS:
      return { ...state, ...action.payload }
    default:
      return state
  }
}

const setMaturityLevels = (state, payload) => {
  const newRows = [...initialRows]

  payload.forEach((row, i) => {
    newRows[i] = { ...row, enabled: true }
  })

  return {
    ...state,
    numberOfEnabledRows: payload.length,
    rows: newRows
  }
}

const addMaturityLevelToRows = (state, payload) => {
  const newRows = state.rows.map(row => {
    if (row.position === payload.position) {
      return { ...payload, enabled: true }
    }
    return row
  })
  return { ...state, rows: newRows }
}

export default maturityLevelReducer
