import React, { useState } from 'react'

import { SelectTemplates, SelectNode } from 'components'
import './ApplyAssessmentForm.scss'
import '../../../styles/loader.scss'
import validateTemplateSelect from '../SelectTemplates/validateTemplateSelect'

const ApplyAssessmentForm = () => {
  const [state, setState] = useState({
    nodes: [],
    templateId: null,
    shortName: null,
    shortDescription: null,
    startDate: null,
    endDate: null,
    openFormDate: null,
    closeFormDate: null
  })

  const isValid = () => {
    const { isValid } = validateTemplateSelect(state)
    if (!isValid) {
      // improve error handling
    }
    return isValid
  }

  const handleStateUpdate = (attr, value) => {
    setState(state => ({ ...state, [attr]: value }))
  }

  return (
    <div className='ApplyAssessmentForm container-fluid'>
      <div className='d-flex ApplyAssessmentForm-flex'>
        <div className='col-md'>
          <SelectTemplates
            onSelectTemplate={(attr, value) => handleStateUpdate(attr, value)}
          />
        </div>
        <div className='col-md ApplyAssessmentForm-SelectNode'>
          <SelectNode
            isTemplateSelected={isValid}
            onSelectNodes={value => handleStateUpdate('nodes', value)}
            newAssessmentData={state}
          />
        </div>
      </div>
    </div>
  )
}

export default ApplyAssessmentForm
