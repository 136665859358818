import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import {
  QuestionMaturity,
  QuestionsObjectives,
  QuestionList,
  Choice,
  NewQuestion,
  CreateQuestion
} from 'components'

import './QuestionForm.scss'

const QuestionForm = ({ percentage, setPercentage }) => {
  const { selectedObjective, questions } = useSelector(state => state.question)
  const assessment = useSelector(state => state.assessment)

  const [title, setTitle] = useState('')
  const [choiceId, setChoiceId] = useState('')
  const [choiceTitle, setChoiceTitle] = useState('')
  const [description, setDescription] = useState('')

  const choicesArray = []
  questions.forEach(
    question =>
      question.choices && question.choices.forEach(c => choicesArray.push(c))
  )

  return (
    <div className='QuestionForm container-fluid'>
      <div className='row QuestionForm-align'>
        <QuestionsObjectives
          setTitle={setTitle}
          setDescription={setDescription}
        />
      </div>
      {selectedObjective.id && ( // Only renders the form body if objective is selected
        <div className='d-flex'>
          <div
            className='d-flex flex-column w-100 '
            style={{ marginLeft: '63px' }}
          >
            <CreateQuestion
              title={title}
              setTitle={setTitle}
              description={description}
              setDescription={setDescription}
            />
            <Choice
              choiceId={choiceId}
              setChoiceId={setChoiceId}
              choiceTitle={choiceTitle}
              setChoiceTitle={setChoiceTitle}
            />

            {!assessment.isDeployed && <NewQuestion />}
          </div>

          <div className='text-center mr-4 ml-4'>
            <QuestionList />
          </div>

          {!assessment.isDeployed && (
            <div className='text-center'>
              <QuestionMaturity
                choicesArray={choicesArray}
                percentage={percentage}
                setPercentage={setPercentage}
              />
            </div>
          )}
          {/* {assessment.isDeployed && (
            <div>

              <div className=''>
                <CreateQuestion
                  title={title}
                  setTitle={setTitle}
                  description={description}
                  setDescription={setDescription}
                />
              </div>

              <div>
                <Choice
                  choiceId={choiceId}
                  setChoiceId={setChoiceId}
                  choiceTitle={choiceTitle}
                  setChoiceTitle={setChoiceTitle}
                />
              </div>

            </div>
          )} */}
        </div>
      )}
    </div>
  )
}

export default QuestionForm
