import { api } from './axios'
import {
  TOGGLE_RADAR,
  TOGGLE_HIDE_ASSESSMENT,
  TOGGLE_AVERAGE,
  TOGGLE_MATURITY,
  TOGGLE_MODE,
  TOGGLE_ASSESSMENT_CONTAINER,
  TOGGLE_SIDE_BAR,
  ADD_BAR_CHART_ANSWERS_PER_LEVEL_PER_OBJECTIVE,
  TOGGLE_MATURITY_MATRIX,
  CLEAR_DASHBOARD
} from '../actionTypes'
import { calculateBranchMaturity } from './treeActions'
import { clearAreas } from './areasAction'

export const toggleRadar = async (assessment, dispatch) => {
  clearAreas(dispatch)
  const assessmentData = { ...assessment }
  const userId = assessmentData.userRadar ? assessmentData.userRadar : null
  const newChartData = await Promise.all(
    assessment.chartData.map(async axis => {
      const { data } = await api.post(
        `/assessments/${axis.objectiveId}/answersPerLevelPerObjective`,
        { userId: userId }
      )
      return {
        ...axis,
        ...data
      }
    })
  )
  assessmentData.chartData = [...newChartData]
  dispatch({
    type: TOGGLE_RADAR,
    payload: { assessment: { ...assessmentData } }
  })
}

export const fetchAssessmentsPerUser = async assessmentId => {
  try {
    const { data } = await api.get(
      `/assessments/${assessmentId}/fetchAssessmentsPerUser`
    )
    return data
  } catch (error) {
    // TODO We should *not* cause side effects on the Front End inside action files
    window.toastr.error(error)
  }
}

export const toggleAssessmentContainer = async (
  containerAssessment,
  dispatch
) => {
  dispatch({
    type: TOGGLE_ASSESSMENT_CONTAINER,
    payload: containerAssessment
  })
}

export const addAnswerPerLevelPerObjective = async (row, dispatch) => {
  dispatch({
    type: ADD_BAR_CHART_ANSWERS_PER_LEVEL_PER_OBJECTIVE,
    payload: { row }
  })
}

export const toggleAverage = async dispatch => {
  dispatch({
    type: TOGGLE_AVERAGE,
    payload: null
  })
}

export const toggleMaturity = async (assessments, dispatch) => {
  let calculatedChartData = []
  if (assessments.length > 0) {
    calculatedChartData = await calculateBranchMaturity({
      selectedAssessments: [...assessments],
      selectedPersonas: []
    })
  }
  dispatch({
    type: TOGGLE_MATURITY,
    payload: calculatedChartData
  })
}

export const toggleMode = async (
  showAverage,
  showMaturity,
  assessments,
  dispatch
) => {
  if (showAverage) {
    dispatch({
      type: TOGGLE_AVERAGE,
      payload: null
    })
  }
  if (showMaturity) {
    dispatch({
      type: TOGGLE_MATURITY,
      payload: null
    })
  }
  dispatch({
    type: TOGGLE_MODE,
    payload: null
  })
  if (showAverage) {
    dispatch({
      type: TOGGLE_AVERAGE,
      payload: null
    })
  }
  if (showMaturity) {
    const calculatedChartData = await calculateBranchMaturity({
      selectedAssessments: [...assessments],
      selectedPersonas: []
    })
    dispatch({
      type: TOGGLE_MATURITY,
      payload: calculatedChartData
    })
  }
}

export const toggleSideBar = async dispatch => {
  dispatch({
    type: TOGGLE_SIDE_BAR,
    payload: null
  })
}

export const toggleHideAssessment = async (element, dispatch) => {
  dispatch({
    type: TOGGLE_HIDE_ASSESSMENT,
    payload: element
  })
}

export const clearDashboard = async (organizationId, userId, dispatch) => {
  dispatch({
    type: CLEAR_DASHBOARD,
    payload: null
  })
}

export const toggleMaturityMatrix = async (matrixData, dispatch) => {
  try {
    const { data } = matrixData.assessmentId
      ? await api.get(`/assessments/${matrixData.assessmentId}/matrix`)
      : matrixData

    dispatch({
      type: TOGGLE_MATURITY_MATRIX,
      payload: {
        ...matrixData,
        data: { ...data }
      }
    })
  } catch (error) {
    window.toastr.error(error)
  }
}
