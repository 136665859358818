import React, { useEffect, useState } from 'react'
import Autosuggest from 'react-autosuggest'
import { useTranslation } from 'react-i18next'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'

import './AutoComplete.scss'

const AutoComplete = ({ users, setData, setSelectedUser, newValue }) => {
  const [t] = useTranslation()
  const [value, setValue] = useState('')
  const [suggestions, setSuggestions] = useState([])

  const escapeRegexCharacters = str => {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
  }

  const getSuggestions = value => {
    const escapedValue = escapeRegexCharacters(value.trim())
    if (escapedValue === '') {
      return []
    }

    const regex = new RegExp('\\b' + escapedValue, 'i')
    return users.filter(user => regex.test(getSuggestionValue(user)))
  }

  const getSuggestionValue = suggestion => {
    setSelectedUser(suggestion)
    return `${suggestion.firstName} ${suggestion.surName} <${suggestion.email}>`
  }

  const renderSuggestion = (suggestion, { query }) => {
    const suggestionText = `${suggestion.firstName} ${suggestion.surName} <${suggestion.email}>`
    const matches = match(suggestionText, query)
    const parts = parse(suggestionText, matches)

    return (
      <span className='suggestion-content spanEllipsis'>
        <img
          className='profile-pic'
          src={suggestion.avatarUrl}
          alt={suggestion.firstName}
          data-toggle='tooltip'
          title={suggestion.firstName}
          onError={e => {
            e.target.src = '/images/avatar.png'
          }}
        />

        <span className='name spanEllipsis'>
          {parts.map((part, index) => {
            const className = part.highlight ? 'highlight spanEllipsis' : null

            return (
              <span className={className} key={index}>
                {part.text}
              </span>
            )
          })}
        </span>
      </span>
    )
  }

  useEffect(() => {
    if (newValue.trim() === '') {
      setValue(newValue)
    }
  }, [newValue])

  const onChange = (event, { newValue, method }) => {
    setValue(newValue)
    setData(newValue)
  }

  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value))
  }

  const onSuggestionsClearRequested = () => {
    setSuggestions([])
  }

  const inputProps = {
    placeholder: t('autocomplate.placeholder'),
    value,
    onChange: onChange
  }

  return (
    <Autosuggest
      suggestions={suggestions}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      inputProps={inputProps}
    />
  )
}

export default AutoComplete
