import React from 'react'
import { isEmpty } from 'lodash'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import {
  clearHorizontalBarChart,
  renderHorizontalBarChart
} from './HorizontalBarChart'

import './BarChartContainer.scss'

const DashboardHorizontalBarChartContainer = () => {
  const [t] = useTranslation()
  const dashboard = useSelector(state => state.dashboard)
  const { horizontalBarChartData, selectedTemplate, hiddenElements } = dashboard

  const filteredHidden = horizontalBarChartData.filter(
    row => hiddenElements.findIndex(el => el[0] === row.assessment) === -1
  )

  if (!isEmpty(filteredHidden)) {
    renderHorizontalBarChart(
      '#DashboardHorizontalBarChartContainerBuilder',
      filteredHidden,
      selectedTemplate.maturityModel.length
    )
  } else {
    clearHorizontalBarChart('#DashboardHorizontalBarChartContainerBuilder')
  }

  return (
    <div className='mt-3 z-depth-4'>
      {filteredHidden && filteredHidden.length > 0 && (
        <p className='ml-1 mb-2 chart-label'>
          {t('dashboard_horizontal_bar_chart_container.label')}
        </p>
      )}
      <div
        id='DashboardHorizontalBarChartContainer'
        className='DashboardHorizontalBarChartContainer d-flex justify-content-center'
      >
        <div
          name='DashboardHorizontalBarChartContainerBuilder'
          id='DashboardHorizontalBarChartContainerBuilder'
        />
      </div>
    </div>
  )
}

export default DashboardHorizontalBarChartContainer
