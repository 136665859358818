import { isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next'
import ToggleButton from 'react-toggle-button'
import NodeForm from 'components/Tree/NodeForm'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Tree, Modal, ApplyAssessment } from 'components'
import ManageUsers from 'components/ManageUsers/ManageUsers'
import DashboardAreaContainer from './DashBoardAreaContainer'
import DashboardDataContainer from './DashBoardDataContainer'
import ManagePersonas from 'components/Persona/ManagePersonas'
import ManageNodeTypes from 'components/NodeTypes/ManageNodeTypes'
import {
  AccountTree,
  Check,
  Clear,
  Star,
  ListAlt,
  Archive
} from '@material-ui/icons'
import { Confirm } from 'notiflix'

import BranchMaturity from 'components/BranchMaturity/BranchMaturity'
import DashBoardListAssessmentUsers from './DashBoardListAssessmentUsers'
import DashboardBarChartContainer from 'components/BarChart/BarChartContainer'
import TriggerAssessment from 'components/TriggerAssessment/TriggerAssessment'
import DashboardRadarContainer from 'components/RadarChart/DashBoardRadarContainer'
import MaturityMatrix from 'components/MaturityMatrix/MaturityMatrix'
import {
  clearDashboard,
  toggleAverage,
  toggleMaturity,
  toggleMaturityMatrix,
  toggleMode,
  toggleSideBar
} from 'redux/actions/dashboardActions'
import AssessmentContent from 'components/Assessment/AssessmentContent/AssessmentContent'
import DashboardHorizontalBarChartContainer from 'components/BarChart/HorizontalBarChartContainer'

import './Dashboard.scss'
import PersonaForm from 'components/Persona/PersonaForm'
import NodeTypesForm from 'components/NodeTypes/NodeTypesForm'
import { getLoggedInUser } from 'auth'
import { archiveAssessment } from 'redux/actions/assessmentActions'
import DashboardBubbleChartContainer from 'components/BubbleChart/DashBoardBubbleChartContainer'

const Dashboard = () => {
  const [t] = useTranslation()

  const [sideBarToggled, setSideBarToggled] = useState(true)
  const [showAverage, setShowAverage] = useState(false)
  const [showMaturity, setShowMaturity] = useState(false)
  const [assessmentMode, setAssessmentMode] = useState(true)
  const [isLoading, setIsLoading] = useState(false)

  const user = getLoggedInUser()
  const organizationId = user.organization_id

  const {
    toggledApplyForm,
    toggledTriggerAssessmentForm,
    toggledMaturityModelForm
  } = useSelector(state => state.template)

  const { dashboard, tree, persona, nodeType } = useSelector(state => state)

  const {
    nodeFormToggled,
    manageUsersToggled,
    branchMaturityToggled,
    node,
    parentNode
  } = tree
  const { manageNodeTypesToggled, nodeTypeFormToggled } = nodeType

  const { personaManageToggled, personaFormToggled } = persona

  const {
    chartDataIndex,
    checkedData,
    horizontalBarChartData,
    containerAssessment,
    barChartData,
    toggleMatrix,
    selectedTemplate,
    chartData
  } = dashboard
  const dispatch = useDispatch()

  Confirm.Init({
    nameColor: 'rgba(58, 157, 184, 1)',
    okButtonBackground: '#d4a302',
    backgroundColor: 'rgb(40,40,60)',
    borderRadius: '5px',
    messageColor: 'white',
    zindex: 9999
  })

  const toggleClass = sideBarToggled ? 'mt-0 pt-0 toggled ' : 'mt-0 pt-0 '
  const classTeamToggleButtonIn = 'animated fadeInDown p-2'
  const classTeamToggleButtonOut = 'animated fadeOutDown p-2'

  useEffect(() => {
    setSideBarToggled(dashboard.sideBarToggled)
  }, [dashboard.sideBarToggled])

  useEffect(() => {
    setShowAverage(dashboard.showAverage)
  }, [dashboard.showAverage])

  useEffect(() => {
    setShowMaturity(dashboard.showMaturity)
  }, [dashboard.showMaturity])

  useEffect(() => {
    setAssessmentMode(dashboard.assessmentMode)
    if (dashboard.assessmentMode) {
      window.toastr.info(t('dashboard.assessment_mode_toggled_on'))
    } else {
      window.toastr.info(t('dashboard.assessment_mode_toggled_off'))
    }
  }, [dashboard.assessmentMode])

  const tbColors = {
    activeThumb: {
      base: 'rgb(141, 160, 86)'
    },
    inactiveThumb: {
      base: 'rgba(96, 96, 120, 1)'
    },
    active: {
      base: 'rgba(50, 68, 73, 0.42)',
      hover: 'rgba(31, 31, 49, 1)'
    },
    inactive: {
      base: 'rgba(50, 68, 73, 0.42)',
      hover: 'rgba(31, 31, 49, 1)'
    }
  }

  const sideBar = toggleSideBar => {
    return (
      <div id='sidebar-wrapper'>
        <Tree />
      </div>
    )
  }

  const handleArchiveAssessment = assessmentId => {
    Confirm.Show(
      t('dashboard.confirm.title'),
      t('dashboard.confirm.description'),
      t('dashboard.confirm.yes'),
      t('dashboard.confirm.no'),
      async () => {
        setIsLoading(true)
        try {
          clearDashboard(organizationId, user.id, dispatch)
          archiveAssessment(assessmentId, organizationId, user.id, dispatch)
        } catch (e) {
          window.toastr.error(e.response.data.error)
          console.error(e)
        }
        setIsLoading(false)
      }
    )
  }

  const byMaturityIndex = (a, b) => {
    if (a.value > b.value) {
      return -1
    }
    if (a.value < b.value) {
      return 1
    }
    return 0
  }

  const winner =
    horizontalBarChartData.length > 1
      ? [...horizontalBarChartData].sort(byMaturityIndex)[0].assessment
      : ''

  const idTemplateForMatrix =
    selectedTemplate.templateId !== null
      ? selectedTemplate.templateId
      : chartDataIndex.length > 0
      ? chartDataIndex[0][0].id
      : null
  const content = toggleSideBar => {
    return (
      <div id='page-content-wrapper' className='container-fluid mt-0'>
        <div
          id='page-content-header'
          className='pageContentHeader p-1 d-flex justify-content-between ml-0 mr-0  mb-3 z-depth-2'
        >
          <div className={classTeamToggleButtonIn}>
            <span
              data-toggle='tooltip'
              title={t('dasboard.open_organizational_tree')}
            >
              <AccountTree
                className='Tree-icon'
                onClick={() => {
                  toggleSideBar(dispatch)
                }}
              />
            </span>
          </div>
          {idTemplateForMatrix && (
            <span
              className='open-matrix-button'
              data-toggle='tooltip'
              title='Open Maturity Matrix'
            >
              <ListAlt
                onClick={() => {
                  if (!toggleMatrix) {
                    toggleMaturityMatrix(
                      {
                        assessmentId: idTemplateForMatrix,
                        toggleMatrix: !toggleMatrix
                      },
                      dispatch
                    )
                  }
                }}
                className='edit-icon'
              />
            </span>
          )}

          <div className='d-flex w-100 align-items-center justify-content-between'>
            <div className='d-flex justify-content-start align-items-center'>
              <div id='toggleMode' className='ml-3'>
                <span className='showAverage float-left ml-0 mr-2'>
                  {t('dashboard.assessment_mode')}
                </span>
                <ToggleButton
                  className='float-right'
                  inactiveLabel={<Clear style={{ fontSize: '1rem' }} />}
                  activeLabel={<Check style={{ fontSize: '1rem' }} />}
                  value={assessmentMode}
                  colors={tbColors}
                  onToggle={() => {
                    const assessments = chartDataIndex.map(data => data[2])
                    toggleMode(showAverage, showMaturity, assessments, dispatch)
                  }}
                />
              </div>
              <div
                id='toggleAverage'
                className={
                  checkedData.length < 2
                    ? classTeamToggleButtonOut
                    : classTeamToggleButtonIn
                }
              >
                <span className='showAverage float-left ml-3 mr-2'>
                  {t('dashboard.show_average')}
                </span>
                <ToggleButton
                  className='float-right'
                  inactiveLabel={<Clear style={{ fontSize: '1rem' }} />}
                  activeLabel={<Check style={{ fontSize: '1rem' }} />}
                  value={showAverage}
                  colors={tbColors}
                  onToggle={async () => {
                    if (showMaturity) {
                      await toggleMaturity([], dispatch)
                    }
                    toggleAverage(dispatch)
                  }}
                  disabled
                />
              </div>
              {!containerAssessment && (
                <div
                  id='toggleMaturity'
                  className={
                    checkedData.length < 2
                      ? classTeamToggleButtonOut
                      : classTeamToggleButtonIn
                  }
                >
                  <span className='showAverage float-left ml-3 mr-2'>
                    {t('dashboard.show_maturity')}
                  </span>
                  <ToggleButton
                    className='float-right'
                    inactiveLabel={<Clear style={{ fontSize: '1rem' }} />}
                    activeLabel={<Check style={{ fontSize: '1rem' }} />}
                    value={showMaturity}
                    colors={tbColors}
                    onToggle={async () => {
                      if (showAverage) {
                        await toggleAverage(dispatch)
                      }

                      const assessments = chartDataIndex.map(data => data[2])
                      await toggleMaturity(assessments, dispatch)
                    }}
                  />
                </div>
              )}
            </div>
            <div className=''>
              {horizontalBarChartData.length > 1 && (
                <div className='animated fadeIn slower d-flex justify-content-end'>
                  <span className='winner-title d-flex align-items-center'>
                    <Star className='mr-1' />
                    {t('dashboard.winner')}
                  </span>
                  <span className='winner-desc  d-flex align-items-center'>
                    {winner}
                  </span>
                </div>
              )}
            </div>
          </div>
          {horizontalBarChartData.length === 1 &&
            (user.role === 'super' ||
              user.role === 'master' ||
              (chartDataIndex[0][2].isUserAuthorized &&
                user.role !== 'user')) && (
              <div>
                <span
                  className='open-matrix-button'
                  data-toggle='tooltip'
                  title={`Archive ${
                    chartDataIndex[0][0].template_id ? 'Assessment' : 'Template'
                  }`}
                >
                  <Archive
                    disabled={isLoading}
                    onClick={() => {
                      handleArchiveAssessment(chartDataIndex[0][0].id)
                    }}
                    className='edit-icon'
                  />
                </span>
              </div>
            )}
        </div>
        {isEmpty(chartData) && (
          <div className='d-flex justify-content-center'>
            <DashboardBubbleChartContainer />
          </div>
        )}

        {!isEmpty(chartData) && (
          <div className='d-flex justify-content-between'>
            {containerAssessment && (
              <div className='pt-4 d-flex-column'>
                <DashBoardListAssessmentUsers />
              </div>
            )}
            <div className='pt-3 d-flex-column w-100'>
              <DashboardRadarContainer />
            </div>
            <div className='d-flex-column align-items-start pt-2'>
              <DashboardDataContainer />
              <DashboardAreaContainer />
              <DashboardHorizontalBarChartContainer />
            </div>
          </div>
        )}
        {barChartData.length > 0 && <DashboardBarChartContainer />}

        <Modal w={1100} open={toggledTriggerAssessmentForm}>
          <TriggerAssessment />
        </Modal>
        <Modal w={1500} open={toggleMatrix}>
          <MaturityMatrix />
        </Modal>
        <Modal w={500} open={nodeFormToggled}>
          <NodeForm node={node} parentNode={parentNode} />
        </Modal>
        <Modal w={860} open={manageNodeTypesToggled}>
          <ManageNodeTypes />
        </Modal>
        <Modal w={500} open={nodeTypeFormToggled}>
          <NodeTypesForm />
        </Modal>
        <Modal w={860} open={personaManageToggled}>
          <ManagePersonas />
        </Modal>
        <Modal w={500} open={personaFormToggled}>
          <PersonaForm />
        </Modal>
        <Modal w={1200} open={manageUsersToggled}>
          <ManageUsers node={node} />
        </Modal>
        <Modal open={branchMaturityToggled}>
          <BranchMaturity node={node} />
        </Modal>
        <Modal open={toggledApplyForm}>
          <ApplyAssessment />
        </Modal>
        <Modal open={toggledMaturityModelForm}>
          <AssessmentContent />
        </Modal>
      </div>
    )
  }

  return (
    <>
      <div id='wrapper' className={toggleClass}>
        {sideBar(toggleSideBar)}
        {content(toggleSideBar)}
      </div>
    </>
  )
}

export default Dashboard
