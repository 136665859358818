import {
  FETCH_TREE_DATA,
  GET_NODES_FOR_ORGANIZATION_ID,
  REFRESH_SELECTED_NODE_DATA,
  GET_USERS_FOR_ORGANIZATION_ID,
  GET_PERSONAS_FOR_ORGANIZATION_ID,
  TOGGLE_NODE_FORM_CREATE,
  TOGGLE_MANAGE_USERS,
  TOGGLE_NODE_FORM_EDIT,
  TOGGLE_BRANCH_MATURITY,
  GET_AUTHORIZED_NODES,
  UPDATE_TOGGLED_BRANCHES
} from 'redux/actionTypes'

const initialState = {
  treeData: [],
  nodes: {},
  parentNode: {},
  node: [],
  personas: [],
  nodeFormToggled: false,
  manageUsersToggled: false,
  branchMaturityToggled: false,
  users: [],
  toggledBranches: []
}

const treeReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case FETCH_TREE_DATA: {
      return { ...state, ...action.payload }
    }
    case GET_NODES_FOR_ORGANIZATION_ID: {
      return { ...state, nodes: action.payload }
    }
    case GET_AUTHORIZED_NODES: {
      return { ...state, nodes: action.payload }
    }
    case GET_PERSONAS_FOR_ORGANIZATION_ID: {
      return { ...state, personas: action.payload }
    }
    case TOGGLE_NODE_FORM_CREATE: {
      return createNode(state, action)
    }
    case TOGGLE_NODE_FORM_EDIT: {
      return editNode(state, action)
    }
    case TOGGLE_MANAGE_USERS: {
      return manageUsers(state, action)
    }
    case TOGGLE_BRANCH_MATURITY: {
      return analyseBranchMaturity(state, action)
    }
    case REFRESH_SELECTED_NODE_DATA: {
      return refreshSelectedNodeData(state)
    }
    case GET_USERS_FOR_ORGANIZATION_ID: {
      return { ...state, users: action.payload }
    }
    case UPDATE_TOGGLED_BRANCHES: {
      return updateToggledBranches(state, action)
    }
    default:
      return state
  }
}

const findNode = (subMenuItems, id) => {
  for (let i = 0; i < subMenuItems.length; i++) {
    const element = subMenuItems[i]
    if (element.id === id) {
      return element
    }
    if (element.children.length > 0) {
      const found = findNode(element.children, id)
      if (found) {
        return found
      }
    }
  }
  return null
}

const refreshSelectedNodeData = state => {
  if (!state.node) return state
  const selectedNode = state.node
  const foundNode = findNode(state.treeData, selectedNode.id)
  if (foundNode?.id) {
    return { ...state, node: { ...foundNode } }
  } else {
    return state
  }
}

const manageUsers = (state, action) => {
  const { manageUsersToggled } = action.payload
  const newState = {
    ...state,
    manageUsersToggled,
    node: manageUsersToggled ? action.payload.node : null
  }
  return newState
}

const updateToggledBranches = (state, action) => {
  const { toggledBranches, treeData } = state
  const nodeId = action.payload

  let newToggledBranches = toggledBranches
  const alreadyToggled = []

  if (nodeId === 'collapse') {
    newToggledBranches = []
  } else if (nodeId === 'expand') {
    // array with all the nodes in org
    newToggledBranches = []
    const hasChild = treeData => {
      return treeData.forEach(element => {
        if (element.children.length > 0) {
          newToggledBranches.push(element.id)
          hasChild(element.children)
        }
      })
    }
    hasChild(treeData)
  } else if (typeof nodeId === 'object') {
    // if an array is sent it expands all the nodes with ids in array
    nodeId.forEach(element => {
      const i = toggledBranches?.indexOf(element)
      if (i === -1) {
        newToggledBranches.push(element)
      }
    })
  } else {
    // if its just a number it toggles the node id sent
    const i = toggledBranches?.indexOf(nodeId)
    if (i > -1) {
      alreadyToggled.push(nodeId)
    } else if (i === -1) {
      newToggledBranches.push(nodeId)
    }
  }

  newToggledBranches = newToggledBranches.filter(
    item => !alreadyToggled.includes(item)
  )

  const newState = {
    ...state,
    toggledBranches: [...newToggledBranches]
  }

  return newState
}

const analyseBranchMaturity = (state, action) => {
  const { branchMaturityToggled } = action.payload
  const newState = {
    ...state,
    branchMaturityToggled,
    node: branchMaturityToggled ? action.payload.node : null
  }
  return newState
}

const createNode = (state, action) => {
  const { nodeFormToggled } = action.payload
  const newState = {
    ...state,
    nodeFormToggled,
    node: null,
    parentNode: nodeFormToggled ? action.payload.node : null
  }
  return newState
}

const editNode = (state, action) => {
  const { nodeFormToggled } = action.payload
  const newState = {
    ...state,
    nodeFormToggled,
    node: nodeFormToggled ? action.payload.node : null,
    parentNode: null
  }
  return newState
}

export default treeReducer
