import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isEmpty, difference } from 'lodash'
import { passwordStrength } from 'check-password-strength'

import FormPage from 'components/FormPage/FormPage'
import FormTextInput from 'components/FormTextInput/FormTextInput'

import * as utils from 'utils/utils'
import { api } from 'redux/actions/axios'
import useQuery from 'hooks/useQuery'

import './ResetPasswordPage.scss'

const ResetPasswordPage = () => {
  const [t] = useTranslation()
  const queryParams = useQuery()

  const token = queryParams.get('t')
  const isValidURL = !isEmpty(token)

  const [errors, setErrors] = useState(
    isValidURL ? [] : ['reset_password_page.errors.invalid_url']
  )
  const [successMessages, setSuccessMessages] = useState([])
  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const isPasswordValid = password => {
    const result = passwordStrength(password)
    if (result.length < 8) return false
    if (
      difference(result.contains, [
        'lowercase',
        'uppercase',
        'symbol',
        'number'
      ]).length > 0
    ) {
      return false
    }
    return true
  }

  const handleFormSubmit = async e => {
    e.preventDefault()

    setSuccessMessages([])
    setErrors([])

    if (password !== passwordConfirmation) {
      setErrors([t('reset_password_page.errors.passwords_not_match')])
      return
    }

    if (!isPasswordValid(password)) {
      setErrors([t('reset_password_page.password_strenght')])
      return
    }

    try {
      setIsLoading(true)

      const payload = {
        token,
        newPassword: password
      }

      await api.post('/auth/changePass', payload)

      setSuccessMessages([t('reset_password_page.success')])
      setPassword('')
      setPasswordConfirmation('')
    } catch (e) {
      console.error(e)

      const errors = utils.extractErrors(e)
      setErrors(errors)
    }

    setIsLoading(false)
  }

  return (
    <div className='ResetPasswordPage'>
      <FormPage
        title={t('reset_password_page.title')}
        errors={errors}
        successMessages={successMessages}
      >
        <form onSubmit={handleFormSubmit}>
          <label htmlFor='ResetPasswordPage-newPassword'>
            {t('reset_password_page.fields.new_password')}
          </label>
          <FormTextInput
            id='ResetPasswordPage-newPassword'
            type='password'
            value={password}
            onChange={e => setPassword(e.target.value)}
            autoFocus
            required
            disabled={!isValidURL}
          />

          <label htmlFor='ResetPasswordPage-passwordConfirmation'>
            {t('reset_password_page.fields.confirm_new_password')}
          </label>
          <FormTextInput
            id='ResetPasswordPage-passwordConfirmation'
            type='password'
            value={passwordConfirmation}
            onChange={e => setPasswordConfirmation(e.target.value)}
            required
            disabled={!isValidURL}
          />

          <div className='ResetPasswordPage-cta'>
            <FormTextInput type='submit' disabled={!isValidURL || isLoading} />
          </div>
        </form>

        <p>{t('reset_password_page.password_strenght')}</p>
      </FormPage>
    </div>
  )
}

export default ResetPasswordPage
