import { ProgressBar } from 'components'
import React from 'react'
import { useTranslation } from 'react-i18next'
import AnswerFormRadar from '../AnswerFormRadar/AnswerFormRadar'

import './AnswerFormCompletePage.scss'

const AnswerFormCompletePage = ({ userId, assessmentId }) => {
  const [t] = useTranslation()

  return (
    <div className='AnswerFormCompletePage'>
      <h2>{t('answer_form.complete.title')}</h2>
      <div className='AnswerForm-progress'>
        <ProgressBar completed={100} />
      </div>
      <div className='d-flex justify-content-center mb-4'>
        <span className='AnswerFormCompletePage-checkYou'>
          {t('answer_form.complete.check_you')}
        </span>
      </div>
      <AnswerFormRadar userId={userId} assessmentId={assessmentId} />
    </div>
  )
}

export default AnswerFormCompletePage
