import {
  select,
  map,
  scaleBand,
  axisLeft,
  axisBottom,
  scaleLinear,
  scaleOrdinal,
  schemeCategory10
} from 'd3'

// import { max } from 'd3-array'

export const renderBarChart = async (element, data, answersCount) => {
  const color = scaleOrdinal(schemeCategory10)
  const margin = { top: 30, right: 20, bottom: 15, left: 20 }
  const width = 1100 - margin.left - margin.right
  const height = 150 - margin.top - margin.bottom

  clearBarChart(element)

  // append the svg object to the body of the page
  const svg = select(element)
    .append('svg')
    .attr('width', width + margin.left + margin.right)
    .attr('height', height + margin.top + margin.bottom)
    .append('g')
    .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')')

  // console.log('datatttt', data)

  /* example of data structure for the chart (being menaged at dashboardReducer.js)
      Parse the Data
    const data = await d3.csv('https://raw.githubusercontent.com/holtzy/D3-graph-gallery/master/DATA/data_stacked.csv')

    const data =
    [
      { level: 'basic', Cadastro: '12', SavingCash: '50', MinhaRevenda: '13', Cadastro1: '12', SavingCash1: '50', MinhaRevenda1: '13' },
      { level: 'beginner', Cadastro: '6', SavingCash: '6', MinhaRevenda: '33', Cadastro1: '12', SavingCash1: '50', MinhaRevenda1: '13' },
      { level: 'intermediate', Cadastro: '6', SavingCash: '6', MinhaRevenda: '33', Cadastro1: '12', SavingCash1: '50', MinhaRevenda1: '13' },
      { level: 'advanced', Cadastro: '6', SavingCash: '6', MinhaRevenda: '33', Cadastro1: '12', SavingCash1: '50', MinhaRevenda1: '13' },
      { level: 'expert', Cadastro: '6', SavingCash: '6', MinhaRevenda: '33', Cadastro1: '12', SavingCash1: '50', MinhaRevenda1: '13' }]

    data.columns = ['level', 'Cadastro', 'SavingCash', 'MinhaRevenda', 'Cadastro1', 'SavingCash1', 'MinhaRevenda1']
  */
  //   List of sublevels = header of the csv files = soil condition here
  const sublevels = data.columns.slice(1)

  // List of levels = species here = value of the first column called level -> I show them on the X axis
  const levels = map(data, function (d) {
    return d.level
  })

  // Add X axis
  const x = scaleBand().domain(levels).range([0, width]).padding([0.2])
  svg
    .append('g')
    .attr('transform', 'translate(0,' + height + ')')
    .call(axisBottom(x).tickSize(3))

  // Add Y axis
  const y = scaleLinear().domain([0, answersCount]).range([height, 0])
  svg.attr('transform', 'translate(30, 15)').append('g').call(axisLeft(y))

  // Another scale for sublevel position?
  const xSublevel = scaleBand()
    .domain(sublevels)
    .range([0, x.bandwidth()])
    .padding([0.05])

  // Show the bars
  svg
    .append('g')
    .selectAll('g')
    // Enter in data = loop level per level
    .data(data)
    .enter()
    .append('g')
    .attr('transform', function (d) {
      return 'translate(' + x(d.level) + ',0)'
    })
    .selectAll('rect')
    .data(function (d) {
      return sublevels.map(function (key) {
        return { key: key, value: d[key][0] }
      })
    })
    .enter()
    .append('rect')
    .attr('x', function (d) {
      return xSublevel(d.key)
    })
    .attr('y', function (d) {
      return y(0)
    })
    .attr('width', xSublevel.bandwidth())
    .attr('height', function (d) {
      return height - y(0)
    })
    .attr('fill', function (d) {
      return color(d.key)
    })
    .transition()
    .duration(800)
    .attr('x', function (d) {
      return xSublevel(d.key)
    })
    .attr('y', function (d) {
      return y(d.value)
    })
    .attr('width', xSublevel.bandwidth())
    .attr('height', function (d) {
      return height - y(d.value)
    })
    .attr('fill', function (d) {
      return color(d.key)
    })

  // add labels for numbers
  svg
    .append('g')
    .selectAll('g')
    .data(data)
    .enter()
    .append('g')
    .attr('transform', function (d) {
      return 'translate(' + x(d.level) + ',-18)'
    })
    .selectAll('text')
    .data(function (d) {
      return sublevels.map(function (key) {
        return {
          key: key,
          value: d[key][0],
          count: d[key][1],
          total: d[key][2]
        }
      })
    })
    .enter()
    .append('text')
    .attr('x', function (d) {
      return xSublevel(d.key) + xSublevel.bandwidth() / 2 - 9
    })
    .attr('y', function (d) {
      return y(d.value)
    })
    .attr('dy', '.35em')
    .text(function (d) {
      return `${d.count}/${d.total}`
    })
    .style('font-family', "'Quicksand', sans-serif")
    .style('font-size', '0.65rem')
    .style('font-weight', '500')
    .attr('text-anchor', 'right')
    .attr('dy', '1em')
    .attr('fill', 'lightgray')
}

export const clearBarChart = element => {
  select(element).selectAll('svg').remove()
}
