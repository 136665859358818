import { useCallback } from 'react'
import _ from 'lodash'

function useDebounce(callback, delay) {
  // eslint-disable-next-line
  const debouncedFn = useCallback(
    _.debounce((...args) => callback(...args), delay),
    [delay]
  )
  return debouncedFn
}

export default useDebounce
