import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { AssessmentForm, Steps, ObjectivesForm, QuestionForm } from 'components'
import RecomendationForm from 'components/Recommendation/RecomendationForm/RecomendationForm.jsx'

const AssessmentContent = () => {
  const [t] = useTranslation()

  const [currentIndexTab, setCurrentIndexTab] = useState(1)
  const [percentage, setPercentage] = useState(0)

  const handleNextButtonClick = () => {
    setCurrentIndexTab(currentIndexTab + 1)
  }

  const handleBackButtonClick = () => {
    setCurrentIndexTab(currentIndexTab - 1)
  }

  const renderForm = () => {
    if (currentIndexTab === 1) {
      return <AssessmentForm />
    } else if (currentIndexTab === 2) {
      return <ObjectivesForm />
    } else if (currentIndexTab === 3) {
      return <RecomendationForm />
    } else if (currentIndexTab === 4) {
      return (
        <QuestionForm percentage={percentage} setPercentage={setPercentage} />
      )
    } else {
      return null
    }
  }

  const content = [
    {
      title: t('assessment_content.add_assessment'),
      indexTab: 1
    },
    {
      title: t('assessment_content.add_objectives'),
      indexTab: 2
    },
    {
      title: t('assessment_content.recomendation'),
      indexTab: 3
    },
    {
      title: t('assessment_content.add_questions'),
      indexTab: 4
    }
  ]

  return (
    <>
      <Steps
        steps={content}
        currentIndexTab={currentIndexTab}
        onNextClick={handleNextButtonClick}
        onBackClick={handleBackButtonClick}
        percentage={percentage}
      />
      {renderForm()}
    </>
  )
}

export default AssessmentContent
