import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useDispatch, useSelector } from 'react-redux'
import { scaleOrdinal, schemeCategory10 } from 'd3'

import './DashboardDataContainer.scss'
import DashboardDataContainer from './DashBoardDataContainer'
import { setAreas } from 'redux/actions/areasAction'

const DashboardAreaContainer = () => {
  const [t] = useTranslation()
  const dispatch = useDispatch()

  const color = scaleOrdinal(schemeCategory10)
  const { dashboard, area } = useSelector(state => state)
  const { chartData, showAverage, hiddenElements, selectedTemplate } = dashboard
  const [areaDataTable, setAreaDataTable] = useState([])
  const { areas } = area

  useEffect(() => {
    if (areas.length === 0) {
      const fetch = async () => {
        await setAreas(selectedTemplate.id, dispatch)
      }
      fetch()
    }
  }, [areas])

  const renderDataTable = (row, j) => {
    const rowWithFilteredHidden = row.filter(
      (col, k) =>
        hiddenElements.findIndex(el => el[1] === k - 1 && k > 0) === -1
    )

    const foundArea = areas.find(item => item.name === rowWithFilteredHidden[0])

    return (
      <>
        <div
          style={{ color: 'areaColor', backgroundColor: foundArea?.color }}
          className='area-title-col'
          key={j + Math.random()}
        >
          {rowWithFilteredHidden[0]}
        </div>
        <DashboardDataContainer areaName={row[0]} />

        <div key={j + Math.random()} className='total-row'>
          {rowWithFilteredHidden.map((col, i) => {
            return i > 0 ? (
              <div
                style={{ backgroundColor: color(i) }}
                className='area-data-col animated fadeInUp'
                key={i + Math.random()}
              >
                {col}
              </div>
            ) : (
              <div
                style={{
                  color: 'areaColor'
                }}
                className='total-title-col'
                key={i + Math.random()}
              >
                Total:
              </div>
            )
          })}
        </div>
      </>
    )
  }

  useEffect(() => {
    if (chartData.length > 0) {
      const areaDataTable = []
      const limit = chartData.length

      chartData[0].forEach(obj => {
        if (areaDataTable.findIndex(x => x[0] === obj.areaName) === -1) {
          const newArray = new Array(limit + 1).fill(0)
          newArray[0] = obj.areaName
          areaDataTable.push(newArray)
        }
      })

      for (let i = 0; i < limit; i++) {
        chartData[i].forEach(function (obj) {
          const found = areaDataTable.findIndex(x => x[0] === obj.areaName)
          areaDataTable[found][i + 1] = areaDataTable[found][i + 1] + obj.value
        })
      }
      const newDataTable = areaDataTable.map(area => {
        return area.map((item, j) => {
          if (j === 0) {
            return item
          } else {
            return (
              item / chartData[0].filter(o => o.areaName === area[0]).length
            ).toFixed(2)
          }
        })
      })
      setAreaDataTable(newDataTable)
    } else {
      setAreaDataTable([])
    }
  }, [chartData, showAverage])

  return (
    <div className='mt-2 z-depth-4'>
      {areaDataTable.length > 0 && (
        <p className='ml-1 mb-2 data-table-label'>
          {t('dashboard_area_container.areas_maturity_indexes')}
        </p>
      )}
      <div className='areaContainerHeight dataContainer p-1'>
        {areaDataTable.map(renderDataTable)}
      </div>
    </div>
  )
}

export default DashboardAreaContainer
