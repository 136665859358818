import { I18nextProvider } from 'react-i18next'
import i18n from 'i18n'
import { Router } from 'components'

import { Provider } from 'react-redux'
import store from 'redux/store'

import 'styles/reset.scss'
import 'App.scss'
import { useEffect } from 'react'

const App = () => {
  useEffect(() => {
    window.toastr.options.progressBar = true
    window.toastr.options.closeDuration = 700
    window.toastr.options.timeOut = 10000
    window.toastr.options.extendedTimeOut = 3000
    window.toastr.options.closeButton = true
    window.toastr.options.positionClass = 'toast-bottom-right'
    window.toastr.options.showMethod = 'slideDown'
    window.toastr.options.hideMethod = 'slideUp'
    window.toastr.options.closeMethod = 'slideUp'
    window.toastr.options.closeHtml =
      '<button><i class="fa fa-window-close"></i></button>'
  }, [])

  return (
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <Router />
      </I18nextProvider>
    </Provider>
  )
}

export default App
