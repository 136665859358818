import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import './MaturityMatrix.scss'
import { toggleMaturityMatrix } from 'redux/actions/dashboardActions'

const MaturityMatrix = () => {
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const dashboard = useSelector(state => state.dashboard)

  const { toggleMatrix, matrixContent } = dashboard
  const maturityMatrix = matrixContent

  const renderMaturityLevels = () => {
    return maturityLevel => {
      return (
        <div className='MaturityMatrix-maturity-level'>
          {maturityLevel.name}
        </div>
      )
    }
  }

  const addOpacity = hex => {
    const { r, g, b } = hexToRgb(hex)
    return `rgb(${r}, ${g}, ${b}, 0.9)`
  }

  function hexToRgb(hex) {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
    hex = hex.replace(shorthandRegex, function (m, r, g, b) {
      return r + r + g + g + b + b
    })

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16)
        }
      : null
  }

  const renderMaturityDescription = (maturityDescriptionlength, color) => {
    return description => {
      const maturityDescriptionClassName =
        'MaturityMatrix-objective-maturity-description-' +
        maturityDescriptionlength
      return (
        <div
          className={maturityDescriptionClassName}
          style={{ border: color + ' 1px solid' }}
        >
          {description.description}
        </div>
      )
    }
  }

  const renderObjectives = color => {
    return objective => {
      return (
        <div className='MaturityMatrix-objective'>
          <div
            className='MaturityMatrix-objective-name'
            style={{ border: color + ' 1px solid' }}
          >
            {objective.objective.shortName}
          </div>
          <div className='MaturityMatrix-objective-maturity-descriptions'>
            {objective.objective.objectiveMaturity
              .sort((a, b) => a.maturity_id - b.maturity_id)
              .map(
                renderMaturityDescription(
                  objective.objective.objectiveMaturity.length,
                  color
                )
              )}
          </div>
        </div>
      )
    }
  }

  const renderMatrix = () => {
    return area => {
      const color = addOpacity(area.area.color)
      return (
        <>
          <div className='MaturityMatrix-area'>
            <div
              className='MaturityMatrix-area-name'
              style={{ backgroundColor: area.area.color }}
            >
              {area.area.name}
            </div>
            <div className='MaturityMatrix-area-objectives'>
              {area.objectives?.map(renderObjectives(color))}
            </div>
          </div>
        </>
      )
    }
  }
  return (
    <div className='MaturityMatrix animated fadeIn'>
      <div className=' MaturityMatrix-container'>
        <div className='MaturityMatrix-header'>
          {t('maturity_matrix.maturity_matrix')}
          <span>
            {t('maturity_matrix.for_template')} {maturityMatrix.name}
          </span>
        </div>
        <div className='MaturityMatrix-body'>
          <div className='MaturityMatrix-matrix-header'>
            <div className='MaturityMatrix-matrix-header-areas'>Areas</div>
            <div className='MaturityMatrix-matrix-header-objectives'>
              {t('maturity_matrix.objectives')}
            </div>

            <div className='MaturityMatrix-matrix-header-maturity-levels'>
              <div className='MaturityMatrix-maturity-levels-text'>
                {t('maturity_matrix.maturity_levels')}
              </div>

              <div className='MaturityMatrix-maturity-levels'>
                {maturityMatrix.maturityLevels?.map(renderMaturityLevels())}
              </div>
            </div>
          </div>
          <div className='MaturityMatrix-areas'>
            {maturityMatrix.areas.map(renderMatrix())}
          </div>
        </div>
        <div className='MaturityMatrix-footer'>
          <button
            className='MaturityMatrix-cancelButton'
            onClick={() => {
              toggleMaturityMatrix({ toggleMatrix: !toggleMatrix }, dispatch)
            }}
          >
            {t('maturity_matrix.close')}
          </button>
        </div>
      </div>
    </div>
  )
}

export default MaturityMatrix
