import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'

import appReducer from 'appReducer'

const store = createStore(
  appReducer,
  compose(
    applyMiddleware(thunk),
    window.devToolsExtension ? window.devToolsExtension() : f => f
  )
)

export default store
