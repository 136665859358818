import './Modal.scss'

const Modal = ({ children, open, w }) => {
  return open ? (
    <div style={{ width: w || null }} className='Modal z-depth-3'>
      {children}
    </div>
  ) : (
    <> </>
  )
}

export default Modal
