import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import ToggleButton from 'react-toggle-button'
import { useTranslation } from 'react-i18next'
import { scaleOrdinal, schemeCategory10 } from 'd3'

import MoreVertIcon from '@material-ui/icons/MoreVert'
import EditIcon from '@material-ui/icons/Edit'
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder'
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer'
import GroupAddIcon from '@material-ui/icons/GroupAdd'
import AssessmentIcon from '@material-ui/icons/Assessment'
import LaunchIcon from '@material-ui/icons/Launch'
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation'
import {
  AccountTree,
  DonutLarge,
  Clear,
  Check,
  FolderSpecial,
  LibraryAdd,
  Visibility
} from '@material-ui/icons'

import {
  duplicateTemplate,
  getTemplatesByOrganizationId,
  setAssessment
} from 'redux/actions/assessmentActions'
import {
  getNodesByOrganizationId,
  getAuthorizedNodes,
  toggleApplyForm,
  toggleTriggerAssessment,
  toggleMaturityModelForm,
  toggleNodeFormCreate,
  toggleNodeFormEdit,
  toggleManageUsers,
  toggleBranchMaturity,
  fetchTreeData,
  updateToggledBranches
} from 'redux/actions/treeActions'

import { toggleManageNodeTypes } from 'redux/actions/nodeTypeActions'

import {
  clearDashboard,
  fetchAssessmentsPerUser,
  toggleAssessmentContainer,
  toggleRadar,
  toggleSideBar
} from 'redux/actions/dashboardActions'
import { setMaturityLevels } from 'redux/actions/maturityLevelActions'
import { setAreas } from 'redux/actions/areasAction'
import { clearObjective, setObjectives } from 'redux/actions/objectivesAction'

import { getLoggedInUser } from 'auth'

import { prepareAssessmentData } from 'utils/utils'

import NodeUsers from './NodeUsers'

import './Node.scss'
import { togglePersonaManage } from 'redux/actions/personaActions'
import { Confirm } from 'notiflix'
import { clearBubbleChart } from 'components/BubbleChart/BubbleChart'

const color = scaleOrdinal(schemeCategory10)

const Node = ({ content }) => {
  const [t] = useTranslation()

  const dispatch = useDispatch()
  const user = getLoggedInUser()
  const dashboard = useSelector(state => state.dashboard)
  const { checkedData, selectedTemplate, assessmentMode, containerAssessment } =
    dashboard
  const tree = useSelector(state => state.tree)
  const { toggledBranches } = tree
  const organizationId = user.organization_id

  Confirm.Init({
    nameColor: 'rgba(58, 157, 184, 1)',
    okButtonBackground: '#d4a302',
    backgroundColor: 'rgb(40,40,60)',
    borderRadius: '5px',
    messageColor: 'white',
    zindex: 9999,
    width: '400px'
  })

  const colorlevel = node => {
    return (
      <div
        style={{
          backgroundColor: color(level(node)),
          borderRadius: '2px',
          maxWidth: '20px',
          minHeight: '4em'
        }}
      >
        &nbsp;
      </div>
    )
  }

  const level = node => {
    const parent = node.parent_id ? node.parent_id : 0
    return parent
  }

  const shouldOpenBranch = node => {
    const i = toggledBranches?.indexOf(node.id)
    return !!(node.children.length > 0 && i > -1)
  }

  const getCollapseId = id => `collapasableElement_node_${id}`
  const getCollapseHrefId = id => `#collapasableElement_node_${id}`

  const getCollapseIdForMenu = id => `collapasableElement_node_for_menu${id}`
  const getCollapseIdForMenuClose = id =>
    `collapasableElement_node_for_menu_close${id}`
  const getCollapseHrefIdForMenu = id =>
    `#collapasableElement_node_for_menu${id}`

  const applyAssessment = async () => {
    await getTemplatesByOrganizationId(user.organization_id, dispatch)
    user.role === 'super' || user.role === 'master'
      ? await getNodesByOrganizationId(organizationId, dispatch)
      : await getAuthorizedNodes(organizationId, user.id, dispatch)
    await toggleApplyForm(dispatch)
  }

  const editMaturityModel = async (e, assessment) => {
    e.stopPropagation()
    clearObjective(dispatch)
    clearDashboard(organizationId, user.id, dispatch)
    await setAssessment(assessment, dispatch)
    await setMaturityLevels(assessment.maturityLevels, dispatch)
    await setAreas(assessment.id, dispatch)
    await setObjectives(assessment.id, dispatch)
    await toggleMaturityModelForm(dispatch)
  }

  const handleDuplicateTemplate = async (e, assessment) => {
    Confirm.Show(
      t('node.deployed.confirm.title'),
      t('node.deployed.confirm.description'),
      t('node.deployed.confirm.yes'),
      t('node.deployed.confirm.no'),
      async () => {
        try {
          e.stopPropagation()

          await duplicateTemplate(assessment, dispatch)
          clearObjective(dispatch)
          clearDashboard(organizationId, user.id, dispatch)

          fetchTreeData(user.organization_id, user.id, dispatch)
        } catch (e) {
          console.error(e)
          window.toastr.error(e?.response?.data?.error)
        }
      }
    )
  }

  const toggleAssessmentUsersContainer = async (
    e,
    assessment,
    getCheckedState,
    node,
    maturityIndex,
    assessmentMode,
    dispatch
  ) => {
    e.stopPropagation()
    const assessmentData = prepareAssessmentData(
      assessment,
      getCheckedState(node, assessment),
      maturityIndex,
      node,
      assessmentMode
    )
    const { data } = await fetchAssessmentsPerUser(assessment.id)
    const newUsers = [...data].map(user => {
      return {
        ...user,
        persona: node.nodeUsers.find(nodeUser => nodeUser.id === user.id)
          ?.persona
      }
    })

    assessmentData.users = [...newUsers]

    toggleAssessmentContainer(assessmentData, dispatch)
    setTimeout(() => {
      if (!getCheckedState(node, assessment)) {
        toggleSideBar(dispatch)
      }
    }, 200)
    setTimeout(() => {
      if (!getCheckedState(node, assessment)) {
        toggleRadar(assessmentData, dispatch)
      }
    }, 600)
  }

  const toggleMenu = node => {
    document.getElementById(getCollapseIdForMenuClose(node.id)).click()
  }

  const renderMenu = (applyAssessment, node) => {
    return (
      <div className='Tree-node-menu' aria-labelledby='menuNode'>
        <button
          className='dropdown-item'
          onClick={() => {
            toggleMenu(node)
            toggleNodeFormEdit({ nodeFormToggled: true, node }, dispatch)
          }}
        >
          <EditIcon />
          <span>{t('node.edit_node')}</span>
        </button>
        <button
          className='dropdown-item'
          onClick={() => {
            toggleMenu(node)
            toggleNodeFormCreate({ nodeFormToggled: true, node }, dispatch)
          }}
        >
          <CreateNewFolderIcon />
          <span>{t('node.create_child_node')}</span>
        </button>
        <button
          className='dropdown-item'
          onClick={() => {
            toggleMenu(node)
            toggleManageNodeTypes({ manageNodeTypesToggled: true }, dispatch)
          }}
        >
          <FolderSpecial />
          <span>{t('node.manage_node_types')}</span>
        </button>
        <hr />
        <button
          className='dropdown-item'
          onClick={async () => {
            toggleMenu(node)
            await toggleMaturityModelForm(dispatch, node.id)
          }}
        >
          <DonutLarge />
          <span>{t('node.create_maturity_model')}</span>
        </button>
        <button
          className='dropdown-item'
          onClick={e => {
            e.stopPropagation()
            toggleMenu(node)
            applyAssessment()
          }}
        >
          <AssessmentIcon />
          <span>{t('node.apply_assessment')}</span>
        </button>
        <button
          className='dropdown-item'
          onClick={async e => {
            e.stopPropagation()
            toggleMenu(node)
            await toggleTriggerAssessment(dispatch)
          }}
        >
          <QuestionAnswerIcon />
          <span>{t('node.trigger_assessment')}</span>
        </button>
        <hr />
        <button
          className='dropdown-item'
          onClick={() => {
            toggleMenu(node)
            toggleManageUsers({ manageUsersToggled: true, node }, dispatch)
          }}
        >
          <GroupAddIcon />
          <span>{t('node.manage_users')}</span>
        </button>
        <button
          className='dropdown-item'
          onClick={() => {
            toggleMenu(node)
            togglePersonaManage({ personaManageToggled: true }, dispatch)
          }}
        >
          <GroupAddIcon />
          <span>{t('node.personas')}</span>
        </button>
      </div>
    )
  }

  const renderAssessments = node => {
    const tbColors = {
      activeThumb: {
        base: 'rgb(141, 160, 86)'
      },
      inactiveThumb: {
        base: 'rgba(96, 96, 120, 1)'
      },
      active: {
        base: 'rgba(50, 68, 73, 0.42)',
        hover: 'rgba(31, 31, 49, 1)'
      },
      inactive: {
        base: 'rgba(50, 68, 73, 0.42)',
        hover: 'rgba(31, 31, 49, 1)'
      }
    }

    const getCheckedState = (node, assessment) => {
      return (
        checkedData.indexOf(
          node.nodeType.name
            .concat(` - ${node.name} - `)
            .concat(assessment.name)
        ) !== -1
      )
    }
    return node.assessments.map(assessment => {
      if (assessment.template_id === null) return null
      const toggleDataPoints = async e => {
        await toggleAssessmentUsersContainer(
          e,
          assessment,
          getCheckedState,
          node,
          maturityIndex,
          assessmentMode,
          dispatch
        )
      }

      const maturityIndex =
        assessment.objectives
          .map(o => (assessmentMode ? o.assessmentValue : o.maturityValue))
          .reduce((p, c) => {
            return p + c
          }, 0) / assessment.objectives.length
      return (
        <div className='Tree-assessment-card' key={assessment.id}>
          <span className='assessment-name'>{assessment.name}</span>
          <div className='d-inline-flex align-items-center assessment-icons'>
            {assessment.template_id != null && (
              <span className='tag' alt='Maturity Index'>
                {maturityIndex.toFixed(2)}
              </span>
            )}

            {assessment.template_id === null && (
              <span
                className={
                  !assessment.isDeployed ? 'undeployed' : 'template-tag'
                }
                alt='Template'
              >
                {!assessment.isDeployed ? 'template' : 'template'}
              </span>
            )}
            {(assessment.template_id === null
              ? !!assessment.isDeployed &&
                (checkedData.length === 0 ||
                  assessment.id === selectedTemplate.id)
              : true) &&
              (checkedData.length === 0 ||
                assessment.template_id === selectedTemplate.templateId) &&
              !(
                containerAssessment && containerAssessment.id === assessment.id
              ) && (
                <ToggleButton
                  inactiveLabel={<Clear style={{ fontSize: '1rem' }} />}
                  activeLabel={<Check style={{ fontSize: '1rem' }} />}
                  value={getCheckedState(node, assessment)}
                  colors={tbColors}
                  onToggle={value => {
                    const assessmentData = prepareAssessmentData(
                      assessment,
                      value,
                      maturityIndex,
                      node,
                      assessmentMode
                    )
                    assessmentData.assessmentPack = {
                      isUserAuthorized: node.isUserAuthorized,
                      nodeId: node.id,
                      nodeName: node.name,
                      nodeType: node.nodeType.name,
                      nodeUsers: node.nodeUsers.map(user => {
                        return { ...user, selected: true }
                      }),
                      assessment
                    }
                    toggleRadar(assessmentData, dispatch)
                  }}
                />
              )}
            <span data-toggle='tooltip' title='Toggle assessment details'>
              {checkedData.length === 0 &&
                assessment.template_id != null &&
                !containerAssessment && (
                  <LaunchIcon
                    onClick={toggleDataPoints}
                    className={
                      assessment.id === containerAssessment?.id
                        ? 'launch-icon'
                        : 'edit-icon'
                    }
                  />
                )}
              {assessment.template_id != null &&
                containerAssessment &&
                containerAssessment.id === assessment.id && (
                  <CancelPresentationIcon
                    id='cancelpresentation'
                    onClick={toggleDataPoints}
                    className={
                      assessment.id === containerAssessment?.id
                        ? 'launch-icon'
                        : 'edit-icon'
                    }
                  />
                )}
            </span>

            {assessment.template_id === null &&
              (user.role === 'super' ||
                user.role === 'master' ||
                (node.isUserAuthorized && user.role !== 'user')) && (
                <div>
                  {assessment.isDeployed ? (
                    <div>
                      <span data-toggle='tooltip' title='View maturity model'>
                        <Visibility
                          onClick={e => {
                            e.stopPropagation()
                            editMaturityModel(e, assessment)
                          }}
                          className='edit-icon'
                        />
                      </span>
                      <span
                        data-toggle='tooltip'
                        title='Duplicate maturity model'
                      >
                        <LibraryAdd
                          onClick={e => {
                            e.stopPropagation()
                            handleDuplicateTemplate(e, assessment)
                          }}
                          className='edit-icon'
                        />
                      </span>
                    </div>
                  ) : (
                    <div>
                      <span data-toggle='tooltip' title='Edit maturity model'>
                        <EditIcon
                          onClick={e => {
                            e.stopPropagation()

                            clearBubbleChart('#bubbleChartBuilder')
                            editMaturityModel(e, assessment)
                          }}
                          className='edit-icon'
                        />
                      </span>
                    </div>
                  )}
                </div>
              )}
          </div>
        </div>
      )
    })
  }
  const byName = (a, b) => {
    const nameA = a.name.toLowerCase()
    const nameB = b.name.toLowerCase()
    if (nameA < nameB) {
      return -1
    }
    if (nameA > nameB) {
      return 1
    }
    return 0
  }

  const renderTreeMenu = node => {
    if (
      user.role === 'super' ||
      user.role === 'master' ||
      (node.isUserAuthorized && user.role !== 'user')
    ) {
      return (
        <a
          id={getCollapseIdForMenuClose(node.id)}
          className='mt-2'
          data-toggle='collapse'
          href={getCollapseHrefIdForMenu(node.id)}
          aria-expanded='false'
          aria-controls={getCollapseIdForMenu(node.id)}
          data-parent={getCollapseHrefIdForMenu(node.id)}
        >
          <MoreVertIcon />
        </a>
      )
    }
  }
  return (
    <div>
      <ul className='Tree'>
        {content.sort(byName).map(node => (
          <li key={node.id} className='animated fadeInDown pl-3'>
            <div
              className='Tree-node d-flex w-100 flex-row justify-content-start z-depth-2'
              id={`node-${node.id}`}
            >
              {colorlevel(node)}
              <div className='Tree-node-content d-flex flex-column w-100'>
                <div className='d-flex flex-row'>
                  <div className='d-flex flex-column align-items-start w-100'>
                    <span
                      className='mt-2'
                      data-toggle='tooltip'
                      title={t('node.show_node_description')}
                    >
                      <a
                        data-toggle='collapse'
                        href={getCollapseHrefId(node.id)}
                        aria-expanded='false'
                        aria-controls={getCollapseId(node.id)}
                        data-parent={getCollapseHrefId(node.id)}
                      >
                        <span className='Tree-node-name'>{node.name}</span>
                      </a>
                    </span>
                    <span className='Tree-node-type'>{node.nodeType.name}</span>
                    <div className='collapse' id={getCollapseId(node.id)}>
                      <p className='Tree-node-description'>
                        {node.description}
                      </p>
                    </div>
                  </div>
                  <div className='d-flex flex-column w-100'>
                    <div className='d-flex flex-row justify-content-end'>
                      {(user.role === 'super' ||
                        user.role === 'master' ||
                        (node.isUserAuthorized && user.role !== 'user')) && (
                        <span
                          data-toggle='tooltip'
                          title={t('node.analyse_branch_maturity')}
                        >
                          <DonutLarge
                            className='Tree-node-icon'
                            onClick={() => {
                              toggleSideBar(dispatch)
                              setTimeout(() => {
                                toggleBranchMaturity(
                                  { branchMaturityToggled: true, node },
                                  dispatch
                                )
                              }, 500)
                            }}
                          />
                        </span>
                      )}
                      {node.children.length > 0 && (
                        <span
                          data-toggle='tooltip'
                          title={t('node.toggle_branch')}
                        >
                          <AccountTree
                            className='Tree-node-icon'
                            onClick={async () => {
                              await updateToggledBranches(node.id, dispatch)
                            }}
                          />
                        </span>
                      )}

                      {renderTreeMenu(node)}
                    </div>
                    <div className='d-flex flex-row'>
                      <div
                        className='collapse'
                        id={getCollapseIdForMenu(node.id)}
                      >
                        {renderMenu(applyAssessment, node)}
                      </div>
                    </div>
                  </div>
                </div>

                <div className='Tree-assessment-container'>
                  <NodeUsers nodeUsers={node.nodeUsers} />
                </div>

                <div className='Tree-assessment-container d-flex flex-wrap align-items-begin z-depth-1'>
                  {renderAssessments(node)}
                </div>
              </div>
            </div>
            {shouldOpenBranch(node) && <Node content={node.children} />}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Node
