import React from 'react'

import './StepProgress.scss'

const StepProgress = ({ currValue, maxValue }) => {
  return (
    <div className='StepProgress-wrapper' style={customStyle.wrapper}>
      <div style={customStyle.progress(currValue, maxValue)} />
    </div>
  )
}

const customStyle = {
  wrapper: {
    height: '3px',
    margin: '1.563rem 5.063rem',
    background: '#282839'
  },
  progress: (currValue, maxValue) => ({
    width: `${(currValue * 100) / maxValue}%`,
    height: '3px',
    background: 'linear-gradient(to top, #2d73a0, #5ce7ea)',
    transition: 'all 300ms ease-in-out'
  })
}

export default StepProgress
