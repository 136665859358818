import React from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { newQuestion } from 'redux/actions/questionsAction'

import './NewQuestion.scss'

const NewQuestion = () => {
  const [t] = useTranslation()
  const dispatch = useDispatch()

  return (
    <div className='AddQuestion ml-auto'>
      <button
        className='AddQuestion--btn ml-auto'
        onClick={() => {
          document.getElementById('CreateQuestion-title').focus()
          newQuestion(dispatch)
        }}
      >
        <span>{t('new_question.button')}</span>
      </button>
    </div>
  )
}

export default NewQuestion
