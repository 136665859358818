import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'

import { getLoggedInUser } from 'auth'

import {
  createAssessment,
  getTemplatesByOrganizationId,
  updateAssessment
} from 'redux/actions/assessmentActions'
import { MaturityLevel } from 'components'

import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import './AssessmentForm.scss'
const AssessmentForm = () => {
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const shortName = useRef()
  const shortDescription = useRef()
  const { assessment, template } = useSelector(state => state)
  const { fromNode } = template
  const user = getLoggedInUser()
  const organizationId = user.organization_id

  useEffect(() => {
    const fetchData = async () => {
      await getTemplatesByOrganizationId(organizationId, dispatch)
    }
    fetchData()
  }, [organizationId, dispatch])

  useEffect(() => {
    if (assessment.id) {
      shortName.current.value = assessment.name
      shortDescription.current.value = assessment.description
    }
  }, [assessment])

  const handleFieldChange = async (value, field, assessment) => {
    try {
      assessment.id
        ? await updateAssessment(field, value, assessment, dispatch)
        : await createAssessment(
            field,
            value,
            assessment,
            user.organization_id,
            fromNode,
            dispatch
          )
    } catch (e) {
      if (
        e?.response?.data?.error?.message === 'Name already exists' &&
        field === 'name'
      ) {
        window.toastr.error(e?.response?.data?.error?.message)
        document.getElementById('shortName').value = ''
        document.getElementById('shortName').className =
          'wiggle border-red AssessmentForm-input z-depth-3 mt-3 '
        delete assessment.date
        setTimeout(async () => {
          if (document.getElementById('shortName')) {
            document.getElementById('shortName').className =
              'AssessmentForm-input z-depth-3 mt-3'
          }
        }, 3000)
      }
    }
  }

  return (
    <div className='AssessmentForm'>
      <div className='modal-body'>
        <div className='AssessmentForm-container'>
          <div className='text-center AssessmentForm-formField'>
            <label htmlFor='shortName'>
              {t('label.short_name')}
              <input
                className='AssessmentForm-input z-depth-3 mt-3 '
                id='shortName'
                ref={shortName}
                type='text'
                disabled={assessment.isDeployed}
                onBlur={e => {
                  e.preventDefault()
                  handleFieldChange(e.target.value, 'name', assessment)
                }}
              />
            </label>
            <label htmlFor='shortDescription'>
              {t('assessment_form.description')}
              <textarea
                className='AssessmentForm-input AssessmentForm-marginTop22 z-depth-3'
                id='shortDescription'
                type='text'
                ref={shortDescription}
                disabled={assessment.isDeployed}
                onBlur={e => {
                  e.preventDefault()
                  handleFieldChange(e.target.value, 'description', assessment)
                }}
              />
            </label>
          </div>
          {/* <div className='col-md text-center AssessmentForm-formField' /> */}
          {/* <div className='col-md text-center AssessmentForm-formField'>
            <label htmlFor='startDate'>
              <AccessTime />
              &nbsp;&nbsp;
              {t('assessment_form.assessment_period')}
            </label>
            <div className='AssessmentForm-dateInputs mb-3'>
              <span className='AssessmentForm-dateLabel'>
                {t('label.start_date')}
              </span>
              <span className='AssessmentForm-dateLabel'>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>

              <span className='AssessmentForm-dateLabel'>
                {t('label.end_date')}
              </span>
            </div>
            <DateRange
              className='z-depth-3'
              editableDateInputs
              color='#FFF'
              disabled={assessment.isDeployed}
              onChange={item => {
                if (!assessment.isDeployed) {
                  setDate(item.selection)
                  handleFieldChange(item.selection, 'date', assessment)
                }
              }}
              moveRangeOnFirstSelection={false}
              ranges={[date]}
            />
          </div> */}
          <div className='text-center AssessmentForm-formField'>
            <MaturityLevel />
          </div>
        </div>
      </div>
    </div>
  )
}

export default AssessmentForm
