import {
  GET_NODE_TYPES_BY_ORGANIZATION_ID,
  TOGGLE_MANAGE_NODE_TYPES,
  TOGGLE_NODE_TYPE_FORM
} from 'redux/actionTypes'

const initialState = {
  nodeTypes: [{ name: 'nodetype' }],
  manageNodeTypesToggled: false,
  nodeTypeFormToggled: false
}

const nodeTypeReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_NODE_TYPES_BY_ORGANIZATION_ID: {
      return { ...state, nodeTypes: action.payload }
    }
    case TOGGLE_MANAGE_NODE_TYPES: {
      return manageNodeTypes(state, action)
    }
    case TOGGLE_NODE_TYPE_FORM: {
      return toggleNodeTypeForm(state, action)
    }
    default:
      return state
  }
}

const manageNodeTypes = (state, action) => {
  const { manageNodeTypesToggled } = action.payload
  const newState = {
    ...state,
    manageNodeTypesToggled
  }
  return newState
}

const toggleNodeTypeForm = (state, action) => {
  const { nodeTypeFormToggled } = action.payload
  const newState = {
    ...state,
    nodeTypeFormToggled,
    nodeType: { ...action.payload.nodeType }
  }
  return newState
}

export default nodeTypeReducer
