import React from 'react'
import ToggleButton from 'react-toggle-button'
import { Clear, Check } from '@material-ui/icons'

const NodeUsersSelect = ({
  value,
  nodeId,
  assessmentId,
  userId,
  callBack,
  user
}) => {
  const tbColors = {
    activeThumb: {
      base: 'rgb(141, 160, 86)'
    },
    inactiveThumb: {
      base: 'rgba(96, 96, 120, 1)'
    },
    active: {
      base: 'rgba(50, 68, 73, 0.42)',
      hover: 'rgba(31, 31, 49, 1)'
    },
    inactive: {
      base: 'rgba(50, 68, 73, 0.42)',
      hover: 'rgba(31, 31, 49, 1)'
    }
  }

  return (
    <div className='animated fadeInDown'>
      <ToggleButton
        inactiveLabel={
          <Clear
            style={{
              fontSize: '0.7rem',
              margin: '0 auto',
              transform: 'translateY(-4px)'
            }}
          />
        }
        activeLabel={
          <Check
            style={{
              fontSize: '0.7rem',
              margin: '0 auto',
              transform: 'translateY(-4px)'
            }}
          />
        }
        trackStyle={{
          height: '11px',
          width: '33px',
          margin: 'auto',
          padding: '0px'
        }}
        thumbStyle={{ height: '13px', width: '12px' }}
        value={value}
        colors={tbColors}
        onToggle={value => callBack(value, nodeId, assessmentId, userId, user)}
      />
    </div>
  )
}

export default NodeUsersSelect
