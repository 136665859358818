import { getLoggedInUser } from 'auth'
import OrganizationProfile from 'components/OrganizationProfile/OrganizationProfile'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { createNodeType } from 'redux/actions/nodeTypeActions'
import {
  createOrganization,
  getOrganizations
} from 'redux/actions/organizationActions'
import { createPersona } from 'redux/actions/personaActions'
import { createNode, inviteUsers } from 'redux/actions/treeActions'

import './Master.scss'

const Master = ({ addOn, callBack }) => {
  const [t] = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const [name, setName] = useState('')
  const [billingEmail, setBillingEmail] = useState('')
  const [contactName, setContactName] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [orgSelected, setOrgSelected] = useState(null)
  const [orgs, setOrgs] = useState(null)
  const dispatch = useDispatch()
  const loggedInUser = getLoggedInUser()

  useEffect(() => {
    const fetchData = async () => {
      if (orgs === null) {
        const { organizations } = await getOrganizations(false)
        setOrgs(organizations)
      }
    }
    setIsLoading(true)
    fetchData()
    setIsLoading(false)
  }, [phoneNumber])

  const renderOrganizations = organization => {
    return (
      <div
        key={organization.id}
        className='Master-organization z-depth-1'
        onClick={() => {
          setOrgSelected({ ...organization })
        }}
      >
        <div className='Master-user-tag animated fadeInLeft'>
          <div className='Master-user-tag-top-panel'>
            <h1 className='mr-auto Master-organization-name'>
              {`${organization.name}`}
            </h1>
          </div>
          <div className='Master-organization-email'>
            {`${organization.billingEmail}`}
          </div>
        </div>
      </div>
    )
  }

  const handleCreateOrganization = async () => {
    try {
      setIsLoading(true)
      const org = {
        name: name,
        billingEmail: billingEmail,
        contactName: contactName,
        phoneNumber: phoneNumber,
        suspended: false
      }

      const { organization } = await createOrganization(org)

      const newPersona = {
        organization_id: organization.id,
        title: 'Persona',
        description: 'This is the default persona'
      }

      const { persona } = await createPersona(
        newPersona,
        organization.id,
        dispatch
      )

      const { nodeType } = await createNodeType({
        organization_id: organization.id,
        name: 'Organization'
      })

      const { node } = await createNode({
        parent_id: null,
        node_type_id: nodeType.id,
        organization_id: organization.id,
        name: organization.name,
        description: 'The root node of your organization'
      })

      await inviteUsers(
        {
          invitedUsers: [
            {
              avatarUrl: '',
              email: billingEmail,
              id: null,
              name: null,
              persona: persona,
              role: 'super'
            }
          ],
          selectedNodes: [node]
        },
        organization.id,
        loggedInUser.id,
        dispatch
      )
    } catch (e) {
      window.toastr.error(e?.response?.data?.errors?.message)
    }

    setIsLoading(false)
  }

  const renderOrgs = () => {
    return orgs?.map(renderOrganizations)
  }

  return (
    <div className='Master'>
      <div className='Master-container'>
        <div className='Master-header'>
          <h1>{t('master.organizations-management')}</h1>
        </div>
        <body className='Master-body'>
          <div className='Master-list-orgs z-depth-1'>
            <div className='Master-list-orgs-header'>
              <h1>{t('master.organizations')}</h1>
            </div>
            <div className='Master-organizations'>
              {renderOrgs}
              {!orgSelected && renderOrgs()}
              {orgSelected && (
                <OrganizationProfile
                  organizationData={orgSelected}
                  closeCallBack={setOrgSelected}
                />
              )}
            </div>
          </div>
          <div className='Master-add-org z-depth-1'>
            <div className='Master-add-org-header'>
              <h1>{t('master.add-organization')}</h1>
            </div>

            <div className='Master-fields'>
              <div className='Master-row'>
                <label className='Master-label'>{t('master.name')}</label>
                <input
                  className='Master-user-input'
                  id='Master-name'
                  value={name}
                  onChange={e => setName(e.target.value)}
                  disabled={isLoading}
                  name='name'
                  type='name'
                />
              </div>
              <div className='Master-row'>
                <label className='Master-label'>
                  {t('master.billing-email')}
                </label>
                <input
                  className='Master-user-input'
                  id='Master-billingEmail'
                  value={billingEmail}
                  onChange={e => setBillingEmail(e.target.value)}
                  disabled={isLoading}
                  name='billingEmail'
                  type='email'
                />
              </div>
              <div className='Master-row'>
                <label className='Master-label'>
                  {t('master.contact-name')}
                </label>
                <input
                  className='Master-user-input'
                  id='Master-name'
                  value={contactName}
                  onChange={e => setContactName(e.target.value)}
                  disabled={isLoading}
                  name='contact-name'
                  type='name'
                />
              </div>
              <div className='Master-row'>
                <label className='Master-label'>
                  {t('master.phone-number')}
                </label>
                <input
                  className='Master-user-input'
                  id='Master-phoneNumber'
                  value={phoneNumber}
                  onChange={e => setPhoneNumber(e.target.value)}
                  disabled={isLoading}
                  name='phone-number'
                  type='phoneNumber'
                />
              </div>
              <input
                className='Master-submit-org'
                type='submit'
                value={t('master.submit')}
                disabled={
                  isLoading ||
                  !name ||
                  !billingEmail ||
                  !contactName ||
                  !phoneNumber
                }
                onClick={e => handleCreateOrganization(e)}
              />
            </div>
          </div>
        </body>
      </div>
    </div>
  )
}

export default Master
