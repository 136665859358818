import { reduce } from 'lodash'

import {
  CREATE_OBJECTIVE_MATURITY,
  UPDATE_OBJECTIVE_MATURITY,
  GET_OBJECTIVE_MATURITY,
  UPDATE_SELECTED_OBJECTIVE,
  CLEAR_SELECTED_OBJECTIVE,
  INITIALIZE_OBJECTIVE_MATURITY
} from 'redux/actionTypes'

const initialState = {
  selectedObjective: null,
  objectiveMaturitiesByMaturityId: {}
}

const objectiveMaturityReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_OBJECTIVE_MATURITY:
      return {
        ...state,
        objectiveMaturitiesByMaturityId: reduce(
          action.payload,
          (result, o) => {
            result[o.maturity_id] = o
            return result
          },
          {}
        )
      }
    case CREATE_OBJECTIVE_MATURITY:
    case UPDATE_OBJECTIVE_MATURITY: {
      const { payload } = action
      const assessmentValue =
        state.objectiveMaturitiesByMaturityId[payload.maturity_id]
      state.objectiveMaturitiesByMaturityId[action.payload.maturity_id] = {
        ...assessmentValue,
        ...payload
      }
      return state
    }
    case UPDATE_SELECTED_OBJECTIVE:
      return { ...state, selectedObjective: action.payload }
    case INITIALIZE_OBJECTIVE_MATURITY:
      return { ...initialState }
    case CLEAR_SELECTED_OBJECTIVE:
      return { ...state, selectedObjective: action.payload }
    default:
      return state
  }
}

export default objectiveMaturityReducer
