import React from 'react'
import { useTranslation } from 'react-i18next'

import { ApplyAssessmentForm, ModalHeader } from 'components'

const ApplyAssessment = () => {
  const [t] = useTranslation()

  const renderForm = () => {
    return <ApplyAssessmentForm />
  }

  const tabs = [
    {
      title: t('Apply Assessment')
    }
  ]

  return (
    <div>
      <ModalHeader tabs={tabs} />
      {renderForm()}
    </div>
  )
}

export default ApplyAssessment
