import { React, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { persistQuestion } from 'redux/actions/questionsAction'

import './CreateQuestion.scss'

const CreateQuestion = ({ title, setTitle, description, setDescription }) => {
  const [t] = useTranslation()

  const assessment = useSelector(state => state.assessment)
  const { question } = useSelector(state => state)
  const { selectedObjective, selectedQuestion } = question

  const dispatch = useDispatch()

  useEffect(() => {
    setTitle(selectedQuestion.title)
    setDescription(selectedQuestion.description)
  }, [selectedQuestion, setTitle, setDescription])

  const numberOfQuestions =
    selectedQuestion.choices && selectedQuestion.choices.length > 0
      ? selectedQuestion.choices.length
      : 0

  return (
    <div className='CreateQuestion-container'>
      <div className='CreateQuestion-header'>
        <input
          id='CreateQuestion-title'
          disabled={assessment.isDeployed}
          type='text'
          name='title'
          placeholder={t('create_question.question_title_placeholder')}
          value={title}
          onChange={e => setTitle(e.target.value)}
          onBlur={() =>
            selectedQuestion &&
            persistQuestion(
              selectedObjective.id,
              {
                id: selectedQuestion.id,
                title,
                description,
                choices: selectedQuestion.choices
              },
              dispatch
            )
          }
        />
        <div className='CreateQuestion-text'>
          {t('create_question.number_choices')}
        </div>
        <div className='CreateQuestion-countBox'>
          <div className='CreateQuestion-countValue'>{numberOfQuestions}</div>
        </div>
      </div>
      <textarea
        name='description'
        id='question'
        cols='30'
        rows='10'
        disabled={assessment.isDeployed}
        value={description}
        placeholder={t('create_question.question_description_placeholder')}
        onChange={e => setDescription(e.target.value)}
        onBlur={e =>
          persistQuestion(
            selectedObjective.id,
            {
              id: selectedQuestion.id,
              title,
              description,
              choices: selectedQuestion.choices
            },
            dispatch
          )
        }
      />
    </div>
  )
}

export default CreateQuestion
