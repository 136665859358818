import { api } from './axios'
import { GET_USER_BY_ID } from '../actionTypes'

export const createUser = async user => {
  const { data } = await api.post('/users', user)
  return data
}

export const updateUser = async (userData, dispatch) => {
  const response = await api.put('/users/update', userData)
  return response
}

export const sendEmailVerification = async userData => {
  const response = await api.post('/users/verifyEmail', userData)
  return response
}

export const getUserById = async (userId, dispatch) => {
  const { data } = await api.get(`/users/getUser/${userId}`)

  dispatch({
    type: GET_USER_BY_ID,
    payload: data
  })
  return data
}

export const getProfilePicture = async name => {
  const { data } = await api.get(`/users/download/${name}`)

  // dispatch({
  //   type: GET_USER_BY_ID,
  //   payload: data
  // })

  return data
}

export const uploadProfilePicture = async (formData, config) => {
  const { data } = await api.post('/users/upload', formData, config)

  // dispatch({
  //   type: UPLOAD_PROFILE_PICTURE,
  //   payload: data
  // })

  return data
}
