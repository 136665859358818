import { isEmpty, map, reduce, flattenDeep } from 'lodash'

import {
  ANSWER_FORM_COMPLETE,
  ANSWER_FORM_ERROR,
  ANSWER_FORM_LOADING,
  ANSWER_FORM_MOVE_NEXT_PAGE,
  ANSWER_FORM_MOVE_PREVIOUS_PAGE,
  ANSWER_FORM_PREPARE_FORM,
  ANSWER_FORM_TOGGLE_CHOICE
} from '../actionTypes'

const initialState = {
  isLoading: true,
  isComplete: false,
  currentPage: 0,
  errors: [],
  answers: {},
  assessment: {}
}

const anserFormReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case ANSWER_FORM_LOADING: {
      return {
        ...state,
        isLoading: action.payload ?? false,
        errors: []
      }
    }
    case ANSWER_FORM_PREPARE_FORM: {
      const assessment = action.payload
      const { answers, errors } = parseAnswers(assessment)

      return {
        ...state,
        currentPage: 0,
        answers,
        assessment,
        errors,
        isLoading: false
      }
    }
    case ANSWER_FORM_ERROR: {
      const { errors } = state

      errors.push(action.payload.error)

      return {
        ...state,
        errors,
        isLoading: false
      }
    }
    case ANSWER_FORM_MOVE_NEXT_PAGE: {
      const nextPage = state.currentPage + 1
      return { ...state, currentPage: nextPage }
    }
    case ANSWER_FORM_MOVE_PREVIOUS_PAGE: {
      const previousPage = state.currentPage - 1
      return { ...state, currentPage: previousPage }
    }
    case ANSWER_FORM_TOGGLE_CHOICE: {
      const { choiceId } = action.payload
      const { answers } = state

      answers[choiceId] = !answers[choiceId]

      return { ...state, answers }
    }
    case ANSWER_FORM_COMPLETE: {
      return {
        ...state,
        isLoading: false,
        isComplete: true
      }
    }
    default:
      return state
  }
}

const parseAnswers = assessment => {
  const { areas } = assessment

  const errors = []
  let answers = {}

  if (isEmpty(areas)) {
    errors.push('assessment_form.errors.no_area')
  } else {
    const objectives = flattenDeep(map(areas, area => area.objectives))
    const questions = flattenDeep(
      map(objectives, objective => objective.questions)
    )

    if (isEmpty(questions)) {
      errors.push('assessment_form.errors.no_questions')
    } else {
      const choices = flattenDeep(map(questions, question => question.choices))

      if (isEmpty(choices)) {
        errors.push('assessment_form.errors.no_choices')
        return { answers, errors }
      }

      answers = reduce(
        choices,
        (result, choice) => {
          result[choice.id] = false
          return result
        },
        {}
      )
    }
  }

  return { answers, errors }
}

export default anserFormReducer
