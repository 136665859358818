import { api } from './axios'

import {
  CREATE_ASSESSMENT,
  UPDATE_ASSESSMENT,
  GET_ASSESSMENT,
  SET_ASSESSMENT,
  GET_TEMPLATES_BY_ORGANIZATION_ID,
  GET_COMPLETE_TEMPLATES_BY_ORGANIZATION_ID,
  GET_DEPLOYED_TEMPLATES_BY_ORGANIZATION_ID,
  GET_MATURITY_LEVELS_BY_ASSESSMENT_ID,
  GET_ASSESSMENT_COMPLETION,
  INITIALIZE_ASSESSMENT,
  GET_PUBLIC_TEMPLATES,
  GET_SUBMITTED_PUBLIC_TEMPLATES
} from '../actionTypes'
import { fetchTreeData } from './treeActions'

export const createAssessment = async (
  attrName,
  value,
  assessment,
  organizationId,
  fromNode,
  dispatch
) => {
  assessment[attrName] = value
  assessment.node_id = fromNode
  const newAssessment = {
    ...assessment,
    templateForOrganization_id: organizationId,
    startDate: new Date(),
    endDate: new Date()
  }

  const { data } = await api.post('/assessments', newAssessment)

  dispatch({
    type: CREATE_ASSESSMENT,
    payload: data
  })
}

export const duplicateTemplate = async assessmentData => {
  const { data } = await api.post(
    '/assessments/duplicateTemplate',
    assessmentData
  )
  return data
}

export const isTemplateDeployed = async templateData => {
  const { data } = await api.post(
    '/assessments/isTemplateDeployed',
    templateData
  )
  return data
}

export const isTemplateComplete = async templateData => {
  const { data } = await api.post(
    '/assessments/isTemplateComplete',
    templateData
  )
  return data
}

export const updateAssessment = async (
  attrName,
  value,
  assessment,
  dispatch
) => {
  assessment[attrName] = value
  if (!assessment.name) return

  const updatedAssessment = () => {
    if (assessment.date) {
      const startDate = assessment.date.startDate.toISOString()
      const endDate = assessment.date.endDate.toISOString()
      return {
        ...assessment,
        startDate,
        endDate
      }
    } else {
      return { ...assessment }
    }
  }

  const { data } = await api.put(
    `/assessments/${assessment.id}`,
    updatedAssessment()
  )
  dispatch({
    type: UPDATE_ASSESSMENT,
    payload: data
  })
  return data
}

export const archiveAssessment = async (
  assessmentId,
  organizationId,
  userId,
  dispatch
) => {
  await api.put(`/assessments/archive/${assessmentId}`)
  await fetchTreeData(organizationId, userId, dispatch)
}

export const getAssessment = async (assessmentId, dispatch) => {
  try {
    const { data } = await api.get(`/assessments/${assessmentId}`)
    dispatch({
      type: GET_ASSESSMENT,
      payload: data
    })
  } catch (e) {
    const err = e.response
    const error = {
      status: err.status,
      errorMsg: err.data.errors.assessmentError
    }
    return error
  }
}

export const setAssessment = async (assessment, dispatch) => {
  dispatch({
    type: SET_ASSESSMENT,
    payload: assessment
  })
}

export const initializeAssessment = async dispatch => {
  dispatch({
    type: INITIALIZE_ASSESSMENT,
    payload: null
  })
}

export const getTemplatesByOrganizationId = async (
  organizationId,
  dispatch
) => {
  try {
    const { data } = await api.get(
      `/assessments/organizationTemplates/${organizationId}`
    )
    dispatch({
      type: GET_TEMPLATES_BY_ORGANIZATION_ID,
      payload: data
    })
  } catch (e) {
    const err = e.response
    const error = {
      status: err?.status,
      errorMsg: err?.data?.errors?.assessmentError
    }
    return error
  }
}

export const getPublicTemplates = async dispatch => {
  const { data } = await api.get('/assessments/public/templates')
  dispatch({
    type: GET_PUBLIC_TEMPLATES,
    payload: data
  })
}

export const getSubmittedPublicTemplates = async dispatch => {
  const { data } = await api.get('/assessments/public/submitted')
  dispatch({
    type: GET_SUBMITTED_PUBLIC_TEMPLATES,
    payload: data
  })
}

export const getCompleteTemplatesByOrganizationId = async (
  organizationId,
  dispatch
) => {
  const { data } = await api.get(
    `/assessments/organizationCompleteTemplates/${organizationId}`
  )

  dispatch({
    type: GET_COMPLETE_TEMPLATES_BY_ORGANIZATION_ID,
    payload: data
  })
  return data
}

export const getDeployedTemplatesByOrganizationId = async (
  organizationId,
  dispatch
) => {
  const { data } = await api.get(
    `/assessments/organizationDeployedTemplates/${organizationId}`
  )
  dispatch({
    type: GET_DEPLOYED_TEMPLATES_BY_ORGANIZATION_ID,
    payload: data
  })
}

export const getMaturityLevelsByAssessmentId = async (
  assessmentId,
  dispatch
) => {
  try {
    const { data } = await api.get(`/assessments/maturitylevel/${assessmentId}`)
    dispatch({
      type: GET_MATURITY_LEVELS_BY_ASSESSMENT_ID,
      payload: data
    })
  } catch (e) {
    const err = e.response
    const error = {
      status: err.status,
      errorMsg: err.data.errors.assessmentError
    }
    return error
  }
}

export const getAssessmentCompletion = async (assessmentId, dispatch) => {
  try {
    const data = await api.get(`/assessments/${assessmentId}/completion`)
    dispatch({
      type: GET_ASSESSMENT_COMPLETION,
      payload: data
    })
  } catch (e) {
    const err = e.response
    const error = {
      status: err.status,
      errorMsg: err.data.errors.assessmentError
    }
    return error
  }
}

export const applyAssessment = async newAssessment => {
  try {
    const { data } = await api.post(
      '/assessments/applyAssessment',
      newAssessment
    )
    return data
  } catch (e) {
    const err = e.response
    const error = {
      status: err.status,
      errorMsg: err.data.errors.assessmentError
    }
    return error
  }
}

export const isAssessmentDuplicated = async (shortName, nodeId) => {
  try {
    const checkData = { shortName, nodeId }
    const { data } = await api.post(
      '/assessments/isAssessmentDuplicated',
      checkData
    )
    return data.found
  } catch (e) {
    return e
  }
}

export const triggerAssessments = async selectedAssessments => {
  try {
    const { data } = await api.post(
      '/assessments/triggerAssessments',
      selectedAssessments
    )
    return data.processedEmails
  } catch (e) {
    const err = e.response
    const error = {
      status: err.status,
      errorMsg: err.data.errors.assessmentError
    }
    return error
  }
}
