import React from 'react'
import classNames from 'classnames'

import './FormButton.scss'

const FormButton = ({ variant, disabled, onClick, children }) => {
  const classes = classNames('FormButton', {
    'FormButton--green': variant === 'green',
    'FormButton--red': variant === 'red',
    'FormButton--blue': variant === 'blue',
    'FormButton--gray': variant === 'gray'
  })
  return (
    <button className={classes} disabled={disabled} onClick={onClick}>
      {children}
    </button>
  )
}

export default FormButton
