import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { AddCircleOutline } from '@material-ui/icons'

import './AddChoice.scss'

import { addChoice } from 'redux/actions/questionsAction'

const AddChoice = ({
  choiceId,
  choiceTitle,
  selectedLevelMaturity,
  setChoiceTitle,
  setChoiceId
}) => {
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const { question, maturityLevel } = useSelector(state => state)
  const { rows } = maturityLevel
  const choice = {
    questionId: question.selectedQuestion.id,
    objectiveId: question.selectedObjective.id,
    maturityId: rows[selectedLevelMaturity - 1].id,
    title: choiceTitle
  }
  const [isInvalid, setIsInvalid] = useState(true)

  useEffect(() => {
    if (choiceTitle.trim() === '') {
      setIsInvalid(true)
    } else {
      setIsInvalid(false)
    }
  }, [choiceTitle])

  return (
    <div className='AddChoice'>
      <button
        className='AddChoice--btn  ml-auto'
        disabled={
          !(
            question &&
            question.selectedQuestion &&
            question.selectedQuestion.id
          ) || isInvalid
        }
        onClick={() => {
          addChoice(choiceId, choice, dispatch)
          setChoiceId('')
          setChoiceTitle('')
          setTimeout(() => {
            const objDiv = document.getElementById(
              'Choice-createdChoice-container'
            )
            if (objDiv) {
              objDiv.scrollTop = objDiv.scrollHeight
            }
          }, 100)
        }}
      >
        <AddCircleOutline />
        <span>
          {!choiceId
            ? t('add_choice.new_alternative')
            : t('add_choice.save_alternative')}
        </span>
      </button>
    </div>
  )
}

export default AddChoice
