import React, { useEffect, useState } from 'react'
import { getLoggedInUser } from 'auth'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getOrganizationById } from 'redux/actions/organizationActions'

import { Confirm } from 'notiflix'

import {
  getUsersByOrganizationId,
  toggleManageUsers
} from 'redux/actions/treeActions'

import TenantUsers from 'components/TenantUserManagement/TenantUsers'

import './TenantUserManagementPage.scss'
import '../../../styles/loader.scss'
import { PersonAdd } from '@material-ui/icons'
import Modal from 'components/Modal/Modal'
import ManageUsers from 'components/ManageUsers/ManageUsers'

const TenantUserManagement = () => {
  const [t] = useTranslation()
  const dispatch = useDispatch()

  const { users } = useSelector(state => state.tree)
  const [currentOrganization, setCurrentOrganization] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [filteredUsers, setFilteredUsers] = useState([])
  const [search, setSearch] = useState('')

  const { tree } = useSelector(state => state)
  const { manageUsersToggled, node } = tree

  const user = getLoggedInUser()
  Confirm.Init({
    titleColor: 'rgba(58, 157, 184, 1)',
    okButtonBackground: 'rgba(172, 2, 2, 1)',
    backgroundColor: 'rgb(40,40,60)',
    borderRadius: '5px',
    messageColor: 'white',
    zindex: 9999
  })
  useEffect(() => {
    const fetchData = async () => {
      if (currentOrganization === null) {
        await getUsersByOrganizationId(user.organization_id, dispatch)
        const { organization } = await getOrganizationById(user.organization_id)
        setCurrentOrganization(organization)
      }
    }
    setIsLoading(true)
    fetchData()
    setIsLoading(false)
  }, [user, dispatch])

  useEffect(() => {
    setFilteredUsers(
      users.filter(
        u =>
          u.role.toLowerCase().includes(search.toLowerCase().trim()) ||
          u.email.toLowerCase().includes(search.toLowerCase().trim()) ||
          u.firstName.toLowerCase().includes(search.toLowerCase().trim()) ||
          u.surName.toLowerCase().includes(search.toLowerCase().trim())
      )
    )
  }, [search, users])

  const onChange = e => {
    setSearch(e.target.value)
  }

  const renderPageBody = () => (
    <>
      <div className='mb-2'>
        <input
          autoFocus
          type='text'
          maxLength={35}
          title='search'
          id='search'
          placeholder={t('label.search')}
          value={search}
          onChange={onChange}
          className='TenantUserManagementPage-input'
        />
        {search.length > 0 && (
          <small className='blink-me'>
            <span className='foundTitle '>
              {filteredUsers.length}&nbsp;
              {t('persona_manage.found')}
            </span>
          </small>
        )}
      </div>
      <TenantUsers addOn users={filteredUsers} />
    </>
  )

  return (
    <>
      <Modal w={1200} open={manageUsersToggled}>
        <ManageUsers node={node} />
      </Modal>
      <div className='TenantUserManagementPage'>
        <div className='TenantUserManagementPage-container z-depth-4'>
          <div className='TenantUserManagementPage-header'>
            <p>{t('tenant_user_management.title')}</p>
            <div>
              <span className='mt-1 title'>
                {t('tenant_user_management.header')}
              </span>
              <span className='mt-1'>{currentOrganization?.name}</span>
            </div>
          </div>

          <div className='TenantUserManagementPage-body'>
            {isLoading && (
              <div className='d-flex flex-column justify-content-center align-items-center v100'>
                <div className='loader1' />
              </div>
            )}
            {!isLoading && renderPageBody()}
          </div>

          <div className='TenantUserManagementPage-footer'>
            <button
              className='TenantUserManagementPage-add-user-button'
              onClick={() => {
                toggleManageUsers(
                  { manageUsersToggled: true, node: null },
                  dispatch
                )
              }}
            >
              <PersonAdd />
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default TenantUserManagement
