import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import ReactPlaceholder from 'react-placeholder'
import 'react-placeholder/lib/reactPlaceholder.css'
import _ from 'lodash'

import useDebounce from '../../../hooks/useDebounce'
import {
  createObjectiveMaturity,
  updateObjectiveMaturity,
  getObjectiveMaturitiesByObjectveId
} from '../../../redux/actions/objectiveMaturityActions'
import { getAssessmentCompletion } from '../../../redux/actions/assessmentActions'

import CountBox from 'components/CountBox/CountBox'

import './ObjectiveMaturityContainer.scss'

const ObjectiveMaturityRow = ({ objectiveMaturity, onChange }) => {
  const [t] = useTranslation()
  const assessment = useSelector(state => state.assessment)
  const delayedPersistHandler = useDebounce(value => onChange(value), 1000)
  const onChangeHandler = e => {
    const { value } = e.target
    setDescription(value)
    delayedPersistHandler(value)
  }
  const [description, setDescription] = useState(objectiveMaturity.description)
  const [isFocused, setFocused] = useState(false)

  const countBoxVariant = objectiveMaturity.id ? 'green' : 'gray'

  return (
    <div className='ObjectiveMaturityRow'>
      <CountBox
        className='ObjectiveMaturity-CountBox'
        text={objectiveMaturity.position}
        highlighted={isFocused}
        variant={countBoxVariant}
      />

      <p>{objectiveMaturity.maturity_name}</p>

      <textarea
        disabled={assessment.isDeployed}
        name='description'
        className='ObjectiveMaturityRow-description'
        placeholder={t('label.description')}
        value={description}
        onChange={onChangeHandler}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
      />
    </div>
  )
}

const ObjectiveMaturityContainer = () => {
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const [objectiveSelected, setObjectiveSelected] = useState(false)
  const { assessment, objectiveMaturity } = useSelector(state => state)
  const { selectedObjective, objectiveMaturitiesByMaturityId } =
    objectiveMaturity

  useEffect(() => {
    if (!selectedObjective) return
    setObjectiveSelected(true)
    getObjectiveMaturitiesByObjectveId(selectedObjective.id, dispatch)
  }, [selectedObjective, dispatch])

  const persistObjectiveMaturity = async (maturityLevelId, description) => {
    if (!description || description === '') return

    const persistedObjectiveMaturity =
      objectiveMaturitiesByMaturityId[maturityLevelId]

    if (persistedObjectiveMaturity && persistedObjectiveMaturity.id) {
      if (persistedObjectiveMaturity.description === description) return

      await updateObjectiveMaturity(
        persistedObjectiveMaturity.id,
        selectedObjective.id,
        maturityLevelId,
        description,
        dispatch
      )
    } else {
      await createObjectiveMaturity(
        selectedObjective.id,
        maturityLevelId,
        description,
        dispatch
      )
    }

    await getAssessmentCompletion(assessment.id, dispatch)
  }

  return (
    <ReactPlaceholder
      className='ObjectiveMaturityContainer'
      color='rgb(205 205 205 / 12%)'
      showLoadingAnimation
      type='text'
      rows={7}
      ready={objectiveSelected}
    >
      <div className='ObjectiveMaturityContainer'>
        <div className='ObjectiveMaturityContainer-grid'>
          <div className='ObjectiveMaturityContainer-textLeft'>
            <h1 className='ObjectiveMaturityContainer-title'>
              {t('objective_maturity_container.title')}
            </h1>
            <h1 className='ObjectiveMaturityContainer-textColor'>
              {t('objective_maturity_container.subtitle', {
                objective: selectedObjective
                  ? selectedObjective.description
                  : null
              })}
            </h1>
          </div>
          <CountBox text={_.size(objectiveMaturitiesByMaturityId)} />
        </div>
        {_.sortBy(_.values(objectiveMaturitiesByMaturityId), 'position').map(
          objectiveMaturity => (
            <ObjectiveMaturityRow
              className='ml-auto'
              key={`${objectiveMaturity.objective_id}-${objectiveMaturity.maturity_id}`}
              objectiveMaturity={objectiveMaturity}
              onChange={description =>
                persistObjectiveMaturity(
                  objectiveMaturity.maturity_id,
                  description
                )
              }
            />
          )
        )}
      </div>
    </ReactPlaceholder>
  )
}

export default ObjectiveMaturityContainer
