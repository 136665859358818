import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { AddChoice } from 'components'
import { deleteChoice } from '../../redux/actions/questionsAction'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import Slider from '@material-ui/core/Slider'

import './Choice.scss'

const Choice = ({ choiceId, setChoiceId, choiceTitle, setChoiceTitle }) => {
  const [t] = useTranslation()

  const dispatch = useDispatch()

  const question = useSelector(state => state.question)
  const maturityLevel = useSelector(state => state.maturityLevel)
  const assessment = useSelector(state => state.assessment)

  const { numberOfEnabledRows, rows } = maturityLevel
  const { selectedQuestion } = question
  const { choices } = selectedQuestion

  const [selectedLevelMaturity, setSelectedLevelMaturity] = useState(1)

  const removeChoice = choice => deleteChoice(choice, dispatch)

  const renderCreatedChoices = choice => {
    const getMaturityLevelPosition = rows.filter(
      ml => ml.id === choice.maturity_id
    )[0].position
    return (
      <div className='Choice-createdChoice' key={Math.random()}>
        <div
          className='Choice-select'
          onClick={() => {
            if (!assessment.isDeployed) {
              setChoiceId(choice.id)
              setChoiceTitle(choice.title)
              setSelectedLevelMaturity(getMaturityLevelPosition)
              document.getElementById('choice-text-area').focus()
            }
          }}
        >
          <p>{choice.title}</p>
          {!assessment.isDeployed && (
            <div className='d-flex flex-row overflow-wrap'>
              <p className='ml-auto disabled'>
                {t('choice.maturity_level')}
                <br />
                <Slider
                  style={{ minWidth: '130px' }}
                  min={1}
                  max={numberOfEnabledRows}
                  step={1}
                  value={getMaturityLevelPosition}
                />
              </p>
              <div className='Choice-countBox'>
                <div className='Choice-countValue'>
                  {getMaturityLevelPosition}
                </div>
              </div>
              <button onClick={() => removeChoice(choice)}>
                <DeleteOutlineIcon className='Choice-deleteButton' />
              </button>
            </div>
          )}
        </div>
      </div>
    )
  }

  return (
    <div className='Choice-container'>
      {!assessment.isDeployed && (
        <div>
          <div className='Choice-header'>
            <p>{t('choice.type_choice')}</p>
            <p className='ml-auto'>
              {t('choice.maturity_level')}
              <br />
              <Slider
                style={{ minWidth: '130px' }}
                min={1}
                max={numberOfEnabledRows}
                step={1}
                value={selectedLevelMaturity}
                onChange={(e, value) => setSelectedLevelMaturity(value)}
              />
            </p>
            <div className='Choice-countBox'>
              <div className='Choice-countValue'>{selectedLevelMaturity}</div>
            </div>
          </div>
          <textarea
            id='choice-text-area'
            name='title'
            cols='30'
            rows='10'
            value={choiceTitle}
            placeholder={t('choice.text_placeholder')}
            onChange={e => {
              setChoiceTitle(e.target.value)
            }}
          />

          <AddChoice
            choiceId={choiceId}
            choiceTitle={choiceTitle}
            selectedLevelMaturity={selectedLevelMaturity}
            setChoiceTitle={setChoiceTitle}
            setChoiceId={setChoiceId}
          />
        </div>
      )}
      <div
        id='Choice-createdChoice-container'
        className='Choice-createdChoice-container'
      >
        {choices && choices.map(choice => renderCreatedChoices(choice))}
      </div>
    </div>
  )
}

export default Choice
