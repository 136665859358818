import { getLoggedInUser } from 'auth'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { Confirm } from 'notiflix'

import './NodeTypesForm.scss'
import {
  archiveNodeType,
  createNodeType,
  getNodeTypesByOrganizationId,
  toggleNodeTypesForm,
  updateNodeType
} from 'redux/actions/nodeTypeActions'

const NodeTypesForm = () => {
  const [name, setName] = useState('')
  const [t] = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const [isInvalid, setIsInvalid] = useState(false)
  const dispatch = useDispatch()

  const { nodeType } = useSelector(state => state.nodeType)

  const user = getLoggedInUser()
  const organizationId = user.organization_id

  Confirm.Init({
    nameColor: 'rgba(58, 157, 184, 1)',
    okButtonBackground: '#d4a302',
    backgroundColor: 'rgb(40,40,60)',
    borderRadius: '5px',
    messageColor: 'white',
    zindex: 9999
  })

  const clearForm = () => {
    setName('')
    document.getElementById('name').focus()
  }

  useEffect(() => {
    setIsInvalid(!name)
  }, [name])

  useEffect(() => {
    if (nodeType?.id) {
      setName(nodeType.name)
    }
  }, [nodeType])

  const onChange = e => {
    switch (e.target.name) {
      case 'name':
        setName(e.target.value)
        break
      default:
        break
    }
  }

  const handleCreateNodeType = async e => {
    e && e.stopPropagation()
    setIsLoading(true)
    const newNodeType = {
      organization_id: organizationId,
      name
    }
    try {
      await createNodeType(newNodeType, organizationId)
    } catch (error) {
      const message = error?.response?.data?.errors?.name
      if (message) {
        window.toastr.error(message)
        setIsLoading(false)
        document.getElementById('name').focus()
        return
      }
    }
    await getNodeTypesByOrganizationId(organizationId, dispatch)
    clearForm()
    toggleNodeTypesForm({ nodeTypeFormToggled: false }, dispatch)
    setIsLoading(false)
  }

  const handleUpdateNodeType = async e => {
    e && e.stopPropagation()
    if (nodeType && nodeType.id && nodeType.name !== name) {
      setIsLoading(true)
      try {
        await updateNodeType(
          { ...nodeType, name },
          organizationId,
          user.id,
          dispatch
        )
      } catch (error) {
        const message = error?.response?.data?.errors?.name
        window.toastr.error(message)
        setIsLoading(false)
        return
      }
    }
    window.toastr.success('Successfuly updated node type') // todo translate
    clearForm()
    toggleNodeTypesForm({ nodeTypeFormToggled: false }, dispatch)
    setIsLoading(false)
  }

  const confirmArchive = () => {
    Confirm.Show(
      t('node_type_form.confirm.title'),
      t('node_type_form.confirm.description'),
      t('node_type_form.confirm.yes'),
      t('node_type_form.confirm.no'),
      async () => {
        if (nodeType && nodeType.id) {
          setIsLoading(true)
          try {
            await archiveNodeType(nodeType, organizationId, user.id, dispatch)
          } catch (error) {
            const message = error?.response?.data?.errors?.message
            if (message) {
              window.toastr.error(message)
              setIsLoading(false)
              return
            }
          }
          window.toastr.success('Successfuly archived node type')
          clearForm()
          toggleNodeTypesForm(
            { nodeTypeFormToggled: false, nodeType: null },
            dispatch
          )
          setIsLoading(false)
        }
      }
    )
  }

  const handleArchiveNodeType = async e => {
    e && e.stopPropagation()
    confirmArchive()
  }

  return (
    <div className='NodeTypes animated fadeIn'>
      <div className='NodeTypes-container'>
        <div className='NodeTypes-header'>
          <p>
            {nodeType?.id
              ? t('node_type_form.edit_node_type')
              : t('node_type_form.new_node_type')}
            <span className='mt-1'> {nodeType?.id && nodeType.name}</span>
          </p>
        </div>

        <div className='NodeTypes-input'>
          <input
            autoFocus
            type='text'
            maxLength={35}
            name='name'
            id='name'
            placeholder={t('node_type_form.node_type_title')}
            value={name}
            onChange={onChange}
          />
          {name.length > 15 && (
            <small className='blink-me' style={{ color: '#e438db' }}>
              &nbsp;
              {35 - name.length}
            </small>
          )}
        </div>
        <div className='NodeTypes-button-group'>
          {nodeType && nodeType.id && (
            <button
              className='NodeTypes-updateButton'
              disabled={isLoading || isInvalid}
              onClick={handleUpdateNodeType}
            >
              {t('label.update')}
            </button>
          )}
          {!nodeType?.id && (
            <button
              className='NodeTypes-createButton'
              disabled={isLoading || isInvalid}
              onClick={handleCreateNodeType}
            >
              {t('label.create')}
            </button>
          )}
          {nodeType && nodeType.id && (
            <button
              className='NodeTypes-archiveButton'
              disabled={isLoading || isInvalid}
              onClick={handleArchiveNodeType}
            >
              {t('label.archive')}
            </button>
          )}
          <button
            className='NodeTypes-cancelButton'
            onClick={() => {
              toggleNodeTypesForm({ nodeTypeFormToggled: false }, dispatch)
            }}
          >
            {t('label.cancel')}
          </button>
        </div>
      </div>
    </div>
  )
}

export default NodeTypesForm
