import { combineReducers } from 'redux'

import area from 'redux/reducers/areaReducer'
import tree from 'redux/reducers/treeReducer'
import persona from 'redux/reducers/personaReducer'
import template from 'redux/reducers/templateReducer'
import question from 'redux/reducers/questionsReducer'
import answerForm from 'redux/reducers/answerFormReducer'
import assessment from 'redux/reducers/assessmentReducer'
import recommendation from 'redux/reducers/recommendationReducer'
import objective from 'redux/reducers/objectivesReducer'
import dashboard from 'redux/reducers/dashboardReducer'
import maturityLevel from 'redux/reducers/maturityLevelReducer'
import objectiveMaturity from 'redux/reducers/objectiveMaturityReducer'
import nodeType from 'redux/reducers/nodeTypeReducer'
import user from 'redux/reducers/userReducer'
import organization from 'redux/reducers/organizationReducer'

export default combineReducers({
  area,
  tree,
  template,
  question,
  assessment,
  objective,
  recommendation,
  answerForm,
  maturityLevel,
  objectiveMaturity,
  dashboard,
  persona,
  nodeType,
  user,
  organization
})
