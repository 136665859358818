import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { GithubPicker } from 'react-color'

import FormButton from 'components/FormButton/FormButton'

import './AreaInput.scss'

const AreaInput = ({
  onCreate,
  onUpdate,
  onCancel,
  refAreaName,
  refAreaDescription,
  setAreaColor,
  isDeployed,
  isEdit
}) => {
  // const sinebow = [
  //   '#ff4040',
  //   '#e19608',
  //   '#96e108',
  //   '#40ff40',
  //   '#08e196',
  //   '#0896e1',
  //   '#4040ff',
  //   '#9608e1',
  //   '#e10896'
  // ]

  const rainbow = [
    '#6e40aa',
    '#c83dac',
    '#ff5375',
    '#ff8c38',
    '#c9d33a',
    '#79f659',
    '#28ea8d',
    '#1eb8d0',
    '#4775de'
  ]

  // const cool = ['#6e40aa', '#5e57cb', '#4775de', '#2f96e0', '#1eb8d0', '#1ad5b1', '#28ea8d', '#4af56c', '#79f659', '#aff05b']
  // const warm = ['#6e40aa', '#9b3db3', '#c83dac', '#ee4395', '#ff5375', '#ff6b53', '#ff8c38', '#e8b02e', '#c9d33a', '#aff05b']

  // const colors = [
  //   '#9C27B0',
  //   '#E91E63',
  //   '#F44336',
  //   '#FF6900',
  //   '#FCB900',
  //   '#CDDC39',
  //   '#00D084',
  //   '#00BCD4',
  //   '#BACCDC'
  // ]
  const [colorHexCode, setColorHexCode] = useState('#ff4040')

  const [isColorPickerToggled, setIsColorPickerToggled] = useState(false)
  const toggleColorPicker = () => {
    setIsColorPickerToggled(!isColorPickerToggled)
  }
  const [t] = useTranslation()

  useEffect(() => {
    setIsColorPickerToggled(false)
  }, [isEdit])

  return (
    <div className='AreaInput'>
      <input
        type='text'
        className='AreaInput-input'
        disabled={isDeployed}
        autoFocus
        required
        placeholder={t('area_input.fields.title')}
        ref={refAreaName}
      />

      <textarea
        className='AreaInput-input AreaInput-textArea'
        disabled={isDeployed}
        required
        placeholder={t('area_input.fields.description')}
        ref={refAreaDescription}
      />
      <div className='AreaInput-buttons'>
        <button
          type='button'
          className='AreaInput-color'
          style={{ backgroundColor: colorHexCode }}
          onClick={toggleColorPicker}
        />
        {isColorPickerToggled && (
          <div className='color-picker'>
            <GithubPicker
              styles={{
                backgroundColor: '#000'
              }}
              width='240px'
              height='160px'
              colors={rainbow}
              color={colorHexCode}
              onChange={e => {
                setColorHexCode(e.hex)
                setAreaColor(e.hex)
              }}
            />
          </div>
        )}

        {!isDeployed && (
          <div className='AreaInput-actions'>
            {isEdit ? (
              <>
                <FormButton variant='blue' onClick={onUpdate}>
                  {t('label.update')}
                </FormButton>
                <FormButton variant='gray' onClick={onCancel}>
                  {t('label.cancel')}
                </FormButton>
              </>
            ) : (
              <FormButton variant='green' onClick={onCreate}>
                {t('label.create')}
              </FormButton>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default AreaInput
