import { api } from './axios'
import {
  CREATE_OBJECTIVE,
  UPDATE_OBJECTIVE,
  DELETE_OBJECTIVES,
  GET_OBJECTIVE,
  SET_OBJECTIVES,
  CLEAR_OBJECTIVE,
  SET_QUESTIONS,
  INITIALIZE_OBJECTIVE
} from 'redux/actionTypes'
import { clearSelectedObjective } from './objectiveMaturityActions'

export const createObjective = async (objective, dispatch) => {
  try {
    const { data } = await api.post('/objectives', objective)
    clearObjective(dispatch)
    dispatch({
      type: CREATE_OBJECTIVE,
      objective: data
    })
    // TODO We should *not* cause side effects on the Front End inside action files
    window.toastr.success(
      'Successfuly created objective ' + objective.shortName
    )
  } catch (e) {
    // TODO We should *not* cause side effects on the Front End inside action files
    window.toastr.error('We have got an error: ' + e)
  }
}

export const updateObjective = async (objective, dispatch) => {
  try {
    const { data } = await api.put(`/objectives/${objective.id}`, objective)
    clearObjective(dispatch)
    dispatch({
      type: UPDATE_OBJECTIVE,
      objective: data
    })
    // TODO We should *not* cause side effects on the Front End inside action files
    window.toastr.success(
      'Successfuly updated objective ' + objective.shortName
    )
  } catch (e) {
    // TODO We should *not* cause side effects on the Front End inside action files
    window.toastr.error('We have got an error: ' + e)
  }
}

export const deleteObjective = async (id, assessmentId, dispatch) => {
  try {
    const { data } = await api.delete(`/objectives/${id}`)
    if (data) {
      dispatch({
        type: DELETE_OBJECTIVES,
        id
      })
      await setObjectives(assessmentId, dispatch)

      // TODO We should *not* cause side effects on the Front End inside action files
      window.toastr.success('Successfuly deleted objective id: ' + id)
    }
  } catch (e) {
    // TODO We should *not* cause side effects on the Front End inside action files
    window.toastr.error(e.response.data.message)
  }
}

export const getObjectiveById = async (objectiveId, dispatch) => {
  try {
    const { data } = await api.get(`/objectives/${objectiveId}`)
    if (data) {
      dispatch({
        type: GET_OBJECTIVE,
        payload: data
      })
    }
  } catch (e) {
    // TODO We should *not* cause side effects on the Front End inside action files
    window.toastr.error('We have got an error: ' + e)
  }
}

export const setObjectives = async (assessmentId, dispatch) => {
  try {
    const { data } = await api.get(`/objectives/assessment/${assessmentId}`)
    if (data) {
      dispatch({
        type: SET_OBJECTIVES,
        payload: data
      })
      dispatch({
        type: SET_QUESTIONS,
        payload: data
      })
    }
  } catch (e) {
    // TODO We should *not* cause side effects on the Front End inside action files
    window.toastr.error('We have got an error: ' + e)
  }
}
export const clearObjective = async dispatch => {
  clearSelectedObjective(dispatch)
  dispatch({
    type: CLEAR_OBJECTIVE,
    payload: null
  })
}

export const initializeObjectives = async dispatch => {
  dispatch({
    type: INITIALIZE_OBJECTIVE,
    payload: null
  })
}
