import {
  GET_TEMPLATES_BY_ORGANIZATION_ID,
  GET_COMPLETE_TEMPLATES_BY_ORGANIZATION_ID,
  GET_DEPLOYED_TEMPLATES_BY_ORGANIZATION_ID,
  TOGGLE_APPLY_FORM,
  TOGGLE_MATURITY_MODEL_FORM,
  TOGGLE_TRIGGER_ASSESSMENT,
  GET_PUBLIC_TEMPLATES,
  GET_SUBMITTED_PUBLIC_TEMPLATES
} from 'redux/actionTypes'

const initialState = {
  submittedPublicTemplates: [],
  publicTemplates: [],
  templates: [],
  completeTemplates: [],
  deployedTemplates: [],
  toggledApplyForm: false,
  toggledTriggerAssessmentForm: false,
  toggledMaturityModelForm: false,
  fromNode: null
}

const templateReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_PUBLIC_TEMPLATES: {
      return { ...state, publicTemplates: action.payload }
    }
    case GET_SUBMITTED_PUBLIC_TEMPLATES: {
      return { ...state, submittedPublicTemplates: action.payload }
    }
    case GET_TEMPLATES_BY_ORGANIZATION_ID: {
      return { ...state, templates: action.payload }
    }
    case GET_COMPLETE_TEMPLATES_BY_ORGANIZATION_ID: {
      return { ...state, completeTemplates: action.payload }
    }
    case GET_DEPLOYED_TEMPLATES_BY_ORGANIZATION_ID: {
      return { ...state, deployedTemplates: action.payload }
    }
    case TOGGLE_APPLY_FORM: {
      return { ...state, toggledApplyForm: !state.toggledApplyForm }
    }
    case TOGGLE_TRIGGER_ASSESSMENT: {
      return {
        ...state,
        toggledTriggerAssessmentForm: !state.toggledTriggerAssessmentForm
      }
    }
    case TOGGLE_MATURITY_MODEL_FORM: {
      return {
        ...state,
        fromNode: action.payload,
        toggledMaturityModelForm: !state.toggledMaturityModelForm
      }
    }
    default:
      return state
  }
}

export default templateReducer
