import { Launch, School } from '@material-ui/icons'
import { getLoggedInUser } from 'auth'
import { isEmpty } from 'lodash'

import { React, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import {
  getRecommendationsByObjectiveId,
  getRecommendationsByOrganizationId
} from 'redux/actions/recommendationActions'
import { firstBy } from 'thenby'

import './AnswerRecommendations.scss'

const AnswerRecommendations = ({ chartData }) => {
  const dispatch = useDispatch()
  const [t] = useTranslation()

  const user = getLoggedInUser()
  const organizationId = user.organization_id

  const [userRecommendations, setUserRecommendations] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      await getRecommendationsByOrganizationId(organizationId, dispatch)
    }
    fetchData()
  }, [organizationId, dispatch])

  useEffect(() => {
    if (!isEmpty(chartData)) {
      const fetchData = async () => {
        const objectiveValues = chartData[0]
          .map(el => {
            return {
              assessmentValue: el.assessmentValue,
              objective_id: el.objective_id,
              color: el.areaColor
            }
          })
          .sort(firstBy('assessmentValue').thenBy('areaName'))
          .filter((el, i) => i < 4)

        const recommendations = []

        const objectiveRecommendations = await Promise.all(
          objectiveValues.map(async element => {
            const data = await getRecommendationsByObjectiveId(
              element.objective_id
            )
            return data.map(rec => {
              return {
                ...rec.recommendation,
                color: element.color
              }
            })
          })
        )
        objectiveRecommendations.forEach(el => {
          el.forEach(rec => {
            if (!recommendations.find(item => item.id === rec.id)) {
              recommendations.push(rec)
            }
          })
        })

        setUserRecommendations(recommendations)
      }

      fetchData()
    }
  }, [chartData, dispatch])

  const renderRecommendation = recommendation => {
    return (
      <div
        className='AnswerRecommendations-recommendation'
        key={`AnswerRecommendations-recomendation-${recommendation.id}`}
      >
        <div className='d-flex flex-row'>
          <School
            className='AnswerRecommendations-recommendation-content-icon'
            style={{ color: recommendation.color }}
          />
          <div className='AnswerRecommendations-recommendation-content'>
            <span className='AnswerRecommendations-recommendation-name'>
              {recommendation.title}
            </span>
            <span className='AnswerRecommendations-recommendation-description'>
              {recommendation.description}
            </span>
          </div>
        </div>

        <div className='AnswerRecommendations-recommendation-knowmore'>
          <a
            className='AnswerRecommendations-recommendation-link'
            target='_blank'
            href={recommendation.url}
            rel='noreferrer'
          >
            Learn how
            <Launch className='AnswerRecommendations-recommendation-icon' />
          </a>
        </div>
      </div>
    )
  }
  return (
    <div className='AnswerRecommendations z-depth-1 animated fadeInDown'>
      <>
        <div className='AnswerRecommendations-header'>
          <span className='AnswerRecommendations-title'>
            {t('answer_form.our_recommendations')}
          </span>
          <span className='AnswerRecommendations-description'>
            {t('answer_form.algorithm')}
          </span>
        </div>
        <div className='AnswerRecommendations-recommendations z-depth-1'>
          {userRecommendations?.map(renderRecommendation)}
        </div>
      </>
    </div>
  )
}

export default AnswerRecommendations
