import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'lodash'
import { useSelector } from 'react-redux'

import { clearBarChart, renderBarChart } from './BarChart'

import './BarChartContainer.scss'

const DashboardBarChartContainer = () => {
  const [t] = useTranslation()
  const dashboard = useSelector(state => state.dashboard)
  const { barChartData } = dashboard

  useEffect(() => {
    if (!isEmpty(barChartData[0])) {
      renderBarChart('#dashBarChartBuilder', barChartData, 100)
    } else {
      clearBarChart('#dashBarChartBuilder')
    }
  })

  return (
    <div className='z-depth-4 p-2 mt-2'>
      {/* {barChartData[0] && barChartData[0].length > 0 && ( */}
      <p className='ml-1 mb-2 chart-label'>
        {t('bar_chart_container.label')}{' '}
        <span className='objectiveName'>{barChartData.objectiveName}</span>
      </p>
      {/* )} */}
      <div
        id='dashboardBarChartContainer'
        className='dashboardBarChartContainer d-flex justify-content-center'
      >
        <div name='dashBarChartBuilder' id='dashBarChartBuilder' />
      </div>
    </div>
  )
}

export default DashboardBarChartContainer
