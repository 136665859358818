import React, { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Confirm } from 'notiflix'

import {
  clearObjective,
  createObjective,
  deleteObjective,
  updateObjective
} from 'redux/actions/objectivesAction'

import FormButton from 'components/FormButton/FormButton'

import './ObjectivesAreas.scss'

const ObjectivesAreas = ({ areaId, assessmentId }) => {
  const [t] = useTranslation()

  const [shortName, setShortName] = useState('')
  const [description, setDescription] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isInvalid, setIsInvalid] = useState(false)
  const [selectedArea, setSelectedArea] = useState()

  const dispatch = useDispatch()

  const area = useSelector(state => state.area)
  const maturityLevel = useSelector(state => state.maturityLevel)
  const { objective } = useSelector(state => state.objective)

  const { areas } = area
  const { numberOfEnabledRows } = maturityLevel
  const assessmentValue = numberOfEnabledRows

  const cancel = useCallback(
    e => {
      if (e) {
        e.stopPropagation()
        clearObjective(dispatch)
      }
      setShortName('')
      setDescription('')
      document.getElementById('shortName').focus()
    },
    [dispatch]
  )

  useEffect(() => {
    const [selectedAreaArray] = areas.filter(area => area.id === areaId)
    setSelectedArea(selectedAreaArray)
    cancel()
  }, [dispatch, areas, areaId, cancel])

  useEffect(() => {
    if (objective) {
      setShortName(objective.shortName)
      setDescription(objective.description)
    }
  }, [objective])

  useEffect(() => {
    setIsInvalid(!!(!shortName || !description))
  }, [shortName, description])

  const onChange = e => {
    switch (e.target.name) {
      case 'shortName':
        setShortName(e.target.value)
        break
      case 'description':
        setDescription(e.target.value)
        break
      default:
        break
    }
  }

  Confirm.Init({
    titleColor: 'rgba(58, 157, 184, 1)',
    okButtonBackground: 'rgb(172, 2, 2)',
    backgroundColor: 'rgb(40,40,60)',
    borderRadius: '5px',
    messageColor: 'white',
    zindex: 9999
  })

  const confirmDeletion = () => {
    Confirm.Show(
      t('objectives_areas.confirm.title'),
      t('objectives_areas.confirm.description', {
        objective: shortName.toUpperCase
      }),
      t('objectives_areas.confirm.yes'),
      t('objectives_areas.confirm.no'),
      async () => {
        setIsLoading(true)
        deleteObjective(objective.id, assessmentId, dispatch)
        cancel()
        clearObjective(dispatch)
        setIsLoading(false)
      }
    )
  }

  const handleCreateObjective = async e => {
    e && e.stopPropagation()
    setIsLoading(true)
    await createObjective(
      {
        assessment_id: assessmentId,
        area_id: areaId,
        shortName,
        description,
        assessmentValue,
        currentMaturity_id: null
      },
      dispatch
    )
    cancel()
    clearObjective(dispatch)
    setIsLoading(false)
  }

  const handleUpdateObjective = e => {
    e && e.stopPropagation()
    if (objective && objective.id) {
      setIsLoading(true)
      updateObjective({ ...objective, shortName, description }, dispatch)
      cancel()
      clearObjective(dispatch)
      setIsLoading(false)
    }
  }

  const handleDeleteObjective = e => {
    e && e.stopPropagation()
    if (objective && objective.id) {
      confirmDeletion()
    }
  }

  return (
    <>
      <div className='ObjectivesAreas'>
        <div className='ObjectivesAreas-container'>
          <div className='ObjectivesAreas-header'>
            <p>
              {t('objectives_areas.header', {
                area: selectedArea ? selectedArea.name : null
              })}{' '}
            </p>
          </div>
          <div className='ObjectivesAreas-input'>
            <input
              autoFocus
              type='text'
              maxLength={30}
              name='shortName'
              id='shortName'
              placeholder={t('objective_area.fields.objective')}
              value={shortName}
              onChange={onChange}
            />
            {shortName.length > 20 && (
              <small className='blink-me' style={{ color: '#e438db' }}>
                &nbsp;
                {30 - shortName.length}
              </small>
            )}
          </div>
          <div className='ObjectivesAreas-texarea'>
            <textarea
              name='description'
              id='description'
              placeholder={t('objective_area.fields.description')}
              value={description}
              onChange={onChange}
            />
          </div>
          <div className='ObjectivesAreas-button-group'>
            {objective && objective.id && (
              <FormButton
                variant='green'
                disabled={isLoading || isInvalid}
                onClick={handleUpdateObjective}
              >
                {t('label.update')}
              </FormButton>
            )}
            {!objective && (
              <FormButton
                variant='blue'
                disabled={isLoading || isInvalid}
                onClick={handleCreateObjective}
              >
                {t('label.create')}
              </FormButton>
            )}
            {objective && objective.id && (
              <FormButton
                variant='red'
                disabled={isLoading || !(objective && objective.id)}
                onClick={handleDeleteObjective}
              >
                {t('label.delete')}
              </FormButton>
            )}
            <FormButton variant='gray' onClick={cancel}>
              {t('label.cancel')}
            </FormButton>
          </div>
        </div>
      </div>
    </>
  )
}

export default ObjectivesAreas
