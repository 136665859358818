import React, { useEffect, useState } from 'react'
import classnames from 'classnames'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Confirm } from 'notiflix'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'

import {
  newQuestion,
  deleteQuestion,
  selectQuestion,
  selectQuestionObjective
} from 'redux/actions/questionsAction'

import './QuestionList.scss'

const QuestionList = () => {
  const [t] = useTranslation()
  const dispatch = useDispatch()

  const [selectedQuestionId, setSelectedQuestionId] = useState('')
  const { question, objective } = useSelector(state => state)
  const assessment = useSelector(state => state.assessment)
  const { questions, selectedObjective, selectedQuestion } = question
  const { objectives } = objective

  Confirm.Init({
    titleColor: 'rgba(58, 157, 184, 1)',
    okButtonBackground: 'rgba(58, 157, 184, 1)',
    backgroundColor: 'rgb(40,40,60)',
    borderRadius: '5px',
    messageColor: 'white',
    zindex: 9999
  })

  useEffect(() => {
    setSelectedQuestionId(selectedQuestion.id)
  }, [selectedQuestion])

  useEffect(() => {
    if (selectedQuestionId === '') {
      newQuestion(dispatch)
    }
  }, [selectedQuestionId, dispatch])

  const removeQuestion = id => {
    deleteQuestion(id, dispatch).then(resp => {
      if (resp?.status === 500) {
        window.toastr.error(t('question_list.error.server'))
      } else {
        window.toastr.success(t('question_list.success', { id }))
      }
    })
  }

  const renderCreatedQuestions = quest => {
    const classes = classnames('QuestionList-question', {
      selected: selectedQuestionId === quest.id
    })

    const confirmMessage = id => {
      Confirm.Show(
        t('question_list.confirm.title'),
        t('question_list.confirm.description'),
        t('question_list.confirm.yes'),
        t('question_list.confirm.no'),
        () => {
          removeQuestion(id)
          newQuestion(dispatch)
        }
      )
    }

    return (
      <div
        key={quest.id}
        className={classes}
        onClick={() => {
          setSelectedQuestionId(quest.id)
          selectQuestionObjective({ id: quest.objective_id }, dispatch)
          selectQuestion(quest.id, dispatch)
        }}
      >
        <h1 className='mr-auto'>{quest.title}</h1>
        {!assessment.isDeployed && (
          <button
            className='QuestionList-deleteButton'
            onClick={() => confirmMessage(quest.id)}
          >
            <DeleteOutlineIcon />
          </button>
        )}
      </div>
    )
  }

  return (
    <div className='QuestionList-container '>
      <div className='QuestionList-header'>
        <div className='QuestionList-textLeft'>
          <h1 className='QuestionList-title'>{t('question_list.title')}</h1>
          <div>
            <h1 className='QuestionList-textColor'>
              {t('question_list.subtitle')}
              <span className='QuestionList-objective'>
                {' '.concat(
                  objectives.find(item => item.id === selectedObjective.id)
                    .shortName
                )}
              </span>
            </h1>
          </div>
        </div>
      </div>
      <div className='QuestionList-content'>
        {questions
          .filter(q => q.objective_id === selectedObjective.id)
          .map(renderCreatedQuestions)}
      </div>
    </div>
  )
}

export default QuestionList
