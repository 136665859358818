import {
  FETCH_PERSONAS_FOR_ORGANIZATION_ID,
  TOGGLE_PERSONA_MANAGE,
  TOGGLE_PERSONA_FORM
} from 'redux/actionTypes'

const initialState = {
  personas: [],
  personaManageToggled: false,
  personaFormToggled: false
}

const personaReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case FETCH_PERSONAS_FOR_ORGANIZATION_ID: {
      return { ...state, personas: action.payload }
    }
    case TOGGLE_PERSONA_MANAGE: {
      return togglePersonaManage(state, action)
    }
    case TOGGLE_PERSONA_FORM: {
      return togglePersonaForm(state, action)
    }
    default:
      return state
  }
}

const togglePersonaManage = (state, action) => {
  const { personaManageToggled } = action.payload
  const newState = {
    ...state,
    personaManageToggled
  }
  return newState
}

const togglePersonaForm = (state, action) => {
  const { personaFormToggled } = action.payload
  const newState = {
    ...state,
    personaFormToggled,
    persona: { ...action.payload.persona }
  }
  return newState
}

export default personaReducer
