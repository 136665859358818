import React, { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import { clearRadar, renderRadar } from './RadarChart'
import { useDispatch, useSelector } from 'react-redux'
import { toggleHideAssessment } from 'redux/actions/dashboardActions'

const DashboardRadarContainer = () => {
  const dispatch = useDispatch()
  const { dashboard } = useSelector(state => state)

  const {
    chartData,
    mycfg,
    legendOptions,
    selectedTemplate,
    showAverage,
    showMaturity,
    hiddenElements
  } = dashboard

  const [localChartData, setLocalChartData] = useState([])

  mycfg.levels = selectedTemplate.maturityModel.length
  mycfg.maxValue = selectedTemplate.maturityModel.length
  mycfg.maturityModel = selectedTemplate.maturityModel

  const hideElement = element => {
    toggleHideAssessment(element, dispatch)
  }

  useEffect(() => {
    setLocalChartData(chartData)
  }, [chartData])

  useEffect(() => {
    if (!isEmpty(localChartData)) {
      const filteredChartData = localChartData.filter(
        (chart, i) => hiddenElements.findIndex(el => el[1] === i) === -1
      )

      // currentChartData[0].map(item => {
      //   // item.areaColor = '#ff0057'

      //   // areas.forEach((element) => {
      //   //   if (element.id === item.areaId) {
      //   //     item.areaColor = element.color
      //   //   }
      //   // })
      //   return item
      // })

      const filteredLegendOptions = legendOptions.filter(
        item => hiddenElements.findIndex(i => i[0] === item) === -1
      )

      renderRadar(
        filteredChartData, // .filter((chart, i) => hiddenElements.findIndex(el => el[1] === i) === -1),
        mycfg,
        filteredLegendOptions,
        '#dashChartBuilder',
        () => {
          /** do something when the ball is clicked */
        },
        showAverage,
        showMaturity,
        hideElement,
        hiddenElements
      )
    } else {
      clearRadar('#dashChartBuilder')
    }
  }, [localChartData, hiddenElements, selectedTemplate])

  const renderRadarContainer = () => {
    if (!isEmpty(chartData)) {
      return (
        <div
          id='dashboardRadarContainer'
          className='d-flex justify-content-center'
        >
          <div name='dashChartBuilder' id='dashChartBuilder' />
        </div>
      )
    } else {
      return <div />
    }
  }
  return renderRadarContainer()
}

export default DashboardRadarContainer
