import { api } from './axios'
import {
  CREATE_QUESTION,
  DELETE_QUESTION,
  UPDATE_QUESTION,
  ADD_CHOICE,
  UPDATE_CHOICE,
  DELETE_CHOICE,
  NEW_QUESTION,
  SELECT_QUESTION,
  UPDATE_SELECTED_QUESTION_OBJECTIVE,
  INITIALIZE_QUESTION
} from '../actionTypes'

export const createQuestion = async (question, dispatch) => {
  try {
    const { data } = await api.post('/questions', question)
    dispatch({
      type: CREATE_QUESTION,
      payload: data
    })
  } catch (e) {
    return e
  }
}

export const updateQuestion = async (id, question, dispatch) => {
  try {
    if (!id) return
    const { data } = await api.put(`/questions/${id}`, question)
    dispatch({
      type: UPDATE_QUESTION,
      payload: data
    })
  } catch (e) {
    return e
  }
}

export const deleteQuestion = async (id, dispatch) => {
  try {
    if (!id) return
    const { data } = await api.delete(`/questions/${id}`)
    if (data) {
      dispatch({
        type: DELETE_QUESTION,
        id
      })
      return data
    }
  } catch (e) {
    const err = e.response
    const error = {
      status: err.status,
      errorMsg: err.data.errors.questionError
    }
    return error
  }
}

export const persistQuestion = async (objectiveId, question, dispatch) => {
  if (!question.title || !question.description) return
  if (question.id) {
    await updateQuestion(question.id, question, dispatch)
  } else {
    await createQuestion({ objectiveId, ...question }, dispatch)
  }
}

export const selectQuestionObjective = async (objective, dispatch) => {
  dispatch({
    type: UPDATE_SELECTED_QUESTION_OBJECTIVE,
    payload: objective
  })
}

export const addChoice = async (id, choice, dispatch) => {
  if (!choice) return
  if (id) {
    updateChoice(id, choice, dispatch)
  } else {
    try {
      const { data } = await api.post('/choices', choice)
      dispatch({
        type: ADD_CHOICE,
        payload: data
      })
    } catch (error) {
      return error
    }
  }
}

export const updateChoice = async (id, choice, dispatch) => {
  if (!id) return
  try {
    const { data } = await api.put(`/choices/${id}`, choice)
    if (data) {
      dispatch({
        type: UPDATE_CHOICE,
        payload: data
      })
    }
  } catch (error) {
    return error
  }
}

export const deleteChoice = async (choice, dispatch) => {
  if (!choice.id) return
  try {
    const { data } = await api.delete(`/choices/${choice.id}`)
    if (data) {
      dispatch({
        type: DELETE_CHOICE,
        payload: choice
      })
      // TODO We should *not* cause side effects on the Front End inside action files
      window.toastr.success(`Choice ${choice.id} deleted successfuly!`)
    }
  } catch (error) {
    // TODO We should *not* cause side effects on the Front End inside action files
    window.toastr.error(`Could not delete choice ${choice.id}`)
  }
}

export const newQuestion = async dispatch => {
  dispatch({
    type: NEW_QUESTION
  })
}

export const selectQuestion = async (id, dispatch) => {
  dispatch({
    type: SELECT_QUESTION,
    payload: id
  })
}

export const initializeQuestion = async dispatch => {
  dispatch({
    type: INITIALIZE_QUESTION,
    payload: null
  })
}
