import React, { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { DateRange } from 'react-date-range'
import { useDispatch, useSelector } from 'react-redux'
import { Confirm } from 'notiflix'

import { getLoggedInUser } from 'auth'

import NodeUsers from 'components/Tree/NodeUsers'
import Leanit101Select from 'components/Select/Leanit101Select'

import { toggleTriggerAssessment } from 'redux/actions/treeActions'
import {
  getDeployedTemplatesByOrganizationId,
  triggerAssessments
} from 'redux/actions/assessmentActions'

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import DoneAllIcon from '@material-ui/icons/DoneAll'

import './TriggerAssessment.scss'

const TriggerAssessment = () => {
  const [t] = useTranslation()

  const { deployedTemplates } = useSelector(state => state.template)
  const [selectedTemplate, setSelectedTemplate] = useState(null)
  const [groupedOptions, setGroupedOptions] = useState([])
  const [selectedDate, setSelectedDate] = useState('assessmentDate') // formDate

  const { treeData } = useSelector(state => state.tree)

  const [isLoading, setIsLoading] = useState(false)
  const [isInvalid, setIsInvalid] = useState(false)

  const [assessmentsFiltered, setAssessmentsFiltered] = useState([])
  const [selectedAssessments, setSelectedAssessments] = useState([])

  const dispatch = useDispatch()
  const user = getLoggedInUser()
  const organizationId = user.organization_id

  Confirm.Init({
    titleColor: 'rgba(58, 157, 184, 1)',
    okButtonBackground: 'rgba(58, 157, 184, 1)',
    backgroundColor: 'rgb(40,40,60)',
    borderRadius: '5px',
    messageColor: 'white',
    zindex: 9999
  })

  const [assessmentDates, setAssessmentDates] = useState({
    startDate: new Date(),
    endDate: new Date(),
    color: '#338ba7',
    key: 'selection'
  })

  const filterAssessments = useCallback(
    subTree => {
      const result = []
      if (isInvalid || subTree.length === 0) {
        return result
      }
      if (subTree) {
        for (let i = 0; i < subTree.length; i++) {
          if (subTree[i].assessments.length > 0) {
            for (let j = 0; j < subTree[i].assessments.length; j++) {
              if (
                subTree[i].assessments[j].template_id ===
                  selectedTemplate?.id &&
                new Date(
                  selectedDate === 'assessmentDate'
                    ? subTree[i].assessments[j].startDate
                    : subTree[i].assessments[j].openFormDate
                ) >= new Date(assessmentDates.startDate) &&
                new Date(
                  selectedDate === 'assessmentDate'
                    ? subTree[i].assessments[j].endDate
                    : subTree[i].assessments[j].closeFormDate
                ) <= new Date(assessmentDates.endDate) &&
                (user.role === 'super' ||
                  user.role === 'master' ||
                  (subTree[i].isUserAuthorized && user.role !== 'user'))
              ) {
                result.push({
                  nodeId: subTree[i].id,
                  nodeName: subTree[i].name,
                  nodeType: subTree[i].nodeType.name,
                  nodeUsers: subTree[i].nodeUsers.map(user => {
                    return { ...user, selected: true }
                  }),
                  assessment: { ...subTree[i].assessments[j] }
                })
              }
            }
          }
          if (subTree[i].children.length > 0) {
            const found = filterAssessments(subTree[i].children)
            if (found.length > 0) result.push(...found)
          }
        }
      }
      return result
    },
    [
      isInvalid,
      selectedTemplate,
      assessmentDates.startDate,
      assessmentDates.endDate,
      selectedDate
    ]
  )

  useEffect(() => {
    setIsLoading(true)
    const getTemplates = async () =>
      await getDeployedTemplatesByOrganizationId(organizationId, dispatch)
    getTemplates()
    setIsLoading(false)
  }, [organizationId, dispatch])

  useEffect(() => {
    setIsInvalid(
      !selectedTemplate ||
        !assessmentDates.startDate ||
        !assessmentDates.endDate
    )
  }, [selectedTemplate, assessmentDates.startDate, assessmentDates.endDate])

  useEffect(() => {
    if (!isInvalid && selectedTemplate?.id) {
      const filtered = filterAssessments(treeData)
      setAssessmentsFiltered(filtered)
    } else {
      setAssessmentsFiltered([])
    }
  }, [
    isInvalid,
    treeData,
    filterAssessments,
    selectedTemplate,
    assessmentDates.startDate,
    assessmentDates.endDate
  ])

  useEffect(() => {
    const groupedOptions = [
      {
        label: t('trigger_assessment.available_templates'),
        options: deployedTemplates.map(template => {
          return {
            label: template.name,
            value: template.id,
            template
          }
        })
      }
    ]
    setGroupedOptions(groupedOptions)
  }, [deployedTemplates])

  const onChangeSelectedTemplate = e => {
    if (e) {
      setSelectedTemplate(e.template)
    } else {
      setSelectedTemplate(null)
    }
  }

  const clearFilter = () => {
    setSelectedTemplate(null)
    setAssessmentsFiltered([])
    setSelectedAssessments([])
    setAssessmentDates({
      startDate: new Date(),
      endDate: new Date(),
      color: '#338ba7',
      key: 'selection'
    })
  }

  const renderFilteredAssessments = () => {
    if (assessmentsFiltered.length === 0) {
      return (
        <span className='TriggerAssessment-not-found'>
          {t('trigger_assessment.no_assessment_found')}
        </span>
      )
    }

    return assessmentsFiltered.map(item => {
      return (
        <div
          className='TriggerAssessment-assessment-card'
          key={item.assessment.id}
        >
          <div className='TriggerAssessment-assessment-card-content'>
            <span className='assessment-name'>{item.assessment.name}</span>
            <div className='d-linline-flex mt-2'>
              <span className='node-name'>{item.nodeName}</span>
              <span className='node-type'>{item.nodeType}</span>
            </div>
          </div>
          <div className='d-inline-flex align-items-center'>
            <span
              data-toggle='tooltip'
              title={t('trigger_assessment.select_assessment')}
            >
              <AddCircleOutlineIcon
                onClick={e => {
                  e.stopPropagation()
                  const newSelectedAssessment = [...selectedAssessments]
                  if (
                    !newSelectedAssessment.find(
                      el => el.assessment.id === item.assessment.id
                    )
                  ) {
                    newSelectedAssessment.push(item)
                    setSelectedAssessments(newSelectedAssessment)
                  }
                  const newAssessmentsFiltered = assessmentsFiltered.filter(
                    el => el.assessment.id !== item.assessment.id
                  )
                  setAssessmentsFiltered(newAssessmentsFiltered)
                }}
                className='launch-icon'
              />
            </span>
          </div>
        </div>
      )
    })
  }

  const toggleUser = (value, nodeId, assessmentId, userId) => {
    const newSelectedAssessment = selectedAssessments.map(item => {
      return {
        ...item,
        nodeUsers:
          item.nodeId === nodeId && item.assessment.id === assessmentId
            ? item.nodeUsers.map(user => {
                return user.id === userId
                  ? { ...user, selected: !value }
                  : { ...user }
              })
            : [...item.nodeUsers]
      }
    })
    setSelectedAssessments(newSelectedAssessment)
  }

  const checkAll = (value, nodeId, assessmentId) => {
    const newSelectedAssessment = selectedAssessments.map(item => {
      return {
        ...item,
        nodeUsers:
          item.nodeId === nodeId && item.assessment.id === assessmentId
            ? item.nodeUsers.map(user => {
                return { ...user, selected: value }
              })
            : [...item.nodeUsers]
      }
    })
    setSelectedAssessments(newSelectedAssessment)
  }

  const renderSelectedAssessments = () => {
    if (selectedAssessments.length === 0) {
      return (
        <span className='TriggerAssessment-not-found'>
          {t('trigger_assessment.no_assessment_selected')}
        </span>
      )
    }

    return selectedAssessments.map(item => {
      return (
        <div
          className='TriggerAssessment-assessment-selected-card'
          key={'selected_' + item.assessment.id}
        >
          <div className='d-flex flex-row justify-content-between'>
            <div className='TriggerAssessment-assessment-selected-card-content'>
              <span className='selected-assessment-name'>
                {item.assessment.name}
              </span>
              <div className='d-linline-flex mt-2'>
                <span className='selected-node-name'>{item.nodeName}</span>
                <span className='selected-node-type'>{item.nodeType}</span>
              </div>
            </div>
            <div className='d-inline-flex align-items-center'>
              <span
                data-toggle='tooltip'
                title={t('trigger_assessment.select_assessment')}
              >
                <HighlightOffIcon
                  onClick={e => {
                    e.stopPropagation()
                    const newSelectedAssessment = selectedAssessments.filter(
                      el => el.assessment.id !== item.assessment.id
                    )
                    setSelectedAssessments(newSelectedAssessment)
                  }}
                  className='launch-icon'
                />
              </span>
            </div>
          </div>
          <div className='mt-3'>
            <NodeUsers
              nodeUsers={item.nodeUsers}
              addOn
              callBack={toggleUser}
              nodeId={item.nodeId}
              assessmentId={item.assessment.id}
              checkAll={checkAll}
            />
          </div>
        </div>
      )
    })
  }

  const confirmMessage = () => {
    Confirm.Show(
      t('trigger_assessment.confirm.title'),
      t('trigger_assessment.confirm.description'),
      t('trigger_assessment.confirm.yes'),
      t('trigger_assessment.confirm.no'),
      async () => {
        // Yes button callback
        setIsLoading(true)
        const processedEmails = await triggerAssessments(selectedAssessments)
        clearFilter()
        window.toastr.success(
          t('trigger_assessment.confirm.response', { processedEmails })
        )
        setIsLoading(false)
        toggleTriggerAssessment(dispatch)
      }
    )
  }

  const trigger = e => {
    e.stopPropagation()
    confirmMessage()
  }

  const renderSummary = () => {
    if (!selectedAssessments.length) return null

    const users = selectedAssessments
      .map(i => i.nodeUsers.reduce((p, c) => (c.selected ? 1 + p : p), 0))
      .reduce((p, c) => p + c, 0)
    return (
      <>
        <span>
          {t('trigger_assessment.summary', {
            selectedAssessments: selectedAssessments.length,
            users
          })}
        </span>
      </>
    )
  }

  return (
    <div className='TriggerAssessment'>
      <div className='TriggerAssessment-container'>
        <div className='TriggerAssessment-header'>
          <p>{t('trigger_assessment.title')}</p>
          <div className='mt-2'>{renderSummary()}</div>
        </div>
        <div className='TriggerAssessment-body'>
          <div className='TriggerAssessment-filter z-depth-2 '>
            <Leanit101Select
              name='selectTemplate'
              groupedOptions={groupedOptions}
              selectedPosition={null}
              placeHolderText={t('trigger_assessment.select_template')}
              onChange={onChangeSelectedTemplate}
              width='330'
            />

            <div className='mt-1 section-title'>
              {t('trigger_assessment.filter_by')}
            </div>
            <div className='d-flex justify-content-between mb-2'>
              <div
                className={`date-select ${
                  selectedDate === 'assessmentDate' && 'date-selected'
                }`}
                onClick={() => setSelectedDate('assessmentDate')}
              >
                {t('trigger_assessment.assessment_date')}
              </div>
              <div
                className={`date-select ${
                  selectedDate === 'formDate' && 'date-selected'
                }`}
                onClick={() => setSelectedDate('formDate')}
              >
                {t('trigger_assessment.form_date')}
              </div>
            </div>
            <DateRange
              className='mt-2'
              editableDateInputs
              color='#FFF'
              onChange={item => {
                setAssessmentDates(item.selection)
              }}
              moveRangeOnFirstSelection={false}
              ranges={[assessmentDates]}
            />
          </div>
          <div className='TriggerAssessment-filter-results z-depth-2'>
            {isLoading && (
              <div className='d-flex flex-column justify-content-center align-items-center'>
                <div className='loader1' />
              </div>
            )}
            <div className='d-flex justify-content-between'>
              <div className='section-title'>
                {t('trigger_assessment.filtered_assessments')}
              </div>
              <DoneAllIcon
                onClick={e => {
                  e.stopPropagation()
                  const newSelectedAssessments = [...selectedAssessments]
                  assessmentsFiltered.forEach(item => {
                    if (
                      !newSelectedAssessments.find(
                        el => el.assessment.id === item.assessment.id
                      )
                    ) {
                      newSelectedAssessments.push(item)
                    }
                  })

                  setSelectedAssessments(newSelectedAssessments)
                  setAssessmentsFiltered([])
                }}
                className={
                  assessmentsFiltered.length === 0
                    ? 'mt-2 mr-2 disabled'
                    : 'mt-2 mr-2 launch-icon'
                }
              />
            </div>
            {!isLoading && (
              <div className='TriggerAssessment-filter-results-content d-flex flex-column justify-content-start align-items-center'>
                {renderFilteredAssessments()}
              </div>
            )}
          </div>
          <div className='TriggerAssessment-selected-assessments z-depth-2'>
            <div className='section-title'>
              {t('trigger_assessment.select_assessments')}
            </div>
            <div className='TriggerAssessment-selected-assessments-content d-flex flex-column justify-content-start align-items-center'>
              {renderSelectedAssessments()}
            </div>
          </div>
        </div>

        <div className='TriggerAssessment-button-group'>
          <button
            disabled={selectedAssessments.length === 0}
            className='TriggerAssessment-triggerButton'
            onClick={e => trigger(e)}
          >
            {t('trigger_assessment.trigger_assessments')}
          </button>
          <button
            className='TriggerAssessment-clearButton'
            onClick={() => clearFilter()}
          >
            {t('trigger_assessment.clear_filter')}
          </button>
          <button
            className='TriggerAssessment-cancelButton'
            onClick={() => toggleTriggerAssessment(dispatch)}
          >
            {t('label.cancel')}
          </button>
        </div>
      </div>
    </div>
  )
}

export default TriggerAssessment
