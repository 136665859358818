import React, { useEffect } from 'react'
import { isEmpty } from 'lodash'
import { clearRadar, renderRadar } from './RadarChart'
import { useDispatch, useSelector } from 'react-redux'
import { firstBy } from 'thenby'

import { selectObjective } from 'redux/actions/objectiveMaturityActions'
import '../../styles/loader.scss'
import { useTranslation } from 'react-i18next'

const ObjectiveRadarContainer = () => {
  const [t] = useTranslation()
  const { objective, maturityLevel, area } = useSelector(state => state)
  const { chartData, mycfg } = objective
  const { numberOfEnabledRows, rows } = maturityLevel
  const { areas } = area
  mycfg.levels = numberOfEnabledRows
  mycfg.maxValue = numberOfEnabledRows
  mycfg.maturityModel = rows.map(
    (level, i) => i < numberOfEnabledRows && level.name
  )
  const dispatch = useDispatch()

  useEffect(() => {
    const currentChartData = chartData
      .map(item => {
        item[1].areaColor = null

        areas.forEach(element => {
          if (element.id === item[1].areaId) {
            item[1].areaColor = element.color
            item[1].value = numberOfEnabledRows
          }
        })
        return item[1]
      })
      .sort(firstBy('area_id').thenBy('shortName'))
    if (!isEmpty(chartData)) {
      renderRadar(
        [currentChartData],
        mycfg,
        [t('radar.initial_radar')],
        '#chartBuilder',
        (axis, _, id, areaId) => {
          selectObjective(id, axis, areaId, dispatch)
        },
        false,
        false,
        () => {}
      )
    } else {
      clearRadar('#chartBuilder')
    }
  }, [chartData, area])

  return (
    <div id='chartBuilderContainer' className='d-flex mt-4 justify-content-end'>
      {!isEmpty(chartData) && <div name='chartBuilder' id='chartBuilder' />}
      {/* // : (
        //   <div className='loader1' />
        //   )} */}
    </div>
  )
}

export default ObjectiveRadarContainer
