import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { drawBubbleChart } from './BubbleChart'
import { isEmpty } from 'lodash'
import { updateToggledBranches } from 'redux/actions/treeActions'

const DashboardBubbleChartContainer = () => {
  const dashboard = useSelector(state => state.dashboard)
  const tree = useSelector(state => state.tree)
  const { treeData } = tree

  const { chartData } = dashboard
  const dispatch = useDispatch()
  const [data, setData] = useState([])

  useEffect(() => {
    setData(treeData)
  }, [treeData])

  useEffect(() => {
    if (data.length > 0 && isEmpty(chartData)) {
      const newData = shapeTreeDataToBubbleStructure(data)
      drawBubbleChart(
        '#bubbleChartBuilder',
        chartData,
        newData[0],
        handleUpdateToggledBranches
      )
    }
  }, [data, chartData])

  const shapeTreeDataToBubbleStructure = data => {
    return data.map((node, i) => {
      const newNode = { ...node }
      if (node.children.length > 0) {
        newNode.children = shapeTreeDataToBubbleStructure(node.children)
      } else {
        delete newNode.children
      }

      newNode.value = countChildrenMembers([node])
      return newNode
    })
  }

  const countChildrenMembers = data => {
    let newValue = 0
    data.forEach(node => {
      newValue += node.nodeUsers.length
      if (node.children.length > 0) {
        newValue += countChildrenMembers(node.children)
      }
    })
    return newValue
  }

  const blinkNode = async (nodeElement, timeout) => {
    setTimeout(() => {
      nodeElement.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      })
    }, timeout)
    nodeElement.classList.remove('blinking')
    nodeElement.classList.add('blinking')
    setTimeout(() => {
      nodeElement.classList.remove('blinking')
    }, 3000)
  }

  const handleUpdateToggledBranches = async (node, bubbleBranches) => {
    const hasParent = node => {
      if (node.parent) {
        if (node.children) {
          return [node.data.id, ...hasParent(node.parent)]
        } else {
          return hasParent(node.parent)
        }
      }
      return [node.data.id]
    }

    const parentIds = hasParent(node)

    await updateToggledBranches(parentIds, dispatch)

    const nodeElement = document.getElementById(`node-${node.data.id}`)

    await blinkNode(nodeElement, 700)
  }

  return (
    <div
      id='dashboardBubbleContainer'
      className='d-flex justify-content-start text-white'
    >
      {isEmpty(chartData) && data.length > 0 && (
        <div name='bubbleChartBuilder' id='bubbleChartBuilder' />
      )}
    </div>
  )
}

export default DashboardBubbleChartContainer
