import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import classnames from 'classnames'

import { StepProgress } from 'components'

import './Steps.scss'
import {
  fetchTreeData,
  toggleMaturityModelForm
} from 'redux/actions/treeActions'
import { initializeAssessment } from 'redux/actions/assessmentActions'
import { initializeMaturityLevels } from 'redux/actions/maturityLevelActions'
import { initializeObjectives } from 'redux/actions/objectivesAction'
import { initializeObjectiveMaturities } from 'redux/actions/objectiveMaturityActions'
import { initializeQuestion } from 'redux/actions/questionsAction'
import { getLoggedInUser } from 'auth'

const Steps = ({
  steps,
  currentIndexTab,
  onNextClick,
  onBackClick,
  percentage
}) => {
  const [t] = useTranslation()
  const { assessment, maturityLevel } = useSelector(state => state)
  const maturityRows = maturityLevel.rows
  const { completed } = assessment
  const { numberOfEnabledRows } = maturityLevel
  const nextButton = useRef()
  const backButton = useRef()
  const user = getLoggedInUser()

  const dispatch = useDispatch()
  useEffect(() => {
    if (currentIndexTab === 1) {
      let enable = !!numberOfEnabledRows
      maturityRows.forEach(el => {
        if (!el.id && el.position <= numberOfEnabledRows) {
          enable = false
        }
      })
      backButton.current.setAttribute('disabled', '')
      enable
        ? nextButton.current.removeAttribute('disabled', '')
        : nextButton.current.setAttribute('disabled', '')
    } else if (currentIndexTab === 2) {
      completed === 100
        ? nextButton.current.removeAttribute('disabled', '')
        : nextButton.current.setAttribute('disabled', '')
      backButton.current.removeAttribute('disabled', '')
    } else if (currentIndexTab === 3) {
      nextButton.current.removeAttribute('disabled', '')
    } else if (currentIndexTab === 4) {
      percentage === '100.00'
        ? nextButton.current.removeAttribute('disabled', '')
        : nextButton.current.setAttribute('disabled', '')
      backButton.current.removeAttribute('disabled', '')
    } else {
      nextButton.current.setAttribute('disabled', '')
      backButton.current.removeAttribute('disabled', '')
    }
  }, [
    maturityRows,
    numberOfEnabledRows,
    currentIndexTab,
    completed,
    percentage
  ])

  const renderSteps = (el, index) => {
    const classes = classnames('col-md text-center Steps-tab', {
      selected: currentIndexTab === el.indexTab
    })

    return (
      <div key={index} id={el.indexTab} className={classes}>
        <span>{t(el.title)}</span>
      </div>
    )
  }

  const initializeAll = async () => {
    await initializeAssessment(dispatch)
    await initializeMaturityLevels(dispatch)
    await initializeObjectives(dispatch)
    await initializeObjectiveMaturities(dispatch)
    await initializeQuestion(dispatch)
  }

  return (
    <div className='Steps'>
      <div className='Steps-top d-flex justify-content-around'>
        {steps.map((el, index) => renderSteps(el, index))}
      </div>
      <div>
        <StepProgress currValue={currentIndexTab} maxValue={4} />
      </div>

      <div className='Steps-top d-flex justify-content-around'>
        <div className='ml-auto'>
          <button
            ref={backButton}
            className='Steps-loadTemplateButton'
            onClick={onBackClick}
          >
            {t('label.back')}
          </button>
          <button
            ref={nextButton}
            className='Steps-nextButton'
            onClick={async e => {
              e.stopPropagation()
              if (currentIndexTab === 4) {
                await toggleMaturityModelForm(dispatch)
                initializeAll()
                await fetchTreeData(user.organization_id, user.id, dispatch)
              } else {
                onNextClick()
              }
            }}
          >
            {t(currentIndexTab === 4 ? 'label.finish' : 'label.next')}
          </button>
          <button
            className='ml-3 Steps-loadTemplateButton'
            onClick={async e => {
              e.stopPropagation()
              await toggleMaturityModelForm(dispatch)
              initializeAll()
              await fetchTreeData(user.organization_id, user.id, dispatch)
            }}
          >
            {t('label.close')}
          </button>
        </div>
      </div>
    </div>
  )
}

export default Steps
