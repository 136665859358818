import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import './OrganizationProfile.scss'
import '../../styles/loader.scss'
import {
  getNodesByOrganizationId,
  getPersonasByOrganizationId,
  inviteUsers
} from 'redux/actions/treeActions'
import { useDispatch } from 'react-redux'
import { getLoggedInUser } from 'auth'

const OrganizationProfile = ({ organizationData, closeCallBack }) => {
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const loggedInUser = getLoggedInUser()

  const [userEmail, setUserEmail] = useState('')

  const submitUser = async e => {
    const personas = await getPersonasByOrganizationId(
      organizationData.id,
      dispatch
    )
    const nodes = await getNodesByOrganizationId(organizationData.id, dispatch)
    await inviteUsers(
      {
        invitedUsers: [
          {
            avatarUrl: '',
            email: userEmail,
            id: null,
            name: null,
            persona: personas[0],
            role: 'super'
          }
        ],
        selectedNodes: [nodes[0]]
      },
      organizationData.id,
      loggedInUser.id,
      dispatch
    )
  }

  return (
    <div className='OrganizationProfile'>
      <div className='OrganizationProfile-container z-depth-1'>
        <div className='OrganizationProfile-header'>
          <p>{t('organization-profile.header_title')}</p>
          <span className='ml-2'>{organizationData.name}</span>
        </div>

        <div className='OrganizationProfile-body'>
          <div className='OrganizationProfile-row'>
            <span className='OrganizationProfile-user-field-title'>
              {t('organization-profile.fields.name')}
            </span>
            <span className='OrganizationProfile-user-field-content'>{`${organizationData?.name}`}</span>
          </div>
          <div className='OrganizationProfile-row'>
            <span className='OrganizationProfile-user-field-title'>
              {t('organization-profile.fields.email')}
            </span>
            <span className='OrganizationProfile-user-field-content'>{`${organizationData?.billingEmail}`}</span>
          </div>
          <div className='OrganizationProfile-row'>
            <span className='OrganizationProfile-user-field-title'>
              {t('organization-profile.fields.contact')}
            </span>
            <span className='OrganizationProfile-user-field-content'>{`${organizationData?.contactName}`}</span>
          </div>
          <div className='OrganizationProfile-row'>
            <span className='OrganizationProfile-user-field-title'>
              {t('organization-profile.fields.number')}
            </span>
            <span className='OrganizationProfile-user-field-content'>{`${organizationData?.phoneNumber}`}</span>
          </div>
          <div className='OrganizationProfile-row'>
            <span className='OrganizationProfile-user-field-title'>
              {t('organization-profile.add_user')}
            </span>
            <div>
              <input
                className='OrganizationProfile-input'
                type='email'
                name='super'
                id='email'
                value={userEmail}
                onChange={e => setUserEmail(e.target.value)}
              />
            </div>
          </div>
        </div>

        <div className='OrganizationProfile-footer'>
          {userEmail && (
            <button
              className='OrganizationProfile-submit-button'
              onClick={e => {
                closeCallBack(null)
                submitUser(e)
              }}
            >
              {t('label.submit')}
            </button>
          )}
          <button
            className='OrganizationProfile-close-button'
            onClick={() => {
              closeCallBack(null)
            }}
          >
            {t('label.close')}
          </button>
        </div>
      </div>
    </div>
  )
}

export default OrganizationProfile
