import {
  TOGGLE_RADAR,
  TOGGLE_AVERAGE,
  TOGGLE_MATURITY,
  TOGGLE_MODE,
  TOGGLE_ASSESSMENT_CONTAINER,
  TOGGLE_SIDE_BAR,
  TOGGLE_HIDE_ASSESSMENT,
  ADD_BAR_CHART_ANSWERS_PER_LEVEL_PER_OBJECTIVE,
  TOGGLE_MATURITY_MATRIX,
  CLEAR_DASHBOARD
} from 'redux/actionTypes'
import isEmpty from 'lodash/isEmpty'
import i18n from '../../i18n'

const initialState = {
  selectedTemplate: {
    maturityModel: [],
    templateId: null,
    id: null
  },
  sideBarToggled: true,
  hiddenElements: [],
  chartData: [],
  chartDataIndex: [],
  checkedData: [],
  legendOptions: [],
  showAverage: false,
  showMaturity: false,
  assessmentMode: true,
  barChartData: [],
  horizontalBarChartData: [],
  containerAssessment: null,
  mycfg: {
    w: 350,
    h: 350, // preserving aspect ratio
    maxValue: 10,
    levels: 10,
    TranslateX: 165,
    TranslateY: 200,
    ExtraWidthX: 350,
    ExtraWidthY: 260
  }
}

const dashboardReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case TOGGLE_RADAR:
      return toggleRadar(action, state)
    case TOGGLE_HIDE_ASSESSMENT:
      return toggleHideAssessment(action, state)
    case TOGGLE_AVERAGE:
      return toggleAverage(action, state)
    case TOGGLE_MATURITY:
      return toggleMaturity(action, state)
    case TOGGLE_MODE:
      return toggleMode(action, state)
    case TOGGLE_SIDE_BAR:
      return toggleSideBar(state)
    case TOGGLE_ASSESSMENT_CONTAINER:
      return toggleContainerAssessment(action, state)
    case TOGGLE_MATURITY_MATRIX:
      return toggleMaturityMatrix(action, state)
    case ADD_BAR_CHART_ANSWERS_PER_LEVEL_PER_OBJECTIVE:
      return addBarChartData(action, state)
    case CLEAR_DASHBOARD:
      return clearDashboard(action, state)
    default:
      return state
  }
}

const toggleContainerAssessment = (action, state) => {
  return {
    ...state,
    sideBarToggled: true,
    hiddenElements: [],
    chartData: [],
    chartDataIndex: [],
    checkedData: [],
    legendOptions: [],
    barChartData: [],
    horizontalBarChartData: [],
    showAverage: false,
    showMaturity: false,
    containerAssessment:
      state.containerAssessment &&
      state.containerAssessment.id === action?.payload?.id
        ? null
        : action.payload
  }
}

const toggleMaturityMatrix = (action, state) => {
  return {
    ...state,
    toggleMatrix: action.payload.toggleMatrix,
    matrixContent: { ...action.payload.data }
  }
}

const toggleSideBar = state => {
  return {
    ...state,
    sideBarToggled: !state.sideBarToggled
  }
}
const toggleHideAssessment = (action, state) => {
  const cantHide = [
    'Média',
    'Promedio',
    'Average',
    'Maturidade',
    'Madurez',
    'Maturity'
  ]
  const element = action.payload
  const { hiddenElements } = state
  const newHidden = [...hiddenElements]
  const legendIndex = state.legendOptions.findIndex(
    legend => legend === element
  )
  const hiddenIndex = hiddenElements.findIndex(e => e[0] === element)
  if (hiddenIndex === -1) {
    const preventClick = cantHide.findIndex(item => item === element) > -1
    if (preventClick) {
      window.toastr.error(i18n.t('dashboard.cantHideElement'))
      return state
    }
    if (state.chartData.length - state.hiddenElements.length > 1) {
      newHidden.push([element, legendIndex])
    } else {
      window.toastr.error(i18n.t('dashboard.cantHideElement'))
      return state
    }
  } else {
    newHidden.splice(hiddenIndex, 1)
  }
  return {
    ...state,
    barChartData: [],
    hiddenElements: [...newHidden]
  }
}

const toggleRadar = (action, state) => {
  let {
    selectedTemplate,
    exclude,
    assessmentChartData,
    chartDataIndex,
    chartDataLegend,
    checkedData,
    legend,
    chartCfg,
    showAverage,
    showMaturity,
    horizontalBarChartData
  } = getInitialReducerState(action, state)

  if (showAverage) {
    removeLast(assessmentChartData, chartDataLegend, horizontalBarChartData)
  }
  if (showMaturity) {
    removeLast(assessmentChartData, chartDataLegend, horizontalBarChartData)
    showMaturity = false
  }

  handleChartToggle(
    exclude,
    assessmentChartData,
    chartDataIndex,
    chartDataLegend,
    checkedData,
    legend,
    action,
    selectedTemplate,
    horizontalBarChartData
  )

  if (assessmentChartData.length < 2) {
    showAverage = false
    showMaturity = false
  }

  if (showAverage && assessmentChartData.length > 1) {
    addAverage(assessmentChartData, horizontalBarChartData, chartDataLegend)
  }

  return {
    ...state,
    hiddenElements: [],
    chartData: assessmentChartData,
    chartDataIndex: chartDataIndex,
    legendOptions: chartDataLegend,
    checkedData: checkedData,
    mycfg: chartCfg,
    showAverage: showAverage,
    showMaturity: showMaturity,
    selectedTemplate,
    barChartData: [],
    horizontalBarChartData: [...horizontalBarChartData]
  }
}

const handleChartToggle = (
  exclude,
  assessmentChartData,
  chartDataIndex,
  chartDataLegend,
  checkedData,
  legend,
  action,
  selectedTemplate,
  horizontalBarChartData
) => {
  if (exclude) {
    removeRadar(
      assessmentChartData,
      chartDataIndex,
      chartDataLegend,
      checkedData,
      action,
      selectedTemplate,
      horizontalBarChartData
    )
  } else {
    addRadar(
      assessmentChartData,
      chartDataIndex,
      checkedData,
      chartDataLegend,
      legend,
      action,
      selectedTemplate,
      horizontalBarChartData
    )
  }
}

const toggleAverage = (action, state) => {
  const horizontalBarChartData = [...state.horizontalBarChartData]
  const assessmentChartData = [...state.chartData]
  const chartDataIndex = [...state.chartDataIndex]
  const chartDataLegend = [...state.legendOptions]
  const checkedData = [...state.checkedData]
  const chartCfg = { ...state.mycfg }
  let showAverage = state.showAverage
  let showMaturity = state.showMaturity

  showAverage = !showAverage

  if (assessmentChartData.length > 1 && showAverage) {
    addAverage(assessmentChartData, horizontalBarChartData, chartDataLegend)
    if (showMaturity) {
      removeLast(assessmentChartData, chartDataLegend, horizontalBarChartData)

      showMaturity = false
    }
  }

  if (assessmentChartData.length > 1 && !showAverage) {
    removeLast(assessmentChartData, chartDataLegend, horizontalBarChartData)
  }

  return {
    ...state,
    barChartData: [],
    hiddenElements: [],
    chartData: assessmentChartData,
    chartDataIndex: chartDataIndex,
    legendOptions: chartDataLegend,
    checkedData: checkedData,
    mycfg: chartCfg,
    showAverage: showAverage,
    showMaturity: showMaturity,
    horizontalBarChartData: [...horizontalBarChartData]
  }
}

const toggleMaturity = (action, state) => {
  const horizontalBarChartData = [...state.horizontalBarChartData]
  const assessmentChartData = [...state.chartData]
  const chartDataIndex = [...state.chartDataIndex]
  const chartDataLegend = [...state.legendOptions]
  const checkedData = [...state.checkedData]
  const chartCfg = { ...state.mycfg }
  let showMaturity = state.showMaturity
  let showAverage = state.showAverage

  showMaturity = !showMaturity

  if (assessmentChartData.length > 1 && showMaturity) {
    addMaturity(
      assessmentChartData,
      horizontalBarChartData,
      chartDataLegend,
      action,
      state.assessmentMode
    )
    if (showAverage) {
      removeLast(assessmentChartData, chartDataLegend, horizontalBarChartData)
      showAverage = false
    }
  }

  if (assessmentChartData.length > 1 && !showMaturity) {
    removeLast(assessmentChartData, chartDataLegend, horizontalBarChartData)
  }

  return {
    ...state,
    barChartData: [],
    hiddenElements: [],
    chartData: assessmentChartData,
    chartDataIndex: chartDataIndex,
    legendOptions: chartDataLegend,
    checkedData: checkedData,
    mycfg: chartCfg,
    showAverage: showAverage,
    showMaturity: showMaturity,
    horizontalBarChartData: [...horizontalBarChartData]
  }
}

const clearDashboard = (action, state) => {
  return {
    ...state,
    barChartData: [],
    hiddenElements: [],
    chartData: [],
    chartDataIndex: [],
    legendOptions: [],
    checkedData: [],
    showAverage: false,
    showMaturity: false,
    horizontalBarChartData: []
  }
}

const toggleMode = (action, state) => {
  const assessmentMode = !state.assessmentMode
  const { horizontalBarChartData } = state
  const newChartData = state.chartData.map((radar, i) => {
    const newRadar = radar.map(objective => {
      let newValue
      if (!state.showAverage || !state.showMaturity) {
        newValue = assessmentMode
          ? objective.assessmentValue
          : objective.maturityValue
      } else {
        if (i < state.chartData.length - 1) {
          newValue = assessmentMode
            ? objective.assessmentValue
            : objective.maturityValue
        } else {
          newValue = objective.value
        }
      }
      return {
        ...objective,
        value: newValue
      }
    })
    horizontalBarChartData[i].value =
      getMaturityIndex(newRadar) / newRadar.length
    return newRadar
  })

  return {
    ...state,
    hiddenElements: [],
    chartData: newChartData,
    assessmentMode: assessmentMode
  }
}

const removeLast = (
  assessmentChartData,
  chartDataLegend,
  horizontalBarChartData
) => {
  assessmentChartData.pop()
  chartDataLegend.pop()
  horizontalBarChartData.pop()
}

const addAverage = (
  assessmentChartData,
  horizontalBarChartData,
  chartDataLegend
) => {
  const averageChart = assessmentChartData[0].map(item => {
    return {
      axis: item.axis,
      value: 0,
      answersPerLevel: item.answersPerLevel.map(item => {
        return {
          ...item,
          sum: 0,
          count: 0,
          countAllRespondents: 0
        }
      })
    }
  })

  assessmentChartData.forEach(obj => {
    obj.forEach((axis, i) => {
      averageChart[i].value = averageChart[i].value + parseFloat(axis.value)
      averageChart[i].areaId = axis.areaId
      averageChart[i].areaName = axis.areaName
      averageChart[i].areaColor = axis.areaColor
      const newAnswersPerLevel = axis.answersPerLevel.map((level, j) => {
        return {
          ...level,
          sum:
            averageChart[i].answersPerLevel?.length > 0
              ? averageChart[i].answersPerLevel[j].sum + parseInt(level.sum)
              : 0,
          count:
            averageChart[i].answersPerLevel?.length > 0
              ? averageChart[i].answersPerLevel[j].count + parseInt(level.count)
              : 0,
          countAllRespondents:
            averageChart[i].answersPerLevel?.length > 0
              ? !axis.user_id
                ? parseInt(
                    averageChart[i].answersPerLevel[j].countAllRespondents
                  ) + parseInt(level.countAllRespondents)
                : parseInt(
                    averageChart[i].answersPerLevel[j].countAllRespondents
                  ) + parseInt(level.count)
              : 0
        }
      })
      averageChart[i].answersPerLevel = [...newAnswersPerLevel]
    })
  })
  const finalAverage = averageChart.map(item => {
    return {
      ...item,
      value: item.value / assessmentChartData.length
    }
  })
  assessmentChartData.push(finalAverage)
  horizontalBarChartData.push({
    assessment: i18n.t('label.average') + ' -  ',
    value: getMaturityIndex(finalAverage) / finalAverage.length
  })
  chartDataLegend.push(i18n.t('label.average'))
}

const getMaturityIndex = chartData => {
  return chartData.reduce((prev, curr) => {
    return prev + curr.value
  }, 0)
}

const addMaturity = (
  assessmentChartData,
  horizontalBarChartData,
  chartDataLegend,
  action,
  mode
) => {
  const averageChart = assessmentChartData[0].map(item => {
    return {
      axis: item.axis,
      value: 0,
      answersPerLevel: item.answersPerLevel.map(item => {
        return {
          ...item,
          sum: 0,
          count: 0,
          countAllRespondents: 0
        }
      })
    }
  })

  assessmentChartData.forEach(obj => {
    obj.forEach((axis, i) => {
      averageChart[i].value = 0
      averageChart[i].areaId = axis.areaId
      averageChart[i].areaName = axis.areaName
      averageChart[i].areaColor = axis.areaColor
      const newAnswersPerLevel = axis.answersPerLevel.map((level, j) => {
        return {
          ...level,
          sum:
            averageChart[i].answersPerLevel?.length > 0
              ? averageChart[i].answersPerLevel[j].sum + parseInt(level.sum)
              : 0,
          count:
            averageChart[i].answersPerLevel?.length > 0
              ? averageChart[i].answersPerLevel[j].count + parseInt(level.count)
              : 0,
          countAllRespondents:
            averageChart[i].answersPerLevel?.length > 0
              ? !axis.user_id
                ? parseInt(
                    averageChart[i].answersPerLevel[j].countAllRespondents
                  ) + parseInt(level.countAllRespondents)
                : parseInt(
                    averageChart[i].answersPerLevel[j].countAllRespondents
                  ) + parseInt(level.count)
              : 0
        }
      })
      averageChart[i].answersPerLevel = [...newAnswersPerLevel]
    })
  })
  const maturityChartData = action.payload
  const finalAverage = averageChart.map(item => {
    const index =
      action.payload.length > 0
        ? maturityChartData.findIndex(obj => obj.axis === item.axis)
        : -1
    return {
      ...item,
      value:
        index > -1
          ? mode
            ? maturityChartData[index].assessmentValue
            : maturityChartData[index].maturityValue
          : 0
    }
  })
  assessmentChartData.push(finalAverage)
  horizontalBarChartData.push({
    assessment: i18n.t('label.maturity') + ' -  ',
    value: getMaturityIndex(finalAverage) / finalAverage.length
  })

  chartDataLegend.push(i18n.t('label.maturity'))
}

const getInitialReducerState = (action, state) => {
  const legend = action.payload.assessment.name
  const selectedTemplate = { ...state.selectedTemplate }
  const assessmentChartData = [...state.chartData]
  const chartDataIndex = [...state.chartDataIndex]
  const chartDataLegend = [...state.legendOptions]
  const checkedData = [...state.checkedData]
  const chartCfg = { ...state.mycfg }
  const exclude = action.payload.assessment.value
  const showAverage = state.showAverage
  const showMaturity = state.showMaturity
  const assessmentMode = state.assessmentMode
  const barChartData = state.barChartData
  const toggleMatrix = false
  const horizontalBarChartData = state.horizontalBarChartData
  barChartData.columns = state.barChartData.columns
  return {
    exclude,
    selectedTemplate,
    assessmentChartData,
    chartDataIndex,
    chartDataLegend,
    checkedData,
    legend,
    chartCfg,
    showAverage,
    showMaturity,
    assessmentMode,
    barChartData,
    horizontalBarChartData,
    toggleMatrix
  }
}

const addHorizontalBarChartData = (horizontalBarChartData, action) => {
  horizontalBarChartData.push({
    assessment: action.payload.assessment.name,
    value: action.payload.assessment.maturityIndex
  })
}

const removeHorizontalBarChartData = (action, horizontalBarChartData) => {
  const index = horizontalBarChartData.findIndex(
    item => item.assessment === action.payload.assessment.name
  )
  if (index > -1) {
    horizontalBarChartData.splice(index, 1)
  }
}

const addBarChartData = (action, state) => {
  const barChartData = []
  barChartData.columns = ['level']
  const { row } = action.payload
  barChartData.objectiveName = row.length > 0 ? row[0].value : ''

  row.forEach(column => {
    if (column.answersPerLevel?.length > 0) {
      const chosenId = column.user_id ? column.user_id : column.objectiveId
      column.answersPerLevel.forEach(level => {
        const chosenCount = column.user_id
          ? level.count
          : level.countAllRespondents
        const index = barChartData.findIndex(item => item.level === level.name)
        if (index === -1) {
          const newLevel = { level: level.name }
          newLevel['_' + chosenId] = [
            (level.sum / chosenCount) * 100,
            level.sum,
            chosenCount
          ]
          barChartData.push(newLevel)
        } else {
          const newLevel = barChartData[index]
          newLevel[`_${chosenId}`] = [
            (level.sum / chosenCount) * 100,
            level.sum,
            chosenCount
          ]
          barChartData[index] = newLevel
        }
      })
      if (column.answersPerLevel && !isEmpty(column.answersPerLevel)) {
        barChartData.columns.push(`_${chosenId}`)
      }
    }
  })
  return {
    ...state,
    barChartData
  }
}

const addRadar = (
  assessmentChartData,
  chartDataIndex,
  checkedData,
  chartDataLegend,
  legend,
  action,
  selectedTemplate,
  horizontalBarChartData
) => {
  if (
    isEmpty(assessmentChartData) ||
    action.payload.assessment.chartData.length === assessmentChartData[0].length
  ) {
    assessmentChartData.push(action.payload.assessment.chartData)
    chartDataIndex.push([
      {
        name: action.payload.assessment.name,
        id: action.payload.assessment.id,
        template_id: action.payload.assessment.template_id
      },
      action.payload.assessment.chartData,
      action.payload.assessment.assessmentPack
    ])
    if (checkedData.indexOf(action.payload.assessment.name) === -1) {
      checkedData.push(action.payload.assessment.name)
    }
    if (chartDataLegend.indexOf(legend) === -1) {
      chartDataLegend.push(legend)
    }
    if (assessmentChartData.length === 1) {
      selectedTemplate.maturityModel = action.payload.assessment.maturityModel
      selectedTemplate.templateId = action.payload.assessment.template_id
      selectedTemplate.id = action.payload.assessment.id
    }
    addHorizontalBarChartData(horizontalBarChartData, action)
  }
}

const removeRadar = (
  assessmentChartData,
  chartDataIndex,
  chartDataLegend,
  checkedData,
  action,
  selectedTemplate,
  horizontalBarChartData
) => {
  chartDataIndex.forEach((index, i) => {
    if (index[0].name === action.payload.assessment.name) {
      assessmentChartData.splice(i, 1)
      chartDataIndex.splice(i, 1)
      checkedData.splice(i, 1)
      chartDataLegend.splice(i, 1)
      if (assessmentChartData.length === 0) {
        selectedTemplate.maturityModel = []
        selectedTemplate.templateId = null
        selectedTemplate.id = null
      }
    }
  })

  removeHorizontalBarChartData(action, horizontalBarChartData)
}

export default dashboardReducer
