const initialState = {
  name: '',
  billingEmail: '',
  contactName: '',
  phoneNumber: null,
  suspended: false
}

const organizationReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    default:
      return state
  }
}

export default organizationReducer
