import isEmpty from 'lodash/isEmpty'

export default function validateTemplateSelect(data) {
  const errors = {}
  if (!data.templateId || data.templateId === null) {
    errors.templateId = 'TemplateId is required'
  }
  if (!data.shortName || data.shortName === null) {
    errors.shortName = 'Short name date is required'
  }
  if (!data.shortDescription || data.shortDescription === null) {
    errors.shortDescription = 'Short description is required'
  }
  if (!data.startDate || data.startDate === null) {
    errors.startDate = 'Start date is required'
  }
  if (!data.endDate || data.endDate === null) {
    errors.endDate = 'End date is required'
  }
  if (!data.openFormDate || data.openFormDate === null) {
    errors.openFormDate = 'Open form date is required'
  }
  if (!data.closeFormDate || data.closeFormDate === null) {
    errors.closeFormDate = 'Close form date is required'
  }
  return {
    errors,
    isValid: isEmpty(errors)
  }
}
