import ObjectivesList from 'components/Objective/ObjectiveList/ObjectivesList'
import { React, useState } from 'react'
import ObjectiveRecomendations from '../ObjectiveRecomendations/ObjectiveRecomendations'

import './RecomendationForm.scss'

const RecomendationForm = () => {
  const [selectedObjective, setSelectedObjective] = useState(null)

  const handleSetSelectedObjective = objective => {
    setSelectedObjective(objective)
    const element = document.getElementById(
      `ObjectivesList-objective-${objective.id}`
    )
    element.classList.add('selected')
  }

  return (
    <div className='RecomendationForm z-depth-1 animated fadeInDown'>
      <div className='RecomendationForm-leftPanel'>
        <ObjectivesList
          selectedObjective={selectedObjective}
          setSelectedObjective={handleSetSelectedObjective}
        />
      </div>
      <div className='RecomendationForm-rightPanel'>
        <ObjectiveRecomendations selectedObjective={selectedObjective} />
      </div>
    </div>
  )
}

export default RecomendationForm
