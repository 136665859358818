import React from 'react'
import { useSelector } from 'react-redux'

import { getLoggedInUser } from 'auth'

import './AnswerFormWelcomePage.scss'

const AnswerFormWelcomePage = () => {
  const assessment = useSelector(state => state.answerForm.assessment)
  const user = getLoggedInUser()

  return (
    <div className='AnswerFormWelcomePage'>
      <h2>{`Hello ${user.firstName}, welcome!`}</h2>
      <p>{assessment.description}</p>
    </div>
  )
}

export default AnswerFormWelcomePage
