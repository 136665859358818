import React from 'react'
import { useDispatch } from 'react-redux'
import './TenantTemplate.scss'
import { clearObjective, setObjectives } from 'redux/actions/objectivesAction'
import { clearAreas, setAreas } from 'redux/actions/areasAction'
import { Confirm } from 'notiflix'

const TenantTemplate = ({
  templates,
  setSelectedTemplate,
  selectedTemplate
}) => {
  const dispatch = useDispatch()

  Confirm.Init({
    nameColor: 'rgba(58, 157, 184, 1)',
    okButtonBackground: '#d4a302',
    backgroundColor: 'rgb(40,40,60)',
    borderRadius: '5px',
    messageColor: 'white',
    zindex: 9999,
    width: '400px'
  })

  const renderTemplate = template => {
    return (
      <button
        id={`template-${template.id}`}
        key={template.id}
        className='TenantTemplate-template z-depth-1'
        onClick={async () => {
          setSelectedTemplate(template)
          await clearObjective(dispatch)
          await clearAreas(dispatch)
          await setAreas(template.id, dispatch)
          await setObjectives(template.id, dispatch)
        }}
      >
        <h1 className='TenantTemplate-template-name animated fadeInLeft'>
          {`${template.name}`}
        </h1>
        <div>
          {!!template.isDeployed && (
            <span className='deployed-tag'> Deployed </span>
          )}
        </div>
      </button>
    )
  }

  return (
    <div className='TenantTemplate-container z-depth-1 animated fadeInDown'>
      <div className='TenantTemplate-content '>
        {templates.map(renderTemplate)}
      </div>
    </div>
  )
}

export default TenantTemplate
