import React, { useEffect, useState } from 'react'
import { getLoggedInUser } from 'auth'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getOrganizationById } from 'redux/actions/organizationActions'

import { Confirm } from 'notiflix'

import {
  fetchTreeData,
  getUsersByOrganizationId,
  toggleMaturityModelForm,
  getRootNodeIdByOrganizationId
} from 'redux/actions/treeActions'

import { firstBy } from 'thenby'

import './TenantTemplateManagementPage.scss'
import '../../../styles/loader.scss'
import Modal from 'components/Modal/Modal'
import {
  Archive,
  ListAlt,
  Public,
  Search,
  Visibility
} from '@material-ui/icons'
import EditIcon from '@material-ui/icons/Edit'
import CachedIcon from '@mui/icons-material/Cached'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { clearRadar, renderRadar } from 'components/RadarChart/RadarChart'
import { isEmpty } from 'lodash'
import {
  archiveAssessment,
  duplicateTemplate,
  getPublicTemplates,
  getSubmittedPublicTemplates,
  getTemplatesByOrganizationId,
  initializeAssessment,
  setAssessment,
  updateAssessment
} from 'redux/actions/assessmentActions'
import TenantTemplate from 'components/TenantTemplateManagement/TenantTemplate'
import { AssessmentContent } from 'components'
import MaturityMatrix from 'components/MaturityMatrix/MaturityMatrix'
import { clearAreas, setAreas } from 'redux/actions/areasAction'
import {
  clearObjective,
  initializeObjectives,
  setObjectives
} from 'redux/actions/objectivesAction'
import {
  initializeMaturityLevels,
  setMaturityLevels
} from 'redux/actions/maturityLevelActions'
import {
  clearDashboard,
  toggleMaturityMatrix
} from 'redux/actions/dashboardActions'
import { ContentCopy } from '@mui/icons-material'
import { initializeObjectiveMaturities } from 'redux/actions/objectiveMaturityActions'
import { initializeQuestion } from 'redux/actions/questionsAction'

const TenantUserManagement = () => {
  const [t] = useTranslation()
  const dispatch = useDispatch()

  const { dashboard, template, objective, area } = useSelector(state => state)
  const { chartData, mycfg } = objective
  const { toggleMatrix } = dashboard
  const { areas } = area

  const {
    templates,
    publicTemplates,
    submittedPublicTemplates,
    toggledMaturityModelForm
  } = template
  const [currentOrganization, setCurrentOrganization] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [search, setSearch] = useState('')
  const [localChartData, setLocalChartData] = useState(chartData)
  const [filteredTemplates, setFilteredTemplates] = useState([])
  const [filteredPublicTemplates, setFilteredPublicTemplates] = useState([])
  const [selectedTemplate, setSelectedTemplate] = useState({})
  const [masterMode, setMasterMode] = useState(false)

  const user = getLoggedInUser()
  const organizationId = user.organization_id

  Confirm.Init({
    titleColor: 'rgba(58, 157, 184, 1)',
    okButtonBackground: 'rgba(172, 2, 2, 1)',
    backgroundColor: 'rgb(40,40,60)',
    borderRadius: '5px',
    messageColor: 'white',
    zindex: 9999,
    width: '400px',
    distance: '10px',
    titleMaxLength: 50,
    messageMaxLength: 600
  })

  useEffect(() => {
    setLocalChartData(chartData)
  }, [chartData])

  useEffect(() => {
    if (
      !isEmpty(selectedTemplate) &&
      !isEmpty(selectedTemplate.areas) &&
      !isEmpty(selectedTemplate.objectives)
    ) {
      mycfg.levels = selectedTemplate.maturityLevels.length
      mycfg.maxValue = selectedTemplate.maturityLevels.length
      mycfg.maturityModel = selectedTemplate.maturityLevels
      mycfg.maturityModel = mycfg.maturityModel.map(item => {
        return item.name
      })
      const currentChartData = localChartData
        .map(item => {
          item[1].areaColor = null

          areas.forEach(element => {
            if (element.id === item[1].areaId) {
              item[1].areaColor = element.color
              item[1].value = selectedTemplate.maturityLevels.length
            }
          })
          return item[1]
        })
        .sort(firstBy('area_id').thenBy('shortName'))
      if (!isEmpty(currentChartData)) {
        renderRadar(
          [currentChartData],
          mycfg,
          [selectedTemplate.name],
          '#templateManagementChartBuilder',
          () => {},
          false,
          false,
          () => {}
        )
      }
    } else {
      clearRadar('#templateManagementChartBuilder')
    }
  }, [selectedTemplate, localChartData])

  useEffect(() => {
    const fetchData = async () => {
      await getTemplatesByOrganizationId(organizationId, dispatch)
      await getPublicTemplates(dispatch)
      await fetchTreeData(organizationId, user.id, dispatch)
    }
    fetchData()
  }, [organizationId, dispatch])

  useEffect(() => {
    const fetchData = async () => {
      await getSubmittedPublicTemplates(dispatch)
    }
    fetchData()
  }, [masterMode])

  useEffect(() => {
    const fetchData = async () => {
      if (currentOrganization === null) {
        await getUsersByOrganizationId(user.organization_id, dispatch)
        const { organization } = await getOrganizationById(user.organization_id)
        setCurrentOrganization(organization)
      }
    }
    setIsLoading(true)
    fetchData()
    setIsLoading(false)
  }, [user, dispatch])

  useEffect(() => {
    setFilteredTemplates(
      templates.filter(
        t =>
          t.name.toLowerCase().includes(search.toLowerCase().trim()) ||
          t.description.toLowerCase().includes(search.toLowerCase().trim())
      )
    )
    setFilteredPublicTemplates(
      publicTemplates?.filter(
        t =>
          t.name.toLowerCase().includes(search.toLowerCase().trim()) ||
          t.description.toLowerCase().includes(search.toLowerCase().trim())
      )
    )
  }, [search, templates, publicTemplates])

  const initializeAll = async () => {
    await initializeAssessment(dispatch)
    await initializeMaturityLevels(dispatch)
    await clearAreas(dispatch)
    await initializeObjectives(dispatch)
    await initializeObjectiveMaturities(dispatch)
    await initializeQuestion(dispatch)
  }

  const handlePublishAssessment = async (assessment, factor) => {
    Confirm.Show(
      t('tenant_template_management.confirm.title'),
      t('tenant_template_management.confirm.publish'),
      t('tenant_template_management.confirm.publish.yes'),
      t('tenant_template_management.confirm.no'),
      async () => {
        try {
          await updateAssessment(
            'isPublicTemplate',
            factor,
            assessment,
            dispatch
          )
          // if (assessment.isDeployed === 0) await updateAssessment('isDeployed', 1, assessment, dispatch)
          await getTemplatesByOrganizationId(organizationId, dispatch)
          await getPublicTemplates(dispatch)
        } catch (e) {
          console.error(e)
          window.toastr.error(e?.response?.data?.error)
        }
      }
    )
  }

  const handleDuplicateTemplate = async assessment => {
    Confirm.Show(
      t('node.deployed.confirm.title'),
      t('node.deployed.confirm.description'),
      t('node.deployed.confirm.yes'),
      t('node.deployed.confirm.no'),
      async () => {
        try {
          await duplicateTemplate(assessment, dispatch)
          await getTemplatesByOrganizationId(organizationId, dispatch)
          setSelectedTemplate({})
        } catch (e) {
          console.error(e)
          window.toastr.error(e?.response?.data?.error)
        }
      }
    )
  }
  const handleArchiveAssessment = assessmentId => {
    Confirm.Show(
      t('tenant_template_management.confirm.title'),
      t('tenant_template_management.confirm.archive'),
      t('tenant_template_management.confirm.archive.yes'),
      t('tenant_template_management.confirm.no'),
      async () => {
        setIsLoading(true)
        try {
          await archiveAssessment(
            assessmentId,
            organizationId,
            user.id,
            dispatch
          )
          await getTemplatesByOrganizationId(organizationId, dispatch)
          setSelectedTemplate({})
        } catch (e) {
          window.toastr.error(e.response.data.error)
          console.error(e)
        }
        setIsLoading(false)
      }
    )
  }

  const editMaturityModel = async (e, assessment) => {
    e.stopPropagation()
    clearObjective(dispatch)
    clearDashboard(organizationId, user.id, dispatch)
    await setAssessment(assessment, dispatch)
    await setMaturityLevels(assessment.maturityLevels, dispatch)
    await setAreas(assessment.id, dispatch)
    await setObjectives(assessment.id, dispatch)
    await toggleMaturityModelForm(dispatch)
  }

  const handleSetSelectedTemplate = selectedTemplate => {
    setSelectedTemplate(selectedTemplate)
  }
  const onChange = e => {
    setSearch(e.target.value)
  }

  const renderPageBody = () => (
    <div className='TenantTemplateManagementPage-container'>
      <div className='TenantTemplateManagementPage-leftPanel'>
        <div className='TenantTemplateManagementPage-input-container '>
          <div className='input-icon'>
            <Search />
          </div>
          <input
            autoFocus
            type='text'
            maxLength={35}
            title='search'
            id='search'
            placeholder={t('label.search')}
            value={search}
            onChange={onChange}
            className='TenantTemplateManagementPage-input w-100'
          />
          {search.length > 0 && (
            <small className='blink-me'>
              <span className='foundTitle '>
                {filteredTemplates.length}&nbsp;
                {t('persona_manage.found')}
              </span>
            </small>
          )}
        </div>
        <div className='TenantTemplateManagementPage-panels'>
          <div className='TenantTemplateManagementPage-panel'>
            <div className='TenantUserManagementPage-panel-header'>
              <p>{t('select_templates.options.group.private_template')}</p>

              <AddCircleOutlineIcon
                title='New Template'
                className='master-icon'
                onClick={async e => {
                  e.stopPropagation()
                  initializeAll()
                  clearObjective(dispatch)
                  clearDashboard(organizationId, user.id, dispatch)
                  const rootNodeId = await getRootNodeIdByOrganizationId(
                    organizationId
                  )
                  await toggleMaturityModelForm(dispatch, rootNodeId)
                }}
              />
            </div>
            <TenantTemplate
              templates={filteredTemplates}
              setSelectedTemplate={handleSetSelectedTemplate}
              selectedTemplate={selectedTemplate}
            />
          </div>
          <div className='TenantTemplateManagementPage-panel'>
            <div className='TenantUserManagementPage-panel-header'>
              {!masterMode ? (
                <p>{t('select_templates.options.group.public_template')}</p>
              ) : (
                <div> Submitted templates:</div>
              )}
              {user.role === 'master' && (
                <CachedIcon
                  className='master-icon'
                  onClick={() => {
                    setMasterMode(!masterMode)
                  }}
                />
              )}
            </div>
            {!masterMode ? (
              <TenantTemplate
                templates={filteredPublicTemplates}
                setSelectedTemplate={handleSetSelectedTemplate}
                selectedTemplate={selectedTemplate}
              />
            ) : (
              <TenantTemplate
                templates={submittedPublicTemplates || []}
                setSelectedTemplate={handleSetSelectedTemplate}
                selectedTemplate={selectedTemplate}
              />
            )}
          </div>
        </div>
      </div>
      <div className='TenantTemplateManagementPage-rightPanel'>
        {!isEmpty(selectedTemplate) && (
          <div className='TenantTemplateManagementPage-radar-actions'>
            <div className='radar-icons'>
              {selectedTemplate.isDeployed ? (
                <>
                  <div className='radar-icon-title-wrapper'>
                    <Visibility
                      className='radar-icon'
                      onClick={e => {
                        e.stopPropagation()
                        editMaturityModel(e, selectedTemplate)
                      }}
                    />
                    <span className='radar-icon-title'>
                      {t('tenant_template_management.visualize')}
                    </span>
                  </div>
                  {(user.role === 'master' ||
                    selectedTemplate.isPublicTemplate === 0) && (
                    <div className='radar-icon-title-wrapper'>
                      <Public
                        className='radar-icon'
                        onClick={e => {
                          e.stopPropagation()
                          handlePublishAssessment(selectedTemplate, 1)
                        }}
                      />
                      <span className='radar-icon-title'>
                        {t('tenant_template_management.publish')}
                      </span>
                    </div>
                  )}

                  <div className='radar-icon-title-wrapper'>
                    <ListAlt
                      className='radar-icon'
                      onClick={() => {
                        if (!toggleMatrix) {
                          toggleMaturityMatrix(
                            {
                              assessmentId: selectedTemplate.id,
                              toggleMatrix: !toggleMatrix
                            },
                            dispatch
                          )
                        }
                      }}
                    />
                    <span className='radar-icon-title'>
                      {t('tenant_template_management.open_matrix')}
                    </span>
                  </div>

                  <div className='radar-icon-title-wrapper'>
                    <ContentCopy
                      className='radar-icon'
                      onClick={e => {
                        e.stopPropagation()
                        handleDuplicateTemplate(selectedTemplate)
                      }}
                    />
                    <span className='radar-icon-title'>
                      {t('tenant_template_management.duplicate')}
                    </span>
                  </div>
                </>
              ) : (
                <div className='radar-icon-title-wrapper'>
                  <EditIcon
                    className='radar-icon'
                    onClick={e => {
                      e.stopPropagation()
                      editMaturityModel(e, selectedTemplate)
                    }}
                  />
                  <span className='radar-icon-title'>
                    {t('tenant_template_management.edit')}
                  </span>
                </div>
              )}
              {(user.role === 'master' ||
                selectedTemplate.isPublicTemplate !== 2) && (
                <div className='radar-icon-title-wrapper'>
                  <Archive
                    className='radar-icon'
                    onClick={e => {
                      e.stopPropagation()
                      handleArchiveAssessment(selectedTemplate.id)
                    }}
                  />
                  <span className='radar-icon-title'>
                    {t('tenant_template_management.archive')}
                  </span>
                </div>
              )}
            </div>

            {selectedTemplate.isPublicTemplate === 1 && (
              <div className='submitted-tag'>
                <Public />
                {t('submitted')}
              </div>
            )}
            {user.role === 'master' && (
              <div className='radar-icons'>
                <div className='radar-icon-title-wrapper'>
                  <Public
                    className='radar-icon'
                    onClick={e => {
                      e.stopPropagation()
                      handlePublishAssessment(selectedTemplate, 2)
                    }}
                  />
                  <span className='radar-icon-title'>
                    publish submitted template
                  </span>
                </div>
                <div className='radar-icon-title-wrapper'>
                  <RemoveCircleOutlineIcon
                    className='radar-icon'
                    onClick={e => {
                      e.stopPropagation()
                      handlePublishAssessment(selectedTemplate, 0)
                    }}
                  />
                  <span className='radar-icon-title'>
                    remove template from public
                  </span>
                </div>
              </div>
            )}
          </div>
        )}
        <div className='TenantTemplateManagementPage-radar-chart'>
          <div
            name='templateManagementChartBuilder'
            id='templateManagementChartBuilder'
          />
        </div>
      </div>
    </div>
  )

  return (
    <div className='TenantUserManagementPage'>
      <Modal open={toggledMaturityModelForm}>
        <AssessmentContent />
      </Modal>
      <Modal w={1500} open={toggleMatrix}>
        <MaturityMatrix />
      </Modal>
      <div className='TenantUserManagementPage-container z-depth-4'>
        <div className='TenantUserManagementPage-header'>
          <div className='TenantUserManagementPage-header-content'>
            <p>{t('tenant_template_management.title')}</p>
            <div>
              <span className='mt-1 title'>
                {t('tenant_user_management.header')}
              </span>
              <span className='mt-1'>{currentOrganization?.name}</span>
            </div>
          </div>
          {/* <div className='TenantUserManagementPage-header-help'>
            <div className='help-message-wrapper'>
              <HelpIcon className='help-icon' />
              <span className='help-message'>
                This is the template management page, you can do this and that
                and this and that and alsox this and not forggetting that
              </span>
            </div>
          </div> */}
        </div>

        <div className='TenantTemplateManagementPage-body'>
          {isLoading && (
            <div className='d-flex flex-column justify-content-center align-items-center v100'>
              <div className='loader1' />
            </div>
          )}
          {!isLoading && renderPageBody()}
        </div>
        {/* <div className='TenantUserManagementPage-footer'>a</div> */}
      </div>
    </div>
  )
}

export default TenantUserManagement
