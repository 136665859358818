import {
  CREATE_QUESTION,
  UPDATE_QUESTION,
  DELETE_QUESTION,
  SELECT_QUESTION,
  ADD_CHOICE,
  UPDATE_CHOICE,
  UPDATE_SELECTED_QUESTION_OBJECTIVE,
  NEW_QUESTION,
  DELETE_CHOICE,
  SET_QUESTIONS,
  INITIALIZE_QUESTION
} from '../actionTypes'

const initialState = {
  selectedQuestion: {
    id: null,
    objective_id: null,
    title: '',
    description: '',
    choices: []
  },
  questions: [],
  selectedObjective: { id: null, name: '' }
}

const questionsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case UPDATE_QUESTION:
      return updateQuestion(action, state)
    case CREATE_QUESTION:
      return createQuestion(state, action)
    case DELETE_QUESTION:
      return deleteQuestion(state, action)
    case NEW_QUESTION:
      return newQuestion(state)
    case SELECT_QUESTION:
      return selectQuestion(state, action)
    case UPDATE_SELECTED_QUESTION_OBJECTIVE:
      return updateSelectedQuestionObjective(state, action)
    case ADD_CHOICE:
      return addChoice(state, action)
    case UPDATE_CHOICE:
      return updateChoice(action, state)
    case DELETE_CHOICE:
      return deleteChoice(action, state)
    case SET_QUESTIONS:
      return setQuestions(action, state)
    case INITIALIZE_QUESTION:
      return { ...initialState }
    default:
      return state
  }
}

const setQuestions = (action, state) => {
  const questions = []
  action.payload.forEach(objective => {
    questions.push(...objective.questions)
  })

  return { ...state, questions }
}

function deleteQuestion(state, action) {
  return {
    ...state,
    questions: state.questions.filter(question => {
      return question.id !== action.id
    })
  }
}

function updateSelectedQuestionObjective(state, action) {
  return {
    ...state,
    selectedObjective: action.payload
  }
}

function selectQuestion(state, action) {
  const { questions } = state
  const selectedQuestion = questions.find(q => q.id === action.payload)
  const newState = {
    ...state,
    selectedQuestion
  }
  return newState
}

function newQuestion(state) {
  const newState = {
    ...state,
    selectedQuestion: { ...initialState.selectedQuestion }
  }
  newState.selectedQuestion.choices = []
  return newState
}

function createQuestion(state, action) {
  const { questions } = state
  questions.push(action.payload)
  return {
    ...state,
    questions,
    selectedQuestion: { ...state.selectedQuestion, ...action.payload }
  }
}

function updateQuestion(action, state) {
  const { payload } = action
  const { selectedQuestion, questions } = state
  const { choices } = selectedQuestion
  const updatedQuestions = questions.map(q =>
    q.id === payload.id ? { ...payload, choices } : q
  )

  return {
    ...state,
    questions: updatedQuestions,
    selectedQuestion: { ...payload, choices }
  }
}

function addChoice(state, action) {
  const { choices } = state.selectedQuestion
  choices.push(action.payload)
  const selectedQuestion = { choices, ...state.selectedQuestion }

  const questions = state.questions
  const questionIndex = questions.findIndex(
    q => q.id === action.payload.question_id
  )

  if (questionIndex > -1) {
    questions[questionIndex].choices = [...choices]
  }
  return {
    selectedQuestion,
    questions,
    ...state
  }
}

function updateChoice(action, state) {
  const { payload } = action
  const { questions } = state
  const [question] = questions.filter(q => q.id === payload.question_id)
  const updatedChoices = question.choices.map(c =>
    parseInt(c.id) === parseInt(payload.id) ? payload : c
  )
  question.choices = [...updatedChoices]
  const updatedQuestion = questions.map(q =>
    q.id === question.id ? question : q
  )
  const [selectedQuestion] = updatedQuestion.filter(
    q => q.id === payload.question_id
  )

  return {
    ...state,
    questions: updatedQuestion,
    selectedQuestion
  }
}

function deleteChoice(action, state) {
  const { payload } = action
  const { questions } = state
  const [question] = questions.filter(q => q.id === payload.question_id)
  const updatedChoices = question.choices.filter(c => c.id !== payload.id)
  question.choices = [...updatedChoices]
  const updatedQuestion = questions.map(q =>
    q.id === question.id ? question : q
  )
  const [selectedQuestion] = updatedQuestion.filter(
    q => q.id === payload.question_id
  )

  return {
    ...state,
    questions: updatedQuestion,
    selectedQuestion
  }
}

export default questionsReducer
