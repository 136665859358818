import { api } from './axios'

export const getOrganizationById = async organizationId => {
  const { data } = await api.get(`/organizations/${organizationId}`)
  return data
}
export const getOrganizationByName = async organizationName => {
  const { data } = await api.get(`/organizations/${organizationName}`)
  return data
}

export const createOrganization = async organization => {
  const { data } = await api.post('/organizations', organization)
  return data
}

export const getOrganizations = async suspended => {
  const { data } = await api.get(`/organizations/retrieve/${suspended}`)
  return data
}

export const createOrganizationForAssistedSignup = async organizationData => {
  const { data } = await api.post('/organizations/assisted', organizationData)
  return data.data
}

export const getFormLinkForAssistedSignup = async organizationData => {
  const { data } = await api.post(
    '/organizations/assistedFormLink',
    organizationData
  )
  return data.data
}
