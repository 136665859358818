import { api } from './axios'
import {
  CREATE_RECOMMENDATION,
  UPDATE_RECOMMENDATION,
  GET_RECOMMENDATIONS_BY_ORGANIZATION_ID
} from '../actionTypes'

export const createRecommendation = async (recommendation, dispatch) => {
  try {
    const { data } = await api.post('/recommendations', recommendation)
    dispatch({
      type: CREATE_RECOMMENDATION,
      payload: data
    })
  } catch (e) {
    return e
  }
}

export const createObjectiveRecommendation = async objectiveRecommendation => {
  try {
    await api.post(
      '/recommendations/objectiveRecommendations',
      objectiveRecommendation
    )
  } catch (e) {
    return e
  }
}

export const deleteObjectiveRecommendation =
  async objectiveRecommendationData => {
    try {
      await api.post(
        '/recommendations/objectiveRecommendations/delete',
        objectiveRecommendationData
      )
    } catch (e) {
      return e
    }
  }

export const updateRecommendation = async (id, recommendation, dispatch) => {
  try {
    if (!id) return
    const { data } = await api.put(`/recommendations/${id}`, recommendation)
    dispatch({
      type: UPDATE_RECOMMENDATION,
      payload: data
    })
  } catch (e) {
    return e
  }
}

export const getRecommendationsByOrganizationId = async (
  organizationId,
  dispatch
) => {
  try {
    const { data } = await api.get(
      `/recommendations/organization/${organizationId}`
    )

    dispatch({
      type: GET_RECOMMENDATIONS_BY_ORGANIZATION_ID,
      payload: data
    })
    return data
  } catch (error) {
    return error
  }
}

export const getRecommendationsByObjectiveId = async objectiveId => {
  try {
    const { data } = await api.get(`/recommendations/objective/${objectiveId}`)
    return data
  } catch (error) {
    return error
  }
}
