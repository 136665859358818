import {
  CREATE_ASSESSMENT,
  UPDATE_ASSESSMENT,
  GET_ASSESSMENT,
  SET_ASSESSMENT,
  GET_MATURITY_LEVELS_BY_ASSESSMENT_ID,
  GET_ASSESSMENT_COMPLETION,
  INITIALIZE_ASSESSMENT
} from '../actionTypes'

const initialState = {
  id: null,
  node_id: null,
  template_id: null,
  name: '',
  description: '',
  startDate: null,
  endDate: null,
  openFormDate: null,
  closeFormDate: null,
  step: 1,
  isArchived: 0,
  isPublicTemplate: 0,
  templateForOrganization_id: null,
  completed: 0
}

const assessmentReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case CREATE_ASSESSMENT:
      return { ...state, ...action.payload }
    case UPDATE_ASSESSMENT:
      return { ...state, ...action.payload }
    case GET_ASSESSMENT:
      return { ...state, ...action.payload }
    case SET_ASSESSMENT:
      return { ...state, ...action.payload }
    case INITIALIZE_ASSESSMENT:
      return { ...initialState }
    case GET_MATURITY_LEVELS_BY_ASSESSMENT_ID:
      return { ...state, ...action.payload }
    case GET_ASSESSMENT_COMPLETION:
      return { ...state, completed: action.payload.data }
    default:
      return state
  }
}

export default assessmentReducer
