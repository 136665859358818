import React from 'react'
import classnames from 'classnames'

import './Button.scss'

const Button = ({ title, onClick, isPrimary = false }) => {
  const classes = classnames('Button', {
    'Button--primary': isPrimary
  })

  return (
    <button className={classes} onClick={onClick}>
      {title}
    </button>
  )
}

export default Button
