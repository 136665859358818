import { api } from './axios'
import {
  UPDATE_MATURITY_LEVEL_ROWS,
  HANDLE_ROWS_CHANGE,
  CREATE_MATURITY_LEVEL,
  UPDATE_MATURITY_LEVEL,
  GET_MATURITY_LEVELS,
  SET_MATURITY_LEVELS,
  INITIALIZE_MATURITY_LEVELS
} from 'redux/actionTypes'

export const getMaturityLevelsByAssessmentId = async (
  assessmentId,
  dispatch
) => {
  try {
    const { data } = await api.get(`/maturitylevels/${assessmentId}/assessment`)
    dispatch({
      type: GET_MATURITY_LEVELS,
      payload: data
    })
  } catch (e) {
    const err = e.response
    const error = {
      status: err.status,
      errorMsg: err.data?.errors?.objectiveMaturityError
    }
    return error
  }
}

export const setMaturityLevels = async (maturityLevels, dispatch) => {
  dispatch({
    type: SET_MATURITY_LEVELS,
    payload: maturityLevels
  })
}

export const initializeMaturityLevels = async dispatch => {
  dispatch({
    type: INITIALIZE_MATURITY_LEVELS,
    payload: null
  })
}

export const createMaturityLevel = async (maturityLevel, dispatch) => {
  try {
    const { data } = await api.post('/maturitylevels', maturityLevel)
    dispatch({
      type: CREATE_MATURITY_LEVEL,
      payload: data
    })
  } catch (e) {
    const err = e.response
    const error = {
      status: err.status,
      errorMsg: err.data?.errors?.objectiveMaturityError
    }
    return error
  }
}

export const updateMaturityLevel = async (maturityLevel, dispatch) => {
  try {
    const { data } = await api.put(
      `/maturitylevels/${maturityLevel.id}`,
      maturityLevel
    )
    dispatch({
      type: UPDATE_MATURITY_LEVEL,
      payload: data
    })
  } catch (e) {
    const err = e.response
    const error = {
      status: err.status,
      errorMsg: err.data?.errors?.objectiveMaturityError
    }
    return error
  }
}

export const updateRow = (position, name, value, rows, dispatch) => {
  const newRows = rows.map(row => {
    if (row.position === position) {
      return { ...row, [name]: value }
    }
    return row
  })
  dispatch({
    type: UPDATE_MATURITY_LEVEL_ROWS,
    payload: { rows: newRows }
  })
}

export const handleRowsChange = async (value, rows, dispatch) => {
  try {
    const r = await changeRows(rows, value)
    dispatch({
      type: HANDLE_ROWS_CHANGE,
      payload: { rows: r, numberOfEnabledRows: value }
    })
    return r
  } catch (e) {
    return e
  }
}

const changeRows = async (rows, value) => {
  const newRows = rows.map(async row => {
    if (row.position - 1 < value) {
      return {
        ...row,
        enabled: true
      }
    }
    if (row.id != null) {
      try {
        await api.delete(`/maturitylevels/${row.id}`)
        return {
          id: null,
          assessment_id: null,
          name: '',
          description: '',
          position: row.position,
          enabled: false
        }
      } catch (e) {
        const err = e.response
        const error = {
          status: err.status,
          errorMsg: err.data?.errors?.objectiveMaturityError
        }
        return error
      }
    }
    return row
  })
  return await Promise.all(newRows)
}
