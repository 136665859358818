import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { CountBox, QuestionProgressBar } from 'components'

import './QuestionMaturity.scss'

const QuestionMaturity = ({ choicesArray, percentage, setPercentage }) => {
  const [t] = useTranslation()

  const { questions, selectedObjective } = useSelector(state => state.question)
  const { objectives } = useSelector(state => state.objective)
  const { rows } = useSelector(state => state.maturityLevel)
  const maturities = rows.filter(maturity => maturity.id)

  const RenderQuestionsMaturity = maturity => {
    const numberOfChoices = choicesArray.filter(
      choice =>
        choice.maturity_id === maturity.id &&
        choice.objective_id === selectedObjective.id
    ).length

    return (
      <div key={maturity.position} className='QuestionMaturityRow'>
        <CountBox
          style={{ marginLeft: '0px !important' }}
          text={maturity.position}
        />
        <div className='QuestionMaturityRow-name'>
          <p>{maturity.name}</p>
          {questions && (
            <span className='ml-auto QuestionMaturityRow-number-of-questions'>
              {numberOfChoices}
            </span>
          )}
        </div>
      </div>
    )
  }

  return (
    <div className='QuestionMaturityContainer'>
      <div className='QuestionMaturityContainer-grid d-flex justify-content-between'>
        <div className='QuestionMaturityContainer-textLeft'>
          <h1 className='QuestionMaturityContainer-title'>
            {t('question_maturity.title')}
          </h1>
          <h1 className='QuestionMaturityContainer-textColor'>
            {t('question_maturity.alternatives_for')}
            <span className='QuestionMaturityObjective'>
              {' '.concat(
                objectives.find(item => item.id === selectedObjective.id)
                  .shortName
              )}
            </span>
          </h1>
        </div>
        <div className='QuestionMaturityContainer-countBox'>
          {maturities.length}
        </div>
      </div>
      {maturities.map(RenderQuestionsMaturity)}
      <QuestionProgressBar
        percentage={percentage}
        setPercentage={setPercentage}
      />
      <div className='d-flex justify-content-between align-items-center'>
        <div className='QuestionMaturityContainer-text'>
          {t('question.maturity.total_questions')}
        </div>
        <div className='QuestionMaturityContainer-countBox'>
          {questions.length}
        </div>
      </div>
      <div className='d-flex justify-content-between align-items-center'>
        <div className='QuestionMaturityContainer-text'>
          {t('question_maturity.total_alternatives')}
        </div>
        <div className='QuestionMaturityContainer-countBox'>
          {choicesArray.length}
        </div>
      </div>
    </div>
  )
}

export default QuestionMaturity
