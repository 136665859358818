import './ProgressBar.scss'

const ProgressBar = ({ completed }) => {
  const validValue = Math.min(100, Math.max(0, Math.round(completed)))

  const fillerStyles = {
    width: `${validValue}%`
  }

  return (
    <div className='ProgressBar'>
      <div className='ProgressBar-filler' style={fillerStyles} />
      <span className='ProgressBar-text'>{`${validValue}%`}</span>
    </div>
  )
}

export default ProgressBar
