import { getLoggedInUser } from 'auth'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  getNodeTypesByOrganizationId,
  toggleManageNodeTypes,
  toggleNodeTypesForm
} from 'redux/actions/nodeTypeActions'
import './ManageNodeTypes.scss'
import '../../styles/loader.scss'

import EditIcon from '@material-ui/icons/Edit'
const ManageNodeTypes = () => {
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const user = getLoggedInUser()
  const organizationId = user.organization_id
  const [search, setSearch] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [filteredNodeTypes, setFilteredNodeTypes] = useState([])
  const { nodeTypes } = useSelector(state => state.nodeType)
  const onChange = e => {
    setSearch(e.target.value)
  }

  useEffect(() => {
    const fetchData = async () => {
      await getNodeTypesByOrganizationId(organizationId, dispatch)
    }
    setIsLoading(true)
    fetchData()
    setIsLoading(false)
  }, [organizationId, dispatch])

  useEffect(() => {
    setFilteredNodeTypes(
      nodeTypes.filter(p =>
        p.name.toLowerCase().includes(search.toLowerCase().trim())
      )
    )
  }, [search, nodeTypes])

  const editNodeType = index => {
    toggleNodeTypesForm(
      { nodeTypeFormToggled: true, nodeType: filteredNodeTypes[index] },
      dispatch
    )
  }

  const createNodeType = () => {
    toggleNodeTypesForm({ nodeTypeFormToggled: true, nodeType: null }, dispatch)
  }

  const renderNodeTypes = () => {
    return filteredNodeTypes.map((nodeType, index) => (
      <div key={nodeType.id} className='ManageNodeTypes-tag'>
        <span className='nodeType-title'>{nodeType.name}</span>
        <span data-toggle='tooltip' title={t('node_type_manage.archive')}>
          <EditIcon
            className='launch-icon'
            onClick={() => editNodeType(index)}
          />
        </span>
      </div>
    ))
  }

  return (
    <div className='ManageNodeTypes animated fadeIn'>
      <div className='ManageNodeTypes-container'>
        <div className='ManageNodeTypes-header'>
          <p>{t('node_types_manage.header')}</p>
        </div>
        <div className='ManageNodeTypes-list'>
          <div className='ManageNodeTypes-input'>
            <input
              autoFocus
              type='text'
              maxLength={35}
              name='search'
              id='search'
              placeholder={t('label.search')}
              value={search}
              onChange={onChange}
              className='ManageNodeTypes-input'
            />
            {search.length > 0 && (
              <small className='blink-me'>
                <span className='foundTitle '>
                  {filteredNodeTypes.length}&nbsp;
                  {t('node_types_manage.found')}
                </span>
              </small>
            )}
          </div>

          <div className='ManageNodeTypes-list-content'>
            {isLoading && (
              <div className='d-flex flex-column justify-content-center align-items-center v100'>
                <div className='loader1' />
              </div>
            )}

            {!isLoading && renderNodeTypes()}
          </div>
        </div>
        <div className='ManageNodeTypes-button-group'>
          <button
            className='ManageNodeTypes-createButton'
            onClick={e => {
              e.stopPropagation()
              createNodeType()
            }}
          >
            {t('label.create')}
          </button>
          <button
            className='ManageNodeTypes-cancelButton'
            onClick={() => {
              toggleManageNodeTypes({ manageNodeTypesToggled: false }, dispatch)
            }}
          >
            {t('label.close')}
          </button>
        </div>
      </div>
    </div>
  )
}

export default ManageNodeTypes
