import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import classnames from 'classnames'

import { toggleChoice } from 'redux/actions/answerFormActions'

import './AnswerFormChoice.scss'

const AnswerFormChoice = ({ choice }) => {
  const dispatch = useDispatch()
  const { id, title } = choice
  const isSelected = useSelector(state => state.answerForm.answers[id] ?? false)

  const classes = classnames('AnswerFormChoice', {
    'AnswerFormChoice--selected': isSelected
  })

  const inputId = `choice-input-${id}`
  const onChange = () => toggleChoice(id, dispatch)

  return (
    <div className={classes}>
      <input
        type='checkbox'
        id={inputId}
        value={isSelected}
        checked={isSelected}
        onChange={onChange}
      />
      <label htmlFor={inputId}>{title}</label>
    </div>
  )
}

export default AnswerFormChoice
