import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'

import { isAuthenticated, getLoggedInUser } from 'auth'

import { HomePage, NavigationBar, NotFound } from 'components'
import AnswerFormPage from 'components/pages/AnswerFormPage/AnswerFormPage'
import DashboardPage from 'components/pages/DashboardPage/DashboardPage'
import TenantUserManagementPage from 'components/pages/TenantUserManagementPage/TenantUserManagementPage'
import TenantTemplateManagementPage from 'components/pages/TenantTemplateManagementPage/TenantTemplateManagementPage'
import Master from 'components/Master/Master'
import ForgotPasswordPage from 'components/pages/ForgotPasswordPage/ForgotPasswordPage'
import LoginPage from 'components/pages/LoginPage/LoginPage'
import SignUpPage from 'components/pages/SignUpPage/SignUpPage'
import ResetPasswordPage from 'components/pages/ResetPasswordPage/ResetPasswordPage'
import AccountFormPage from 'components/pages/AccountFormPage/AccountFormPage'

const isAcessAuthorized = role => {
  const userRole = getLoggedInUser().role

  switch (role) {
    case 'user':
      return true
    case 'admin':
      if (userRole === 'user') {
        return false
      }
      return true
    case 'super':
      if (userRole === 'super' || userRole === 'master') {
        return true
      }
      return false
    case 'master':
      if (userRole === 'master') {
        return true
      }
      return false
    default:
      return false
  }
}

const PrivateRoute = ({ component: Component, role: Role, ...rest }) => {
  if (!Role || !Component) throw new Error('Invalid parameters for role')

  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated() ? (
          isAcessAuthorized(Role) ? (
            <Component {...props} />
          ) : (
            <div
              style={{ color: 'red', height: '600px' }}
              className='d-flex animated fadeInDown mt-4 justify-content-center align-items-center'
            >
              <h1>User not authorized for the requested route</h1>
            </div>
          )
        ) : (
          <Redirect
            to={{ pathname: '/login', state: { from: props.location } }}
          />
        )
      }
    />
  )
}

const UnauthenticatedRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated() && rest.path !== '/signup' ? (
        <Redirect
          to={{ pathname: '/dashboard', state: { from: props.location } }}
        />
      ) : (
        <Component {...props} />
      )
    }
  />
)

const Router = ({ children }) => {
  return (
    <BrowserRouter>
      <NavigationBar />
      {children}
      <Switch>
        <UnauthenticatedRoute path='/login' component={LoginPage} />
        <UnauthenticatedRoute path='/signup' component={SignUpPage} />
        <UnauthenticatedRoute
          path='/reset-password'
          component={ResetPasswordPage}
        />
        <UnauthenticatedRoute
          path='/forgot-password'
          component={ForgotPasswordPage}
        />

        <PrivateRoute role='user' path='/' exact component={HomePage} />
        <PrivateRoute
          role='user'
          path='/user/edit'
          component={AccountFormPage}
        />
        <PrivateRoute role='user' path='/dashboard' component={DashboardPage} />
        <PrivateRoute
          role='super'
          path='/user-management'
          component={TenantUserManagementPage}
        />
        <PrivateRoute
          role='super'
          path='/template-management'
          component={TenantTemplateManagementPage}
        />
        <PrivateRoute role='master' path='/master' component={Master} />
        <PrivateRoute role='user' path='/form' component={AnswerFormPage} />

        <Route path='*' component={NotFound} />
      </Switch>
    </BrowserRouter>
  )
}

export default Router
