import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'lodash'

import AnswerFormPage from 'components/AnswerForm/AnswerFormPage/AnswerFormPage'
import AnswerFormWelcomePage from 'components/AnswerForm/AnswerFormWelcomePage/AnswerFormWelcomePage'
import AnswerFormCompletePage from 'components/AnswerForm/AnswerFormCompletePage/AnswerFormCompletePage'
import Loading from 'components/Loading/Loading'
import Button from 'components/Button/Button'
import ProgressBar from 'components/ProgressBar/ProgressBar'

import {
  loadForm,
  setLoading,
  moveToNextPage,
  moveToPreviousPage,
  submitAnswers,
  getAssessmentWithNode
} from 'redux/actions/answerFormActions'

import './AnswerForm.scss'
import { getLoggedInUser } from 'auth'

const AnswerForm = ({ userId, assessmentId }) => {
  const [t] = useTranslation()
  const user = getLoggedInUser()

  const dispatch = useDispatch()
  const answerForm = useSelector(state => state.answerForm)

  const [assessment, setAssessment] = useState(null)

  const { isLoading, errors } = answerForm

  useEffect(() => {
    if (!userId || !assessmentId) {
      setLoading(false, dispatch)
      return
    }
    const getAsessment = async () => {
      const data = await getAssessmentWithNode(assessmentId)
      setAssessment(data)
    }
    getAsessment()
    loadForm(userId, assessmentId, dispatch)
  }, [userId, assessmentId, dispatch])

  // Move scroll to top and remove focus when current page changes
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    document.activeElement.blur()
  }, [answerForm.currentPage])

  const isUserIdValid = !!userId || userId !== user.id
  const isAssessmentIdValid = !!assessmentId
  const hasErrors =
    !isUserIdValid || !isAssessmentIdValid || (errors && !isEmpty(errors))

  const renderLoading = () => (
    <div className='AnswerForm-loading'>
      <Loading />
    </div>
  )

  const renderErrors = () => (
    <div className='AnswerForm-errors'>
      <h2>{t('label.attention')}</h2>
      <hr />
      <ul>
        {isUserIdValid ? null : <li>{t('answer_form.user_invalid')}</li>}
        {isAssessmentIdValid ? null : (
          <li>{t('answer_form.assessment_invalid')}</li>
        )}
        {errors.map((error, index) => (
          <li key={index}>{t(error)}</li>
        ))}
      </ul>
    </div>
  )

  const renderContent = () => {
    const { isComplete, currentPage } = answerForm

    const shouldRenderCompletePage = isComplete
    const shouldRenderWelcomePage = !hasErrors && currentPage === 0
    const shouldRenderFooter = !isComplete

    return (
      <div className='AnswerForm-content'>
        {shouldRenderCompletePage ? (
          <AnswerFormCompletePage userId={userId} assessmentId={assessmentId} />
        ) : shouldRenderWelcomePage ? (
          <AnswerFormWelcomePage />
        ) : (
          renderBody()
        )}
        {shouldRenderFooter ? renderFooter() : null}
      </div>
    )
  }

  const renderBody = () => {
    const { areas } = answerForm.assessment
    if (isEmpty(areas)) {
      return null
    }

    const area = areas[answerForm.currentPage - 1]
    if (!area) {
      return null
    }

    return (
      <div className='AnswerForm-body'>
        <AnswerFormPage area={area} />
      </div>
    )
  }

  const renderFooter = () => {
    const { currentPage, assessment } = answerForm
    if (isEmpty(assessment.areas)) return null

    const totalPages = assessment.areas.length + 1
    const isFirstPage = currentPage === 0
    const isLastPage = currentPage + 1 === totalPages

    const currentProgress = (currentPage / totalPages) * 100

    return (
      <div className='AnswerForm-footer'>
        <div className='AnswerForm-cta'>
          {isFirstPage ? null : (
            <Button
              title={t('label.back')}
              onClick={() => moveToPreviousPage(dispatch)}
            />
          )}
          {isLastPage ? (
            <Button
              title={t('label.send')}
              isPrimary
              onClick={() => dispatch(submitAnswers(userId, assessmentId))}
            />
          ) : (
            <Button
              title={t('label.next')}
              isPrimary
              onClick={() => moveToNextPage(dispatch)}
            />
          )}
        </div>

        <div className='AnswerForm-progress'>
          <p>
            {t('answer_form.page_progress', {
              currentPage: currentPage + 1,
              totalPages
            })}
          </p>
          <ProgressBar completed={currentProgress} />
        </div>
      </div>
    )
  }

  const renderPeriod = () => (
    <span className='AnswerForm-assessment-date-dates'>
      {`${
        new Date(answerForm.assessment.startDate).toISOString().split('T')[0]
      } to ${
        new Date(answerForm.assessment.endDate).toISOString().split('T')[0]
      }`}
    </span>
  )

  return (
    <div className='AnswerForm z-depth-3'>
      <div className='AnswerForm-header'>
        <div className='AnswerForm-header-left'>
          <h1>{t('answer_form.title')}</h1>
          {assessment && (
            <div>
              <span className='AnswerForm-assessment-node'>{`${assessment.nodeType.name} - ${assessment.node.name}`}</span>
            </div>
          )}
          <span className='AnswerForm-assessment-name'>
            {answerForm.assessment.name}
          </span>
        </div>
        <div className='AnswerForm-header-right'>
          <span className='AnswerForm-assessment-date'>
            {t('answer_form.period')}
          </span>
          {answerForm.assessment.startDate &&
            answerForm.assessment.endDate &&
            renderPeriod()}
        </div>
      </div>

      {hasErrors ? renderErrors() : null}
      {isLoading ? renderLoading() : renderContent()}
    </div>
  )
}

export default AnswerForm
